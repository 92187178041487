import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { LOGIN_ROUTE, ROOT_URL, routes } from '../constatnts/routerUrls';
import PrivateRoute from './PrivateRoute';
import { BrowserRouter } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import Sidebar from '../components/Sidebar';
import TopNavbar from '../components/TopNavbar';
import { useSelector } from 'react-redux';

const RootRoute = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const layoutStyle =
    isLoggedIn === true
      ? {
          width: 'calc(100% - 80px)',
          boxSizing: 'border-box',
          position: 'absolute',
          top: '70px',
          left: '80px',
        }
      : {
          width: '100%',
          boxSizing: 'border-box',
        };
  return (
    <BrowserRouter>
      <Sidebar />
      <TopNavbar />
      <Box __css={layoutStyle}>
        <Switch>
          <Route exact path={ROOT_URL} render={() => <Redirect to={LOGIN_ROUTE} />} />
          {routes.map((item, key) =>
            item.protected ? (
              <PrivateRoute exact path={item.path} component={item.comp} key={key} />
            ) : (
              <Route exact path={item.path} component={item.comp} key={key} />
            ),
          )}
        </Switch>
      </Box>
    </BrowserRouter>
  );
};

export default RootRoute;
