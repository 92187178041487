import axios from './axios';

export const fetchAllAdminNotification = async () => {
  try {
    const response = await axios.get('/notification');
    return response.data.notifications;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const markNotificationAsRead = async (notifId) => {
  try {
    const response = await axios.patch('/notification/read/one', { notifId });
    return response.status;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const markAllNotificationAsRead = async (notifIds) => {
  try {
    const resposne = await axios.patch('/notification/read/all', { notifIds });

    return resposne.status;
  } catch (error) {
    console.log(error);
    return null;
  }
};
