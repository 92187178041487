import { CheckIcon, TriangleUpIcon } from '@chakra-ui/icons';
import {
  Badge,
  Box,
  Center,
  CircularProgress,
  CircularProgressLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  IconButton,
  Spinner,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast,
  VStack,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  increaseBatchPriority,
  updateBatchAsDelivered,
  updateOrderAsDelivered,
} from '../../api/batch';
import { fetchAllFabric, getAllFcWithBatchInfo } from '../../api/fcInfo';
import AlertBox from '../../components/common/AlertBox';
import { ORDER_REFRESH_TIMEOUT } from '../../constatnts/time';
import toastFormat from '../../constatnts/toastFormat';
import { mapStatusToColor } from '../../utilities/mapStatusToColor';

const OrderDetail = () => {
  const { fcNumber } = useParams();
  const [order, setOrder] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [alertSeubTitle, setAlertsubTitle] = useState('');
  const [showPriorityIncreaseAlert, setPriorityIncreaseAlert] = useState(false);
  const [updatePriorityOn, setUpdatePriorityOn] = useState();
  const [showMarkOrderCompleteModal, setShowMarkOrderComplete] = useState(false);
  const [allFabrics, setAllFabrics] = useState([]);

  const toast = useToast();

  const fetchData = async () => {
    setIsLoading(true);
    const orderData = await getAllFcWithBatchInfo({ searchKey: fcNumber });
    const fabrics = await fetchAllFabric(fcNumber);
    setAllFabrics(fabrics);
    setOrder(orderData.data[0]);
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();

    const interval = setInterval(async () => {
      await fetchData();
    }, ORDER_REFRESH_TIMEOUT);

    return () => clearInterval(interval);
  }, []);

  const getColorCode = (value) => {
    if (value <= 20) {
      return 'red.400';
    } else if (value > 20 && value <= 60) {
      return 'yellow.400';
    } else {
      return 'green.400';
    }
  };

  const getNextPriority = (priority) => {
    if (priority === 'LOW') {
      return 'MEDIUM';
    } else if (priority === 'MEDIUM') {
      return 'HIGH';
    }
  };

  const handlePrirotyIncrease = async () => {
    const response = await increaseBatchPriority(
      getNextPriority(updatePriorityOn.priority),
      updatePriorityOn.lotNumber,
    );
    if (response === 200) {
      await fetchData();
      toast({
        title: 'Priority Increased',
        status: 'success',
        ...toastFormat,
      });
    } else {
      toast({
        title: 'Failed to increase priority',
        status: 'error',
        ...toastFormat,
      });
    }

    setPriorityIncreaseAlert(false);
  };

  const isAllFabricProcessed = () => {
    let allFabricProcessed = totalFabricProcessed === 100 ? true : false;

    return allFabricProcessed;
  };

  useEffect(() => {
    if (isAllFabricProcessed()) {
      setShowMarkOrderComplete(true);
    } else {
      setShowMarkOrderComplete(false);
    }
  }, [order]);

  const markSelectedBatchDelivered = async (lotNumber) => {
    const status = await updateBatchAsDelivered(lotNumber);
    if (status === 200) {
      await fetchData();

      toast({
        title: 'Batches delivered',
        status: 'success',
        ...toastFormat,
      });
    } else {
      toast({
        title: 'Failed to deliver batches',
        status: 'error',
        ...toastFormat,
      });
    }
  };

  const markAllBatchAsDelivered = async () => {
    const lots = order?.batchInfo?.map((item) => item.lotNumber);

    const status = await updateOrderAsDelivered(lots, order.fcNumber);
    if (status === 200) {
      await fetchData();

      toast({
        title: 'Order delivered',
        status: 'success',
        ...toastFormat,
      });
    } else {
      toast({
        title: 'Failed to deliver order',
        status: 'error',
        ...toastFormat,
      });
    }
  };

  const calculateTotalFabricQuantity = () => {
    let total = 0;

    order?.fabricInfo?.map((fabric) => (total += fabric.quantity));
    return total;
  };

  const totalFabricProcessed = () => {
    const totalFabric = calculateTotalFabricQuantity();
    let totalProcessed = 0;

    order?.fabricInfo?.map((fabric) => {
      totalProcessed += fabric.processed;
    });

    return Math.floor((totalProcessed / totalFabric) * 100);
  };

  const calculateProcessingQuantity = (batches, quantity) => {
    let totalInUse = 0;
    batches.map((batch) => {
      if (batch.status === 'DELIVERED') {
        totalInUse += +batch.processingQuantity;
      }
    });

    return Math.floor((totalInUse / quantity) * 100);
  };

  const calculateUningQty = (batches) => {
    let totalInUse = 0;
    batches.map((batch) => {
      if (batch.status !== 'REJECTED') {
        totalInUse += +batch.processingQuantity;
      }
    });

    return totalInUse;
  };

  const handlePriorityChange = (value) => {
    setAlertsubTitle(`Do you want to set priority to ${getNextPriority(value.priority)}`);
    setPriorityIncreaseAlert(true);
    setUpdatePriorityOn(value);
  };
  if (isLoading) {
    return (
      <Box w="100%" h="100vh">
        <Center>
          <Spinner size="lg" />
        </Center>
      </Box>
    );
  } else {
    return (
      <>
        <AlertBox
          title="Mark order as complete"
          subTitle="All the batches for this order is complete. Do you want to mark this order as delivered?"
          show={showMarkOrderCompleteModal}
          onClose={() => setShowMarkOrderComplete(false)}
          onSubmit={markAllBatchAsDelivered}
        />
        <AlertBox
          show={showPriorityIncreaseAlert}
          title="Increase Batch Priority"
          subTitle={alertSeubTitle}
          onSubmit={handlePrirotyIncrease}
          onClose={() => setPriorityIncreaseAlert(false)}
        />
        <Box w="100%" boxSizing="border-box" padding="15px">
          <Grid
            gridTemplateColumns={{
              base: '1fr',
              md: '1fr',
              lg: '1fr 1fr',
            }}
            gap="15px">
            <GridItem
              boxSizing="border-box"
              p="15px"
              border="1px solid rgba(0,0,0,0.2)"
              rounded="md">
              <Text fontSize="lg" fontWeight="bold" mb="15px">
                Order Information
              </Text>
              <Grid
                w="100%"
                gridTemplateColumns={{
                  base: 'repeat(2, 1fr)',
                  md: 'repeat(2, 1fr)',
                  lg: 'repeat(4, 1fr)',
                }}
                gap="20px">
                <GridItem>
                  <Heading size="xl">#{order?.fcNumber}</Heading>
                </GridItem>
                <GridItem>
                  <Badge>Order Status</Badge>
                  <Text mt="10px">{order?.orderStatus}</Text>
                </GridItem>
                <GridItem>
                  <Tooltip label={order?.partyName} placement="top">
                    <Box>
                      <Badge>Party Name</Badge>
                      <Text mt="10px">{order?.partyName?.substring(0, 14)}...</Text>
                    </Box>
                  </Tooltip>
                </GridItem>
                <GridItem>
                  <Badge>Batch Assigned</Badge>
                  <Text mt="10x" fontWeight="bold">
                    {order?.batchInfo ? order?.batchInfo?.length : 'No batch assiigned'}
                  </Text>
                </GridItem>
                <GridItem>
                  <Badge>Client Ref</Badge>
                  <Text mt="10px">{order?.clientRefNo}</Text>
                </GridItem>
                <GridItem>
                  <Badge>Client&apos;s Contact</Badge>
                  <Text mt="10px">{order?.clientContactPerson}</Text>
                </GridItem>
                <GridItem>
                  <Badge>Sales Person</Badge>
                  <Text mt="10px">{order?.salesPerson}</Text>
                </GridItem>
                <GridItem>
                  <Badge>Delivery Date</Badge>
                  <Text mt="10px">{dayjs(order?.deliveryDate).format('DD, MMM, YYYY')}</Text>
                </GridItem>
                <GridItem>
                  <Badge>Client Address</Badge>
                  <Text mt="10px">{order?.clientAddress}</Text>
                </GridItem>
                <GridItem>
                  <Badge>Remarks</Badge>
                  <Text mt="10px">{order?.remarks ? order?.remarks : 'No remarks'}</Text>
                </GridItem>
              </Grid>
            </GridItem>
            <GridItem
              boxSizing="border-box"
              p="15px"
              border="1px solid rgba(0,0,0,0.2)"
              rounded="md">
              <Text fontSize="lg" fontWeight="bold">
                Fabric Summary
              </Text>
              <Box maxH="150px" overflow="auto" mt="15px">
                <Table size="sm">
                  <Thead bgColor="white" position="sticky" top="0" p="15px">
                    <Tr>
                      <Th>Shade Group</Th>
                      <Th>Color</Th>
                      <Th>Shade %</Th>
                      <Th>Fabric Type</Th>
                      <Th>Quantity (KG)</Th>
                      <Th>Processed (KG)</Th>
                      <Th>GSM</Th>
                      <Th>DIA</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {order?.fabricInfo?.map((fabric) => (
                      <Tr key={JSON.stringify(fabric)}>
                        <Td>{fabric.shadeGroup}</Td>
                        <Td>{fabric.color}</Td>
                        <Td>{fabric.shade}</Td>
                        <Td>{fabric.fabricType}</Td>
                        <Td>{fabric.quantity}</Td>
                        <Td>{fabric.processed}</Td>
                        <Td>{fabric.gsm}</Td>
                        <Td>{fabric.dia}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
              <HStack mt="15px" w="100%" justify="flex-end" spacing={15}>
                <Text fontSize="sm" fontWeight="bold">
                  Total Quantity: {calculateTotalFabricQuantity()}KG
                </Text>
                <Text fontSize="sm" fontWeight="bold">
                  Fabric Processed: {totalFabricProcessed()}%
                </Text>
              </HStack>
            </GridItem>
          </Grid>

          <HStack>
            <Heading my="20px" fontSize="2xl">
              Fabrics
            </Heading>
          </HStack>

          <Grid
            gridTemplateColumns={{
              base: '1fr',
              md: '1fr 1fr',
              lg: 'repeat(3, 1fr)',
              xl: 'repeat(3, 1fr)',
            }}
            gap="10px">
            {allFabrics.map((fabric) => (
              <GridItem
                position="relative"
                border="1px solid rgba(0,0,0,0.2)"
                rounded="lg"
                boxSizing="border-box"
                padding="10px"
                key={fabric.id}>
                <HStack w="100%" justify="space-between" align="flex-start">
                  <VStack align="right">
                    <Heading fontSize="xl">
                      {fabric.fabricType}{' '}
                      <Tag ml="5px" colorScheme={mapStatusToColor(fabric.status)}>
                        {fabric.status}
                      </Tag>
                    </Heading>
                    <Text mt="10px">
                      {fabric.color} - {fabric.shade}%
                    </Text>
                    <Text fontSize="sm" mt="5px">
                      Shade Group: {fabric.shadeGroup}
                    </Text>
                    <Text fontSize="sm" mt="5px">
                      GSM: {fabric.gsm}
                    </Text>
                    <Text fontSize="sm" mt="5px">
                      DIA: {fabric.dia}
                    </Text>
                  </VStack>
                  <CircularProgress
                    size="80px"
                    color={getColorCode(
                      calculateProcessingQuantity(fabric.batchInfo, fabric.quantity),
                    )}
                    value={calculateProcessingQuantity(fabric.batchInfo, fabric.quantity)}>
                    <CircularProgressLabel>
                      {calculateProcessingQuantity(fabric.batchInfo, fabric.quantity)}%
                    </CircularProgressLabel>
                  </CircularProgress>
                </HStack>
                <HStack my="10px" w="100%" justify="space-between" align="start">
                  <Text size="sm" fontWeight="bold">
                    Total Quantity <br />
                    {fabric.quantity} KG
                  </Text>
                  <Text size="sm" fontWeight="bold">
                    Processing <br />
                    {calculateUningQty(fabric.batchInfo)} KG
                  </Text>
                  <Text size="sm" fontWeight="bold">
                    Remaining <br /> {fabric.quantity - calculateUningQty(fabric.batchInfo)} KG
                  </Text>
                </HStack>
                {fabric?.batchInfo?.length > 0 ? (
                  <Box minH="150px" overflow="auto">
                    <Table size="sm" my="15px">
                      <Thead bgColor="white" zIndex={100} position="sticky" top="0">
                        <Th>Lot#</Th>
                        <Th>Qty (KG)</Th>
                        <Th>Status</Th>
                        <Th>#</Th>
                      </Thead>
                      <Tbody>
                        {fabric.batchInfo?.map((item) => (
                          <Tr key={item.lotNumber}>
                            <Td>{item.lotNumber.split('-')[1]}</Td>
                            <Td>{item.processingQuantity}</Td>
                            <Td>
                              <Tooltip label={item.status}>{item.status.substring(0, 8)}</Tooltip>
                            </Td>
                            <Td>
                              <HStack>
                                {item.isComplete && item.status !== 'DELIVERED' && (
                                  <Tooltip label="Mark as delivered">
                                    <IconButton
                                      size="xs"
                                      colorScheme="facebook"
                                      icon={<CheckIcon />}
                                      onClick={async () => {
                                        await markSelectedBatchDelivered(item.lotNumber);
                                      }}
                                    />
                                  </Tooltip>
                                )}
                                {item.priority !== 'HIGH' && (
                                  <Tooltip label="Increase priority">
                                    <IconButton
                                      size="xs"
                                      colorScheme="facebook"
                                      icon={<TriangleUpIcon />}
                                      onClick={() => handlePriorityChange(item)}
                                    />
                                  </Tooltip>
                                )}
                              </HStack>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </Box>
                ) : (
                  <Center mt="30px">
                    <Text>Not assigned to any batch yet</Text>
                  </Center>
                )}
              </GridItem>
            ))}
          </Grid>
        </Box>
      </>
    );
  }
};

export default OrderDetail;
