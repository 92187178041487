import React, { useState } from 'react';
import {
  Box,
  Center,
  Grid,
  GridItem,
  Heading,
  HStack,
  IconButton,
  Input,
  Spinner,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { clientStats, fetchAllClients } from '../../api/clients';
import { useHistory } from 'react-router-dom';
import { CLIENTS } from '../../constatnts/routerUrls';
import { InfoIcon } from '@chakra-ui/icons';
import RefreshIco from '../../components/RefreshIco';

const ClientList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [clients, setClients] = useState();
  const history = useHistory();
  const [stats, setStats] = useState([]);

  const fetchData = async () => {
    setIsLoading(true);
    const clientData = await fetchAllClients();
    const clientStatsData = await clientStats();
    setStats(clientStatsData);
    setClients(clientData);
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => await fetchData())();
  }, []);

  const handleSearch = (e) => {
    const value = e.target.value;

    if (value.length > 3) {
      const searchedItem = clients?.filter(
        (item) =>
          item.clientName.toUpperCase().includes(value.toUpperCase()) ||
          item.clientName.toUpperCase().startsWith(value.toUpperCase()) ||
          item.clientName.toUpperCase().endsWith(value.toUpperCase()),
      );

      setClients(searchedItem);
    }
  };
  return (
    <Box w="100%" boxSizing="border-box" p="15px">
      <Heading>Clients</Heading>
      <Grid gridTemplateColumns="repeat(4, 1fr)" gap="5px" w="550px" my="15px">
        {stats &&
          stats.map((item) => (
            <GridItem
              key={item.label}
              w="100%"
              padding="10px"
              rounded="lg"
              bgColor="white"
              border="1px solid rgba(0,0,0,0.2)"
            >
              <Text fontSize="xs" fontWeight="bold" color="gray.600">
                {item.label}
              </Text>
              <Text fontSize="2xl" fontWeight="bold" color={item.color}>
                {item.count}
              </Text>
            </GridItem>
          ))}
      </Grid>
      <HStack mb="25px" w={{ base: '100%', md: '400px', lg: '400px' }}>
        <Input placeholder="Search" onChange={(e) => handleSearch(e)} w="250px" />
        <Tooltip label="Reset" placement="top">
          <IconButton colorScheme="facebook" icon={<RefreshIco />} onClick={fetchData} />
        </Tooltip>
      </HStack>
      {isLoading ? (
        <Center>
          <Spinner size="lg" />
        </Center>
      ) : (
        <Grid
          gridTemplateColumns={{
            base: '1fr',
            md: 'repeat(4, 1fr)',
            lg: 'repeat(8, 1fr)',
          }}
          gap="20px"
        >
          {clients ? (
            clients.map((client) => (
              <Tooltip key={client.uid} label="View details">
                <GridItem
                  cursor="pointer"
                  border="1px solid rgba(0,0,0,0.2)"
                  rounded="md"
                  boxSizing="border-box"
                  padding="10px"
                  onClick={() => history.push(`${CLIENTS}/${client.uid}`)}
                >
                  <HStack w="100%" justify="space-between" align="start">
                    <Text fontSize="lg" fontWeight="bold">
                      {client.clientName}
                    </Text>
                    <Text fontSize="4xl" fontWeight="bold">
                      {client.orderCount}
                    </Text>
                  </HStack>
                </GridItem>
              </Tooltip>
            ))
          ) : (
            <GridItem
              boxSizing="border-box"
              padding="10px"
              rounded="md"
              w="100%"
              h="150px"
              border="1px solid rgba(0,0,0,0.2)"
            >
              <Center w="full" h="full">
                <VStack>
                  <InfoIcon />
                  <Text>No Client Found</Text>
                </VStack>
              </Center>
            </GridItem>
          )}
        </Grid>
      )}
    </Box>
  );
};

export default ClientList;
