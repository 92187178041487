import { ExternalLinkIcon, TimeIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  HStack,
  IconButton,
  Progress,
  StatUpArrow,
  Tag,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BATCHES } from '../../constatnts/routerUrls';
import { mapStatusToColor } from '../../utilities/mapStatusToColor';
import msToTime from '../../utilities/msToTime';

const Card = ({ batch }) => {
  const history = useHistory();

  const [isDelayed, setIsDelayed] = useState(false);

  // const combineDateTimeFormated = (date, time) => {
  //   const loadingDateTime = new Date(
  //     date.substring(0, 4),
  //     date.substring(5, 7) - 1,
  //     date.substring(8, 10),
  //     time.substring(0, 2),
  //     time.substring(3, 5),
  //   );

  //   return formatTime(loadingDateTime);
  // };

  const combineDateTime = (date, time) => {
    const loadingDateTime = new Date(
      date.substring(0, 4),
      date.substring(5, 7) - 1,
      date.substring(8, 10),
      time.substring(0, 2),
      time.substring(3, 5),
    );

    return loadingDateTime;
  };
  const formatTime = (time) => dayjs(time).format('DD, MMM - hh:mm A');
  const MotionBox = motion(Box);

  useEffect(() => {
    const currentTime = new Date();
    const estCompleteTime = new Date(batch.currentTask.estCompleteTime);

    if (currentTime > estCompleteTime) {
      setIsDelayed(true);
    } else if (
      batch.isBatchStarted === false &&
      currentTime > combineDateTime(batch.loadDate, batch.loadTime)
    ) {
      setIsDelayed(true);
    } else {
      setIsDelayed(false);
    }
  }, []);

  const variants = {
    initial: { backgroundColor: ['#ffffff'] },
    animate: {
      backgroundColor: ['#ffffff', '#ffe6e6'],
    },
    live: {
      backgroundColor: ['#d9f3e2', '#d9f3e2'],
    },
  };

  const overallTime = () => {
    const statTime = new Date(batch.startTime);
    const currentTime = new Date();
    const duration = currentTime - statTime;

    return msToTime(duration);
  };
  return (
    <Tooltip label="Click to view details" placement="top">
      <MotionBox
        w="100%"
        boxSizing="border-box"
        p="10px"
        rounded="md"
        border="1px solid rgba(0,0,0,0.2)"
        variants={variants}
        initial="initial"
        animate={isDelayed ? 'animate' : 'live'}
        cursor="pointer"
        transition={{
          duration: 5,
          ease: 'easeInOut',
          times: [0, 0.2, 0.5, 0.8, 1],
          repeat: Infinity,
          repeatDelay: 1,
        }}>
        <HStack justify="space-between" w="100%">
          <HStack>
            <Text fontWeight="bold" color="gray.600" fontSize="lg">
              #{batch.lotNumber.split('-')[1]} - {batch.machineName}
            </Text>
            <IconButton
              size="xs"
              icon={<ExternalLinkIcon />}
              colorScheme="facebook"
              onClick={() => history.push(BATCHES + `/${batch.lotNumber}`)}
            />
          </HStack>
          <Text fontWeight="bold" color="gray.500" fontSize="sm">
            <TimeIcon mr="5px" /> {overallTime()}
          </Text>
        </HStack>

        <Text fontSize="sm" fontWeight="bold" color="gray.600">
          {batch.partyName}
        </Text>
        <HStack mt="10px">
          <Tag colorScheme={mapStatusToColor(batch.batchStatus)}>{batch.batchStatus}</Tag>
          <Tag colorScheme={mapStatusToColor(batch.priority)}>
            <StatUpArrow color="black" mr="5px" /> {batch.priority}
          </Tag>
          <Tag colorScheme="orange">Delay: {batch.extraTime}</Tag>
        </HStack>
        <Tag mt="10px">{batch.batchType}</Tag>
        <HStack justify="space-between" w="100%" mt="10px">
          <Text fontWeight="bold" color="gray.700" fontSize="xs">
            {batch.progress}% Complete
          </Text>
          <Text fontWeight="bold" color="gray.600" fontSize="xs">
            {batch.batchStatus === 'STARTED'
              ? `Est. End Time: ${formatTime(batch.estCompletion)}`
              : `Loading Time: ${formatTime(batch.startTime)}`}
          </Text>
        </HStack>
        <Progress value={batch.progress} rounded="md" />
        <Accordion allowToggle mt="5px">
          <AccordionItem border="none">
            <AccordionButton _focus={{ outline: 'none' }} rounded="md" p="0">
              <Flex w="100%" justify="space-between">
                <Tag>Details</Tag>{' '}
                <Text fontSize="12px" fontWeight="bold" color="gray.600">
                  {batch.estFinishTime}
                </Text>
                <AccordionIcon />
              </Flex>
            </AccordionButton>

            <AccordionPanel>
              <HStack mt="20px" w="100%" justify="space-between">
                <VStack align="flex-start">
                  <Text fontSize="sm" fontWeight="bold" color="gray.600">
                    Current Task
                  </Text>
                  <Text fontSize="lg" color="gray.800">
                    {batch.currentTask.taskName}
                  </Text>
                  {batch.isBatchStarted ? (
                    <Text fontSize="xs" fontWeight="medium" color="gray.600">
                      Est. End Time <br />
                      {formatTime(batch.currentTask.estCompleteTime)}
                    </Text>
                  ) : (
                    <Text fontSize="xs" fontWeight="medium" color="gray.600">
                      Batch not started
                    </Text>
                  )}
                </VStack>
                <VStack align="flex-start">
                  <Text fontSize="sm" fontWeight="bold" color="gray.600">
                    Next Task
                  </Text>
                  {batch.nextTask ? (
                    <>
                      <Text fontSize="lg" color="gray.800">
                        {batch.nextTask.taskName}
                      </Text>
                      {batch.isBatchStarted ? (
                        <Text fontSize="xs" fontWeight="medium" color="gray.600">
                          Est. Start <br /> {formatTime(batch.nextTask.estStartTime)}
                        </Text>
                      ) : (
                        <Text fontSize="xs" fontWeight="medium" color="gray.600">
                          Batch not started
                        </Text>
                      )}
                    </>
                  ) : (
                    <Text fontSize="lg" color="gray.800">
                      No more task
                    </Text>
                  )}
                </VStack>
                <VStack align="flex-start">
                  <Text fontSize="sm" fontWeight="bold" color="gray.600">
                    Machine
                  </Text>
                  <Text fontSize="sm" color="gray.800">
                    {batch.machineName}
                  </Text>
                  <Text fontSize="sm" fontWeight="bold" color="gray.600">
                    Party
                  </Text>
                  <Tooltip label={batch.partyName}>
                    <Text fontSize="xs" color="gray.800">
                      {batch.partyName?.substring(0, 6)}...
                    </Text>
                  </Tooltip>
                </VStack>
              </HStack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </MotionBox>
    </Tooltip>
  );
};

export default Card;
