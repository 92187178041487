import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Heading, Flex, VStack, HStack, Text } from '@chakra-ui/layout';
import { Image, Spinner } from '@chakra-ui/react';
import { Button, IconButton } from '@chakra-ui/button';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { Tooltip } from '@chakra-ui/tooltip';
import CustomTable2 from '../../components/common/CustomTable2';
import AlertBox from '../../components/common/AlertBox';
import { useDisclosure } from '@chakra-ui/hooks';
import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import {
  getAllChemicals,
  getAllDyeGroups,
  addNewChemical,
  addNewDyeGroup,
  deleteChemical,
  deleteDyeGroup,
  getAllDyes,
  deleteDye,
  addNewDye,
} from '../../api/chemicals';
import { useDispatch, useSelector } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import { GET_ALL_CHEMICALS, GET_ALL_DYE_GROUPS } from '../../store/actions/action-types';
import EMPTYTABLE from '../../assets/images/empty-table.svg';
import toastFormat from '../../constatnts/toastFormat';

const AddNewChemical = ({ onClose, refreshCallback }) => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();
  const toast = useToast();
  const [showAlert, setShowAlert] = useState(false);

  const submit = async (data) => {
    try {
      const { status, msg } = await addNewChemical(data);
      if (status === 200) {
        refreshCallback();
        onClose();
        toast({
          title: msg,
          status: 'success',
          ...toastFormat,
        });
      }
    } catch (error) {
      toast({
        title: 'Failed to add new chemical!',
        status: 'error',
        ...toastFormat,
      });
    }
  };

  return (
    <Box>
      <AlertBox
        onSubmit={() => handleSubmit(submit)()}
        onClose={() => setShowAlert(false)}
        show={showAlert}
        title="Add new Chemical"
        subTitle="Are you sure? You want to add new chemical?"
      />
      <Flex as="form" width="100%" direction="column" align="center" justify="center">
        <FormControl my="10px" width="100%" isRequired={true} isTruncated={true}>
          <FormLabel>Name</FormLabel>
          <Input {...register('chemicalName')} required placeholder="Chemical Name" width="300px" />
        </FormControl>
        <Button
          isLoading={isSubmitting}
          colorScheme="facebook"
          size="sm"
          width="300px"
          onClick={() => setShowAlert(true)}
        >
          ADD NEW
        </Button>
      </Flex>
    </Box>
  );
};

const AddNewDyeGroup = ({ onClose, refreshCallback }) => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();
  const toast = useToast();
  const [showAlert, setShowAlert] = useState(false);

  const submit = async (data) => {
    try {
      const { status, msg } = await addNewDyeGroup(data);
      if (status === 200) {
        refreshCallback();
        onClose();
        toast({
          title: msg,
          status: 'success',
          ...toastFormat,
        });
      }
    } catch (error) {
      toast({
        title: 'Failed to add new dye group!',
        status: 'error',
        ...toastFormat,
      });
    }
  };

  return (
    <Box>
      <AlertBox
        onSubmit={() => handleSubmit(submit)()}
        onClose={() => setShowAlert(false)}
        show={showAlert}
        title="Add new Dye Group"
        subTitle="Are you sure? You want to add new dye group?"
      />
      <Flex width="100%" direction="column" align="center" justify="center">
        <FormControl
          my="10px"
          width="100%"
          isRequired={true}
          isLoading={isSubmitting}
          isTruncated={true}
        >
          <FormLabel>Name</FormLabel>
          <Input {...register('groupName')} required placeholder="Dye group Name" width="300px" />
        </FormControl>
        <Button colorScheme="facebook" size="sm" width="300px" onClick={() => setShowAlert(true)}>
          ADD NEW
        </Button>
      </Flex>
    </Box>
  );
};

const DeleteChemical = ({ data, refreshCallback }) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const handleDelete = async () => {
    try {
      setLoading(true);
      const response = await deleteChemical(data.uid);
      if (response.status === 200) {
        refreshCallback();
        toast({
          title: response.msg,
          status: 'warning',
          ...toastFormat,
        });
        setLoading(false);
        setShowAlert(false);
      } else {
        setLoading(false);
        setShowAlert(false);
        toast({
          title: 'Failed to delete chemical!',
          status: 'error',
          ...toastFormat,
        });
      }
    } catch (error) {
      setLoading(false);
      setShowAlert(false);
      toast({
        title: 'Failed to delete chemical!',
        status: 'error',
        ...toastFormat,
      });
    }
  };

  return (
    <>
      <AlertBox
        title="Delete Chemical"
        subTitle="Are you sure you want delete this chemical?"
        onClose={() => setShowAlert(false)}
        onSubmit={handleDelete}
        show={showAlert}
      />
      <Tooltip label="Delete Chemical">
        <IconButton
          colorScheme="facebook"
          ml="5px"
          size="sm"
          isLoading={loading}
          onClick={() => setShowAlert(true)}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

const DeleteDyeGroup = ({ data, refreshCallback }) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const handleDelete = async () => {
    try {
      setLoading(true);
      const response = await deleteDyeGroup(data.uid);
      if (response.status === 200) {
        refreshCallback();
        toast({
          title: response.msg,
          status: 'warning',
          ...toastFormat,
        });
        setLoading(false);
        setShowAlert(false);
      } else {
        setLoading(false);
        setShowAlert(false);
        toast({
          title: 'Failed to delete dye group!',
          status: 'error',
          ...toastFormat,
        });
      }
    } catch (error) {
      setLoading(false);
      setShowAlert(false);
      toast({
        title: 'Failed to delete dye group!',
        status: 'error',
        ...toastFormat,
      });
    }
  };

  return (
    <>
      <AlertBox
        title="Delete Dye Group"
        subTitle="Are you sure you want delete this dye group? All dyes of this group will be deleted too!"
        onClose={() => setShowAlert(false)}
        onSubmit={handleDelete}
        show={showAlert}
      />
      <Tooltip label="Delete Dye Group">
        <IconButton
          colorScheme="facebook"
          ml="5px"
          size="sm"
          isLoading={loading}
          onClick={() => setShowAlert(true)}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

const CreateNewDye = ({ dyeGroup, updateList }) => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();
  const toast = useToast();
  const [showAlert, setShowAlert] = useState(false);

  const submit = async (data) => {
    try {
      const { status, msg } = await addNewDye({ ...data, dyeGroupId: dyeGroup.id });
      if (status === 200) {
        updateList();
        setShowAlert(false);
        toast({
          title: msg,
          status: 'success',
          ...toastFormat,
        });
      }
    } catch (error) {
      setShowAlert(false);
      toast({
        title: 'Failed to add new chemical!',
        status: 'error',
        ...toastFormat,
      });
    }
  };

  return (
    <Box>
      <AlertBox
        onSubmit={() => handleSubmit(submit)()}
        onClose={() => setShowAlert(false)}
        show={showAlert}
        title="Add new dye"
        subTitle="Are you sure? You want to add new dye to this dye group?"
      />
      <Flex width="100%" direction="column" align="center" justify="center">
        <FormControl my="10px" width="100%" isRequired={true} isTruncated={true}>
          <FormLabel>Name</FormLabel>
          <Input {...register('dyeName')} required placeholder="Dye Name" width="300px" />
        </FormControl>
        <Button
          isLoading={isSubmitting}
          colorScheme="facebook"
          size="sm"
          width="300px"
          onClick={() => setShowAlert(true)}
        >
          ADD NEW
        </Button>
      </Flex>
    </Box>
  );
};

const UpdateDyeGroup = ({ data }) => {
  const toast = useToast();
  const [dyes, setDyes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentItem, setCurrentItem] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [contentLoading, setContentLoading] = useState(false);

  const updateDyeList = async () => {
    setContentLoading(true);
    const response = await getAllDyes(data.uid);
    if (response.status === 200) {
      setDyes(response.dyes);
    } else {
      setDyes([]);
    }
    setContentLoading(false);
  };

  useEffect(() => {
    if (isOpen) {
      updateDyeList();
    }
  }, [isOpen]);

  const handleDelete = async (item) => {
    try {
      setLoading(true);
      const response = await deleteDye(item.dyid);
      if (response.status === 200) {
        toast({
          title: response.msg,
          status: 'warning',
          ...toastFormat,
        });
        updateDyeList();
        setLoading(false);
        setShowAlert(false);
      } else {
        setLoading(false);
        setShowAlert(false);
        toast({
          title: 'Failed to delete dye!',
          status: 'error',
          ...toastFormat,
        });
      }
    } catch (error) {
      setLoading(false);
      setShowAlert(false);
      toast({
        title: 'Failed to delete dye!',
        status: 'error',
        ...toastFormat,
      });
    }
  };

  const handleAlert = (item) => {
    setCurrentItem(item);
    setShowAlert(true);
  };

  return (
    <>
      <Tooltip label="Update dye group">
        <IconButton colorScheme="green" ml="5px" onClick={onOpen} size="sm">
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Modal isOpen={isOpen} onClose={onClose} size="sm">
        <ModalOverlay />
        <ModalContent padding="15px 0">
          <AlertBox
            title="Delete Dye"
            subTitle={`Are you sure you want to delete ${currentItem?.name} from ${data.name}?`}
            onClose={() => setShowAlert(false)}
            onSubmit={() => handleDelete(currentItem)}
            show={showAlert}
          />

          <Flex
            width="100%"
            pading="16px 20px"
            boxSizing="border-box"
            direction="column"
            align="center"
            justify="center"
          >
            <Heading mb="15px">{data.name}</Heading>

            {contentLoading ? (
              <VStack w="100%" py="25px">
                <Spinner size="xl" />
              </VStack>
            ) : (
              <>
                <VStack
                  mb={4}
                  spacing={0}
                  maxH="40vh"
                  overflow="auto"
                  w="90%"
                  shadow="md"
                  borderRadius="md"
                >
                  <HStack
                    w="100%"
                    px="5px"
                    py="10px"
                    shadow="sm"
                    bg="blue.50"
                    position="sticky"
                    zIndex="100"
                    top="0"
                  >
                    <Text flex={1} textAlign="center">
                      Dyes
                    </Text>
                  </HStack>
                  {dyes?.length > 0 ? (
                    dyes?.map((item, index) => (
                      <HStack
                        key={index}
                        w="100%"
                        px="5px"
                        py="10px"
                        bg={index % 2 === 0 ? 'gray.100' : 'white'}
                        justifyContent="space-between"
                      >
                        <Tooltip label={item.name?.length > 24 ? item.name : ''}>
                          <Text>{item.name?.substring(0, 24)}</Text>
                        </Tooltip>
                        <Tooltip label="Delete Dye">
                          <IconButton
                            colorScheme="facebook"
                            size="sm"
                            isLoading={loading}
                            onClick={() => handleAlert(item)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </HStack>
                    ))
                  ) : (
                    <VStack w="100%" bg="gray.100" py="20px">
                      <Image src={EMPTYTABLE} w="40px" />
                      <Text>No items to show</Text>
                    </VStack>
                  )}
                </VStack>
                <CreateNewDye dyeGroup={data} updateList={updateDyeList} />
              </>
            )}
          </Flex>
        </ModalContent>
      </Modal>
    </>
  );
};

const Chemicals = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const [entries1, setEntries1] = useState();
  const [entries2, setEntries2] = useState();
  const chemicals = useSelector((state) => state.dyesChemicals.chemicals);
  const dyeGroups = useSelector((state) => state.dyesChemicals.dyeGroups);
  const [contentLoading1, setContentLoading1] = useState(false);
  const [contentLoading2, setContentLoading2] = useState(false);

  const columns = [
    { header: 'sl no.', type: 'INCREMENTS' },
    { header: 'name', key: 'name', type: 'TEXT', isToolTip: false, shouldShorten: true },
  ];

  const filters = [{ name: 'Name', queryParamKey: 'searchKey', type: 'INPUT' }];

  const handleQuery1 = async (data) => {
    try {
      setContentLoading1(true);
      const { chemicals, totalEntries } = await getAllChemicals(data);
      if (chemicals) {
        dispatch({
          type: GET_ALL_CHEMICALS,
          payload: chemicals,
        });
        setEntries1(totalEntries);
        setContentLoading1(false);
      }
    } catch (error) {
      setContentLoading1(false);
      toast({
        title: 'Failed to query chemicals',
        status: 'error',
        ...toastFormat,
      });
    }
  };

  const handleQuery2 = async (data) => {
    try {
      setContentLoading2(true);
      const { dyeGroups, totalEntries } = await getAllDyeGroups(data);
      if (dyeGroups) {
        dispatch({
          type: GET_ALL_DYE_GROUPS,
          payload: dyeGroups,
        });
        setEntries2(totalEntries);
        setContentLoading2(false);
      }
    } catch (error) {
      setContentLoading2(false);
      toast({
        title: 'Failed to query dye groups',
        status: 'error',
        ...toastFormat,
      });
    }
  };

  useEffect(() => {
    handleQuery1();
    handleQuery2();
  }, []);

  return (
    <Box mx="auto" w="98%" my="15px">
      <Heading>Chemicals & Dyes</Heading>

      <VStack
        my="20px"
        w="200px"
        p="15px"
        boxSizing="border-box"
        rounded="md"
        border="1px solid rgba(0,0,0,0.2)"
        align="flex-start"
      >
        <Text fontSize="sm" fontWeight="bold">
          Total Chemicals
        </Text>
        <Text fontSize="2xl" fontWeight="bold">
          {entries1}
        </Text>
      </VStack>
      <Heading mb="10px" fontSize="2xl">
        Chemical list
      </Heading>
      <CustomTable2
        data={chemicals}
        columns={columns}
        filters={filters}
        entries={entries1}
        queryCallback={(data) => handleQuery1(data)}
        refreshCallback={() => handleQuery1()}
        loading={contentLoading1}
        Actions={[DeleteChemical]}
        showCreateButton={true}
        CreateNewComponent={AddNewChemical}
      />
      <Heading mb="10px" fontSize="2xl" mt="25px">
        Dye groups
      </Heading>
      <VStack
        my="20px"
        w="200px"
        p="15px"
        boxSizing="border-box"
        rounded="md"
        border="1px solid rgba(0,0,0,0.2)"
        align="flex-start"
      >
        <Text fontSize="sm" fontWeight="bold">
          Total Dye Groups
        </Text>
        <Text fontSize="2xl" fontWeight="bold">
          {entries2}
        </Text>
      </VStack>
      <CustomTable2
        data={dyeGroups}
        columns={columns}
        filters={filters}
        entries={entries2}
        queryCallback={(data) => handleQuery2(data)}
        refreshCallback={() => handleQuery2()}
        loading={contentLoading2}
        Actions={[DeleteDyeGroup, UpdateDyeGroup]}
        showCreateButton={true}
        CreateNewComponent={AddNewDyeGroup}
      />
    </Box>
  );
};

export default Chemicals;
