import { Tag } from '@chakra-ui/react';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

const Clock = () => {
  const [time, setTime] = useState();

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 30000);

    return () => clearInterval(interval);
  });
  return (
    <Tag colorScheme="orange" fontSize="lg" fontWeight="bold">
      {dayjs(time).format('hh:mm A | dddd | DD/MMM/YYYY')}
    </Tag>
  );
};

export default Clock;
