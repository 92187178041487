import { combineReducers } from 'redux';
import authReducer from './authReducer';
import batchReducer from './batchReducer';
import fcReducer from './fcReducer';
import globalReducer from './globalReducer';
import machineReducer from './machineReducer';
import recipeReducer from './recipeReducer';
import userReducer from './userReducer';
import chemicalsReducer from './chemicalsReducer';
import orderReducer from './orderReducer';

const reducers = combineReducers({
  auth: authReducer,
  machine: machineReducer,
  user: userReducer,
  global: globalReducer,
  batch: batchReducer,
  fc: fcReducer,
  recipe: recipeReducer,
  dyesChemicals: chemicalsReducer,
  order: orderReducer,
});

export default reducers;
