import { NOTIFY } from '../actions/action-types';

const initialState = {
  notification: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case NOTIFY: {
      const { title, description, status, duration, isClosable, position } = action.payload;
      return {
        notification: { title, description, status, duration, isClosable, position },
      };
    }

    default:
      return state;
  }
}
