import { GET_ALL_BATCHES } from '../actions/action-types';

const initState = [];

const batchReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_ALL_BATCHES:
      return action.payload;

    default:
      return state;
  }
};

export default batchReducer;
