/* eslint-disable no-unused-vars */
// axios
import axios from 'axios';

// local storage utils
// import LocalStorage from '../util';

// url constants
// import { ROOT_URL } from '../constatnts/routerUrls';
// redux
import { store } from '../store';
// import { useDispatch } from 'react-redux';
// import { logOut } from '../store/actions/auth-actions';
import { ERROR } from '../constatnts/toast';
import { notify } from '../store/actions/toast-action';
// import { refreshToken } from '../store/actions/auth-actions';
import { ROOT_URL } from '../constatnts/routerUrls';
import { LOGOUT } from '../store/actions/action-types';

// get base url from environtment
const baseURL = process.env.REACT_APP_API_BASEURL;

// create axios instance
const instance = axios.create({
  baseURL,
});

//request interceptor to add the auth token header to requests
instance.interceptors.request.use(
  (config) => {
    // checking if the access token exist in the localStorage
    const accessToken = store.getState().auth.accessToken;
    config.headers = {
      'Content-Type': config.headers['Content-Type']
        ? config.headers['Content-Type']
        : 'application/json',
      Authorization: `Bearer ${accessToken}`,
    };
    // config.withCredentials = true;
    return config;
  },
  (error) => {
    if (error.response) {
      store.dispatch(
        notify({
          title: 'Error!',
          status: ERROR,
          description: error.response.data?.msg || 'Something went wrong, please contact support!',
        }),
      );
      console.error(error.response);
    } else if (error.request) {
      store.dispatch(
        notify({
          title: 'Error!',
          status: ERROR,
          description: 'Could not fetch data from server! Please check your internet connection.',
        }),
      );
    } else {
      store.dispatch(
        notify({
          title: 'Error!',
          status: ERROR,
          description: 'Unknown error occured!',
        }),
      );
    }
    return Promise.reject(error);
  },
);

// response interceptor to refresh token on receiving token expired error
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401) {
      // logging user out
      store.dispatch({
        type: LOGOUT,
      });
      window.location.href = ROOT_URL;
      return;
    }
    if (error.response) {
      store.dispatch(
        notify({
          title: 'Error!',
          status: ERROR,
          description: error.response.data?.msg || 'Something went wrong, please contact support!',
        }),
      );
    } else if (error.request) {
      store.dispatch(
        notify({
          title: 'Error!',
          status: ERROR,
          description: 'Could not fetch data from server! Please check your internet connection.',
        }),
      );
    } else {
      store.dispatch(
        notify({
          title: 'Error!',
          status: ERROR,
          description: 'Unknown error occured!',
        }),
      );
    }
    return Promise.reject(error);
  },
);

export default instance;
