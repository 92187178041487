/* eslint-disable react/no-children-prop */
import { ChevronDownIcon, EditIcon } from '@chakra-ui/icons';
import {
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalContent,
  ModalOverlay,
  PinInput,
  PinInputField,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getOtpForUserUpdate, updateUser } from '../../api/user';
import toastFormat from '../../constatnts/toastFormat';
import { SAVE_SESSION_ID, UPDATE_USER_DATA } from '../../store/actions/action-types';
import { mapStatusToColor } from '../../utilities/mapStatusToColor';
import AlertBox from '../common/AlertBox';

export const UpdateUserInfo = ({ data }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();

  const [roles, setRoles] = useState(
    data.roles.split(',').splice(0, data.roles.split(',').length - 1),
  );
  const [showAlert, setShowAlert] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      name: data.name,
      userStatus: data.status,
      isVerified: data.isVerified,
      phone: data.phone.slice(-10),
      password: '',
    },
  });
  const toast = useToast();
  const watchFields = watch();

  const addRole = (role) => {
    if (roles.includes(role)) {
      toast({
        title: `Role "${role}" already added`,
        status: 'warning',
        ...toastFormat,
      });
    } else {
      setRoles([...roles, role]);
    }
  };

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(data.status);
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [otpValid, setOtpValid] = useState(true);

  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    setStatus(data.status);
  }, [data]);

  const submit = async (otp) => {
    try {
      setLoading(true);
      let userRoles = '';
      roles.forEach((rl) => (userRoles = userRoles + rl + ','));
      const val = getValues();

      if (val.password.length > 0) {
        const response = await updateUser({
          uid: data.uid,
          data: {
            ...val,
            roles: userRoles,
            phone: `+880${val.phone.slice(-10)}`,
          },
          username: auth.user.username,
          sessionId: auth.sessionId,
          phone: auth.user.phone,
          otpToken: otp,
        });
        if (response) {
          toast({
            title: 'User data updated',
            status: 'success',
            ...toastFormat,
          });

          dispatch({
            type: UPDATE_USER_DATA,
            payload: {
              uid: data.uid,
              data: {
                ...val,
                status: val.userStatus,
                roles: userRoles,
                phone: `+880${val.phone.slice(-10)}`,
              },
            },
          });
          setLoading(false);
          setShowAlert(false);
          setOtpValid(true);
          setShowOtpForm(false);
          onClose();
        } else {
          toast({
            title: 'Invalid or wrong OTP',
            status: 'warning',
            ...toastFormat,
          });
          setOtpValid(false);
        }
      } else {
        const response = await updateUser({
          uid: data.uid,
          data: {
            name: val.name,
            userStatus: val.userStatus,
            isVerified: val.isVerified,
            roles: userRoles,
            phone: val.phone,
          },
          username: auth.user.username,
          sessionId: auth.sessionId,
          phone: auth.user.phone,
          otpToken: otp,
        });
        if (response) {
          toast({
            title: 'User data updated',
            status: 'success',
            ...toastFormat,
          });

          dispatch({
            type: UPDATE_USER_DATA,
            payload: {
              uid: data.uid,
              data: {
                ...val,
                status: val.userStatus,
                roles: userRoles,
                phone: `+880${val.phone.slice(-10)}`,
              },
            },
          });
          setLoading(false);
          setShowAlert(false);
          setOtpValid(true);
          setShowOtpForm(false);
          onClose();
        } else {
          toast({
            title: 'Invalid or wrong OTP',
            status: 'warning',
            ...toastFormat,
          });
          setOtpValid(false);
        }
      }
    } catch (error) {
      setLoading(false);
      setShowAlert(false);
      toast({
        title: 'Failed to update user info',
        ...toastFormat,
      });
    }
  };

  const getOtp = async () => {
    let userRoles = '';
    roles.forEach((rl) => (userRoles = userRoles + rl + ','));

    if (!isDirty && data.roles === userRoles) {
      setLoading(false);
      setShowAlert(false);
      toast({
        title: 'You did not make any changes',
        status: 'warning',
        ...toastFormat,
      });
    } else {
      setShowAlert(false);
      setShowOtpForm(true);
      const sessionId = await getOtpForUserUpdate();
      if (sessionId) {
        dispatch({
          type: SAVE_SESSION_ID,
          payload: sessionId,
        });
      } else {
        toast({
          title: 'Failed to get session id',
          status: 'error',
          ...toastFormat,
        });
      }
    }
  };

  return (
    <>
      <AlertBox
        title="Update user info"
        subTitle="Are you sure you want to update user info?"
        show={showAlert}
        onSubmit={handleSubmit(getOtp)}
        onClose={() => {
          setLoading(false);
          setShowAlert(false);
        }}
      />
      <Tooltip label="Update user info">
        <IconButton colorScheme="green" ml="5px" onClick={onOpen} size="sm">
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setLoading(false);
          setShowAlert(false);
          setShowOtpForm(false);
          setOtpValid(true);
        }}
        size="sm"
      >
        <ModalOverlay />
        <ModalContent padding="15px 0">
          {showOtpForm ? (
            <Flex
              width="100%"
              pading="16px 20px"
              boxSizing="border-box"
              direction="column"
              align="center"
              justify="center"
            >
              <Text textAlign="center">
                Please enter OTP sent to your verified <br /> Email to update user information
              </Text>
              <HStack margin="30px 0">
                <PinInput otp size="lg" mask isDisabled={!otpValid} onComplete={submit}>
                  <PinInputField size="lg" />
                  <PinInputField size="lg" />
                  <PinInputField size="lg" />
                  <PinInputField size="lg" />
                  <PinInputField size="lg" />
                  <PinInputField size="lg" />
                </PinInput>
              </HStack>
              {otpValid && (
                <Countdown
                  zeroPadTime={2}
                  date={Date.now() + 240000}
                  onComplete={() => {
                    setOtpValid(false);
                    toast({
                      title: 'OTP is expired',
                      status: 'warning',
                      ...toastFormat,
                    });
                  }}
                  renderer={({ minutes, seconds }) => (
                    <Flex>
                      <Text mb={15} fontSize={16} color="gray.600" fontWeight="bold">
                        Valid till{' '}
                      </Text>
                      <Text fontWeight="bold" ml={15} color="red.400">
                        {minutes}:{seconds === 0 ? '00' : seconds}
                      </Text>
                    </Flex>
                  )}
                />
              )}
              {!otpValid && (
                <Flex pading="16px 20px" boxSizing="border-box">
                  <Button
                    colorScheme="red"
                    onClick={() => {
                      setOtpValid(true);
                      setShowOtpForm(false);
                      setLoading(false);
                    }}
                    width="100%"
                  >
                    Try Again
                  </Button>
                </Flex>
              )}
            </Flex>
          ) : (
            <Flex
              width="100%"
              pading="16px 20px"
              boxSizing="border-box"
              direction="column"
              align="center"
              justify="center"
            >
              <Heading mb="15px">Update user info</Heading>
              <Text fontSize="sm" fontWeight="bold" w="300px" textAlign="left" mb="10px">
                UID - <Badge>{data.uid}</Badge>{' '}
                <Badge colorScheme={mapStatusToColor(data.status)}>{data.status}</Badge>
              </Text>
              <FormControl w="300px">
                <FormLabel>Name</FormLabel>
                <Input
                  width="300px"
                  size="md"
                  mt="5px"
                  name="name"
                  placeholder="Name"
                  {...register('name')}
                />
              </FormControl>
              <FormControl w="300px" mt="10px">
                <FormLabel>Phone</FormLabel>
                <InputGroup width="300px">
                  <InputLeftAddon children="+88" />
                  <Input
                    {...register('phone')}
                    required
                    placeholder="Phone number  - ie - 017***"
                    width="300px"
                    type="tel"
                    minLength={11}
                    maxLength={11}
                  />
                </InputGroup>
              </FormControl>

              <FormControl w="300px" mt="15px">
                <FormLabel>Update Password - 6 - 10 character</FormLabel>
                <Input
                  width="300px"
                  size="md"
                  mt="5px"
                  placeholder="Update user password - 6 - 10 character"
                  name="password"
                  {...register('password')}
                  maxLength={10}
                  minLength={6}
                />
              </FormControl>
              <Box width="300px" mt="10px">
                <Menu colorScheme="blue">
                  <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                    Roles
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={() => addRole('ADMIN')}>Admin</MenuItem>
                    <MenuItem onClick={() => addRole('AD')}>Accounts & Delivery</MenuItem>
                    <MenuItem onClick={() => addRole('SI')}>Store Incharge</MenuItem>
                    <MenuItem onClick={() => addRole('DPO')}>Dyeing Operator</MenuItem>
                    <MenuItem onClick={() => addRole('FS')}>Finishing Operator</MenuItem>
                    <MenuItem onClick={() => addRole('QCS')}>Quality Supervisor</MenuItem>
                    <MenuItem onClick={() => addRole('BM')}>Batch Manager</MenuItem>
                    <MenuItem onClick={() => addRole('MANAGER')}>Manager</MenuItem>
                    <MenuItem onClick={() => addRole('SUPERVISOR')}>Supervisor</MenuItem>
                    <MenuItem onClick={() => addRole('DI')}>Delivery Incharge</MenuItem>
                  </MenuList>
                </Menu>
                <Grid
                  gridTemplateColumns="repeat(2, minmax(80px, max-content))"
                  width="300px"
                  border="1px solid rgba(0,0,0,0.1)"
                  borderRadius="6px"
                  margin="10px 0"
                  padding="3px 5px"
                >
                  {roles.map((role) => (
                    <Tag colorScheme={mapStatusToColor(role)} key={role} mx="5px" my="5px">
                      <TagLabel>{role}</TagLabel>
                      <TagCloseButton
                        onClick={() => {
                          if (roles.length >= 2) {
                            const filteredSelectedRoles = roles.filter((rl) => rl !== role);
                            setRoles(filteredSelectedRoles);
                          } else {
                            toast({
                              title: 'Atleast one role must be selected',
                              status: 'info',
                              ...toastFormat,
                            });
                          }
                        }}
                      />
                    </Tag>
                  ))}
                </Grid>
              </Box>

              <Box mt="10px" width="300px">
                <Menu>
                  <Tooltip label="User status" placement="top">
                    <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                      {status}
                    </MenuButton>
                  </Tooltip>
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        setValue('userStatus', 'ACTIVE', {
                          shouldDirty: true,
                          shouldTouch: true,
                        });
                        setStatus('ACTIVE');
                      }}
                    >
                      Active
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setValue('userStatus', 'INACTIVE', {
                          shouldDirty: true,
                          shouldTouch: true,
                        });
                        setStatus('INACTIVE');
                      }}
                    >
                      In Active
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setValue('userStatus', 'BANNED', {
                          shouldDirty: true,
                          shouldTouch: true,
                        });
                        setStatus('BANNED');
                      }}
                    >
                      Banned
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Box>
              {data.isVerified ? (
                <></>
              ) : (
                <Box mt="10px" width="300px">
                  <Menu>
                    <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                      Verify User -{' '}
                      {watchFields.isVerified ? (
                        <Badge colorScheme="green">YES</Badge>
                      ) : (
                        <Badge colorScheme="red">NO</Badge>
                      )}
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={() => setValue('isVerified', true, { shouldDirty: true })}>
                        YES
                      </MenuItem>
                      <MenuItem
                        onClick={() => setValue('isVerified', false, { shouldDirty: true })}
                      >
                        NO
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Box>
              )}
              <Button
                type="submit"
                w="300px"
                colorScheme="facebook"
                mt="15px"
                isLoading={loading}
                onClick={(e) => {
                  e.preventDefault();
                  setLoading(true);
                  setShowAlert(true);
                }}
              >
                Update
              </Button>
            </Flex>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
