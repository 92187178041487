import { DownloadIcon } from '@chakra-ui/icons';
import {
  Box,
  Heading,
  HStack,
  IconButton,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import ExcelExport from 'export-xlsx';
import React, { useEffect, useState } from 'react';
import { deliveryDelayReport } from '../../api/batch';

const DeliveryReport = () => {
  const [duration, setDuration] = useState(5);
  const [reportData, setReportData] = useState(null);

  useEffect(() => {
    (async () => {
      const report = await deliveryDelayReport(duration);
      setReportData(report);
    })();
  }, [duration]);

  const generateXl = () => {
    const excel = new ExcelExport();

    const SHEET_CONFIG = {
      fileName: `Delayed Delivery Report - ${dayjs().format('DD-MMM-YYYY')}`,
      workSheets: [
        {
          sheetName: 'On Time Batches',
          startingRowNumber: 1,
          tableSettings: {
            data: {
              importable: true,
              headerDefinition: [
                {
                  name: 'FC Number',
                  key: 'fcNumber',
                },
                {
                  name: 'Lot Number',
                  key: 'lotNumber',
                },
                {
                  name: 'Fabric Weight (Kg)',
                  key: 'usedQty',
                },
                {
                  name: 'Color',
                  key: 'color',
                },
                {
                  name: 'Shade (%)',
                  key: 'shade',
                },
                {
                  name: 'Party',
                  key: 'party',
                },
                {
                  name: 'DIA',
                  key: 'dia',
                },
                {
                  name: 'GSM',
                  key: 'gsm',
                },
                {
                  name: 'Complted At',
                  key: 'completeTime',
                },
                {
                  name: 'Days Waiting',
                  key: 'daySinceCompleted',
                },
              ],
            },
          },
        },
      ],
    };

    const data = [];

    reportData?.map((order) => {
      order.fabrics.map((fabric) => {
        data.push({ ...fabric, fcNumber: order.fcNumber });
      });
    });

    excel.downloadExcel(SHEET_CONFIG, [{ data: data }]);
  };

  return (
    <Box w="full" p="15px" boxSizing="border-box">
      <Heading fontSize="lg">Delivery Delay Report</Heading>
      <HStack p="15px" w="full" justify="space-between" align="center" bg="gray.100" my="15px">
        <HStack>
          <Text>Set Duration</Text>
          <Select
            defaultValue={duration}
            onChange={(e) => setDuration(e.target.value)}
            w="200px"
            bg="white">
            <option value="5">5 days</option>
            <option value="7">1 Week</option>
            <option value="15">15 days</option>
            <option value="30">1 Month</option>
          </Select>
        </HStack>
        <HStack>
          <Tooltip label="Download XLSX">
            <IconButton
              icon={<DownloadIcon />}
              onClick={generateXl}
              colorScheme="facebook"
              size="sm"
            />
          </Tooltip>
        </HStack>
      </HStack>
      <Table size="sm" variant="fill">
        <Thead>
          <Tr>
            <Th border="1px solid rgba(0,0,0,0.5)">FC Number</Th>
            <Th border="1px solid rgba(0,0,0,0.5)">Detail</Th>
          </Tr>
        </Thead>
        <Tbody>
          {reportData ? (
            reportData.map((order, orderIndex) => (
              <Tr key={order.fcNumber}>
                <Td
                  border="1px solid rgba(0,0,0,0.5)"
                  bg="gray.100"
                  fontSize="lg"
                  fontWeight="bold"
                  textAlign="center">
                  {order.fcNumber}
                </Td>
                <Td>
                  <Table size="sm" variant="fill">
                    <Thead>
                      {orderIndex <= 0 ? (
                        <Tr>
                          <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
                            Lot
                          </Th>
                          <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
                            Party
                          </Th>
                          <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
                            Color
                          </Th>
                          <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
                            Fabric Type
                          </Th>
                          <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
                            Shade (%)
                          </Th>
                          <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
                            Quantity (Kg)
                          </Th>
                          <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
                            DIA
                          </Th>
                          <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
                            GSM
                          </Th>
                          <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
                            Completed
                          </Th>
                          <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
                            Days Waiting
                          </Th>
                        </Tr>
                      ) : (
                        <Tr>
                          <Th></Th>
                          <Th></Th>
                          <Th></Th>
                          <Th></Th>
                          <Th></Th>
                          <Th></Th>
                          <Th></Th>
                          <Th></Th>
                          <Th></Th>
                          <Th></Th>
                        </Tr>
                      )}
                    </Thead>
                    <Tbody>
                      {order?.fabrics.map((fabric) => (
                        <Tr key={fabric.lotNumber}>
                          <Td border="1px solid rgba(0,0,0,0.5)" w="100px">
                            {fabric.lotNumber.split('-')[1]}
                          </Td>
                          <Td border="1px solid rgba(0,0,0,0.5)" w="100px">
                            {fabric.party}
                          </Td>
                          <Td border="1px solid rgba(0,0,0,0.5)" w="100px">
                            {fabric.color}
                          </Td>
                          <Td border="1px solid rgba(0,0,0,0.5)" w="100px">
                            {fabric.fabricType}
                          </Td>
                          <Td border="1px solid rgba(0,0,0,0.5)" w="100px">
                            {fabric.shade}
                          </Td>
                          <Td border="1px solid rgba(0,0,0,0.5)" w="100px">
                            {fabric.usedQty}
                          </Td>
                          <Td border="1px solid rgba(0,0,0,0.5)" w="100px">
                            {fabric.dia}
                          </Td>
                          <Td border="1px solid rgba(0,0,0,0.5)" w="100px">
                            {fabric.gsm}
                          </Td>
                          <Td border="1px solid rgba(0,0,0,0.5)" w="100px">
                            {dayjs(fabric.completeTime).format('hh:mm A | DD/MMM/YYYY')}
                          </Td>
                          <Td border="1px solid rgba(0,0,0,0.5)" w="100px">
                            {fabric.daySinceCompleted}
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={2} border="1px solid rgba(0,0,0,0.5)">
                <Heading size="lg">No data to show</Heading>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  );
};

export default DeliveryReport;
