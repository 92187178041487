import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import QRCode from 'react-qr-code';
import combineDateTime from '../../utilities/combineDateTime';

const BatchCard = React.forwardRef((props, ref) => {
  const { batch } = props;

  return (
    <Box boxSizing="border-box" padding="20px 25px" ref={ref} w="1024px" mx="auto">
      <Flex direction="row">
        <Box mr="25px">
          <QRCode value={batch.id.toString()} size={200} />
        </Box>
        <VStack align="flex-start" justify="flex-start">
          <Heading fontSize="6xl" color="#272739">
            Batch #{batch.lotNumber?.split('-')[1]}
          </Heading>
          <Text>Loading Time: {combineDateTime(batch?.loadDate, batch?.loadTime)}</Text>
          <HStack spacing={15} justify="flex-start" align="flex-start">
            <VStack align="flex-start">
              <Heading fontSize="lg" my="15px" textDecoration="underline">
                Order Information
              </Heading>
              <Text fontSize="lg" fontWeight="600">
                Party Name: {batch.customerInfo.partyName}
              </Text>
              <Text fontSize="lg" fontWeight="600">
                FC Number: {batch.customerInfo.fcNumber}
              </Text>
              <Text fontSize="lg" fontWeight="600">
                Total Fabric Weight: {batch.totalFabricWeight} KG
              </Text>
              <Text fontSize="lg" fontWeight="600">
                Color: {batch.fabricInfo[0]?.color}
              </Text>
              <Text fontSize="lg" fontWeight="600">
                Shade: {batch.fabricInfo[0]?.shade} %
              </Text>
              <Text fontSize="lg" fontWeight="600">
                Process Type: {batch.processType}
              </Text>
            </VStack>
            <Divider orientation="vertical" />
            <VStack align="flex-start">
              <Heading fontSize="lg" my="15px" textDecoration="underline">
                Machine Information
              </Heading>
              <Text fontSize="lg" fontWeight="600">
                Machine: {batch.machineInfo.name}
              </Text>
              <Text fontSize="lg" fontWeight="600">
                Max Capacity: {batch.machineInfo.maxCapacity}
              </Text>
              <Text fontSize="lg" fontWeight="600">
                Machine Utilization:{' '}
                {Math.floor((batch.totalFabricWeight / batch.machineInfo.maxCapacity) * 100)}%
              </Text>
            </VStack>
          </HStack>
        </VStack>
      </Flex>
      <Divider />
      <Heading fontSize="xl" my="25px">
        Fabrics
      </Heading>
      <Table>
        <Thead>
          <Th>Shade Group</Th>
          <Th>Color</Th>
          <Th>Shade</Th>
          <Th>Fabric Type</Th>
          <Th>DIA</Th>
          <Th>GSM</Th>
          <Th>Quantity (Kg)</Th>
          <Th>Used (Kg) </Th>
        </Thead>
        <Tbody>
          {batch?.fabricInfo?.map((item) => (
            <Tr key={JSON.stringify(item)}>
              <Td>{item.shadeGroup}</Td>
              <Td>{item.color}</Td>
              <Td>{item.shade}</Td>
              <Td>{item.fabricType}</Td>
              <Td>{item.dia}</Td>
              <Td>{item.gsm}</Td>
              <Td>{item.quantity}</Td>
              <Td>{item.usedQty}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Divider />
      <Grid gridTemplateColumns="1fr 1fr 1fr" gap="20px" mt="30px">
        <GridItem>
          <Text fontSize="lg" fontWeight="bold">
            Batch Manager: {batch?.creatorInfo?.name}
          </Text>
        </GridItem>
        <GridItem>
          <Text fontSize="lg" fontWeight="bold">
            Approved By: {batch?.approverInfo?.name}
          </Text>
        </GridItem>
        <GridItem>
          <Text fontSize="lg" fontWeight="bold">
            Dyeing Operator: {batch?.dyeingOperatorInfo?.name}
          </Text>
        </GridItem>
        <GridItem>
          <Text fontSize="lg" fontWeight="bold">
            Finishing Operator: {batch?.finishingOperatorInfo?.name}
          </Text>
        </GridItem>
        <GridItem>
          <Text fontSize="lg" fontWeight="bold">
            QC: {batch?.qcOperatorInfo?.name}
          </Text>
        </GridItem>
      </Grid>
    </Box>
  );
});

BatchCard.displayName = 'Batch Card';

export default BatchCard;
