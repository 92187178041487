const initState = [];

export default (state = initState, actions) => {
  switch (actions.type) {
    case 'GET_ALL_ORDERS':
      return state;
    case 'SET_ALL_ORDERS':
      return actions.payload;
    default:
      return state;
  }
};
