import {
  BATCHES,
  CHEMICALS_DYES,
  CLIENTS,
  DASHBOARD_ROUTE,
  MACHINES,
  ORDER_INDEX,
  REPORTS,
  USERS,
} from './routerUrls';
import HomeIco from '../assets/images/home.svg';
import BatchIco from '../assets/images/batch.svg';
import ChemicalIco from '../assets/images/chemicals.svg';
import MachineIco from '../assets/images/machine.svg';
import PeopleIco from '../assets/images/people.svg';
import Orders from '../assets/images/orders.svg';
import Clients from '../assets/images/clients.svg';
import ReportsIco from '../assets/images/reports.svg';

const sidebarLocations = [
  { title: 'Dashboard', path: DASHBOARD_ROUTE, icon: HomeIco },
  { title: 'Orders', path: ORDER_INDEX, icon: Orders },
  { title: 'Batches', path: BATCHES, icon: BatchIco },
  { title: 'Users', path: USERS, icon: PeopleIco },
  { title: 'Clients', path: CLIENTS, icon: Clients },
  { title: 'Machines', path: MACHINES, icon: MachineIco },
  { title: 'Chemicals', path: CHEMICALS_DYES, icon: ChemicalIco },
  { title: 'Reports', path: REPORTS, icon: ReportsIco },
];

export default sidebarLocations;
