import {
  ChevronDownIcon,
  CloseIcon,
  DownloadIcon,
  ExternalLinkIcon,
  SearchIcon,
} from '@chakra-ui/icons';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Heading,
  HStack,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Tooltip,
  Tag,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  Input,
  Link,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import ExcelExport from 'export-xlsx';
import React, { useEffect, useState } from 'react';
import { BATCHES } from '../../constatnts/routerUrls';
import { mapStatusToColor } from '../../utilities/mapStatusToColor';
import Clock from '../common/Clock';
import RefreshIco from '../RefreshIco';
import sortTypes from '../../constatnts/sortTypes';

const FullScreenListView = ({ batches, show, close }) => {
  const [filter, setFilter] = useState('Select Filter');
  const [searchQuery, setSearchQuery] = useState('');

  const [batchList, setBatchList] = useState(batches || []);

  useEffect(() => {
    setBatchList(batches);
    sortData(batches, sortTypes[0].value, setBatchList);
  }, [batches]);

  const filterBatches = (value, type) => {
    if (type === 'STATUS') {
      const filteredBatch = batches?.filter((item) => item.batchStatus === value);
      setBatchList(filteredBatch);
    } else {
      const filteredBatch = batches?.filter((item) => item.batchPhase === value);
      setBatchList(filteredBatch);
    }
    setFilter(value);
  };

  const search = () => {
    const filteredBatches = batchList.filter((item) =>
      item.machineName.toLowerCase().includes(searchQuery.toLowerCase()),
    );

    setBatchList(filteredBatches);
  };

  const getColor = (batch) => {
    if (batch.batchStatus === 'ON HOLD') {
      return 'red.300';
    }
    if (batch.batchStatus === 'IS PAUSED') {
      return 'yellow.300';
    } else {
      return 'green.200';
    }
  };

  const exportReport = () => {
    const excelExport = new ExcelExport();

    const SHEET_CONFIG = {
      fileName: 'Live Tracking - ' + dayjs(new Date()).format('DD MMM YYYY'),
      workSheets: [
        {
          sheetName: 'Live Tracking',
          startingRowNumber: 1,
          tableSettings: {
            data: {
              importable: true,
              headerDefinition: [
                {
                  name: 'Machine',
                  key: 'machineName',
                },
                {
                  name: 'Capacity (Kg)',
                  key: 'capacity',
                },

                {
                  name: 'FC Number',
                  key: 'fcNumber',
                },
                {
                  name: 'Lot Number',
                  key: 'lotNumber',
                },
                {
                  name: 'Party Name',
                  key: 'partyName',
                },
                {
                  name: 'Colour',
                  key: 'color',
                },
                {
                  name: 'Fabric Weight (Kg)',
                  key: 'totalFabricWeight',
                },
                {
                  name: 'Loading (%)',
                  key: 'loading',
                },
                {
                  name: 'Process',
                  key: 'processType',
                },
                {
                  name: 'Phase',
                  key: 'batchPhase',
                },
                {
                  name: 'Status',
                  key: 'batchStatus',
                },
                {
                  name: 'Standard Time',
                  key: 'stdTime',
                },
                {
                  name: 'Total Time',
                  key: 'totalTime',
                },
                {
                  name: 'Extra Time',
                  key: 'extraTime',
                },
                {
                  name: 'Dyeing Time STD',
                  key: 'dyeingTimeSTD',
                },
                {
                  name: 'Dyeing Time Total',
                  key: 'dyeingTimeTotal',
                },
                {
                  name: 'Batch Type',
                  key: 'batchType',
                },
                {
                  name: 'Dyeing Remarks',
                  key: 'dyeingRemark',
                },
                {
                  name: 'Finishing Remark',
                  key: 'finishingRemark',
                },
                {
                  name: 'QC Remark',
                  key: 'qcRemark',
                },
              ],
            },
          },
        },
      ],
    };
    let data = batchList;

    const totalCapacity = data.reduce((prValue, item) => prValue + item.capacity, 0);
    const totalFabric = data.reduce((prValue, item) => prValue + item.totalFabricWeight, 0);
    const totalLoad = data.reduce(
      (prValue, item) => prValue + Math.floor((item.totalFabricWeight / item.capacity) * 100),
      0,
    );

    data.push({
      machineName: '',
      capacity: totalCapacity,
      fcNumber: '',
      lotNumber: '',
      partyName: '',
      color: '',
      totalFabricWeight: totalFabric,
      loading: Math.floor(totalLoad / data.length),
      process: '',
      batchPhase: '',
      batchStatus: '',
      batchType: '',
      dyeingSTD: '',
      dyeingTimeTotal: '',
      stdTime: '',
      totalTime: '',
      extraTime: '',
    });

    excelExport.downloadExcel(SHEET_CONFIG, [{ data: data }]);
  };

  const sortByStartTime = (data, updateCallback) => {
    const sortedData = data?.sort((prev, next) => {
      const prevDate = new Date(prev.startTime);
      const nextDate = new Date(next.startTime);

      return nextDate - prevDate;
    });
    updateCallback(sortedData);
  };
  const sortByDyeingTime = (data, updateCallback) => {
    const dyeingBatches = data.filter((item) => item.batchPhase === 'DYING');
    const notFinishing = data.filter((item) => item.batchPhase !== 'DYING');

    const sortedData = dyeingBatches?.sort((prev, next) => {
      const prevDate = new Date(prev.currentTask.startTime);
      const nextDate = new Date(next.currentTask.startTime);

      return nextDate - prevDate;
    });
    updateCallback([...sortedData, ...notFinishing]);
  };
  const sortByFinishingTime = (data, updateCallback) => {
    const finishingBatches = data.filter((item) => item.batchPhase === 'FINISHING');
    const notFinishing = data.filter((item) => item.batchPhase !== 'FINISHING');

    const sortedData = finishingBatches?.sort((prev, next) => {
      const prevDate = new Date(prev.currentTask.startTime);
      const nextDate = new Date(next.currentTask.startTime);

      return nextDate - prevDate;
    });
    updateCallback([...sortedData, ...notFinishing]);
  };
  const sortByMachineName = (data, updateCallback) => {
    const sortedData = data?.sort((prev, next) => {
      const prevDate = prev.machineName;
      const nextDate = next.machineName;

      return prevDate.localeCompare(nextDate);
    });
    updateCallback(sortedData);
  };

  const sortData = (data, sortType, updateCallback) => {
    switch (sortType) {
      case 'startTime':
        sortByStartTime(data, updateCallback);
        break;
      case 'dyeingTime':
        sortByDyeingTime(data, updateCallback);
        break;
      case 'finishingTime':
        sortByFinishingTime(data, updateCallback);
        break;
      case 'machineName':
        sortByMachineName(data, updateCallback);
        break;
    }
  };

  const [selectedSortType, setSelectedSortType] = useState(sortTypes[0]);

  return (
    <Drawer size="full" isOpen={show} onClose={close}>
      <DrawerCloseButton />
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody>
          <HStack
            w="100%"
            justify="space-between"
            position="sticky"
            top="0"
            bgColor="#fff"
            py="15px"
            zIndex="1555">
            <HStack>
              <Heading fontSize="2xl">Live Tracking</Heading>
              <Clock />
            </HStack>
            <IconButton onClick={close} icon={<CloseIcon />} size="sm" colorScheme="red" />
          </HStack>
          <HStack my="15px">
            <Menu>
              <MenuButton as={Button} size="sm" rightIcon={<ChevronDownIcon />}>
                Sort By {selectedSortType.label}
              </MenuButton>
              <MenuList>
                {sortTypes.map((item) => (
                  <MenuItem
                    key={item.value}
                    onClick={() => {
                      sortData(batchList, item.value, setBatchList);
                      setSelectedSortType(item);
                    }}>
                    {item.label}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
            <Menu>
              <MenuButton as={Button} size="sm" rightIcon={<ChevronDownIcon />}>
                {filter}
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => filterBatches('DYING', 'PHASE')}>In Dyeing</MenuItem>
                <MenuItem onClick={() => filterBatches('FINISHING', 'PHASE')}>
                  In Finishing
                </MenuItem>
                <MenuItem onClick={() => filterBatches('QC', 'PHASE')}>In QC</MenuItem>
                <MenuItem onClick={() => filterBatches('ON HOLD', 'STATUS')}>Halted</MenuItem>
                <MenuItem onClick={() => filterBatches('STARTED', 'STATUS')}>Started</MenuItem>
                <MenuItem onClick={() => filterBatches('IS PAUSED', 'STATUS')}>Paused</MenuItem>
              </MenuList>
            </Menu>
            <Input
              w="200px"
              placeholder="Machine Name"
              size="sm"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Tooltip label="Search By machine">
              <IconButton icon={<SearchIcon />} size="sm" colorScheme="facebook" onClick={search} />
            </Tooltip>
            <Tooltip label="Reset">
              <IconButton
                icon={<RefreshIco />}
                size="sm"
                colorScheme="facebook"
                onClick={() => {
                  setFilter('Select Filter');
                  setSearchQuery('');
                  setSelectedSortType(sortTypes[0]);
                  setBatchList(batches);
                }}
              />
            </Tooltip>
            <Tooltip label="Export to xlsx">
              <IconButton
                icon={<DownloadIcon />}
                size="sm"
                colorScheme="facebook"
                onClick={exportReport}
              />
            </Tooltip>
          </HStack>
          <Box w="full" overflow="auto" maxH="80vh">
            <Table size="sm">
              <Thead position="sticky" top="0" bg="white">
                <Tr>
                  <Th border="1px solid rgba(0,0,0,0.2)">Machine</Th>
                  <Th border="1px solid rgba(0,0,0,0.2)">Lot</Th>
                  <Th border="1px solid rgba(0,0,0,0.2)">Type</Th>
                  <Th border="1px solid rgba(0,0,0,0.2)">Party</Th>
                  <Th border="1px solid rgba(0,0,0,0.2)">Colour</Th>
                  <Th border="1px solid rgba(0,0,0,0.2)">Fabric</Th>
                  <Th border="1px solid rgba(0,0,0,0.2)">Load </Th>
                  <Th border="1px solid rgba(0,0,0,0.2)">Phase</Th>
                  <Th border="1px solid rgba(0,0,0,0.2)">Status</Th>
                  <Th border="1px solid rgba(0,0,0,0.2)">Loading</Th>
                  <Th border="1px solid rgba(0,0,0,0.2)">DyeSTD</Th>
                  <Th border="1px solid rgba(0,0,0,0.2)">DyeRT</Th>
                  <Th border="1px solid rgba(0,0,0,0.2)">Std.</Th>
                  <Th border="1px solid rgba(0,0,0,0.2)">Runtime</Th>
                  <Th border="1px solid rgba(0,0,0,0.2)">Extra</Th>
                  <Th border="1px solid rgba(0,0,0,0.2)"></Th>
                  <Th border="1px solid rgba(0,0,0,0.2)">Remarks(D)</Th>
                  <Th border="1px solid rgba(0,0,0,0.2)">Remarks(F)</Th>
                  <Th border="1px solid rgba(0,0,0,0.2)">Remarks(Q)</Th>
                </Tr>
              </Thead>
              <Tbody>
                {batchList?.length > 0 ? (
                  batchList?.map((item) => (
                    <Tr key={item.lotNumber} bgColor={getColor(item)}>
                      <Td border="1px solid rgba(0,0,0,0.2)">{item.machineName}</Td>
                      <Td border="1px solid rgba(0,0,0,0.2)">
                        <Tooltip label={`FC - ${item.lotNumber.split('-')[0]}`}>
                          {item.lotNumber.split('-')[1]}
                        </Tooltip>
                      </Td>
                      <Td border="1px solid rgba(0,0,0,0.2)">
                        <Tooltip
                          label={
                            item.batchType === 'Batch To Batch' ? 'Batch To Batch' : 'New Shade'
                          }>
                          {item.batchType === 'Batch To Batch' ? 'BTB' : 'NS'}
                        </Tooltip>{' '}
                        ({item.processType})
                      </Td>
                      <Td border="1px solid rgba(0,0,0,0.2)">
                        <Tooltip label={item.partyName}>
                          <Text>{item.partyName.substring(0, 10)}</Text>
                        </Tooltip>
                      </Td>
                      <Td border="1px solid rgba(0,0,0,0.2)">
                        <Tooltip label={item.color}>
                          <Text>{item?.color?.substring(0, 10)}</Text>
                        </Tooltip>
                      </Td>
                      <Td border="1px solid rgba(0,0,0,0.2)" w="90px">
                        {item.totalFabricWeight} Kg
                      </Td>
                      <Td border="1px solid rgba(0,0,0,0.2)">{item.loading} %</Td>
                      <Td border="1px solid rgba(0,0,0,0.2)">
                        <Tag colorScheme={mapStatusToColor(item.batchPhase)}>
                          {item.batchPhase === 'DYING' ? 'DYEING' : item.batchPhase}
                        </Tag>
                      </Td>
                      <Td border="1px solid rgba(0,0,0,0.2)" textAlign="center">
                        <Tag colorScheme={mapStatusToColor(item.batchStatus)}>
                          {item.batchStatus === 'IS PAUSED'
                            ? 'PAUSED'
                            : item.batchStatus === 'ON HOLD'
                            ? 'HOLD'
                            : item.batchStatus}
                        </Tag>
                      </Td>
                      <Td border="1px solid rgba(0,0,0,0.2)">
                        {dayjs(item.startTime).format('hh:mm A - DD/MMM/YYYY')}
                      </Td>
                      <Td border="1px solid rgba(0,0,0,0.2)">{item.dyeingTimeSTD}</Td>
                      <Td border="1px solid rgba(0,0,0,0.2)">{item.dyeingTimeTotal}</Td>

                      <Td border="1px solid rgba(0,0,0,0.2)">{item.stdTime}</Td>
                      <Td border="1px solid rgba(0,0,0,0.2)">{item.totalTime}</Td>
                      <Td border="1px solid rgba(0,0,0,0.2)">{item.extraTime}</Td>
                      <Td border="1px solid rgba(0,0,0,0.2)">
                        <Link fontSize="md" href={`${BATCHES}/${item.lotNumber}`} target="_blank">
                          <ExternalLinkIcon />
                        </Link>
                      </Td>
                      <Td border="1px solid rgba(0,0,0,0.2)" fontSize="10px">
                        {item.dyeingRemark && (
                          <Tooltip label={item.dyeingRemark}>
                            {item.dyeingRemark.substring(0, 14)}
                          </Tooltip>
                        )}
                      </Td>
                      <Td border="1px solid rgba(0,0,0,0.2)" fontSize="10px">
                        {item.finishingRemark && (
                          <Tooltip label={item.finishingRemark}>
                            {item.finishingRemark.substring(0, 14)}
                          </Tooltip>
                        )}
                      </Td>
                      <Td border="1px solid rgba(0,0,0,0.2)" fontSize="10px">
                        {item.qcRemark && (
                          <Tooltip label={item.qcRemark}>{item.qcRemark.substring(0, 14)}</Tooltip>
                        )}
                      </Td>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td colSpan={10}>No Batches To Show</Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default FullScreenListView;
