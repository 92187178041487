import { DownloadIcon } from '@chakra-ui/icons';
import {
  Box,
  Heading,
  HStack,
  IconButton,
  Input,
  Table,
  Tag,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import ExcelExport from 'export-xlsx';
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { batchDetailReport } from '../../api/batch';
import { BATCH_REPORT } from '../../constatnts/routerUrls';
import combineDateTime from '../../utilities/combineDateTime';
import { mapStatusToColor } from '../../utilities/mapStatusToColor';
import msToTime from '../../utilities/msToTime';

const BatchOptimizationReport = () => {
  const { date } = useParams();
  const [reportData, setReportData] = useState();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const data = await batchDetailReport(date);

      setReportData(data);
    })();
  }, [date]);

  const generateXL = () => {
    const excel = new ExcelExport();

    const SHEET_CONFIG = {
      fileName: `Batch report - ${dayjs(date).format('DD-MMM-YYYY')}`,
      workSheets: [
        {
          sheetName: 'On Time Batches',
          startingRowNumber: 1,
          tableSettings: {
            data: {
              importable: true,
              headerDefinition: [
                {
                  name: 'Machine',
                  key: 'machineName',
                },
                {
                  name: 'Lot Number',
                  key: 'lotNumber',
                },
                {
                  name: 'Fabric Weight',
                  key: 'totalFabricWeight',
                },
                {
                  name: 'Batch Type',
                  key: 'batchType',
                },
                {
                  name: 'Finishing Type',
                  key: 'finishingType',
                },
                {
                  name: 'Process Type',
                  key: 'processType',
                },
                {
                  name: 'Priority',
                  key: 'priority',
                },
                {
                  name: 'Status',
                  key: 'status',
                },
                {
                  name: 'Current Task',
                  key: 'currentTask',
                },
                {
                  name: 'Load Date',
                  key: 'loadDate',
                },
                {
                  name: 'Load Time',
                  key: 'loadTime',
                },
                {
                  name: 'Delay (ms)',
                  key: 'delay',
                },
              ],
            },
          },
        },
        {
          sheetName: 'Delayed Batches',
          startingRowNumber: 1,
          tableSettings: {
            data: {
              importable: true,
              headerDefinition: [
                {
                  name: 'Machine',
                  key: 'machineName',
                },
                {
                  name: 'Lot Number',
                  key: 'lotNumber',
                },
                {
                  name: 'Fabric Weight',
                  key: 'totalFabricWeight',
                },
                {
                  name: 'Batch Type',
                  key: 'batchType',
                },
                {
                  name: 'Finishing Type',
                  key: 'finishingType',
                },
                {
                  name: 'Process Type',
                  key: 'processType',
                },
                {
                  name: 'Priority',
                  key: 'priority',
                },
                {
                  name: 'Status',
                  key: 'status',
                },
                {
                  name: 'Current Task',
                  key: 'currentTask',
                },
                {
                  name: 'Load Date',
                  key: 'loadDate',
                },
                {
                  name: 'Load Time',
                  key: 'loadTime',
                },
                {
                  name: 'Delay (ms)',
                  key: 'delay',
                },
              ],
            },
          },
        },
        {
          sheetName: 'Halted Batches',
          startingRowNumber: 1,
          tableSettings: {
            data: {
              importable: true,
              headerDefinition: [
                {
                  name: 'Machine',
                  key: 'machineName',
                },
                {
                  name: 'Lot Number',
                  key: 'lotNumber',
                },
                {
                  name: 'Fabric Weight',
                  key: 'totalFabricWeight',
                },
                {
                  name: 'Batch Type',
                  key: 'batchType',
                },
                {
                  name: 'Finishing Type',
                  key: 'finishingType',
                },
                {
                  name: 'Process Type',
                  key: 'processType',
                },
                {
                  name: 'Priority',
                  key: 'priority',
                },
                {
                  name: 'Status',
                  key: 'status',
                },
                {
                  name: 'Current Task',
                  key: 'currentTask',
                },
                {
                  name: 'Load Date',
                  key: 'loadDate',
                },
                {
                  name: 'Load Time',
                  key: 'loadTime',
                },
                {
                  name: 'Delay (ms)',
                  key: 'delay',
                },
              ],
            },
          },
        },
        {
          sheetName: 'Paused Batches',
          startingRowNumber: 1,
          tableSettings: {
            data: {
              importable: true,
              headerDefinition: [
                {
                  name: 'Machine',
                  key: 'machineName',
                },
                {
                  name: 'Lot Number',
                  key: 'lotNumber',
                },
                {
                  name: 'Fabric Weight',
                  key: 'totalFabricWeight',
                },
                {
                  name: 'Batch Type',
                  key: 'batchType',
                },
                {
                  name: 'Finishing Type',
                  key: 'finishingType',
                },
                {
                  name: 'Process Type',
                  key: 'processType',
                },
                {
                  name: 'Priority',
                  key: 'priority',
                },
                {
                  name: 'Status',
                  key: 'status',
                },
                {
                  name: 'Current Task',
                  key: 'currentTask',
                },
                {
                  name: 'Load Date',
                  key: 'loadDate',
                },
                {
                  name: 'Load Time',
                  key: 'loadTime',
                },
                {
                  name: 'Delay (ms)',
                  key: 'delay',
                },
              ],
            },
          },
        },
      ],
    };
    const delayedBatches = reportData?.delayedBatches;
    const haltedBatches = reportData?.haltedBatches;
    const pausedBatches = reportData?.pausedBatches;
    const onTimeBatches = reportData?.onTimeBatches;

    excel.downloadExcel(SHEET_CONFIG, [
      { data: onTimeBatches },
      { data: delayedBatches },
      { data: haltedBatches },
      { data: pausedBatches },
    ]);
  };

  const handleDateChange = (value) => {
    history.push(`${BATCH_REPORT}/${new Date(value).toISOString()}`);
    window.location.reload(false);
  };

  const getColor = (phase) => {
    if (phase === 'DYING') {
      return 'yellow';
    }
    if (phase === 'FINISHING') {
      return 'orange';
    } else {
      return 'green';
    }
  };
  return (
    <Box w="full" boxSizing="border-box" p="15px">
      <HStack
        w="full"
        justify="space-between"
        align="center"
        boxSizing="border-box"
        padding="15px"
        rounded="md"
        bg="gray.100">
        <HStack>
          <Heading fontSize="lg">Batch Report</Heading>
          <Input
            value={dayjs(date).format('YYYY-MM-DD')}
            w="200px"
            bg="white"
            onChange={(e) => handleDateChange(e.target.value)}
            type="date"
          />
        </HStack>
        <HStack>
          <Tooltip label="Download XLSX">
            <IconButton
              icon={<DownloadIcon />}
              colorScheme="facebook"
              size="sm"
              onClick={generateXL}
            />
          </Tooltip>
        </HStack>
      </HStack>

      <Heading mt="15px">On Time Batches</Heading>
      <Table my="15px" size="sm" variant="fill">
        <Thead>
          <Tr>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              Lot
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              Party
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              Machine
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              Weight (KG)
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              Type
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              T/O
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              Priority
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              Phase
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              Status
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="150px">
              Current Task
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="140px">
              Loading Time
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              Delay
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {reportData?.onTimeBatches?.length > 0 ? (
            reportData?.onTimeBatches?.map((batch) => (
              <Tr key={batch.lotNumber}>
                <Td border="1px solid rgba(0,0,0,0.5)">{batch.lotNumber}</Td>
                <Td border="1px solid rgba(0,0,0,0.5)">{batch.party}</Td>
                <Td border="1px solid rgba(0,0,0,0.5)">{batch.machineName}</Td>
                <Td border="1px solid rgba(0,0,0,0.5)">{batch.totalFabricWeight}</Td>
                <Td border="1px solid rgba(0,0,0,0.5)">
                  {batch.batchType === 'Batch To Batch' ? 'BTB' : 'NS'} - {batch.processType}
                </Td>
                <Td border="1px solid rgba(0,0,0,0.5)">{batch.finishingType}</Td>
                <Td border="1px solid rgba(0,0,0,0.5)">
                  <Tag colorScheme={mapStatusToColor(batch.priority)}>{batch.priority}</Tag>
                </Td>
                <Td border="1px solid rgba(0,0,0,0.5)">
                  <Tag colorScheme={getColor(batch.phase)}>{batch.phase}</Tag>
                </Td>
                <Td border="1px solid rgba(0,0,0,0.5)">
                  <Tag colorScheme={mapStatusToColor(batch.status)}>
                    {batch.status === 'IS PAUSED'
                      ? 'PAUSED'
                      : batch.status === 'ON HOLD'
                      ? 'HALTED'
                      : batch.status === 'STARTED'
                      ? 'RUNNING'
                      : batch.status}
                  </Tag>
                </Td>
                <Td border="1px solid rgba(0,0,0,0.5)">{batch.currentTask}</Td>
                <Td border="1px solid rgba(0,0,0,0.5)">
                  {combineDateTime(batch.loadDate, batch.loadTime)}
                </Td>
                <Td border="1px solid rgba(0,0,0,0.5)">{msToTime(batch.delay)}</Td>
              </Tr>
            ))
          ) : (
            <Tr border="1px solid rgba(0,0,0,0.5)">
              <Td colSpan={2}>
                <Heading fontSize="lg">No on time batches to show</Heading>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      <Heading mt="15px">Delayed Batches</Heading>
      <Table my="15px" size="sm" variant="fill">
        <Thead>
          <Tr>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              Lot
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              Party
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              Machine
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              Weight (KG)
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              Type
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              T/O
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              Priority
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              Phase
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              Status
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="150px">
              Current Task
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="140px">
              Loading Time
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              Delay
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {reportData?.delayedBatches?.length > 0 ? (
            reportData?.delayedBatches?.map((batch) => (
              <Tr key={batch.lotNumber}>
                <Td border="1px solid rgba(0,0,0,0.5)">{batch.lotNumber}</Td>
                <Td border="1px solid rgba(0,0,0,0.5)">{batch.party}</Td>
                <Td border="1px solid rgba(0,0,0,0.5)">{batch.machineName}</Td>
                <Td border="1px solid rgba(0,0,0,0.5)">{batch.totalFabricWeight}</Td>
                <Td border="1px solid rgba(0,0,0,0.5)">
                  {batch.batchType === 'Batch To Batch' ? 'BTB' : 'NS'} - {batch.processType}
                </Td>
                <Td border="1px solid rgba(0,0,0,0.5)">{batch.finishingType}</Td>
                <Td border="1px solid rgba(0,0,0,0.5)">
                  <Tag colorScheme={mapStatusToColor(batch.priority)}>{batch.priority}</Tag>
                </Td>
                <Td border="1px solid rgba(0,0,0,0.5)">
                  <Tag colorScheme={getColor(batch.phase)}>{batch.phase}</Tag>
                </Td>
                <Td border="1px solid rgba(0,0,0,0.5)">
                  <Tag colorScheme={mapStatusToColor(batch.status)}>
                    {batch.status === 'IS PAUSED'
                      ? 'PAUSED'
                      : batch.status === 'ON HOLD'
                      ? 'HALTED'
                      : batch.status === 'STARTED'
                      ? 'RUNNING'
                      : batch.status}
                  </Tag>
                </Td>
                <Td border="1px solid rgba(0,0,0,0.5)">{batch.currentTask}</Td>
                <Td border="1px solid rgba(0,0,0,0.5)">
                  {combineDateTime(batch.loadDate, batch.loadTime)}
                </Td>
                <Td border="1px solid rgba(0,0,0,0.5)">{msToTime(batch.delay)}</Td>
              </Tr>
            ))
          ) : (
            <Tr border="1px solid rgba(0,0,0,0.5)">
              <Td colSpan={2}>
                <Heading fontSize="lg">No delayed batches to show</Heading>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      <Heading mt="15px">Halted Batches</Heading>
      <Table my="15px" size="sm" variant="fill">
        <Thead>
          <Tr>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              Lot
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              Party
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              Machine
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              Weight (KG)
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              Type
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              T/O
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              Priority
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              Phase
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              Status
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="150px">
              Current Task
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="140px">
              Loading Time
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              Delay
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {reportData?.haltedBatches?.length > 0 ? (
            reportData?.haltedBatches?.map((batch) => (
              <Tr key={batch.lotNumber}>
                <Td border="1px solid rgba(0,0,0,0.5)">{batch.lotNumber}</Td>
                <Td border="1px solid rgba(0,0,0,0.5)">{batch.party}</Td>
                <Td border="1px solid rgba(0,0,0,0.5)">{batch.machineName}</Td>
                <Td border="1px solid rgba(0,0,0,0.5)">{batch.totalFabricWeight}</Td>
                <Td border="1px solid rgba(0,0,0,0.5)">
                  {batch.batchType === 'Batch To Batch' ? 'BTB' : 'NS'} - {batch.processType}
                </Td>
                <Td border="1px solid rgba(0,0,0,0.5)">{batch.finishingType}</Td>
                <Td border="1px solid rgba(0,0,0,0.5)">
                  <Tag colorScheme={mapStatusToColor(batch.priority)}>{batch.priority}</Tag>
                </Td>
                <Td border="1px solid rgba(0,0,0,0.5)">
                  <Tag colorScheme={getColor(batch.phase)}>{batch.phase}</Tag>
                </Td>
                <Td border="1px solid rgba(0,0,0,0.5)">
                  <Tag colorScheme={mapStatusToColor(batch.status)}>
                    {batch.status === 'IS PAUSED'
                      ? 'PAUSED'
                      : batch.status === 'ON HOLD'
                      ? 'HALTED'
                      : batch.status === 'STARTED'
                      ? 'RUNNING'
                      : batch.status}
                  </Tag>
                </Td>
                <Td border="1px solid rgba(0,0,0,0.5)">{batch.currentTask}</Td>
                <Td border="1px solid rgba(0,0,0,0.5)">
                  {combineDateTime(batch.loadDate, batch.loadTime)}
                </Td>
                <Td border="1px solid rgba(0,0,0,0.5)">{msToTime(batch.delay)}</Td>
              </Tr>
            ))
          ) : (
            <Tr border="1px solid rgba(0,0,0,0.5)">
              <Td colSpan={2}>
                <Heading fontSize="lg">No halted batches to show</Heading>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      <Heading mt="15px">Paused Batches</Heading>
      <Table my="15px" size="sm" variant="fill">
        <Thead>
          <Tr>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              Lot
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              party
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              Machine
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              Weight (KG)
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              Type
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              T/O
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              Priority
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              Phase
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              Status
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="150px">
              Current Task
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="140px">
              Loading Time
            </Th>
            <Th border="1px solid rgba(0,0,0,0.5)" w="100px">
              Delay
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {reportData?.pausedBatches?.length > 0 ? (
            reportData?.pausedBatches?.map((batch) => (
              <Tr key={batch.lotNumber}>
                <Td border="1px solid rgba(0,0,0,0.5)">{batch.lotNumber}</Td>
                <Td border="1px solid rgba(0,0,0,0.5)">{batch.party}</Td>

                <Td border="1px solid rgba(0,0,0,0.5)">{batch.machineName}</Td>
                <Td border="1px solid rgba(0,0,0,0.5)">{batch.totalFabricWeight}</Td>
                <Td border="1px solid rgba(0,0,0,0.5)">
                  {batch.batchType === 'Batch To Batch' ? 'BTB' : 'NS'} - {batch.processType}
                </Td>
                <Td border="1px solid rgba(0,0,0,0.5)">{batch.finishingType}</Td>
                <Td border="1px solid rgba(0,0,0,0.5)">
                  <Tag colorScheme={mapStatusToColor(batch.priority)}>{batch.priority}</Tag>
                </Td>
                <Td border="1px solid rgba(0,0,0,0.5)">
                  <Tag colorScheme={getColor(batch.phase)}>{batch.phase}</Tag>
                </Td>
                <Td border="1px solid rgba(0,0,0,0.5)">
                  <Tag colorScheme={mapStatusToColor(batch.status)}>
                    {batch.status === 'IS PAUSED'
                      ? 'PAUSED'
                      : batch.status === 'ON HOLD'
                      ? 'HALTED'
                      : batch.status === 'STARTED'
                      ? 'RUNNING'
                      : batch.status}
                  </Tag>
                </Td>
                <Td border="1px solid rgba(0,0,0,0.5)">{batch.currentTask}</Td>
                <Td border="1px solid rgba(0,0,0,0.5)">
                  {combineDateTime(batch.loadDate, batch.loadTime)}
                </Td>
                <Td border="1px solid rgba(0,0,0,0.5)">{msToTime(batch.delay)}</Td>
              </Tr>
            ))
          ) : (
            <Tr border="1px solid rgba(0,0,0,0.5)">
              <Td colSpan={2}>
                <Heading fontSize="lg">No paused batches to show</Heading>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  );
};

export default BatchOptimizationReport;
