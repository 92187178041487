import React from 'react';
import {
  Badge,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { mapStatusToColor } from '../../utilities/mapStatusToColor';
import { useEffect } from 'react';
import { getFilteredMachineList, reAssignMachine } from '../../api/batch';
import { useState } from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import AlertBox from '../common/AlertBox';
import toastFormat from '../../constatnts/toastFormat';

const MachineEditDrawer = ({
  machine,
  closeDrawer,
  isDrawerOpen,
  fabricWeight,
  batchId,
  refresh,
}) => {
  const [machineList, setMachineList] = useState();
  const [selectedMachine, setSelectedMachine] = useState();
  const [showAlert, setShowAlert] = useState(false);

  const toast = useToast();

  useEffect(() => {
    (async () => {
      if (fabricWeight) {
        const response = await getFilteredMachineList(fabricWeight);
        setMachineList(response.machines);
      }
    })();
  }, [isDrawerOpen]);

  const handleMachienReAssign = async () => {
    const response = await reAssignMachine(selectedMachine?.id, batchId);
    if (response === 200) {
      toast({
        title: 'Machine reassigned',
        status: 'success',
        ...toastFormat,
      });
      await refresh();
    } else {
      toast({
        title: 'Failed to reassign machine',
        status: 'error',
        ...toastFormat,
      });
    }

    setShowAlert(false);
    closeDrawer();
  };

  return (
    <>
      <AlertBox
        title="Assign new machine"
        subTitle="Are you sure you want to assign this machine?"
        onClose={() => {
          setShowAlert(false);
          closeDrawer();
        }}
        onSubmit={handleMachienReAssign}
        show={showAlert}
      />
      <Drawer isOpen={isDrawerOpen} size="sm" onClose={closeDrawer}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Assign another machine</DrawerHeader>
          <DrawerBody>
            <Box>
              <Heading fontSize="lg" color="#272739">
                Currently Assigned Machine
              </Heading>
              <Box
                w="100%"
                padding="15px"
                boxSizing="border-box"
                border="1px solid rgba(0,0,0,0.2)"
                mt="15px"
                rounded="md"
              >
                <VStack align="flex-start">
                  <Text fontSize="sm" color="gray.600" fontWeight="bold" letterSpacing="2px">
                    {machine?.name}{' '}
                    <Badge colorScheme={mapStatusToColor(machine?.status)}>{machine?.status}</Badge>
                  </Text>
                  <Text fontSize="sm" color="gray.600" fontWeight="bold">
                    Max Capacity <Badge colorScheme="gray">{machine?.maxCapacity} KG</Badge>
                  </Text>
                  <Text fontSize="sm" color="gray.600" fontWeight="bold">
                    Machine Usage{' '}
                    <Badge colorScheme="gray">
                      {Math.floor((fabricWeight / machine?.maxCapacity) * 100)}%
                    </Badge>
                  </Text>
                </VStack>
              </Box>
            </Box>

            <Box mt="30px">
              <Heading fontSize="lg" color="#272739">
                Change Current Machine
              </Heading>
              <Box
                w="100%"
                padding="15px"
                boxSizing="border-box"
                border="1px solid rgba(0,0,0,0.2)"
                mt="15px"
                rounded="md"
              >
                <FormControl isInvalid={!selectedMachine}>
                  <FormLabel>Assign Machine</FormLabel>
                  <Menu>
                    <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                      {selectedMachine ? selectedMachine.name : 'Please select a machine'}
                    </MenuButton>
                    <MenuList>
                      {machineList?.length > 0 ? (
                        machineList.map((item) => (
                          <MenuItem key={item.name} onClick={() => setSelectedMachine(item)}>
                            {item.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>No Machine Available</MenuItem>
                      )}
                    </MenuList>
                  </Menu>
                  <FormErrorMessage>Please select a machine</FormErrorMessage>
                </FormControl>
                <Button
                  type="submit"
                  mt="15px"
                  colorScheme="blue"
                  onClick={() => {
                    if (selectedMachine && batchId) {
                      setShowAlert(true);
                    } else {
                      toast({
                        title: 'Please Select a machine first',
                        status: 'warning',
                        ...toastFormat,
                      });
                    }
                  }}
                >
                  Re Assign {selectedMachine?.name}
                </Button>
              </Box>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default MachineEditDrawer;
