import { Box } from '@chakra-ui/layout';
import React, { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import BatchDetailForm from '../../components/batch/BatchDetailForm';
import BatchTemplateForm from '../../components/batch/BatchTemplateForm';
import FabricAndMachineForm from '../../components/batch/FabricAndMachineForm';
import StepperForm from '../../components/common/StepperForm';
import {
  batchDetailFromValidator,
  useYupValidationResolver,
} from '../../validators/form-validators';

const BatchCreation = () => {
  const [step, setStep] = useState(0);
  const resolver = useYupValidationResolver(batchDetailFromValidator[step]);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    setError,
    formState: { isValid, errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      customer: null,
      processType: '',
      lotNumber: '',
      finishingType: '',
      deliveryDate: null,
      priority: 'LOW',
      loadTime: null,
      loadDate: null,
      fabricInfo: [{ type: '', weight: 0 }],
      machine: null,
      creator: null,
      dyeingOperator: null,
      finishingOperator: null,
      qcOperator: null,
      batchType: 'New Shade',
      tasks: [
        {
          taskType: '',
          formFields: [],
          taskName: '',
          standardTime: null,
        },
      ],
    },
    resolver,
  });

  const { fields, remove, append } = useFieldArray({ control, name: 'fabricInfo' });

  const {
    fields: processFields,
    remove: processRemove,
    append: processAppend,
  } = useFieldArray({ control, name: 'tasks' });

  const watchAllField = watch();

  const steps = [
    {
      label: 'Batch Detail',
      Form: (
        <BatchDetailForm
          control={control}
          next={setStep}
          errors={errors}
          register={register}
          watchAllField={watchAllField}
          setValue={setValue}
          isValid={isValid}
          handleSubmit={handleSubmit}
          setError={setError}
        />
      ),
    },
    {
      label: 'Fabric & Machine Info',
      Form: (
        <FabricAndMachineForm
          stepUpdate={setStep}
          next={setStep}
          errors={errors}
          register={register}
          watchAllField={watchAllField}
          setValue={setValue}
          isValid={isValid}
          fields={fields}
          control={control}
          remove={remove}
          append={append}
          update={setStep}
          setError={setError}
          handleSubmit={handleSubmit}
        />
      ),
    },
    {
      label: 'Batch Template',
      Form: (
        <BatchTemplateForm
          watchAllField={watchAllField}
          fields={processFields}
          control={control}
          remove={processRemove}
          append={processAppend}
          errors={errors}
          register={register}
          setValue={setValue}
          isValid={isValid}
          handleSubmit={handleSubmit}
          update={setStep}
        />
      ),
    },
  ];

  return (
    <Box>
      <StepperForm
        steps={steps}
        currentStep={step}
        isFull={step === 2 || step === 0 ? true : false}
      />
    </Box>
  );
};

export default BatchCreation;
