import axios from './axios';

export const getAllChemicals = async (query) => {
  try {
    const response = await axios.get('/dyes-chemicals/chemicals', { params: query });
    return {
      status: response.data.status,
      chemicals: response.data.chemicals,
      totalEntries: response.data.totalEntries,
    };
  } catch (error) {
    return error;
  }
};

export const getAllDyeGroups = async (query) => {
  try {
    const response = await axios.get('/dyes-chemicals/dye-groups', { params: query });
    return {
      status: response.data.status,
      dyeGroups: response.data.dyeGroups,
      totalEntries: response.data.totalEntries,
    };
  } catch (error) {
    return error;
  }
};

export const addNewChemical = async (data) => {
  try {
    const response = await axios.post('/dyes-chemicals/chemicals', data);
    return {
      status: response.data.status,
      msg: response.data.msg,
    };
  } catch (error) {
    return error;
  }
};

export const addNewDyeGroup = async (data) => {
  try {
    const response = await axios.post('/dyes-chemicals/dye-groups', data);
    return {
      status: response.data.status,
      msg: response.data.msg,
    };
  } catch (error) {
    return error;
  }
};

export const addNewDye = async (data) => {
  try {
    const response = await axios.post('/dyes-chemicals/dye', data);
    return {
      status: response.data.status,
      msg: response.data.msg,
    };
  } catch (error) {
    return error;
  }
};

export const deleteChemical = async (uid) => {
  try {
    const response = await axios.delete(`/dyes-chemicals/chemicals/${uid}`);
    return {
      status: response.data.status,
      msg: response.data.msg,
    };
  } catch (error) {
    return error;
  }
};

export const deleteDye = async (dyid) => {
  try {
    const response = await axios.delete(`/dyes-chemicals/dye/${dyid}`);
    return {
      status: response.data.status,
      msg: response.data.msg,
    };
  } catch (error) {
    return error;
  }
};

export const deleteDyeGroup = async (uid) => {
  try {
    const response = await axios.delete(`/dyes-chemicals/dye-groups/${uid}`);
    return {
      status: response.data.status,
      msg: response.data.msg,
    };
  } catch (error) {
    return error;
  }
};

export const getAllDyes = async (uid) => {
  try {
    const response = await axios.get(`/dyes-chemicals/dye-groups/${uid}`);
    return {
      status: response.data.status,
      dyes: response.data.dyes,
    };
  } catch (error) {
    return error;
  }
};
