/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Box, Center, Heading, Text } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/spinner';
import { useHistory, useParams } from 'react-router';
import { useToast } from '@chakra-ui/toast';
import { addRecipe, fetchBatchProgressDeatilForRecipe } from '../../api/batch';
import { Flex, Input } from '@chakra-ui/react';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { useFieldArray, useForm } from 'react-hook-form';
import { DeleteIcon } from '@chakra-ui/icons';
import { Select } from '@chakra-ui/select';
import { IconButton, Button } from '@chakra-ui/button';
import { Table, Tbody, Thead, Tr, Td, Th } from '@chakra-ui/table';
import { BATCHES } from '../../constatnts/routerUrls';
import RecipeStepperForm from '../../components/batch/recipe/RecipeStepperForm';
import toastFormat from '../../constatnts/toastFormat';

const RecipeCreationForm = () => {
  const [loading, setLoading] = useState(false);
  const [batch, setBatch] = useState(null);
  const [step, setStep] = useState(0);

  const { lotNumber } = useParams();
  const toast = useToast();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await fetchBatchProgressDeatilForRecipe(lotNumber);
        setBatch(response.batch);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast({
          title: 'Failed to fetch info',
          status: 'error',
          ...toastFormat,
        });
      }
    })();
  }, []);

  const { register, watch } = useForm({
    defaultValues: {
      liqureRatio: 7,
    },
  });

  const watchAllFields = watch();

  const modifyBatchList = (tasks) => [...tasks['DYING'], ...tasks['FINISHING'], ...tasks['QC']];

  const totalSteps = () => {
    const tasks = modifyBatchList(batch.tasks);
    let total = 0;
    tasks.map((task) => (task.isRecipeRequired ? (total += 1) : 0));
    return total;
  };

  const steps = () => {
    const tasks = modifyBatchList(batch.tasks);

    let filteredTasks = tasks.filter((task) => task.isRecipeRequired === true);

    return filteredTasks;
  };

  const handleSubmit = async () => {
    const response = await addRecipe({ ...batch, liqureRatio: watchAllFields.liqureRatio });
    return response.status;
  };

  if (loading) {
    return (
      <Center>
        <Spinner size="lg" colorScheme="blue" />
      </Center>
    );
  } else {
    return (
      <Box w="95%" mx="30px" my="30px">
        {batch && (
          <Box>
            <Flex w="996px">
              <FormControl>
                <FormLabel>Lot Number</FormLabel>
                <Input value={lotNumber} disabled={true} />
              </FormControl>
              <FormControl ml="15px">
                <FormLabel>Total Weight (KG)</FormLabel>
                <Input
                  value={batch?.totalFabricWeight}
                  onChange={(e) =>
                    setBatch({ ...batch, totalFabricWeight: parseFloat(e.target.value) })
                  }
                  disabled={batch.isHeatSetRequired ? false : true}
                />
              </FormControl>
              <FormControl ml="15px">
                <FormLabel>Total Liquor (G/L)</FormLabel>
                <Input
                  value={watchAllFields.liqureRatio * batch.totalFabricWeight}
                  disabled={true}
                />
              </FormControl>
              <FormControl ml="15px">
                <FormLabel>Liquor Ratio</FormLabel>
                <Input type="number" min={0} defaultValue={7} {...register('liqureRatio')} />
              </FormControl>
            </Flex>

            <Heading fontSize="xl" mt="25px" mb="15px">
              Fabric Info
            </Heading>
            <Table w="500px" my="20px" size="sm">
              <Thead>
                <Tr>
                  <Th>Fabric type</Th>
                  <Th>Weight (KG)</Th>
                </Tr>
              </Thead>
              <Tbody>
                {batch.fabricInfo.map((fabric) => (
                  <Tr key={JSON.stringify(fabric)}>
                    <Td>{fabric.fabricType}</Td>
                    <Td>{fabric.quantity}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <RecipeStepperForm
              totalStep={totalSteps()}
              currentStep={step}
              steps={steps()}
              updateStep={setStep}
              liqureRatio={watchAllFields.liqureRatio}
              updateBatch={setBatch}
              batch={batch}
              submit={handleSubmit}
            />
          </Box>
        )}
        <Text textColor="red.400" fontSize="sm">
          Calculation (GL) = Total Fabric weight x Liqure Ratio x Quantity
        </Text>
        <Text textColor="red.400" fontSize="sm">
          Calculation (%) = Total Fabric weight x Quantity x (Change x 0.01) x 10
        </Text>
      </Box>
    );
  }
};

export default RecipeCreationForm;
