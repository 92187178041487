/* eslint-disable no-unused-vars */
import React from 'react';
import { IconButton, Tooltip } from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';
import ExcelExport from 'export-xlsx';
import dayjs from 'dayjs';
import { useWord } from '../../utilities/hooks/useWord';

const CsvExport = ({ queryParams, title, dataCols, query }) => {
  const generateHeaderDefs = () => {
    const defs = [];

    dataCols.map((col) =>
      defs.push({
        name: useWord(col),
        key: col,
      }),
    );
    return defs;
  };

  const SHEET_CONFIG = {
    fileName: title + ' - ' + dayjs(new Date()).format('DD MMM YYYY'),
    workSheets: [
      {
        sheetName: title,
        startingRowNumber: 1,
        tableSettings: {
          data: { importable: true, headerDefinition: generateHeaderDefs() },
        },
      },
    ],
  };

  const handleExport = async () => {
    const excelExport = new ExcelExport();
    const response = await query({ ...queryParams, isPrint: true });
    excelExport.downloadExcel(SHEET_CONFIG, [{ data: response }]);
  };
  return (
    <Tooltip label="Download as XLSX">
      <IconButton icon={<DownloadIcon />} ml="15px" onClick={handleExport} />
    </Tooltip>
  );
};

export default CsvExport;
