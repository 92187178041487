import { Button } from '@chakra-ui/button';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/form-control';
import { Image } from '@chakra-ui/image';
import { Input } from '@chakra-ui/input';
import { Box, Flex, Heading, HStack, Text } from '@chakra-ui/layout';
import { PinInput, PinInputField } from '@chakra-ui/pin-input';
import { useToast } from '@chakra-ui/toast';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router';
import { signIn, verifyOtp } from '../api/auth';
import { DASHBOARD_ROUTE } from '../constatnts/routerUrls';
import { LOGIN, OTP_VERIFY } from '../store/actions/action-types';
import LOGO from '../assets/images/logo.svg';
import { signInformValidator, useYupValidationResolver } from '../validators/form-validators';
import Countdown from 'react-countdown';
import toastFormat from '../constatnts/toastFormat';
import { Link } from '@chakra-ui/react';

const SignIn = () => {
  const resolver = useYupValidationResolver(signInformValidator);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver,
  });
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const tempUserData = useSelector((state) => state.auth.tempUserData);
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [openOtpView, setopenOtpView] = useState(false);
  const [otpValid, setOtpValid] = useState(true);

  const submit = async (otp) => {
    try {
      setLoading(true);
      const { data } = await verifyOtp(tempUserData, otp, 'login-verify');
      if (data) {
        dispatch({
          type: OTP_VERIFY,
          payload: {
            sessionId: data.sessionId,
            accessToken: data.accessToken,
            user: data.user,
            refreshToken: data.refreshToken,
          },
        });
        toast({
          title: `Welcome ${data.user.name}`,
          status: 'success',
          ...toastFormat,
        });
        setLoading(false);
      } else {
        setOtpValid(false);
        toast({
          title: 'OTP is incorrect',
          status: 'error',
          ...toastFormat,
        });
        setLoading(false);
      }
    } catch (error) {
      toast({
        title: 'Wrong OTP',
        status: 'error',
        ...toastFormat,
      });
      setLoading(false);
    }
  };

  const handleSignIn = async (identifier) => {
    try {
      setLoading(true);
      const { data } = await signIn(identifier.username, identifier.password);

      if (data) {
        dispatch({
          type: LOGIN,
          payload: {
            tempUserData: data,
          },
        });
        toast({
          title: 'Credentials verified',
          status: 'success',
          ...toastFormat,
        });
        setopenOtpView(true);
        setLoading(false);
      } else {
        toast({
          title: 'Wrong username or password',
          status: 'error',
          ...toastFormat,
        });
        setLoading(false);
      }
    } catch (error) {
      toast({
        title: 'Server error',
        status: 'error',
        ...toastFormat,
      });
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setopenOtpView(false);
    setOtpValid(true);
  };

  const SignInForm = () => {
    return (
      <>
        <Flex width="100%" height="100%" direction="column" align="center" justify="center">
          <Image src={LOGO} w="200px" mb="35px" />
          <Heading marginBottom="30px">SIGN IN</Heading>
          <form action="">
            <Flex width="310px" direction="column" align="center" justify="center">
              <FormControl id="username" isRequired mb={5} isInvalid={errors?.username}>
                <FormLabel>Username</FormLabel>
                <Input
                  {...register('username')}
                  placeholder="Username"
                  size="lg"
                  variant="filled"
                  required
                  errorBorderColor="red.200"
                />
                <FormErrorMessage>{errors?.username?.message}</FormErrorMessage>
              </FormControl>
              <FormControl id="password" isRequired mb={5} isInvalid={errors?.password}>
                <FormLabel>Password</FormLabel>
                <Input
                  {...register('password')}
                  placeholder="Password"
                  size="lg"
                  variant="filled"
                  type="password"
                  required
                  errorBorderColor="red.200"
                />
                <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
              </FormControl>
              <Button
                isLoading={loading}
                type="submit"
                colorScheme="facebook"
                onClick={handleSubmit(handleSignIn)}
                size="md"
                width="100%"
              >
                SIGN IN
              </Button>
              <Link
                p="8px 15px"
                textAlign="center"
                fontSize="md"
                fontWeight="bold"
                rounded="md"
                mt="15px"
                w="100%"
                bgColor="teal"
                color="white"
                _hover={{ bgColor: 'green.500' }}
                download={true}
                href={`${process.env.REACT_APP_API_BASEURL}/app/download`}
              >
                Download APP
              </Link>
            </Flex>
          </form>
        </Flex>
      </>
    );
  };

  const OTPView = () => {
    return (
      <Flex width="100%" height="100%" direction="column" align="center" justify="center">
        <Heading fontSize="20px" textAlign="center">
          Please enter OTP <br /> Sent to your verified email address
        </Heading>
        <HStack margin="30px 0">
          <PinInput otp size="lg" mask onComplete={submit} isDisabled={!otpValid}>
            <PinInputField size="lg" />
            <PinInputField size="lg" />
            <PinInputField size="lg" />
            <PinInputField size="lg" />
            <PinInputField size="lg" />
            <PinInputField size="lg" />
          </PinInput>
        </HStack>
        {otpValid ? (
          <Countdown
            zeroPadTime={2}
            date={Date.now() + 240000}
            onComplete={() => {
              setOtpValid(false);
              toast({
                title: 'OTP is expired',
                status: 'warning',
                ...toastFormat,
              });
            }}
            renderer={({ minutes, seconds }) => (
              <Flex>
                <Text mb={15} fontSize={16} color="gray.600" fontWeight="bold">
                  Valid till{' '}
                </Text>
                <Text fontWeight="bold" ml={15} color="red.400">
                  {minutes}:{seconds === 0 ? '00' : seconds}
                </Text>
              </Flex>
            )}
          />
        ) : (
          <Flex width="310px">
            <Flex width="100%">
              <Button colorScheme="red" onClick={handleCancel} width="100%">
                Try Again
              </Button>
            </Flex>
          </Flex>
        )}
      </Flex>
    );
  };

  if (isLoggedIn) {
    return (
      <Redirect
        to={{
          pathname: DASHBOARD_ROUTE,
        }}
      />
    );
  } else {
    return (
      <Box width="100%" height="100vh" backgroundColor="#f7f7f7">
        {openOtpView ? <OTPView /> : <SignInForm />}
      </Box>
    );
  }
};

export default SignIn;
