import { useCallback } from 'react';
import * as yup from 'yup';

export const useYupValidationResolver = (validationSchema) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? 'validation',
                message: currentError.message,
              },
            }),
            {},
          ),
        };
      }
    },
    [validationSchema],
  );

export const signInformValidator = yup.object().shape({
  username: yup.string().required(),
  password: yup.string().required(),
});

export const userCreationFormValidator = yup.object().shape({
  name: yup.string().min(3).required('Must enter name'),
  username: yup.string().min(6).required('Must enter username'),
  password: yup.string().min(6).required('Must enter password'),
  phone: yup
    .string()
    .matches(/^(?:|01)?(?:\d{11}|)$/, 'Invalid format for phone number')
    .required('Must enter phone number'),
});

export const machineCreationValidator = yup.object().shape({
  name: yup.string().min(3).required('Must enter name'),
  capacity: yup
    .number()
    .typeError('Can not zero')
    .positive('must be greater than 0')
    .required('Must enter capacity'),
  maxCapacity: yup
    .number()
    .typeError('Can not zero')
    .positive('must be greater than 0')
    .required('Must enter max capacity'),
  minCapacity: yup
    .number()
    .typeError('Can not zero')
    .positive('must be greater than 0')
    .required('Must enter min capacity'),
  machineType: yup.string().min(3).required('Must select machine type'),
});

export const fcCreationValidator = yup.object().shape({
  fcNumber: yup.string().min(4).max(10).required('Must enter fc number'),
  partyName: yup.string().required('Must enter party name'),
  color: yup.string().required('Must enter color'),
  shade: yup
    .number('Must be between 0 to 1')
    .typeError('Must provide a number between 0 to 1')
    .max(1, 'Must be between 0 to 1')
    .required('Must enter color shade percent'),
  labDipRef: yup.string().required('Must enter lab dip ref'),
  clientRefNo: yup.string().required('Must enter client ref no'),
});

export const fcUpdateValidator = yup.object().shape({
  partyName: yup.string().required('Must enter party name'),
  color: yup.string().required('Must enter color'),
  shade: yup
    .number('Must be between 0 to 1')
    .typeError('Must provide a number between 0 to 1')
    .max(1, 'Must be between 0 to 1')
    .required('Must enter color shade percent'),
  labDipRef: yup.string().required('Must enter lab dip ref'),
  clientRefNo: yup.string().required('Must enter client ref no'),
  remarks: yup.string().required('Must enter process information'),
});

export const batchDetailFromValidator = [
  yup.object().shape({
    customer: yup.object().shape({
      fcNumber: yup.string().required(),
    }),
    batchType: yup.string().required('Must select batch type'),
    processType: yup.string().required('Must select process type'),
    lotNumber: yup.string().required('Must enter lot number'),
    deliveryDate: yup.date().required('Must select delivery Date'),
    priority: yup.string(),
    loadTime: yup.mixed().required('Must select Loading date'),
    loadDate: yup.date().required('Must select Loading date'),
    reRunBatchRef: yup.string('Must enter reference lot number').when('processType', {
      is: 'RE PROCESS',
      then: yup.string().required('Must enter reference lot number'),
    }),
  }),

  yup.object().shape({
    fabricInfo: yup.array().of(
      yup.object().shape({
        usedQty: yup
          .number()
          .typeError('Must provide a number')
          .min(1)
          .required('Must be greater than 0')
          .positive('Must enter fabric weight'),
        rolls: yup
          .number()
          .typeError('Must provide roll count')
          .positive('Must be a positive number')
          .required('Must provide roll count'),
        fabricType: yup.string().required('Must enter fabric type'),
      }),
    ),
  }),

  yup.object().shape({
    dyeingOperator: yup.mixed().required('Must select Dyeing operator'),
    finishingOperator: yup.mixed().required('Must select finishing operator'),
    qcOperator: yup.mixed().required('Must select QC operator'),
    tasks: yup.array().of(
      yup.object().shape({
        taskType: yup.string().required('Must select task type'),
        formFields: yup.array().required('Must select process type'),
        taskName: yup.string().required('Must select process type'),
        standardTimeHour: yup.number().typeError('Must provide a number').min(0, 'Must not be 0'),
        standardTimeMinute: yup
          .number()
          .typeError('Must provide a number')
          .positive('Must be positive')
          .min(0, 'Must not be 0'),
      }),
    ),
  }),
];

export const basicOrderInfoValidation = yup.object().shape({
  partyName: yup.mixed().required('Must select client name'),
  clientId: yup.mixed('Must select client id'),
  fcNumber: yup.string().min(4).max(6).required('Must enter FC number'),
  salesPerson: yup.string().required('Must enter sales person name'),
  deliveryDate: yup.date().typeError('Please select a valid date').required('Must select a date'),
  clientRefNo: yup.string(),
  clientContactPerson: yup.string().required('Must enter client contact person'),
  clientAddress: yup.string().required('Must enter delivery address'),

  fabricInfo: yup.array().of(
    yup.object().shape({
      shadeGroup: yup.string().required('Must select shade group'),
      color: yup.string().required('Must select a color'),
      shade: yup.number().typeError('Must enter a number').required('Must add shade %'),

      gsm: yup.number().typeError('Not a valid input').positive('GSM must be a positive number'),
      dia: yup.number().typeError('Not a valid input').positive('DIA must be a positive number'),
      fabricType: yup.string().required('Must select a fabric type'),
      processDescription: yup.string().required('Must enter process description'),
      quantity: yup
        .number()
        .positive('Must be greater than zero')
        .typeError('Not a valid input')
        .required('Must provide a quantity'),
      labDipRef: yup.string(),
    }),
  ),
});

export const newClientAddValidation = yup.object().shape({
  clientName: yup.string().required('Must enter client name'),
});

export const newShadeAddValidation = yup.object().shape({
  shade: yup.string().required('Must enter shade name'),
});

export const newShadeGroupAddValidation = yup.object().shape({
  shadeGroup: yup.string().required('Must enter shade group'),
  startRange: yup.number().typeError('Must be a number').required('Must enter shade group'),
  endRange: yup.number().typeError('Must be a number').required('Must enter shade group'),
});

export const newFabricTypeAddValidation = yup.object().shape({
  fabricType: yup.string().required('Must enter fabric type'),
});

export const reportGenerationValidators = yup.object().shape({
  startDate: yup
    .date()
    .typeError('Please select a starting date')
    .required('Must select a starting date'),

  endDate: yup
    .date()
    .typeError('Please select a ending date')
    .required('Must select a ending date'),
});

export const batchRejectionFormSchema = yup.object().shape({
  batchRejectionRemark: yup.string().required('Must provide reason for rejection'),
});
