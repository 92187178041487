import React, { useState } from 'react';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  FormControl,
  FormLabel,
  Heading,
  Textarea,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { addBatchRemark } from '../../api/batch';
import toastFormat from '../../constatnts/toastFormat';

const BatchRemarksDrawer = ({ show, onClose, batch, refresh }) => {
  const [dyeingRemark, setDyeingRemark] = useState(batch?.dyeingRemark);
  const [finishingRemark, setFinishingRemark] = useState(batch?.finishingRemark);
  const [qcRemark, setQCRemark] = useState(batch?.qcRemark);
  const [deliveryRemark, setDeliveryRemark] = useState(batch?.deliveryRemark);

  const toast = useToast();

  const handleRemarksSubmission = async () => {
    const remarkAddStatus = await addBatchRemark({
      dyeingRemark,
      finishingRemark,
      qcRemark,
      deliveryRemark,
      lotNumber: batch.lotNumber,
    });

    if (remarkAddStatus === 200) {
      refresh();
      onClose();
      toast({ title: 'Remarks added', status: 'success', ...toastFormat });
    } else {
      toast({ title: 'Failed to add remarks', status: 'error', ...toastFormat });
    }
  };
  return (
    <Drawer onClose={onClose} isOpen={show} size="sm">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody>
          <DrawerCloseButton />
          <Heading fontSize="lg" mb="25px" mt="5px">
            Batch Remarks
          </Heading>

          <VStack>
            <FormControl>
              <FormLabel>Dyeing Remarks</FormLabel>
              <Textarea
                value={dyeingRemark}
                placeholder="Someting you want to add regarding dyeing process?"
                onChange={(event) => setDyeingRemark(event.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Finishing Remarks</FormLabel>
              <Textarea
                value={finishingRemark}
                placeholder="Someting you want to add regarding finishing process?"
                onChange={(event) => setFinishingRemark(event.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>QC Remarks</FormLabel>
              <Textarea
                value={qcRemark}
                placeholder="Someting you want to add regarding QC?"
                onChange={(event) => setQCRemark(event.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Delivery Remarks</FormLabel>
              <Textarea
                value={deliveryRemark}
                placeholder="Someting you want to add regarding delivery?"
                onChange={(event) => setDeliveryRemark(event.target.value)}
              />
            </FormControl>
            <Button colorScheme="facebook" w="full" onClick={handleRemarksSubmission}>
              Submit
            </Button>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default BatchRemarksDrawer;
