import React, { useState } from 'react';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormLabel,
  Grid,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { fetchAllUserForTemplate } from '../../api/user';
import AlertBox from '../common/AlertBox';
import { reAssignUser } from '../../api/batch';
import toastFormat from '../../constatnts/toastFormat';

const ReAssignUserDrawer = ({
  isDrawerOpen,
  closeDrawer,
  dyeingOperator,
  finishingOperator,
  qcOperator,
  batchId,
  refresh,
}) => {
  const [dyerList, setDyerList] = useState();
  const [finisherList, setFinisherList] = useState();
  const [qcList, setQcList] = useState();

  const [selectedDyer, setSelectedDyer] = useState();
  const [selectedFinisher, setSelectedFinisher] = useState();
  const [selectedQC, setSelectedQC] = useState();

  const [showAlert, setShowAlert] = useState(false);

  const toast = useToast();

  useEffect(() => {
    (async () => {
      const { data } = await fetchAllUserForTemplate();

      setDyerList(data.dyer?.filter((user) => user.uid !== dyeingOperator?.uid));
      setFinisherList(data.finisher?.filter((user) => user.uid !== finishingOperator?.uid));
      setQcList(data.qc?.filter((user) => user.uid !== qcOperator?.uid));
    })();
  }, [dyeingOperator, finishingOperator, qcOperator]);

  const handleUserReAssign = async () => {
    const dyerId = selectedDyer?.id;
    const finisherId = selectedFinisher?.id;
    const qcId = selectedQC?.id;

    const response = await reAssignUser({ batchId, dyerId, finisherId, qcId });

    if (response === 200) {
      toast({
        title: 'New operators assigned',
        status: 'success',
        ...toastFormat,
      });
      await refresh();
    } else {
      toast({
        title: 'Failed to assign new operators',
        status: 'error',
        ...toastFormat,
      });
    }
    closeDrawer();
    setShowAlert(false);
  };

  return (
    <>
      <AlertBox
        title="Re assign operators"
        subTitle="Are you sure you want to re assign operators"
        show={showAlert}
        onClose={() => {
          setShowAlert(false);
          closeDrawer();
        }}
        onSubmit={handleUserReAssign}
      />
      <Drawer onClose={closeDrawer} isOpen={isDrawerOpen} size="sm">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Re-assign operator</DrawerHeader>
          <DrawerBody>
            <FormControl my="20px">
              <Grid gridTemplateColumns="1fr 1fr" gap="20px">
                <FormLabel>Dyeing Operator</FormLabel>
                <Menu>
                  <MenuButton as={Button}>
                    {selectedDyer ? selectedDyer?.name : dyeingOperator?.name}
                  </MenuButton>
                  <MenuList>
                    {dyerList?.length > 0 ? (
                      dyerList?.map((user) => (
                        <MenuItem key={user.uid} onClick={() => setSelectedDyer(user)}>
                          {user.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>No dying operator found</MenuItem>
                    )}
                  </MenuList>
                </Menu>
              </Grid>
            </FormControl>
            <FormControl my="20px">
              <Grid gridTemplateColumns="1fr 1fr" gap="20px">
                <FormLabel>Finishing Operator</FormLabel>
                <Menu>
                  <MenuButton as={Button}>
                    {selectedFinisher ? selectedFinisher.name : finishingOperator?.name}
                  </MenuButton>
                  <MenuList>
                    {finisherList?.length > 0 ? (
                      finisherList?.map((user) => (
                        <MenuItem key={user.uid} onClick={() => setSelectedFinisher(user)}>
                          {user.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>No finishing operator found</MenuItem>
                    )}
                  </MenuList>
                </Menu>
              </Grid>
            </FormControl>
            <FormControl my="20px">
              <Grid gridTemplateColumns="1fr 1fr" gap="20px">
                <FormLabel>QC Operator</FormLabel>
                <Menu>
                  <MenuButton as={Button}>
                    {selectedQC ? selectedQC.name : qcOperator?.name}
                  </MenuButton>
                  <MenuList>
                    {qcList?.length > 0 ? (
                      qcList?.map((user) => (
                        <MenuItem key={user.uid} onClick={() => setSelectedQC(user)}>
                          {user.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>No QC found</MenuItem>
                    )}
                  </MenuList>
                </Menu>
              </Grid>
            </FormControl>
            <Button
              onClick={() => setShowAlert(true)}
              disabled={selectedDyer || selectedFinisher || selectedQC ? false : true}
              w="100%"
              colorScheme="blue"
            >
              Re Assign
            </Button>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default ReAssignUserDrawer;
