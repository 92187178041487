import { GridItem, HStack, Image, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import BatchIco from '../../assets/images/batch.svg';
import OrderIco from '../../assets/images/orders.svg';
import MachineIco from '../../assets/images/machine.svg';
import FabricIco from '../../assets/images/fabric.svg';

import humanizeNumber from '../../utilities/humanizeNumber';

const StatCard = ({ title, stat, iconName, bgColor }) => {
  const RenderIcon = () => {
    switch (iconName) {
      case 'ORDER':
        return <Image src={OrderIco} w="40px" />;

      case 'BATCH':
        return <Image src={BatchIco} w="40px" />;

      case 'FABRIC':
        return <Image w="40px" src={FabricIco} />;

      case 'MACHINE':
        return <Image src={MachineIco} w="40px" />;
      default:
        return <Image src={BatchIco} w="40px" />;
    }
  };
  return (
    <GridItem
      w="100%"
      boxSizing="border-box"
      p="10px"
      border="1px solid rgba(0,0,0,0.2)"
      rounded="md"
      bgColor={bgColor}
    >
      <HStack w="100%" h="100%" justify="space-between" align="flex-start">
        <VStack h="100%" justify="space-between" align="start">
          <Text fontSize="sm" fontWeight="bold" color="gray.600">
            {title}
          </Text>
          <Text fontSize="3xl" fontWeight="bold" color="gray.800">
            {humanizeNumber(stat)}
          </Text>
        </VStack>
        <RenderIcon />
      </HStack>
    </GridItem>
  );
};

export default StatCard;
