export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const OTP_VERIFY = 'OTP_VERIFY';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const SAVE_SESSION_ID = 'SAVE_SESSION_ID';

export const NOTIFY = 'NOTIFY';

export const GET_ALL_MACHINE = 'GET ALL MACHINE';
export const ADD_NEW_MACHINE = 'ADD_NEW_MACHINE';
export const UPDATE_MACHINE_INFO = 'UPDATE MACHINE INFO';
export const MARK_MACHINE_AS_OOO = 'MARK MACHINE AS OOO';
export const GET_MACHINE_LOGS = 'GET_MACHINE_LOGS';
export const UPDATE_MACHINE_LOG = 'UPDATE_MACHINE_LOG';

export const GET_ALL_USER = 'GET_ALL_USER';
export const MARK_USER_AS_BANNED = 'MARK_USER_AS_BANNED';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';

export const GET_ALL_BATCHES = 'GET_ALL_BATCHES';
export const GET_ALL_FC = 'GET_ALL_FC';
export const UPDATE_FC_INFO = 'UPDATE_FC_INFO';
export const ADD_NEW_FC = 'ADD_NEW_FC';

export const ADD_RECIPE = 'ADD_RECIPE';
export const CLEAR_RECIPE = 'CLEAR_RECIPE';

export const GET_ALL_CHEMICALS = 'GET_ALL_CHEMICALS';
export const GET_ALL_DYE_GROUPS = 'GET_ALL_DYE_GROUPS';
