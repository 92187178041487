import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router';
import { ROOT_URL } from '../constatnts/routerUrls';

const PrivateRoute = ({ component, ...rest }) => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const routeComponent = () =>
    isLoggedIn ? (
      <Route {...rest} component={component} />
    ) : (
      <Redirect to={{ pathname: ROOT_URL }} />
    );
  return <Route {...rest} render={routeComponent} />;
};

export default PrivateRoute;
