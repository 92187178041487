import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { deleteShadeGroup, updateShadeGroup } from '../../api/customer';
import toastFormat from '../../constatnts/toastFormat';
import {
  newShadeGroupAddValidation,
  useYupValidationResolver,
} from '../../validators/form-validators';

const Alert = ({ title, show, close, onSubmit, children }) => {
  return (
    <AlertDialog isOpen={show} onClose={close}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>{children}</AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={close} size="sm">
              Cancel
            </Button>
            <Button colorScheme="facebook" onClick={onSubmit} size="sm" ml={3}>
              Confirm
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

const UpdateShadeGroup = ({ shadeGroups, updateData }) => {
  const [selectedShadeGroup, setSelectedShadeGroup] = useState();
  const resolver = useYupValidationResolver(newShadeGroupAddValidation);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: isEditPanelOpen,
    onOpen: openEditpanel,
    onClose: closeEditPanel,
  } = useDisclosure();

  const {
    setValue,
    register,
    formState: { isDirty, errors },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    resolver,
  });

  const toast = useToast();

  const handleEdit = async (data) => {
    if (isDirty) {
      const shadeGroupData = await updateShadeGroup({
        id: selectedShadeGroup?.id,
        shadeGroup: data.shadeGroup,
        startRange: data.startRange,
        endRange: data.endRange,
      });

      updateData(shadeGroupData);
      closeEditPanel();
    } else {
      toast({ ...toastFormat, title: 'Nothing Chnaged', status: 'warning' });
    }
  };

  useEffect(() => {
    setValue('shadeGroup', selectedShadeGroup?.shadeGroup);
    setValue('startRange', selectedShadeGroup?.startRange);
    setValue('endRange', selectedShadeGroup?.endRange);
  }, [selectedShadeGroup]);

  const handleDelete = async (id) => {
    const shadeGroups = await deleteShadeGroup(id);
    updateData(shadeGroups);
  };
  return (
    <>
      <Alert
        show={isEditPanelOpen}
        close={closeEditPanel}
        onSubmit={handleSubmit(handleEdit)}
        title="Edit Shade Group"
      >
        <FormControl isRequired={true} isInvalid={errors?.shadeGroup}>
          <FormLabel fontSize="xs">Shade Group Name</FormLabel>

          <Input placeholder="Please enter shade group name" {...register('shadeGroup')} />
          <FormErrorMessage>{errors?.shadeGroup?.message}</FormErrorMessage>
        </FormControl>
        <HStack mt="15px">
          <FormControl isRequired={true} isInvalid={errors?.startRange}>
            <FormLabel fontSize="xs">Start Range (%)</FormLabel>
            <Input placeholder="Start Range" type="number" {...register('startRange')} />
            <FormErrorMessage>{errors?.startRange?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired={true}>
            <FormLabel fontSize="xs">End Range (%)</FormLabel>

            <Input
              placeholder="End Range"
              type="number"
              isInvalid={errors?.endRange}
              {...register('endRange')}
            />
            <FormErrorMessage>{errors?.endRange?.message}</FormErrorMessage>
          </FormControl>
        </HStack>
      </Alert>
      <Button size="sm" colorScheme="facebook" onClick={onOpen}>
        Shade Groups
      </Button>
      <Drawer size="xs" isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Shade Groups</DrawerHeader>
          <DrawerBody>
            <VStack>
              {shadeGroups?.map((item) => (
                <HStack key={item.id} w="100%" justify="space-between">
                  <Text>{item.shadeGroup}</Text>
                  <HStack>
                    <IconButton
                      icon={<EditIcon />}
                      size="sm"
                      onClick={() => {
                        setSelectedShadeGroup(item);
                        openEditpanel();
                      }}
                    />
                    <IconButton
                      icon={<DeleteIcon />}
                      size="sm"
                      onClick={async () => await handleDelete(item.id)}
                    />
                  </HStack>
                </HStack>
              ))}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default UpdateShadeGroup;
