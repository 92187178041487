import React from 'react';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Grid,
  GridItem,
  HStack,
  Text,
} from '@chakra-ui/react';
import dayjs from 'dayjs';

const BatchLogsDrawer = ({ logs, isOpen, onClose }) => {
  const getColorForLogStatus = (status) => {
    switch (status) {
      case 'SUCCESS':
        return 'white';
      case 'WARN':
        return 'yellow.100';
      case 'DANGER':
        return 'red.100';
      default:
        return 'gray.50';
    }
  };
  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="sm">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Batch Logs</DrawerHeader>
        <DrawerBody>
          <Grid w="100%" gridTemplateColumns="100%" gap="20px">
            {logs
              ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .map((log) => (
                <GridItem key={log.id}>
                  <Box
                    w="100%"
                    rounded="md"
                    bgColor={getColorForLogStatus(log.logStatus)}
                    border="1px solid rgba(0,0,0,0.3)"
                    boxSizing="border-box"
                    padding="5px">
                    <HStack w="100%">
                      <Box w="20%" ml="5px">
                        <Text fontSize="sm" color="gray.600" fontWeight="bold">
                          {dayjs(log.createdAt).format('hh:mm A')}
                        </Text>
                        <Text color="gray.600" fontWeight="bold" fontSize="md">
                          {dayjs(log.createdAt).format('DD, MMM')}
                        </Text>
                      </Box>
                      <Box w="80%">
                        <Text fontSize="sm" color="gray.700" fontWeight="bold">
                          {log.reason}
                        </Text>
                        <Text fontSize="xs" color="gray.700">
                          {log.remark}
                        </Text>
                        <HStack spacing={1}>
                          <Text fontSize="sm">Done By</Text>
                          <Text mt="5px" fontSize="sm" color="gray.700" fontWeight="bold">
                            {log.name}
                          </Text>
                        </HStack>
                      </Box>
                    </HStack>
                  </Box>
                </GridItem>
              ))}
          </Grid>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default BatchLogsDrawer;
