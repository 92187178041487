import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import React from 'react';
import { Flex, Text } from '@chakra-ui/layout';
const ModalView = ({ Form, modalTitle, onClose, isOpen }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <Flex
          width="100%"
          boxSizing="border-box"
          padding="16px"
          direction="column"
          align="center"
          justify="center"
        >
          <Text fontSize="20px" fontWeight="black" color="#272739" textAlign="center" mb="15px">
            {modalTitle}
          </Text>
        </Flex>
        <Form />
      </ModalContent>
    </Modal>
  );
};

export default ModalView;
