import axios from './axios';

export const fetchAllShades = async () => {
  try {
    const response = await axios.get('/customer/shade');
    return response.data.shades;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const fetchAllFabricTypes = async () => {
  try {
    const response = await axios.get('/customer/fabric-type');
    return response.data.fabricTypes;
  } catch (error) {
    console.error(error);
    return null;
  }
};
export const fetchAllShadeGroup = async () => {
  try {
    const response = await axios.get('/customer/shade-group');
    return response.data.shadeGroups;
  } catch (error) {
    console.error(error);
    return null;
  }
};
export const fetchAllClients = async () => {
  try {
    const response = await axios.get('/customer/client');
    return response.data.clients;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const createNewClient = async (clientName) => {
  try {
    const response = await axios.post('/customer/client', { clientName });
    return response.data.client;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const createNewShade = async ({ shade, shadeGroupId }) => {
  try {
    const response = await axios.post('/customer/shade', { shade, shadeGroupId });
    return response.data.shade;
  } catch (error) {
    console.error(error);
    return null;
  }
};
export const createNewShadeGroup = async ({ shadeGroup, startRange, endRange }) => {
  try {
    const response = await axios.post('/customer/shade-group', {
      shadeGroup,
      startRange,
      endRange,
    });
    return response.data.shadeGroup;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const updateShadeGroup = async ({ id, shadeGroup, startRange, endRange }) => {
  try {
    const requestPayload = {
      id,
      shadeGroup,
      startRange,
      endRange,
    };

    const response = await axios.patch('/customer/shade-group', requestPayload);
    return response.data.shadeGroups;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const deleteShadeGroup = async (id) => {
  try {
    const response = await axios.post('/customer/shade-group/delete', {
      id,
    });
    return response.data.shadeGroups;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const updateShade = async ({ id, shade }) => {
  try {
    const response = await axios.patch('/customer/shade', {
      id,
      shade,
    });
    return response.data.shades;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const deleteShade = async (id) => {
  try {
    const response = await axios.post('/customer/shade/delete', {
      id,
    });
    return response.data.shades;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const updateFabricType = async ({ id, fabricType }) => {
  try {
    const response = await axios.patch('/customer/fabric-type', {
      id,
      fabricType,
    });
    return response.data.fabricTypes;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const deleteFabricType = async (id) => {
  try {
    const response = await axios.post('/customer/fabric-type/delete', {
      id,
    });
    return response.data.fabricTypes;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const createNewFabricType = async (fabricType) => {
  try {
    const response = await axios.post('/customer/fabric-type', { fabricType });
    return response.data.fabricType;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const createOrder = async (formData) => {
  try {
    const {
      partyName,
      fcNumber,
      salesPerson,
      fabricInfo,
      rate,
      deliveryDate,
      clientRefNo,
      labDipRef,
      clientContactPerson,
      clientAddress,
      clientId,
      remarks,
    } = formData;
    const response = await axios.post('/customer', {
      partyName,
      fcNumber,
      salesPerson,
      fabricInfo,
      rate,
      deliveryDate,
      clientRefNo,
      labDipRef,
      clientContactPerson,
      clientAddress,
      clientId,
      remarks,
    });
    return response.status;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const updateOrder = async (formData) => {
  try {
    const {
      partyName,
      fcNumber,
      salesPerson,
      fabricInfo,
      rate,
      deliveryDate,
      clientRefNo,
      labDipRef,
      clientContactPerson,
      clientAddress,
      clientId,
      remarks,
    } = formData;
    const response = await axios.patch('/customer', {
      partyName,
      fcNumber,
      salesPerson,
      fabricInfo,
      rate,
      deliveryDate,
      clientRefNo,
      labDipRef,
      clientContactPerson,
      clientAddress,
      clientId,
      remarks,
    });
    return response.status;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const fetchCustomerByFcNumber = async (fcNumber) => {
  try {
    const response = await axios.get('/customer', {
      params: {
        fcNumber,
      },
    });

    return response.data.order;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const bulkOrderCreation = async (orders) => {
  try {
    const response = await axios.post('/customer/bulk', { orders });
    return response.data.duplicates;
  } catch (error) {
    console.error(error);
    return [];
  }
};
