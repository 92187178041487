import { Image } from '@chakra-ui/image';
import { Box, Flex, Text } from '@chakra-ui/layout';
import React from 'react';
import { useLocation } from 'react-router';
import sidebarLocations from '../constatnts/sidebarLocations';
import SidebarToggleIco from '../assets/images/toggle-side-bar.svg';
import { Link } from 'react-router-dom';
import Logo from '../assets/images/logo.svg';
import { Drawer, DrawerContent, DrawerOverlay, Tooltip, useDisclosure } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

const SidebarLink = ({ item, path, show, onClose }) => {
  const location = useLocation();

  if (location.pathname.includes(path)) {
    return (
      <Link
        to={path}
        onClick={() => {
          if (show) {
            onClose();
          }
        }}
      >
        <Flex
          padding="18px 15px"
          width="100%"
          borderLeft="7px solid #2B6CAF"
          boxSizing="border-box"
          backgroundColor="gray.50"
          direction="row"
          alignItems="center"
          justifyContent={show ? 'flex-start' : 'center'}
        >
          <Tooltip label={item.title}>
            <Image
              src={item.icon}
              marginRight={show ? '20px' : 0}
              width="16px"
              style={{ width: '23px' }}
            />
          </Tooltip>
          <Text color="black" fontWeight="bold" display={show ? 'block' : 'none'}>
            {item.title}
          </Text>
        </Flex>
      </Link>
    );
  } else {
    return (
      <Link
        to={path}
        onClick={() => {
          if (show) {
            onClose();
          }
        }}
      >
        <Flex
          padding="18px 15px"
          width="100%"
          boxSizing="border-box"
          direction="row"
          alignItems="center"
          justifyContent={show ? 'flex-start' : 'center'}
        >
          <Tooltip label={item.title}>
            <Image
              src={item.icon}
              marginRight={show ? '20px' : 0}
              width="16px"
              style={{ width: '23px' }}
            />
          </Tooltip>
          <Text color="blackAlpha.500" display={show ? 'block' : 'none'}>
            {item.title}
          </Text>
        </Flex>
      </Link>
    );
  }
};

const NavDrawer = ({ isOpen, onClose }) => {
  return (
    <Drawer placement="left" isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <Flex
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="70px"
          backgroundColor="gray.100"
          direction="row"
        >
          <Image src={Logo} h="40px" />
        </Flex>
        <Flex weight="100%" boxSizing="border-box" direction="column">
          {sidebarLocations.map((nav) => (
            <SidebarLink item={nav} path={nav.path} key={nav.title} show={true} onClose={onClose} />
          ))}
        </Flex>
      </DrawerContent>
    </Drawer>
  );
};

const Sidebar = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  if (!isLoggedIn) {
    return <></>;
  } else {
    return (
      <>
        <NavDrawer isOpen={isOpen} onClose={onClose} />
        <Box
          width="80px"
          height="100vh"
          backgroundColor="#fff"
          zIndex="150"
          position="fixed"
          top="0"
          left="0"
          boxSizing="border-box"
          borderRight="1px solid rgba(0,0,0,0.1)"
        >
          <Flex weight="100%" boxSizing="border-box" direction="column">
            <Flex
              justifyContent="center"
              alignItems="center"
              width="100%"
              height="70px"
              backgroundColor="gray.100"
              direction="row"
            >
              <Image src={Logo} h="40px" />
            </Flex>
            {sidebarLocations.map((nav) => (
              <SidebarLink item={nav} path={nav.path} key={nav.title} />
            ))}
          </Flex>
          <Flex
            backgroundColor="gray.50"
            position="absolute"
            bottom="0"
            left="0"
            width="100%"
            padding="15px 20px"
            cursor="pointer"
            onClick={onOpen}
          >
            <Image src={SidebarToggleIco} width="20px" />
          </Flex>
        </Box>
      </>
    );
  }
};

export default Sidebar;
