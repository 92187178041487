/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DAILY_PLANNING_REPORT, LOGIN_ROUTE } from '../../constatnts/routerUrls';
import { getDailyPlanningReport } from '../../api/batch';
import {
  Box,
  Grid,
  GridItem,
  Heading,
  HStack,
  IconButton,
  Image,
  Input,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import LOGO from '../../assets/images/logo.svg';
import { RiPrinterLine } from 'react-icons/ri';
import ReactToPrint from 'react-to-print';
import ExcelExport from 'export-xlsx';
import { DownloadIcon } from '@chakra-ui/icons';

const DailyPlanningReport = () => {
  const { date } = useParams();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [reportData, setReportData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    (async () => {
      const report = await getDailyPlanningReport(date);
      setReportData(report);
    })();

    setIsLoading(false);
  }, [date]);

  const printRef = useRef();
  const history = useHistory();

  if (!isLoggedIn) {
    return (
      <Redirect
        to={{
          pathname: LOGIN_ROUTE,
          state: { from: 'daily-planning' },
        }}
      />
    );
  }

  const generateXL = () => {
    const excel = new ExcelExport();

    const SHEET_CONFIG = {
      fileName: 'DAILY PLANNING - ' + dayjs(new Date(date)).format('DD MMM YYYY'),
      workSheets: [
        {
          sheetName: 'Daily Planning',
          startingRowNumber: 1,
          tableSettings: {
            data: {
              importable: true,
              headerDefinition: [
                {
                  name: 'Machine',
                  key: 'machine',
                },
                {
                  name: 'Lot Number',
                  key: 'lotNumber',
                },
                {
                  name: 'Buyer',
                  key: 'buyer',
                },
                {
                  color: 'Color',
                  key: 'color',
                },
                {
                  name: 'Fabric Type',
                  key: 'fabricType',
                },
                {
                  name: `Weight (${dayjs(reportData.today).format('DD, MMM, YYYY')})`,
                  key: 'qtyToday',
                },
                {
                  name: `Weight (${dayjs(reportData.todayPlus1).format('DD, MMM, YYYY')})`,
                  key: 'qtyTodayPlus1',
                },
                {
                  name: `Weight (${dayjs(reportData.todayPlus2).format('DD, MMM, YYYY')})`,
                  key: 'qtyTodayPlus2',
                },
                {
                  name: `Weight (${dayjs(reportData.todayPlus3).format('DD, MMM, YYYY')})`,
                  key: 'qtyTodayPlus3',
                },
              ],
            },
          },
        },
      ],
    };

    let data = [];

    reportData.data.map((item) => {
      item.fabrics.map((fabric) => {
        data.push({
          machine: item.machine,
          lotNumber: `${fabric.lotNumber} (FC-${fabric.fcNumber})`,
          buyer: fabric.buyer,
          color: fabric.color,
          fabricType: fabric.fabricType,
          qtyToday: fabric.qtyToday,
          qtyTodayPlus1: fabric.qtyTodayPlus1,
          qtyTodayPlus2: fabric.qtyTodayPlus2,
          qtyTodayPlus3: fabric.qtyTodayPlus3,
        });
      });
    });

    excel.downloadExcel(SHEET_CONFIG, [{ data: data }]);
  };
  return (
    <>
      {isLoading ? (
        <Box w="100%" h="100vh">
          <Spinner />
        </Box>
      ) : (
        <Box boxSizing="border-box" p="15px">
          <HStack
            bgColor="gray.200"
            rounded="md"
            p="15px"
            boxSizing="border-box"
            justify="space-between">
            <HStack>
              <Text>Filter By Date</Text>
              <Input
                type="date"
                w="200px"
                value={dayjs(new Date(date)).format('YYYY-MM-DD')}
                bgColor="white"
                onChange={(e) => {
                  history.push(
                    `${DAILY_PLANNING_REPORT}/${new Date(e.target.value).toISOString()}`,
                  );
                  window.location.reload(false);
                }}
              />
            </HStack>
            <HStack>
              <Tooltip label="Print">
                <Box>
                  <ReactToPrint
                    trigger={() => <IconButton icon={<RiPrinterLine />} colorScheme="facebook" />}
                    content={() => printRef.current}
                  />
                </Box>
              </Tooltip>
              <Tooltip label="Download XL">
                <IconButton icon={<DownloadIcon />} colorScheme="facebook" onClick={generateXL} />
              </Tooltip>
            </HStack>
          </HStack>
          <Box my="30px" />
          <Box ref={printRef}>
            <VStack>
              <HStack w="100%" justify="space-between">
                <Image src={LOGO} h="50px" />
                <VStack align="start">
                  <Text fontSize="14px" fontWeight="bold">
                    {dayjs(date).format('DD, MMM, YYYY')}
                  </Text>
                  <Text fontSize="18px" fontWeight="bold">
                    Planning of Dyeing of Different M/C
                  </Text>
                </VStack>
              </HStack>
            </VStack>
            <Box my="20px"></Box>

            {reportData?.data.length > 0 ? (
              <Table size="sm" variant="fill">
                <Thead>
                  <Tr>
                    <Th>Machine</Th>
                    <Th textAlign="center">Details</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {reportData?.data?.map((machine, index) => (
                    <>
                      <Tr borderBottom="1px solid rgba(0,0,0,0.4)">
                        <Td bg="gray.100">{machine.machine}</Td>
                        <Td>
                          <Table size="sm" variant="fill">
                            <Thead>
                              <Tr>
                                <Th w="150px" opacity={index > 0 ? 0 : 1}>
                                  Lot Number
                                </Th>
                                <Th w="150px" opacity={index > 0 ? 0 : 1}>
                                  Buyer
                                </Th>
                                <Th w="250px" opacity={index > 0 ? 0 : 1} maxW="250px">
                                  Colour
                                </Th>
                                <Th w="150px" opacity={index > 0 ? 0 : 1}>
                                  Fabric Type
                                </Th>
                                <Th w="150px" opacity={index > 0 ? 0 : 1}>
                                  Weight <br /> ({reportData?.today})
                                </Th>
                                <Th w="150px" opacity={index > 0 ? 0 : 1}>
                                  Weight <br />({reportData?.todayPlus1})
                                </Th>
                                <Th w="150px" opacity={index > 0 ? 0 : 1}>
                                  Weight <br />({reportData?.todayPlus2})
                                </Th>
                                <Th w="150px" opacity={index > 0 ? 0 : 1}>
                                  Weight <br />({reportData?.todayPlus3})
                                </Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {machine.fabrics?.map((fabric) => (
                                <>
                                  <Tr>
                                    <Td w="150px" border="1px solid rgba(0,0,0,0.4)">
                                      {fabric.lotNumber} (FC-{fabric.fcNumber})
                                    </Td>
                                    <Td w="150px" border="1px solid rgba(0,0,0,0.4)">
                                      {fabric.buyer}
                                    </Td>
                                    <Td w="250px" maxW="250px" border="1px solid rgba(0,0,0,0.4)">
                                      {fabric.color}
                                    </Td>
                                    <Td w="150px" border="1px solid rgba(0,0,0,0.4)">
                                      {fabric.fabricType}
                                    </Td>
                                    <Td
                                      textAlign="center"
                                      w="150px"
                                      border="1px solid rgba(0,0,0,0.4)">
                                      {fabric.qtyToday}
                                    </Td>
                                    <Td
                                      textAlign="center"
                                      w="150px"
                                      border="1px solid rgba(0,0,0,0.4)">
                                      {fabric.qtyTodayPlus1}
                                    </Td>
                                    <Td
                                      textAlign="center"
                                      w="150px"
                                      border="1px solid rgba(0,0,0,0.4)">
                                      {fabric.qtyTodayPlus2}
                                    </Td>
                                    <Td
                                      textAlign="center"
                                      w="150px"
                                      border="1px solid rgba(0,0,0,0.4)">
                                      {fabric.qtyTodayPlus3}
                                    </Td>
                                  </Tr>
                                </>
                              ))}
                            </Tbody>
                          </Table>
                        </Td>
                      </Tr>
                      <div className="page-break" />
                    </>
                  ))}
                  <Tr>
                    <Td></Td>
                    <Td>
                      <Table size="sm" variant="fill">
                        <Thead>
                          <Tr>
                            <Th w="150px" opacity={0}>
                              Lot Number
                            </Th>
                            <Th w="150px" opacity={0}>
                              Buyer
                            </Th>
                            <Th w="250px" opacity={0} maxW="250px">
                              Colour
                            </Th>
                            <Th w="150px" opacity={0}>
                              Fabric Type
                            </Th>
                            <Th w="150px" opacity={0}>
                              Weight {reportData?.today}
                            </Th>
                            <Th w="150px" opacity={0}>
                              Weight {reportData?.todayPlus1}
                            </Th>
                            <Th w="150px" opacity={0}>
                              Weight {reportData?.todayPlus2}
                            </Th>
                            <Th w="150px" opacity={0}>
                              Weight {reportData?.todayPlus3}
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          <Tr>
                            <Td></Td>
                            <Td></Td>
                            <Td></Td>
                            <Td textAlign="right" fontSize="20px">
                              Total:{' '}
                            </Td>
                            <Td textAlign="center" fontSize="20px">
                              {reportData?.totalToday} (Kg)
                            </Td>
                            <Td textAlign="center" fontSize="20px">
                              {reportData?.totalTodayPlus1} (Kg)
                            </Td>
                            <Td textAlign="center" fontSize="20px">
                              {reportData?.totalTodayPlus2} (Kg)
                            </Td>
                            <Td textAlign="center" fontSize="20px">
                              {reportData?.totalNext} (Kg)
                            </Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            ) : (
              <HStack w="full" h="30vh" bg="gray.50" justify="center" align="center">
                <Heading>No Data Found For {dayjs(date).format('DD, MMM, YYYY')}</Heading>
              </HStack>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default DailyPlanningReport;
