import { Button } from '@chakra-ui/button';
import { FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import { Box, Flex, Heading } from '@chakra-ui/layout';
import { Select } from '@chakra-ui/select';
import { useToast } from '@chakra-ui/toast';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { addMachine, getAllMachine, queryMachine } from '../../api/machine';
import AlertBox from '../../components/common/AlertBox';
import CustomTable2 from '../../components/common/CustomTable2';
import toastFormat from '../../constatnts/toastFormat';
import { ADD_NEW_MACHINE, GET_ALL_MACHINE } from '../../store/actions/action-types';
import {
  machineCreationValidator,
  useYupValidationResolver,
} from '../../validators/form-validators';

const MachineCreateForm = ({ onClose }) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: useYupValidationResolver(machineCreationValidator),
  });

  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const toast = useToast();

  const submit = async (data) => {
    if (data.capacity < data.maxCapacity) {
      setError('maxCapacity', {
        type: 'max',
        message: 'Max capacity cannot be greater than capacity',
      });
    } else if (data.capacity < data.minCapacity || data.minCapacity > data.maxCapacity) {
      setError('minCapacity', {
        type: 'max',
        message: 'Min capacity cannot be greater than capacity or max capacity',
      });
    } else {
      const res = await addMachine(data);
      if (res.data) {
        dispatch({
          type: ADD_NEW_MACHINE,
          payload: res.data,
        });

        toast({
          title: 'New machine added',
          status: 'success',
          ...toastFormat,
        });
        onClose();
      } else {
        toast({
          title: 'Invalid data for creating new machine',
          status: 'error',
          ...toastFormat,
        });
      }
    }
    setShowAlert(false);
  };

  return (
    <Box>
      <AlertBox
        onSubmit={() => {
          handleSubmit(submit)();
          setShowAlert(false);
        }}
        onClose={() => setShowAlert(false)}
        show={showAlert}
        title="Are you sure?"
        subTitle="You want to add new machine?"
      />
      <Flex width="100%" direction="column" align="center" justify="center">
        <FormControl
          my="10px"
          width="100%"
          isRequired={true}
          isInvalid={errors?.name}
          isTruncated={true}>
          <FormLabel>Name</FormLabel>
          <Input {...register('name')} required placeholder="Machine Name" width="300px" />
          <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
        </FormControl>
        <FormControl my="10px" width="100%" isRequired={true} isInvalid={errors?.capacity}>
          <FormLabel>Capacity</FormLabel>
          <Input
            {...register('capacity')}
            required
            placeholder="Machine Capacity"
            type="number"
            width="300px"
          />
          <FormErrorMessage>{errors?.capacity?.message}</FormErrorMessage>
        </FormControl>
        <FormControl my="10px" width="100%" isRequired={true} isInvalid={errors?.maxCapacity}>
          <FormLabel>Max Capacity</FormLabel>
          <Input
            {...register('maxCapacity')}
            required
            placeholder="Machine Max Capacity"
            type="number"
            width="300px"
          />
          <FormErrorMessage>{errors?.maxCapacity?.message}</FormErrorMessage>
        </FormControl>
        <FormControl my="10px" width="100%" isRequired={true} isInvalid={errors?.minCapacity}>
          <FormLabel>Minimum Capacity</FormLabel>
          <Input
            {...register('minCapacity')}
            required
            placeholder="Machine Minimum Capacity"
            type="number"
            width="300px"
          />
          <FormErrorMessage>{errors?.minCapacity?.message}</FormErrorMessage>
        </FormControl>
        <FormControl my="10px" width="100%" isRequired={true} isInvalid={errors?.machineType}>
          <FormLabel>Machine Type</FormLabel>
          <Select {...register('machineType')} defaultValue="DYING" placeholder="Machine Type">
            <option value="DYEING">DYEING</option>
            <option value="FINISHING">FINISHING</option>
            <option value="AOP">AOP</option>
          </Select>
          <FormErrorMessage>{errors?.machineType?.message}</FormErrorMessage>
        </FormControl>
        <Button colorScheme="blue" size="lg" width="300px" onClick={() => setShowAlert(true)}>
          ADD NEW
        </Button>
      </Flex>
    </Box>
  );
};

const Machines = () => {
  const dispatch = useDispatch();
  const [entirs, setEntries] = useState(0);
  const machines = useSelector((state) => state.machine.machines);
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { machines, totalEntries } = await getAllMachine();
      if (machines) {
        dispatch({
          type: GET_ALL_MACHINE,
          payload: machines,
        });
        setEntries(totalEntries);
      }
      setLoading(false);
    })();
  }, []);

  const filters = [
    { name: 'Machine Name', queryParamKey: 'name', type: 'INPUT' },
    {
      name: 'Machine Type',
      queryParamKey: 'machineType',
      type: 'SELECT',
      options: [
        { value: 'DYEING', label: 'Dyeing' },
        { value: 'FINISHING', label: 'Finishing' },
        { value: 'AOP', label: 'AOP' },
      ],
    },
    {
      name: 'Machine Status',
      queryParamKey: 'status',
      type: 'SELECT',
      options: [
        { value: 'IN USE', kabel: 'In Use' },
        { value: 'IDLE', label: 'Idle' },
        { value: 'MAINTANENCE', label: 'Maintanence' },
        { value: 'OUT OF ORDER', label: 'Out of order' },
        { value: 'IN WASH', label: 'In wash' },
      ],
    },
  ];

  const handleQuery = async (data) => {
    try {
      setLoading(true);
      const { machines, totalEntries } = await queryMachine(data);
      if (machines) {
        dispatch({
          type: GET_ALL_MACHINE,
          payload: machines,
        });
        setEntries(totalEntries);
      }
      setLoading(false);
    } catch (error) {
      toast({
        title: 'Failed to query machines',
        status: 'error',
        ...toastFormat,
      });
      setLoading(false);
    }
  };

  const handleRefresh = async () => {
    setLoading(true);
    const { machines, totalEntries } = await getAllMachine();
    if (machines) {
      dispatch({
        type: GET_ALL_MACHINE,
        payload: machines,
      });
      setEntries(totalEntries);
    }
    setLoading(false);
  };

  const columns = [
    { header: 'name', key: 'name', type: 'TEXT', isToolTip: false, shouldShorten: true },
    { header: 'capacity', key: 'capacity', type: 'TEXT', isToolTip: false, shouldShorten: false },
    {
      header: 'Minimum Capacity',
      key: 'minCapacity',
      type: 'TEXT',
      isToolTip: false,
      shouldShorten: false,
    },
    {
      header: 'Maximum Capacity',
      key: 'maxCapacity',
      type: 'TEXT',
      isToolTip: false,
      shouldShorten: false,
    },
    {
      header: 'Machine Status',
      key: 'status',
      type: 'BADGE',
      isToolTip: false,
      shouldShorten: false,
    },
    {
      header: 'Machine Type',
      key: 'machineType',
      type: 'BADGE',
      isToolTip: false,
      shouldShorten: false,
    },
    {
      header: 'Assigned Batches',
      key: 'batches',
      type: 'BADGE',
    },
  ];

  const canUseFeature = () => {
    if (user.roles.includes('ADMIN') || user.roles.includes('MANAGER')) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Box w="100%" boxSizing="border-box" p="15px">
      <Heading>Machines</Heading>
      <Box mt="30px">
        <CustomTable2
          data={machines}
          columns={columns}
          entries={entirs}
          queryCallback={handleQuery}
          refreshCallback={handleRefresh}
          filters={filters}
          showCreateButton={canUseFeature()}
          CreateNewComponent={MachineCreateForm}
          detailPathParam={canUseFeature() ? 'name' : undefined}
          detailPath="/machine"
          loading={loading}
        />
      </Box>
    </Box>
  );
};

export default Machines;
