/* eslint-disable no-unused-vars */
import { InfoIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  FormLabel,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { CSVReader } from 'react-papaparse';
import { bulkOrderCreation } from '../../api/customer';
import toastFormat from '../../constatnts/toastFormat';
import AlertBox from '../common/AlertBox';

const FabricModal = ({ fabrics, showFabricModal, setShowFabricModal }) => {
  return (
    <Modal isOpen={showFabricModal} onClose={() => setShowFabricModal(false)} size="full">
      <ModalOverlay>
        <ModalContent>
          <ModalBody>
            <ModalCloseButton />
            <Table size="sm" mt="25px" w="85%" mx="auto">
              <Thead>
                <Tr>
                  <Th>Color</Th>
                  <Th>Shade Group</Th>
                  <Th>Fabric Type</Th>
                  <Th>Lab Dip Reference</Th>
                  <Th>Quantity (KG)</Th>
                  <Th>DIA</Th>
                  <Th>GSM</Th>
                  <Th>Process Description</Th>
                </Tr>
              </Thead>
              <Tbody>
                {fabrics.map((fabric) => (
                  <Tr key={JSON.stringify(fabric)}>
                    <Td>
                      {fabric.color ? (
                        fabric.color
                      ) : (
                        <Tag colorScheme="orange">
                          <InfoIcon />
                        </Tag>
                      )}
                    </Td>
                    <Td>
                      {fabric.shadeGroup ? (
                        fabric.shadeGroup
                      ) : (
                        <Tag colorScheme="orange">
                          <InfoIcon />
                        </Tag>
                      )}
                    </Td>
                    <Td>
                      {fabric.fabricType ? (
                        fabric.fabricType
                      ) : (
                        <Tag colorScheme="orange">
                          <InfoIcon />
                        </Tag>
                      )}
                    </Td>
                    <Td>
                      {fabric.labDipRef ? (
                        fabric.labDipRef
                      ) : (
                        <Tag colorScheme="orange">
                          <InfoIcon />
                        </Tag>
                      )}
                    </Td>
                    <Td>
                      {fabric.quantity ? (
                        fabric.quantity
                      ) : (
                        <Tag colorScheme="orange">
                          <InfoIcon />
                        </Tag>
                      )}
                    </Td>
                    <Td>
                      {fabric.dia ? (
                        fabric.dia
                      ) : (
                        <Tag colorScheme="orange">
                          <InfoIcon />
                        </Tag>
                      )}
                    </Td>
                    <Td>
                      {fabric.gsm ? (
                        fabric.gsm
                      ) : (
                        <Tag colorScheme="orange">
                          <InfoIcon />
                        </Tag>
                      )}
                    </Td>
                    <Td>
                      {fabric.processDescription ? (
                        fabric.processDescription
                      ) : (
                        <Tag colorScheme="orange">
                          <InfoIcon />
                        </Tag>
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

const RenderTableBody = ({ data }) => {
  const [showFabricModal, setShowFabricModal] = useState(false);
  const [fabric, setFabric] = useState([]);

  return (
    <>
      <FabricModal
        fabrics={fabric}
        setShowFabricModal={setShowFabricModal}
        showFabricModal={showFabricModal}
      />
      {data.map((item) => (
        <Tr key={item.fcNumber}>
          <Td>{item.fcNumber}</Td>
          <Td>
            {item.partyName ? (
              item.partyName
            ) : (
              <Tag colorScheme="orange">
                <InfoIcon />
              </Tag>
            )}
          </Td>
          <Td>
            {item.clientRefNo ? (
              item.clientRefNo
            ) : (
              <Tag colorScheme="orange">
                <InfoIcon />
              </Tag>
            )}
          </Td>
          <Td>
            {item.clientContactPerson ? (
              item.clientContactPerson
            ) : (
              <Tag colorScheme="orange">
                <InfoIcon />
              </Tag>
            )}
          </Td>
          <Td>
            {item.salesPerson ? (
              item.salesPerson
            ) : (
              <Tag colorScheme="orange">
                <InfoIcon />
              </Tag>
            )}
          </Td>
          <Td>
            {item.clientAddress ? (
              item.clientAddress
            ) : (
              <Tag colorScheme="orange">
                <InfoIcon />
              </Tag>
            )}
          </Td>
          <Td>
            {item.deliveryDate ? (
              dayjs(item.deliveryDate).format('DD, MMM, YYYY')
            ) : (
              <Tag colorScheme="orange">
                <InfoIcon />
              </Tag>
            )}
          </Td>
          <Td>
            <Button
              size="sm"
              colorScheme="purple"
              onClick={() => {
                setShowFabricModal(true);
                setFabric(item.fabrics);
              }}
            >
              Fabrics ({item.fabrics.length})
            </Button>
          </Td>
        </Tr>
      ))}
    </>
  );
};

const BulkUploadModal = ({ show, onClose }) => {
  const [orderData, setOrderData] = useState([]);
  const [showBulkUploadModal, setShowBulkUploadModal] = useState(false);
  const [showNoFileFoundModal, setShowNoFileFoundModal] = useState(false);
  const [duplicateFcNumbers, setDuplocateFcNumbers] = useState([]);

  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const handleFileUpload = (data) => {
    const orderFCs = [];

    data.map((item) => {
      const fcNumber = item.data.WorkOrderNo.split(' ')[1];

      if (!orderFCs.includes(fcNumber)) {
        orderFCs.push(fcNumber);
      }
    });

    let orders = [];
    orderFCs.map((fcNumber) => {
      const filteredOrders = data.filter((item) => `FC ${fcNumber}` === item.data.WorkOrderNo);
      const order = data.find((item) => `FC ${fcNumber}` === item.data.WorkOrderNo);

      let rawData = {
        fcNumber: fcNumber,
        partyName: order.data.ClientName,
        clientRefNo: order.data.ClentsRef,
        remarks: order.data.OrderRemarks,
        createdAt: new Date(order.data.OrderDate),
        salesPerson: order.data.SalesPerson,
        clientContactPerson: order.data.ContactPerson,
        clientAddress: order.data.DeliveryPlace,
        fabrics: [],
      };

      filteredOrders.map((item) => {
        const orderData = item.data;

        let fabricData = {
          color: orderData.Shade,
          shadeGroup: orderData.ShadeGroupName,
          fabricType: orderData.FabricType,
          labDipRef: orderData.LabdipNo,
          processDescription: orderData.ProcessDescription,
          quantity: orderData.Quantity,
          gsm: orderData.FinishedGSM,
          dia: orderData.FinishedDia,
        };

        rawData.fabrics.push(fabricData);
      });

      orders.push(rawData);
    });
    setOrderData(orders);
  };

  const handleOrderCreation = async () => {
    setIsLoading(true);
    try {
      if (orderData.length <= 0) {
        setShowNoFileFoundModal(true);
        toast({
          title: 'No csv file uploaded',
          status: 'info',
          ...toastFormat,
        });
      } else {
        const duplicates = await bulkOrderCreation(orderData);
        if (duplicates.length > 0) {
          setDuplocateFcNumbers(duplicates);
        }
        toast({
          title: 'Orders created',
          status: 'info',
          ...toastFormat,
        });
      }
    } catch (error) {
      toast({
        title: 'Failed to create order',
        status: 'error',
        ...toastFormat,
      });
    }
    setIsLoading(false);
    setShowBulkUploadModal(false);
  };

  return (
    <>
      <AlertBox
        title="No CSV Found"
        subTitle="Please upload your CSV file"
        show={showNoFileFoundModal}
        onClose={() => setShowNoFileFoundModal(false)}
        onSubmit={() => setShowNoFileFoundModal(false)}
      />
      <AlertBox
        title="Are you sure?"
        subTitle="Are you sure you want to add this orders"
        onClose={() => setShowBulkUploadModal(false)}
        show={showBulkUploadModal}
        onSubmit={handleOrderCreation}
      />
      <Drawer isOpen={show} onClose={onClose} size="full">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody>
            <DrawerCloseButton />
            <VStack w="full" justify="center" spacing="25px">
              <Heading textTransform="capitalize" size="md">
                Add new orders from CSV
              </Heading>
              <Box>
                <CSVReader
                  addRemoveButton
                  onDrop={handleFileUpload}
                  config={{ header: true, skipEmptyLines: true }}
                  onRemoveFile={() => setOrderData([])}
                >
                  <FormLabel>Upload or drag and drop csv file</FormLabel>
                </CSVReader>
              </Box>
            </VStack>

            <Box w="95%" mx="auto" mt="25px" h="500px" overflow="auto">
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th w="100px">FC Number</Th>
                    <Th>Client Name</Th>
                    <Th>Client Reference</Th>
                    <Th>Client Contact</Th>
                    <Th>Sales Person</Th>
                    <Th>Delivery Address</Th>
                    <Th>Delivery Date</Th>
                    <Th w="100px">Fabrics</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <RenderTableBody data={orderData} />
                </Tbody>
              </Table>
            </Box>
            <HStack justify="flex-end" w="95%" mt="15px" mx="auto">
              <Button
                colorScheme="facebook"
                onClick={() => setShowBulkUploadModal(true)}
                isLoading={isLoading}
              >
                Add Orders ({orderData.length})
              </Button>
            </HStack>
            {duplicateFcNumbers.length > 0 && (
              <Text>
                Order with this FC Number:{' '}
                {duplicateFcNumbers.map((item) => (
                  <Tag key={item} colorScheme="orange" m="3px">
                    {item}
                  </Tag>
                ))}{' '}
                already exists and was not added to your order list.
              </Text>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default BulkUploadModal;
