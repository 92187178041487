import {
  Badge,
  Box,
  Grid,
  Heading,
  HStack,
  Image,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { getAllUser } from '../../api/user';
import combineDateTime from '../../utilities/combineDateTime';
import { mapStatusToColor } from '../../utilities/mapStatusToColor';
import msToTime from '../../utilities/msToTime';

const RenderFormInputs = ({ field, index }) => {
  switch (field.type) {
    case 'IMAGE INPUT':
      return (
        <Box position="relative" my="5px">
          <Text fontWeight="bold" fontSize="sm" textColor="gray.700">
            {index + 1}. Reference Image
          </Text>
          <Image src={field[field.inputKeyName]} rounded="md" h="250px" />
        </Box>
      );

    case 'YES/NO':
      return (
        <Box my="5px">
          <Text fontWeight="bold" fontSize="sm" textColor="gray.700">
            {index + 1}. {field.label}
          </Text>
          <Badge fontWeight="light" textColor="gray.600" fontSize="xs" mt="3px">
            {field[field.inputKeyName] ? 'YES' : 'NO'}
          </Badge>
        </Box>
      );
    default:
      return (
        <Box>
          <Text fontWeight="bold" fontSize="sm" textColor="gray.700">
            {index + 1}. {field.label}
          </Text>
          <Badge fontWeight="light" textColor="gray.600" fontSize="xs" mt="3px">
            {field[field.inputKeyName]}
          </Badge>
        </Box>
      );
  }
};

const RenderTaskDetails = ({ task, index, batch }) => {
  const [operator, setOperator] = useState();
  const [supervisor, setSupervisor] = useState();
  const calculateStandardTime = (hour, min) => {
    const tempHour = hour ? hour : 0;
    const tempMin = min ? min / 60 : 0;

    let time = tempHour + tempMin;
    return formatTime(time);
  };

  const formatTime = (totalTime) => {
    if (totalTime < 1) {
      return Math.ceil(totalTime * 60) + ' mins';
    } else {
      const minutes = totalTime - Math.floor(totalTime);
      if (minutes > 0) {
        return (totalTime - minutes).toString() + ' hour ' + Math.ceil(minutes * 60) + ' mins ';
      } else {
        return totalTime.toString() + ' hour';
      }
    }
  };

  const getEstCompleteTime = (startTime, stdHour, stdMinute) => {
    const date = new Date(startTime);
    const hour = stdHour ? stdHour : 0;
    const minute = stdMinute ? stdMinute : 0;

    date.setHours(date.getHours() + hour);
    date.setMinutes(date.getMinutes() + minute);

    return dayjs(date).format('DD, MMM, YY - hh:mm A');
  };

  useEffect(() => {
    (async () => {
      if (task.status === 'DONE') {
        if (task.poUsername) {
          const userData = await getAllUser({ searchKey: task.poUsername });
          setOperator(userData.users[0]);
        }

        if (task.supervisorUsername) {
          const supervisorData = await getAllUser({
            searchKey: task.supervisorUsername,
          });
          setSupervisor(supervisorData.users[0]);
        }
      }
    })();
  }, [task]);

  const getPoInfo = (taskType) => {
    if (taskType === 'DYING') {
      return batch.dyeingOperatorInfo;
    } else if (taskType === 'FINISHING') {
      return batch.finishingOperatorInfo;
    } else {
      return batch.qcOperatorInfo;
    }
  };

  const hasDelayed = () => {
    const completeTime = new Date(task.completeTime);

    const shouldHaveCompleted = new Date(task.startTime);

    const hour = task.standardTimeHour ? task.standardTimeHour : 0;
    const min = task.standardTimeMinute ? task.standardTimeMinute : 0;

    shouldHaveCompleted.setHours(shouldHaveCompleted.getHours() + hour);
    shouldHaveCompleted.setMinutes(shouldHaveCompleted.getMinutes() + min);

    if (shouldHaveCompleted < completeTime) {
      return {
        isDelayed: true,
        duration: completeTime - shouldHaveCompleted,
      };
    } else {
      return {
        isDelayed: false,
        duration: shouldHaveCompleted - completeTime,
      };
    }
  };

  const isTooEarly = () => {
    const start = new Date(task.startTime);
    const complete = new Date(task.completeTime);
    const duration = complete - start;

    const hour = task.standardTimeHour ? task.standardTimeHour : 0;
    const minute = task.standardTimeMinute ? task.standardTimeMinute / 60 : 0;

    const stdTime = (hour + minute) * 3600000;

    if (duration > stdTime * 0.2) {
      return false;
    } else {
      return true;
    }
  };

  switch (task.status) {
    case 'DONE':
      return (
        <Box my="10px">
          <Grid gridTemplateColumns="1fr 1fr">
            <Box>
              <Heading fontSize="lg">
                {index + 1}. {task.taskName}{' '}
                <Badge colorScheme={mapStatusToColor(task.status)}>{task.status}</Badge>
                {isTooEarly() && (
                  <Tag ml="5px" colorScheme="orange">
                    Too Early
                  </Tag>
                )}
              </Heading>
              <Text fontSize="sm">
                Std. Time: {calculateStandardTime(task.standardTimeHour, task.standardTimeMinute)}
              </Text>
              <Text fontSize="sm">
                Start Time: {dayjs(task.startTime).format('DD, MMM, YY - hh:mm A')}
              </Text>
              <Text fontSize="sm">
                Completed At: {dayjs(task.completeTime).format('DD, MMM, YY - hh:mm A')}
              </Text>
              <Text fontSize="sm">
                Duration: {msToTime(new Date(task.completeTime) - new Date(task.startTime))}
              </Text>
              {hasDelayed().isDelayed && (
                <>
                  <Text fontSize="sm">Delayed for {msToTime(hasDelayed().duration)}</Text>
                  <Text fontSize="sm">{task.reasonForDelay}</Text>
                </>
              )}
              {task.isSkipped && <Text fontSize="sm">Skipped because: {task.whySkipped}</Text>}
            </Box>
            {getPoInfo(task.taskType) && (
              <Box mt="25px">
                <Text fontSize="sm" fontWeight="bold">
                  PO
                </Text>
                <Text fontSize="sm">Name: {getPoInfo(task.taskType).name}</Text>
                <Text fontSize="sm">Username: {getPoInfo(task.taskType).username}</Text>
                <Text fontSize="sm">Phone: {getPoInfo(task.taskType).phone}</Text>
              </Box>
            )}
            {operator && (
              <Box mt="25px">
                <Text fontSize="sm" fontWeight="bold">
                  Operator
                </Text>
                <Text fontSize="sm">Name: {operator?.name}</Text>
                <Text fontSize="sm">Username: {operator?.username}</Text>
                <Text fontSize="sm">Phone: {operator?.phone}</Text>
              </Box>
            )}
            {supervisor && (
              <Box mt="25px">
                <Text fontSize="sm" fontWeight="bold">
                  Supervisor
                </Text>
                <Text fontSize="sm">Name: {supervisor?.name}</Text>
                <Text fontSize="sm">Username: {supervisor?.username}</Text>
                <Text fontSize="sm">Phone: {supervisor?.phone}</Text>
              </Box>
            )}
          </Grid>
          {task.isRecipeRequired && (
            <Box mt="25px">
              <Text fontSize="sm" fontWeight="bold">
                Chemicals Used
              </Text>
              <Text fontSize="sm">
                Prepared At:{' '}
                {dayjs(task.recipePrepared?.recipePreparedAt).format('DD/MMM - hh:mm A')}
              </Text>
              <Text fontSize="sm">Reference</Text>
              {task?.recipePrepared?.image && <Image src={task?.recipePrepared?.image} h="250px" />}
              {task.recipes?.map((chemical, index) => (
                <Text key={JSON.stringify(chemical)} fontSize="sm">
                  {index + 1}. {chemical.chemical}{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {chemical.quantity} x {chemical.total} {chemical.unit === 'gl' ? 'G/L' : '%'}
                  </span>
                </Text>
              ))}
            </Box>
          )}
          {task.formFields.map((field, index) => (
            <RenderFormInputs key={JSON.stringify(field)} field={field} index={index} />
          ))}
        </Box>
      );

    case 'RUNNING':
      return (
        <Box my="10px">
          <Heading fontSize="lg">
            {index + 1}. {task.taskName}{' '}
            <Badge colorScheme={mapStatusToColor(task.status)}>{task.status}</Badge>
          </Heading>
          <Text fontSize="sm">
            Std. Time: {calculateStandardTime(task.standardTimeHour, task.standardTimeMinute)}
          </Text>
          <Text fontSize="sm">
            Start Time: {dayjs(task.startTime).format('DD, MMM, YY - hh:mm A')}
          </Text>
          <Text fontSize="sm">
            Est. Complete Time:{' '}
            {getEstCompleteTime(task.startTime, task.standardTimeHour, task.standardTimeMinute)}
          </Text>
        </Box>
      );

    case 'DUE':
      return (
        <Box my="10px">
          <Heading fontSize="lg">
            {index + 1}. {task.taskName}{' '}
            <Badge colorScheme={mapStatusToColor(task.status)}>{task.status}</Badge>
          </Heading>
          <Text fontSize="sm">
            Std. Time: {calculateStandardTime(task.standardTimeHour, task.standardTimeMinute)}
          </Text>
        </Box>
      );
  }
};

export const BatchDetailReport = React.forwardRef((props, ref) => {
  const { batch } = props;

  const getActualLoadingTime = (tasks) => {
    const startTime = tasks.DYING[0].startTime;
    return dayjs(startTime).format('DD/MMM/YYYY - hh:mm A ');
  };

  const modifyTasks = () => [
    ...batch.batchProgressInfo.tasks.DYING,
    ...batch.batchProgressInfo.tasks.FINISHING,
    ...batch.batchProgressInfo.tasks.QC,
  ];

  const calculateStandardTime = (hour, min) => {
    const tempHour = hour ? hour : 0;
    const tempMin = min ? min / 60 : 0;

    let time = tempHour + tempMin;
    return time;
  };

  const formatTime = (totalTime) => {
    if (totalTime < 1) {
      return Math.ceil(totalTime * 60) + ' mins';
    } else {
      const minutes = totalTime - Math.floor(totalTime);
      if (minutes > 0) {
        return (totalTime - minutes).toString() + ' hour ' + Math.ceil(minutes * 60) + ' mins ';
      } else {
        return totalTime.toString() + ' hour';
      }
    }
  };

  const calculateTotalStandardTime = () => {
    const tasks = modifyTasks();
    let totalTime = 0;

    tasks.map((task) => {
      totalTime = totalTime + calculateStandardTime(task.standardTimeHour, task.standardTimeMinute);
    });

    const time = formatTime(totalTime);
    return time;
  };

  const hasDelayed = () => {
    const tasks = modifyTasks();
    let totalDelay = 0;

    tasks.map((task) => {
      if (task.status === 'DONE') {
        const completeTime = new Date(task.completeTime);

        const shouldHaveCompleted = new Date(task.startTime);

        const hour = task.standardTimeHour ? task.standardTimeHour : 0;
        const minutes = task.standardTimeMinute ? task.standardTimeMinute : 0;

        shouldHaveCompleted.setHours(shouldHaveCompleted.getHours() + hour);
        shouldHaveCompleted.setMinutes(shouldHaveCompleted.getMinutes() + minutes);

        if (completeTime > shouldHaveCompleted) {
          totalDelay = totalDelay + parseInt(completeTime - shouldHaveCompleted);
        }
      }
    });

    const batchLoadingTime = new Date(
      batch.loadDate.substring(0, 4),
      batch.loadDate.substring(5, 7) - 1,
      batch.loadDate.substring(8, 10),
      batch.loadTime.substring(0, 2),
      batch.loadTime.substring(3, 5),
    );
    const actualLoadingTime = tasks[0].startTime;

    if (actualLoadingTime > batchLoadingTime) {
      totalDelay -= parseInt(actualLoadingTime - batchLoadingTime);
    }
    if (totalDelay > 0) {
      return {
        isDelayed: true,
        duration: totalDelay,
      };
    } else {
      return {
        isDelayed: false,
        duration: totalDelay,
      };
    }
  };

  const hasComptedEarly = () => {
    const tasks = modifyTasks();
    let totalDelay = 0;

    tasks.map((task) => {
      if (task.status === 'DONE') {
        const completeTime = new Date(task.completeTime);

        const shouldHaveCompleted = new Date(task.startTime);

        const hour = task.standardTimeHour ? task.standardTimeHour : 0;
        const minutes = task.standardTimeMinute ? task.standardTimeMinute : 0;

        shouldHaveCompleted.setHours(shouldHaveCompleted.getHours() + hour);
        shouldHaveCompleted.setMinutes(shouldHaveCompleted.getMinutes() + minutes);

        if (completeTime < shouldHaveCompleted) {
          totalDelay = totalDelay + parseInt(shouldHaveCompleted - completeTime);
        }
      }
    });
    const batchLoadingTime = new Date(
      batch.loadDate.substring(0, 4),
      batch.loadDate.substring(5, 7) - 1,
      batch.loadDate.substring(8, 10),
      batch.loadTime.substring(0, 2),
      batch.loadTime.substring(3, 5),
    );
    const actualLoadingTime = tasks[0].startTime;

    if (actualLoadingTime < batchLoadingTime) {
      totalDelay -= parseInt(batchLoadingTime - actualLoadingTime);
    }

    if (totalDelay > 0) {
      return {
        isDelayed: true,
        duration: totalDelay,
      };
    } else {
      return {
        isDelayed: false,
        duration: totalDelay,
      };
    }
  };

  const calculateEstDuration = () => {
    let totalTime = 0;

    const tasks = modifyTasks();
    const { duration: delay } = hasDelayed();
    const { duration: early } = hasComptedEarly();

    tasks.map((task) => {
      totalTime = totalTime + calculateStandardTime(task.standardTimeHour, task.standardTimeMinute);
    });

    totalTime += delay / (1000 * 60 * 60);
    totalTime -= early / (1000 * 60 * 60);

    const time = formatTime(totalTime);
    return time;
  };
  const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date);
  };

  const calculateEstimatedFinishTime = () => {
    let totalTime = 0;
    const tasks = modifyTasks();
    const { duration: delay } = hasDelayed();
    const { duration: eraly } = hasComptedEarly();
    tasks.map((task) => {
      totalTime = totalTime + calculateStandardTime(task.standardTimeHour, task.standardTimeMinute);
    });

    totalTime += delay / (1000 * 60 * 60);
    totalTime -= eraly / (1000 * 60 * 60);

    const hour = Math.floor(totalTime);
    const min = totalTime - Math.floor(totalTime);

    const date = new Date(tasks[0].startTime);

    date.setHours(date.getHours() + hour);
    date.setMinutes(date.getMinutes() + min * 60);

    if (isValidDate(date)) {
      return dayjs(date).format('DD/MMM/YYYY - hh:mm A');
    } else {
      return 'Not started';
    }
  };

  const taskWithRecipes = batch.batchProgressInfo?.tasks?.DYING?.filter(
    (task) => task.isRecipeRequired,
  );

  const RenderRecipe = ({ recipe }) => {
    const formatTotalAmount = (amount) => {
      const kg = Math.floor(amount * 0.001);
      const gm = kg > 0 ? Math.floor((amount * 0.001 - Math.floor(amount * 0.001)) * 1000) : amount;
      const mg = gm > 0 ? 0 : Math.floor(amount * 0.001);
      return `${kg} Kg ${gm} Gm ${mg} Mg`;
    };
    if (recipe.unit === 'gl') {
      return (
        <Tr>
          <Td>{recipe.chemical}</Td>
          <Td>{recipe.quantity}</Td>
          <Td></Td>
          <Td>{formatTotalAmount(parseFloat(recipe.total))}</Td>
          <Td>{recipe.addition}</Td>
        </Tr>
      );
    } else {
      return (
        <Tr>
          <Td>{recipe.dye}</Td>
          <Td></Td>
          <Td>
            {(recipe.quantity + recipe.quantity * recipe.changeBy * 0.01)
              .toString()
              .substring(0, 4)}
          </Td>
          <Td>{formatTotalAmount(parseFloat(recipe.total))}</Td>
          <Td>{recipe.addition}</Td>
        </Tr>
      );
    }
  };

  const getStdTime = (phase) => {
    const tasks = batch.batchProgressInfo.tasks[phase];

    const totalStdTime = tasks.reduce((prValue, task) => {
      const hour = task.standardTimeHour ? task.standardTimeHour : 0;
      const minute = task.standardTimeMinute ? task.standardTimeMinute : 0;

      return prValue + hour + minute / 60;
    }, 0);

    return formatTime(totalStdTime);
  };

  const getActualTime = (phase) => {
    const tasks = batch.batchProgressInfo.tasks[phase];

    const allDoneTask = tasks.filter((item) => item.status === 'DONE');

    if (tasks.length !== allDoneTask.length) {
      return '-';
    } else {
      const actualTime = allDoneTask.reduce((prValue, task) => {
        const startTime = new Date(task.startTime);
        const endTime = new Date(task.completeTime);

        const timeInMs = endTime - startTime;

        const timeInHour = timeInMs / (60 * 60 * 1000);

        return prValue + timeInHour;
      }, 0);

      return formatTime(actualTime);
    }
  };

  const getAdditionalTime = (phase) => {
    const tasks = batch.batchProgressInfo.tasks[phase];

    const allDoneTask = tasks.filter((item) => item.status === 'DONE');

    if (tasks?.length !== allDoneTask?.length) {
      return '-';
    } else {
      const time = allDoneTask.reduce((prValue, task) => {
        const taskStartTime = new Date(task.startTime);
        const taskCompleteTime = new Date(task.completeTime);

        const timeTaken = (taskCompleteTime - taskStartTime) / (60 * 60 * 1000);

        const hour = task.standardTimeHour ? task.standardTimeHour : 0;
        const minute = task.standardTimeMinute ? task.standardTimeMinute : 0;

        const stdTime = hour + minute / 60;

        return prValue + timeTaken - stdTime;
      }, 0);
      return formatTime(time);
    }
  };

  return (
    <Box ref={ref} boxSizing="border-box" p="20px">
      <HStack align="start" justify="space-between">
        <Heading size="xl">Batch #{batch.lotNumber.split('-')[1]} </Heading>
        <Badge colorScheme={mapStatusToColor(batch.status)}>
          <Heading size="xl">{batch.status}</Heading>
        </Badge>
      </HStack>
      <HStack w="full" spacing={30}>
        <VStack align="start">
          <Text mt="10px" fontSize="sm" fontWeight="bold">
            FC: {batch.lotNumber.split('-')[0]}
          </Text>
          <Text mt="10px" fontSize="sm" fontWeight="bold">
            Batch Type: {batch.batchType}
          </Text>
          <Text mt="10px" fontSize="sm" fontWeight="bold">
            Process Type: {batch.processType}
          </Text>
          <Text mt="10px" fontSize="sm" fontWeight="bold">
            Finishing Type: {batch.finishingType}
          </Text>
          <Text mt="10px" fontSize="sm" fontWeight="bold">
            Scheduled Loading Time: <br /> {combineDateTime(batch.loadDate, batch.loadTime)}
          </Text>
          {batch.batchProgressInfo.isBatchStarted && (
            <Text mt="10px" fontSize="sm" fontWeight="bold">
              Actual Loading Time: <br /> {getActualLoadingTime(batch.batchProgressInfo.tasks)}
            </Text>
          )}
        </VStack>
        <VStack align="start">
          <Text mt="10px" fontSize="sm" fontWeight="bold">
            Party: {batch.customerInfo.partyName}
          </Text>
          <Text mt="10px" fontSize="sm" fontWeight="bold">
            Color: {batch.fabricInfo[0]?.color}
          </Text>
          <Text mt="10px" fontSize="sm" fontWeight="bold">
            Machine: {batch.machineInfo.name}
          </Text>
          <Text mt="10px" fontSize="sm" fontWeight="bold">
            Total Weight: {batch.totalFabricWeight} Kg
          </Text>
          <Text mt="10px" fontSize="sm" fontWeight="bold">
            Machine Capacity: {batch.machineInfo.maxCapacity} Kg
          </Text>
          <Text mt="10px" fontSize="sm" fontWeight="bold">
            Machine Usage:{' '}
            {Math.floor((batch.totalFabricWeight / batch.machineInfo.maxCapacity) * 100)} %
          </Text>
        </VStack>
        <VStack align="start">
          <Text mt="10px" fontSize="sm" fontWeight="bold">
            Approved By: {batch.approverInfo?.name}
          </Text>
          <Text mt="10px" fontSize="sm" fontWeight="bold">
            Created By: {batch.creatorInfo?.name}
          </Text>
          <Text mt="10px" fontSize="sm" fontWeight="bold">
            Std. Time: {calculateTotalStandardTime()}
          </Text>
          <Text mt="10px" fontSize="sm" fontWeight="bold">
            Est. Runtime: {calculateEstDuration()}
          </Text>
          <Text mt="10px" fontSize="sm" fontWeight="bold">
            Est. Finish time: {calculateEstimatedFinishTime()}
          </Text>
          <Text mt="10px" fontSize="sm" fontWeight="bold">
            Delay: {msToTime(hasDelayed().duration)}
          </Text>
        </VStack>
      </HStack>
      <Heading fontSize="24px" color="#272739">
        Batch Stats
      </Heading>
      <Table size="sm" my="15px" variant="fill">
        <Thead>
          <Tr>
            <Th>Phase</Th>
            <Th>Standard Time</Th>
            <Th>Actual Time</Th>
            <Th>Additional Time</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>Dyeing</Td>
            <Td>{getStdTime('DYING')}</Td>
            <Td>{getActualTime('DYING')}</Td>
            <Td>{getAdditionalTime('DYING')}</Td>
          </Tr>
          <Tr>
            <Td>Finishing</Td>
            <Td>{getStdTime('FINISHING')}</Td>
            <Td>{getActualTime('FINISHING')}</Td>
            <Td>{getAdditionalTime('FINISHING')}</Td>
          </Tr>
          <Tr>
            <Td>QC</Td>
            <Td>{getStdTime('QC')}</Td>
            <Td>{getActualTime('QC')}</Td>
            <Td>{getAdditionalTime('QC')}</Td>
          </Tr>
        </Tbody>
      </Table>
      <VStack
        h="65px"
        align="center"
        border="1px solid rgba(0,0,0,0.2)"
        padding="10px"
        rounded="md">
        <Badge>Finish Time</Badge>
        <Text fontSize="xs" color="gray.700">
          {calculateEstimatedFinishTime()}
        </Text>
      </VStack>
      <Heading fontSize="lg" mt="25px">
        Fabric Information
      </Heading>

      <Table size="sm" variant="striped" mt="15px">
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Fabric Type</Th>
            <Th>Shade Group</Th>
            <Th>Shade (%)</Th>
            <Th>Color</Th>
            <Th>DIA</Th>
            <Th>GSM</Th>
            <Th>Roll</Th>
            <Th>Used (kg)</Th>
            <Th>Lab dip</Th>
          </Tr>
        </Thead>
        <Tbody>
          {batch.fabricInfo.map((fabric, index) => (
            <Tr key={JSON.stringify(fabric)}>
              <Td>{index + 1}</Td>
              <Td>{fabric.fabricType}</Td>
              <Td>{fabric.shadeGroup}</Td>
              <Td>{fabric.shade}</Td>
              <Td>{fabric.color}</Td>
              <Td>{fabric.dia}</Td>
              <Td>{fabric.gsm}</Td>
              <Td>{fabric.roll}</Td>
              <Td>
                {fabric.usedQty} / ({fabric.quantity})
              </Td>
              <Td>{fabric.labDipRef}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Heading mt="25px" fontSize="lg">
        Chemicals
      </Heading>
      <Table mt="15px" size="sm">
        <Thead>
          <Tr>
            <Th>Chemical</Th>
            <Th>Quantity</Th>
            <Th>Quantity (%)</Th>
            <Th>Total Amount</Th>
            <Th>Addition</Th>
          </Tr>
        </Thead>
        {taskWithRecipes?.map((item) => (
          <Tbody key={item.uid}>
            <Tr>
              <Td colSpan={5} bgColor="gray.200">
                <Heading fontSize="lg">{item.taskName}</Heading>
              </Td>
            </Tr>
            {item?.recipes?.map((recipe, index) => (
              <RenderRecipe key={Date.now() + index} recipe={recipe} />
            ))}
          </Tbody>
        ))}
      </Table>

      <Box className="page-break"></Box>
      <Heading fontSize="lg" pt="25px">
        Task Details
      </Heading>
      {modifyTasks().map((item, index) => (
        <>
          <RenderTaskDetails task={item} key={item.uid} index={index} batch={batch} />
          <Box w="full" h="4px" rounded="full" bgColor="black" />
        </>
      ))}
      <Text my="15px" textAlign="center">
        This is a electronically generated report
      </Text>
    </Box>
  );
});

BatchDetailReport.displayName = 'Batch Detail Report';
