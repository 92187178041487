import { BellIcon, CheckIcon } from '@chakra-ui/icons';
import {
  Badge,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  IconButton,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import React from 'react';
import { markAllNotificationAsRead, markNotificationAsRead } from '../api/global';

const NotificationPanel = ({ onClose, isOpen, notifs, dataRefresh }) => {
  const RenderStatusBadge = ({ type }) => {
    switch (type) {
      case 'INFO':
        return <Badge colorScheme="yellow">Information</Badge>;
      case 'ERROR':
        return <Badge colorScheme="red">Need attention</Badge>;
      case 'WARN':
        return <Badge colorScheme="orange">Warning</Badge>;
      default:
        return <Badge colorScheme="green">No Action</Badge>;
    }
  };

  const markAsRead = async (notifId) => {
    const response = await markNotificationAsRead(notifId);

    if (response === 200) {
      localStorage.setItem('notificationLen', localStorage.getItem('notificationLen') - 1);
      await dataRefresh();
    }
  };

  const markAllAsRead = async () => {
    const notifIds = notifs?.map((item) => item.uid);

    const response = await markAllNotificationAsRead(notifIds);
    localStorage.setItem('notificationLen', 0);
    if (response === 200) {
      await dataRefresh();
    }
  };
  return (
    <Drawer onClose={onClose} isOpen={isOpen} size="sm">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader mt="20px">
          <HStack justify="space-between">
            <Text>Notifcations</Text>
            <Button size="sm" variant="ghost" colorScheme="blue" onClick={markAllAsRead}>
              Clear All
            </Button>
          </HStack>
        </DrawerHeader>
        <DrawerBody>
          {notifs?.length > 0 ? (
            notifs.map((notif) => (
              <Box
                key={notif.uid}
                w="100%"
                boxSizing="border-box"
                padding="15px"
                border="1px solid rgba(0,0,0,0.2)"
                rounded="lg"
                my="15px"
              >
                <HStack justify="space-between" align="flex-start">
                  <Text fontSize="sm" fontWeight="bold" color="gray.600">
                    {notif.title}
                  </Text>
                  <RenderStatusBadge type={notif.type} />
                </HStack>
                <Text fontSize="sm" color="gray.500" mt="5px">
                  {notif.body}
                </Text>
                <HStack justify="space-between">
                  <Text fontSize="sm" color="gray.400" mt="15px">
                    {dayjs(notif.createdAt).format('DD, MMM, YYYY')}
                  </Text>
                  <Tooltip label="Mark as read">
                    <IconButton
                      icon={<CheckIcon />}
                      size="sm"
                      onClick={async () => {
                        await markAsRead(notif.uid);
                      }}
                    />
                  </Tooltip>
                </HStack>
              </Box>
            ))
          ) : (
            <Box
              w="100%"
              boxSizing="border-box"
              padding="15px"
              border="1px solid rgba(0,0,0,0.2)"
              rounded="lg"
              my="15px"
            >
              <VStack spacing={2}>
                <BellIcon w={10} h={10} />
                <Text>No unread notifications</Text>
              </VStack>
            </Box>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default NotificationPanel;
