import { applyMiddleware, createStore } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'order'],
  blacklist: ['global', 'machine', 'user', 'batch', 'fc'],
};
const middlewares = [thunk];

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

import reducers from './reducers/rootReducer';
const persistedReducer = persistReducer(persistConfig, reducers);

export const store = createStore(persistedReducer, applyMiddleware(...middlewares));
export const persistor = persistStore(store);
