import axios from './axios';

export const getProductionChartReport = async (range) => {
  try {
    const response = await axios.get(`/report/production/${range}`);

    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getClientProductionConfig = async (range) => {
  try {
    const response = await axios.get(`/report/client/production/${range}`);

    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getMachineProductionConfig = async (range) => {
  try {
    const response = await axios.get(`/report/machine/production/${range}`);

    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getPOProductionConfig = async (range) => {
  try {
    const response = await axios.get(`/report/po/production/${range}`);

    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getOrderProductionConfig = async (range) => {
  try {
    const response = await axios.get(`/report/order/production/${range}`);

    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const fetchReportData = async (reportOptions) => {
  try {
    const response = await axios.post('/report/generate', { ...reportOptions });

    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
