import { Box, Button, Grid, GridItem, Heading, HStack, Link } from '@chakra-ui/react';
import React, { useState } from 'react';
import Charts from '../../components/reports/Chart';
import Loader from '../../components/common/Loader';
import {
  getClientProductionConfig,
  getMachineProductionConfig,
  getOrderProductionConfig,
  getPOProductionConfig,
  getProductionChartReport,
} from '../../api/reports';
import ProductionChartFooter from '../../components/reports/ProductionChartFooter';
import ProductionClientChartFooter from '../../components/reports/ProductionClientChartFooter';
import ProductionMachineChartFooter from '../../components/reports/ProductionMachineChartFooter';
import ProductionPoChartFooter from '../../components/reports/ProductionPoChartFooter';
import DeliveredVsReceivedFooter from '../../components/reports/DeliveredVsReceivedFooter';
import { BATCH_REPORT, DELIVERY_REPORT } from '../../constatnts/routerUrls';

const Reports = () => {
  const [productionChartConfig, setProductionChartConfig] = useState();
  const [clientProductionConfig, setClientProductionConfig] = useState();
  const [machineProductionConfig, setMachineProductionConfig] = useState();
  const [poProductionConfig, setPOProductionConfig] = useState();
  const [orderProductionConfig, setOrderProductionConfig] = useState();

  const [isLoading, setIsloading] = useState(false);

  // * get production chart
  const fetchProductionChartConfig = async (range) => {
    setIsloading(false);

    const config = await getProductionChartReport(range);
    if (config) {
      setProductionChartConfig(config);
    }
    setIsloading(false);
  };

  // * get client production chart
  const fetchClientProductionConfig = async (range) => {
    setIsloading(false);

    const config = await getClientProductionConfig(range);
    if (config) {
      setClientProductionConfig(config);
    }
    setIsloading(false);
  };

  // * get machine production chart
  const fetchMachineProductionChart = async (range) => {
    setIsloading(false);

    const config = await getMachineProductionConfig(range);
    if (config) {
      setMachineProductionConfig(config);
    }
    setIsloading(false);
  };

  // * get PO production chart
  const fetchPoProductionChart = async (range) => {
    setIsloading(false);

    const config = await getPOProductionConfig(range);
    if (config) {
      setPOProductionConfig(config);
    }
    setIsloading(false);
  };

  // * get Order production chart
  const fetchOrderProductionChart = async (range) => {
    setIsloading(false);

    const config = await getOrderProductionConfig(range);
    if (config) {
      setOrderProductionConfig(config);
    }
    setIsloading(false);
  };

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <Box w="100%" boxSizing="border-box" p="15px">
        <HStack>
          <Heading mb="15px">Reports</Heading>
        </HStack>
        <HStack p="15px" bg="gray.100" my="10px" rounded="md">
          <Button
            _hover={{ textDecoration: 'none' }}
            as={Link}
            colorScheme="facebook"
            href={`/reports/production/${new Date().toISOString()}/${new Date().toISOString()}`}>
            Production Report
          </Button>
          <Button
            _hover={{ textDecoration: 'none' }}
            as={Link}
            colorScheme="facebook"
            href={`/reports/daily-planning/${new Date().toISOString()}`}>
            Planning Report
          </Button>
          <Button
            _hover={{ textDecoration: 'none' }}
            as={Link}
            colorScheme="facebook"
            href={`${BATCH_REPORT}/${new Date().toISOString()}`}>
            Batch Report
          </Button>
          <Button
            _hover={{ textDecoration: 'none' }}
            as={Link}
            colorScheme="facebook"
            href={`${DELIVERY_REPORT}`}>
            Delayed Delivery Report
          </Button>
        </HStack>
        <Grid
          gridTemplateColumns={{
            base: '1fr',
            md: '1fr 1fr',
            lg: 'repeat(3, 1fr)',
          }}
          gap="20px">
          <GridItem boxSizing="border-box" p="15px" rounded="md" border="1px solid rgba(0,0,0,0.2)">
            <Charts
              chartHeader="Production"
              chartConfig={productionChartConfig?.chartConfig}
              fetchConfig={fetchProductionChartConfig}
              footer={<ProductionChartFooter data={productionChartConfig?.stats?.productionDif} />}
            />
          </GridItem>
          <GridItem boxSizing="border-box" p="15px" rounded="md" border="1px solid rgba(0,0,0,0.2)">
            <Charts
              chartHeader="Client Production"
              chartConfig={clientProductionConfig?.chartConfig}
              fetchConfig={fetchClientProductionConfig}
              footer={<ProductionClientChartFooter data={clientProductionConfig?.productionDif} />}
            />
          </GridItem>

          <GridItem boxSizing="border-box" p="15px" rounded="md" border="1px solid rgba(0,0,0,0.2)">
            <Charts
              chartHeader="Machine Production"
              chartConfig={machineProductionConfig?.chartConfig}
              fetchConfig={fetchMachineProductionChart}
              footer={
                <ProductionMachineChartFooter data={machineProductionConfig?.productionDif} />
              }
            />
          </GridItem>
          <GridItem boxSizing="border-box" p="15px" rounded="md" border="1px solid rgba(0,0,0,0.2)">
            <Charts
              chartHeader="User Vs Fabric and Work Hour"
              chartConfig={poProductionConfig?.chartConfig}
              fetchConfig={fetchPoProductionChart}
              footer={<ProductionPoChartFooter />}
            />
          </GridItem>
          <GridItem boxSizing="border-box" p="15px" rounded="md" border="1px solid rgba(0,0,0,0.2)">
            <Charts
              chartHeader="Order Received Vs Delivery"
              chartConfig={orderProductionConfig?.chartConfig}
              fetchConfig={fetchOrderProductionChart}
              footer={<DeliveredVsReceivedFooter data={orderProductionConfig?.productionDif} />}
            />
          </GridItem>
        </Grid>
      </Box>
    );
  }
};

export default Reports;
