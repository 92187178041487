import axios from './axios';

export const addSingleFc = async (formData) => {
  try {
    const insertData = {
      fcNumber: formData.fcNumber,
      partyName: formData.partyName,
      color: formData.color,
      shade: formData.shade,
      labDipRef: formData.labDipRef,
      clientRefNo: formData.clientRefNo,
      remarks: formData.remarks,
    };

    const { status, data } = await axios.post('/fc', { ...insertData });
    return {
      status,
      data,
    };
  } catch (error) {
    console.log(error);

    return {
      error,
    };
  }
};

export const getAllFc = async (query) => {
  try {
    const { status, data } = await axios.get('/fc', { params: query });
    return {
      status,
      data,
    };
  } catch (error) {
    console.log(error);

    return {
      error,
    };
  }
};

export const updateFc = async (formData) => {
  try {
    const updateData = {
      fcNumber: formData.fcNumber,
      partyName: formData.partyName,
      color: formData.color,
      shade: formData.shade,
      labDipRef: formData.labDipRef,
      clientRefNo: formData.clientRefNo,
      remarks: formData.remarks,
    };

    const { status } = await axios.patch('/fc', { ...updateData });
    return {
      status,
    };
  } catch (error) {
    console.log(error);

    return {
      error,
    };
  }
};

export const addBulkFc = async (data) => {
  try {
    const response = await axios.post('/fc/bulk', { data });
    return {
      status: response.status,
      data: response.data.erroredFc,
    };
  } catch (error) {
    console.log(error);

    return {
      error,
    };
  }
};

export const fetchInQueueFc = async () => {
  try {
    const response = await axios.get('/fc/in-queue');
    return {
      status: 200,
      data: response.data.customers,
    };
  } catch (error) {
    console.log(error);

    return {
      error,
    };
  }
};

export const getAllFcWithBatchInfo = async (query) => {
  try {
    if (!query?.isPrint) {
      const response = await axios.get('/fc/extended', { params: query });
      return {
        status: 200,
        data: response.data.customers,
        total: response.data.totalEntries,
        stats: response.data.stats,
      };
    } else {
      const response = await axios.get('/fc/extended', { params: query });
      return response.data.customers;
    }
  } catch (error) {
    console.log(error);

    return {
      error,
    };
  }
};

export const searchOrderByName = async (partyName) => {
  try {
    const response = await axios.get('/batch/search/orders/party-name', { params: { partyName } });
    return {
      status: 200,
      data: response.data.orders,
    };
  } catch (error) {
    console.log(error);

    return {
      data: null,
    };
  }
};

export const searchOrderByColor = async (color) => {
  try {
    const response = await axios.get('/batch/search/orders/color', { params: { color } });
    return {
      status: 200,
      data: response.data.orders,
    };
  } catch (error) {
    console.log(error);

    return {
      data: null,
    };
  }
};

export const fetchAllFabric = async (fcNumber) => {
  try {
    const response = await axios.get(`/fabric/${fcNumber}`);
    return response.data.fabrics;
  } catch (error) {
    return null;
  }
};
