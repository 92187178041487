import { ChakraProvider, cookieStorageManager, localStorageManager } from '@chakra-ui/react';
import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import RootRoute from './router/RootRoute';
import { persistor, store } from './store';
import Fonts from './theme/Fonts';
import theme from './theme/theme';

const App = ({ cookies }) => {
  const colorModeManager =
    typeof cookies === 'string' ? cookieStorageManager(cookies) : localStorageManager;

  return (
    <ChakraProvider theme={theme} colorModeManager={colorModeManager}>
      <Fonts />
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={null}>
          <RootRoute />
        </PersistGate>
      </Provider>
    </ChakraProvider>
  );
};

App.getInitialProps = ({ req }) => {
  return {
    // first time users will not have any cookies and you may not return
    // undefined here, hence ?? is necessary
    cookies: req.headers.cookie ?? '',
  };
};
export default App;
