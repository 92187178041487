import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Heading,
  Badge,
  Grid,
  IconButton,
  Text,
  Modal,
  ModalOverlay,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  ModalContent,
  useDisclosure,
  useToast,
  Divider,
  Textarea,
  HStack,
} from '@chakra-ui/react';
import { EditIcon, DeleteIcon } from '@chakra-ui/icons';
import { mapStatusToColor } from '../../utilities/mapStatusToColor';
import { useForm } from 'react-hook-form';
import {
  getMachineLogs,
  markMachineOOO,
  queryMachine,
  queryMachineLogs,
  updateMachineInfor,
  updateMachineQrId,
  updateMaintanenceLog,
} from '../../api/machine';
import {
  GET_ALL_MACHINE,
  GET_MACHINE_LOGS,
  MARK_MACHINE_AS_OOO,
  UPDATE_MACHINE_INFO,
  UPDATE_MACHINE_LOG,
} from '../../store/actions/action-types';
import AlertBox from '../../components/common/AlertBox';
import CustomTable2 from '../../components/common/CustomTable2';
import QRCode from 'react-qr-code';
import { RiQrCodeLine } from 'react-icons/ri';
import { useReactToPrint } from 'react-to-print';
import { useRef } from 'react';
import toastFormat from '../../constatnts/toastFormat';

const MachineUpdateForm = ({ obj, close }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const [showAlert, setShowAlert] = useState(false);

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      name: obj['name'],
      capacity: obj['capacity'],
      maxCapacity: obj['maxCapacity'],
      minCapacity: obj['minCapacity'],
      status: obj['status'],
      machineType: obj['machineType'],
    },
  });
  const watchAllFields = watch();

  const submit = async (data) => {
    try {
      if (!isDirty) {
        toast({
          title: 'No field changed',
          status: 'info',
          ...toastFormat,
        });
        setShowAlert(false);
      } else {
        const res = await updateMachineInfor(data);
        const logRes = await getMachineLogs(data.name);
        if (res.data) {
          dispatch({
            type: UPDATE_MACHINE_INFO,
            payload: {
              name: obj.name,
              machine: res.data,
            },
          });

          dispatch({
            type: GET_MACHINE_LOGS,
            payload: logRes.machineLogs,
          });

          setShowAlert(false);
          close();
          toast({
            title: 'Machine information updated',
            status: 'success',
            ...toastFormat,
          });
        }
      }
    } catch (error) {
      toast({
        title: 'Failed to update machine information',
        status: 'error',
        ...toastFormat,
      });
    }
  };

  return (
    <Box>
      <Flex width="100%" direction="column" align="center" justify="center">
        <AlertBox
          title="Update Machine Info"
          subTitle="Are you sure you want to update machine information"
          onClose={() => setShowAlert(false)}
          show={showAlert}
          onSubmit={() => handleSubmit(submit)()}
        />
        <FormControl my="10px" width="100%">
          <FormLabel>Name</FormLabel>
          <Input
            {...register('name')}
            required
            placeholder="Machine Name"
            width="300px"
            disabled
            onChange={(e) => setValue('name', e.target.value, { shouldDirty: true })}
          />
        </FormControl>
        <FormControl my="10px" width="100%">
          <FormLabel>Capacity</FormLabel>
          <Input
            {...register('capacity')}
            required
            placeholder="Machine Capacity"
            type="number"
            width="300px"
            onChange={(e) => setValue('capacity', e.target.value)}
          />
        </FormControl>
        <FormControl my="10px" width="100%">
          <FormLabel>Max Capacity</FormLabel>
          <Input
            {...register('maxCapacity')}
            required
            placeholder="Machine Max Capacity"
            type="number"
            width="300px"
            onChange={(e) => setValue('maxCapacity', e.target.value)}
          />
        </FormControl>
        <FormControl my="10px" width="100%">
          <FormLabel>Minimum Capacity</FormLabel>
          <Input
            {...register('minCapacity')}
            required
            placeholder="Machine Minimum Capacity"
            type="number"
            width="300px"
            onChange={(e) => setValue('minCapacity', e.target.value)}
          />
        </FormControl>
        <FormControl my="10px" width="100%">
          <FormLabel>Machine Type</FormLabel>
          <Select
            {...register('machineType')}
            defaultValue={obj['machineType']}
            onChange={(e) => setValue('machineType', e.target.value)}
          >
            <option value="AOP">AOP</option>
            <option value="DYEING">DYEING</option>
            <option value="FINISHING">FINISHING</option>
          </Select>
        </FormControl>
        <FormControl my="10px" width="100%">
          <FormLabel>Machine Status</FormLabel>
          <Select
            {...register('status')}
            defaultValue={obj['status']}
            onChange={(e) => setValue('status', e.target.value)}
          >
            <option value="IN USE">IN USE</option>
            <option value="MAINTANENCE">MAINTANENCE</option>
            <option value="IDLE">IDLE</option>
            <option value="IN WASH">IN WASH</option>
            <option value="OUT OF ORDER">OUT OF ORDER</option>
          </Select>
        </FormControl>

        {watchAllFields.status === 'MAINTANENCE' || watchAllFields.status === 'OUT OF ORDER' ? (
          <>
            <FormControl my="10px" width="100%">
              <FormLabel>Reason</FormLabel>
              <Input
                {...register('reason')}
                required
                placeholder="Reason for maintanence"
                type="text"
                width="300px"
                onChange={(e) => setValue('reason', e.target.value)}
              />
            </FormControl>
            <FormControl my="10px" width="100%">
              <FormLabel>Aditional Remark</FormLabel>
              <Textarea
                {...register('remark')}
                required
                placeholder="Additional Remarks"
                width="300px"
                onChange={(e) => setValue('remark', e.target.value)}
              />
            </FormControl>
          </>
        ) : (
          <></>
        )}
        <Button
          colorScheme="blue"
          size="lg"
          width="300px"
          onClick={() => setShowAlert(true)}
          mt="20px"
        >
          UPDATE MACHINE INFO
        </Button>
      </Flex>
    </Box>
  );
};

const RenderMachineLogUpdateModal = ({ data }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [logInfo, setlogInfo] = useState({
    reason: data.reason ? data.reason : '',
    remark: data.remark ? data.remark : '',
  });
  const [showAlert, setShowAlert] = useState(false);

  const toast = useToast();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    try {
      if (logInfo.reason.length < 3 && logInfo.remark.length < 3) {
        toast({
          title: 'Please add remark and reason',
          ...toastFormat,
        });
      } else {
        const { status } = await updateMaintanenceLog(data.logId, { ...logInfo });
        if (status === 200) {
          dispatch({
            type: UPDATE_MACHINE_LOG,
            payload: {
              logId: data.logId,
              data: { ...logInfo },
            },
          });
          toast({
            title: 'Machine log updated',
            status: 'success',
            ...toastFormat,
          });
          setShowAlert(false);
          onClose();
        }
      }
    } catch (error) {
      console.log(error);
      toast({
        title: 'Failed to update machine log',
        status: 'error',
        ...toastFormat,
      });
    }
  };
  if (data.machineStatus === 'OUT OF ORDER' || data.machineStatus === 'MAINTANENCE') {
    return (
      <>
        <AlertBox
          show={showAlert}
          title="Update Machine Log"
          subTitle="Are you sure you want to update machine log"
          onClose={() => setShowAlert(false)}
          onSubmit={handleSubmit}
        />
        <IconButton icon={<EditIcon />} colorScheme="yellow" onClick={onOpen} />
        <Modal isOpen={isOpen} onClose={onClose} size="sm">
          <ModalOverlay />
          <ModalContent>
            <Flex
              width="100%"
              pading="16px 20px"
              boxSizing="border-box"
              direction="column"
              align="center"
              justify="center"
            >
              <Text margin="30px 0 10px 0">
                LOG ID <Badge>{data.logId}</Badge>{' '}
                <Badge colorScheme="purple" ml="5px">
                  {data.machineStatus}
                </Badge>
              </Text>
              <Badge ml="16px" mb="20px" colorScheme="red">
                Time - {data.time}
              </Badge>
              <Text textColor="gray.600" fontSize="sm">
                Reason for {data.machineStatus.toLowerCase()}
              </Text>
              <Input
                width="80%"
                mb="20px"
                value={logInfo.reason}
                name="reason"
                onChange={(e) => setlogInfo({ ...logInfo, [e.target.name]: e.target.value })}
                placeholder={`Why machine in ${data.machineStatus.toLowerCase()}`}
              />
              <Text textColor="gray.600" fontSize="sm">
                Additional Remarks
              </Text>
              <Textarea
                width="80%"
                mb="20px"
                value={logInfo.remark}
                name="remark"
                onChange={(e) => setlogInfo({ ...logInfo, [e.target.name]: e.target.value })}
                placeholder="Additional Information"
              />
              <Button w="80%" colorScheme="teal" mb="16px" onClick={() => setShowAlert(true)}>
                Update
              </Button>
            </Flex>
          </ModalContent>
        </Modal>
      </>
    );
  } else {
    return <></>;
  }
};

const QrCodePage = React.forwardRef((props, ref) => {
  const machine = useSelector((state) => state.machine.machines).find(
    (ma) => ma.name === props.name,
  );

  return (
    <Flex
      ref={ref}
      direction="row"
      align="start"
      justify="flex-start"
      boxSizing="border-box"
      p="30px"
    >
      <HStack justify="start" align="start" spacing={5}>
        {machine.qrId && <QRCode value={machine.qrId} size={120} />}
        <Heading lineHeight="25px" fontSize="6xl">
          {machine?.name}
        </Heading>
      </HStack>
    </Flex>
  );
});

QrCodePage.displayName = 'Machine QR Code';

const MachineDetails = () => {
  const { name } = useParams();
  const toast = useToast();
  const scanPageRef = useRef();

  const machine = useSelector((state) => state.machine.machines).find((ma) => ma.name === name);
  const logs = useSelector((state) => state.machine.logs);

  const { onClose, onOpen, isOpen } = useDisclosure();
  const [showAlert, setShowAlert] = useState(false);
  const [totalLogEntries, setTotalLogEntries] = useState(0);
  const [showQrCodeGenerationAlert, setShowQrCodeGenerationAlert] = useState(false);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { machines } = await queryMachine({ name });
      const { machineLogs, totalEntries } = await getMachineLogs(name);

      if (machines) {
        dispatch({
          type: GET_ALL_MACHINE,
          payload: machines,
        });

        dispatch({
          type: GET_MACHINE_LOGS,
          payload: machineLogs,
        });

        setTotalLogEntries(totalEntries);
      }
      setLoading(false);
    })();
  }, []);

  const handleDelete = async () => {
    try {
      const { status } = await markMachineOOO(name);
      const { machineLogs, totalEntries } = await getMachineLogs(name);

      if (status === 200) {
        dispatch({
          type: MARK_MACHINE_AS_OOO,
          payload: name,
        });
        dispatch({
          type: GET_MACHINE_LOGS,
          payload: machineLogs,
        });
        setTotalLogEntries(totalEntries);

        setShowAlert(false);
        toast({
          title: 'Machine marked as out order',
          status: 'success',
          ...toastFormat,
        });
      } else {
        setShowAlert(false);
        toast({
          title: 'Machine already out of order',
          status: 'info',
          ...toastFormat,
        });
      }
    } catch (error) {
      toast({
        title: 'Failed to mark machine as out order',
        status: 'error',
        ...toastFormat,
      });
    }
  };

  const logFilters = [
    {
      name: 'Log Id',
      queryParamKey: 'logId',
      type: 'INPUT',
    },
    {
      name: 'Machine Status',
      queryParamKey: 'machineStatus',
      type: 'SELECT',
      options: [
        { value: 'IN USE', label: 'In Use' },
        { value: 'IDLE', label: 'Idle' },
        { value: 'MAINTANENCE', label: 'Maintanence' },
        { value: 'OUT OF ORDER', label: 'Out of order' },
        { value: 'IN WASH', label: 'In wash' },
      ],
    },
    {
      name: 'Start Date',
      queryParamKey: 'startDate',
      type: 'DATE',
    },
    {
      name: 'End Date',
      queryParamKey: 'endDate',
      type: 'DATE',
    },
  ];

  const handleQuery = async (data) => {
    try {
      setLoading(true);

      const { machineLogs, totalEntries } = await queryMachineLogs(name, data);

      dispatch({
        type: GET_MACHINE_LOGS,
        payload: machineLogs,
      });

      setTotalLogEntries(totalEntries);
      setLoading(false);
    } catch (error) {
      toast({
        title: 'No data found',
        status: 'info',
        ...toastFormat,
      });
      setLoading(false);
    }
  };

  const handleRefresh = async () => {
    try {
      setLoading(true);
      const { machineLogs, totalEntries } = await getMachineLogs(name);

      dispatch({
        type: GET_MACHINE_LOGS,
        payload: machineLogs,
      });

      setTotalLogEntries(totalEntries);
      setLoading(false);
    } catch (error) {
      toast({
        title: 'Something went worng',
        status: 'info',
        ...toastFormat,
      });
      setLoading(false);
    }
  };

  const columns = [
    {
      header: 'log id',
      key: 'logId',
      type: 'BADGE',
    },
    {
      header: 'Machine Status',
      key: 'machineStatus',
      type: 'BADGE',
    },
    {
      header: 'Time',
      key: 'time',
      type: 'TEXT',
    },
    {
      header: 'Reason',
      key: 'reason',
      type: 'TEXT',
    },
    {
      header: 'Remarks',
      key: 'remark',
      type: 'TOOLTIP',
    },
  ];

  const generateQrCode = async () => {
    const qrId = name + Date.now().toString();

    await updateMachineQrId(machine.id, qrId);

    dispatch({
      type: 'UPDATE_QR_ID',
      payload: {
        name,
        qrId,
      },
    });

    setShowQrCodeGenerationAlert(false);
    setTimeout(() => {
      printQrcode();
    }, 500);
  };

  const printQrcode = useReactToPrint({
    documentTitle: 'Machine QR Code',
    content: () => scanPageRef.current,
  });

  return (
    <Box margin="40px 30px">
      <AlertBox
        show={showAlert}
        onClose={() => setShowAlert(false)}
        title="Mark machine as out of order"
        subTitle="Are you sure? You want to mark machine as out of order"
        onSubmit={handleDelete}
      />
      <AlertBox
        show={showQrCodeGenerationAlert}
        onClose={() => setShowQrCodeGenerationAlert(false)}
        title="New QR code will be generated"
        subTitle="New QR code will be generated for this machine. Are you sure you want to do this? If you generate a new QR code you will need to refresh batches currently running on the app"
        onSubmit={generateQrCode}
      />
      <Modal isOpen={isOpen} onClose={onClose} size="sm">
        <ModalOverlay />
        <ModalContent>
          <Flex
            width="100%"
            boxSizing="border-box"
            padding="16px"
            direction="column"
            align="center"
            justify="center"
          >
            <Text mb="30px" fontSize="xl" fontWeight="bold" textAlign="center">
              Update machine info for {name}
            </Text>
            <MachineUpdateForm obj={machine} close={onClose} />
          </Flex>
        </ModalContent>
      </Modal>
      {machine && (
        <>
          <Flex align="center" justify="space-between">
            <Heading>{machine.name.split('_').map((i) => i + ' ')} </Heading>
            <Box>
              <Button
                size="sm"
                rightIcon={<RiQrCodeLine size="20px" />}
                colorScheme="facebook"
                ml="10px"
                onClick={() => setShowQrCodeGenerationAlert(true)}
              >
                GENERATE QR CODE
              </Button>
              <Button
                size="sm"
                rightIcon={<EditIcon />}
                colorScheme="green"
                ml="10px"
                onClick={onOpen}
              >
                UPDATE INFO
              </Button>
              <Button
                size="sm"
                rightIcon={<DeleteIcon />}
                colorScheme="yellow"
                ml="10px"
                onClick={() => setShowAlert(true)}
              >
                OUT OF ORDER
              </Button>
            </Box>
          </Flex>
          <Badge
            fontSize="lg"
            fontWeight="bold"
            colorScheme={mapStatusToColor(machine.status)}
            mt="10px"
          >
            {machine.status}
          </Badge>
          <Divider my="15px" />
          <Grid marginTop="30px" templateColumns="repeat(4, 1fr)" width="500px">
            <Box>
              <Badge colorScheme="purple">Capacity</Badge>
              <Text fontSize="lg" fontWeight="bold">
                {machine.capacity} KG
              </Text>
            </Box>
            <Box>
              <Badge colorScheme="purple">Max Capacity</Badge>
              <Text fontSize="lg" fontWeight="bold">
                {machine.maxCapacity} KG
              </Text>
            </Box>
            <Box>
              <Badge colorScheme="purple">Min Capacity</Badge>
              <Text fontSize="lg" fontWeight="bold">
                {machine.minCapacity} KG
              </Text>
            </Box>
            <Box>
              <Badge colorScheme="purple">Machine Type</Badge>
              <Text fontSize="lg" fontWeight="bold">
                {machine.machineType}
              </Text>
            </Box>
          </Grid>
        </>
      )}
      <Divider my="30px" />
      <CustomTable2
        columns={columns}
        data={logs}
        entries={totalLogEntries}
        filters={logFilters}
        queryCallback={handleQuery}
        refreshCallback={handleRefresh}
        showCreateButton={false}
        Actions={[RenderMachineLogUpdateModal]}
        loading={loading}
      />
      <Box display="none">{machine && <QrCodePage name={name} ref={scanPageRef} />}</Box>
    </Box>
  );
};

export default MachineDetails;
