import { CloseIcon, InfoIcon } from '@chakra-ui/icons';
import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  IconButton,
  Text,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import Clock from '../common/Clock';
import Card from './Card';

const FullScreenBatchCard = ({ show, close, batches }) => {
  const { inDyeingBatches, inFinishingBatches, inQCBatches, haltedBatches } = batches;
  const [mac] = useMediaQuery('(max-width: 1440px)');

  return (
    <Drawer size="full" isOpen={show} onClose={close}>
      <DrawerCloseButton />
      <DrawerOverlay />
      <DrawerContent boxSizing="border-box" px="25px" overflow="auto">
        <HStack
          w="100%"
          justify="space-between"
          position="sticky"
          top="0"
          bgColor="#fff"
          py="15px"
          zIndex="1555">
          <HStack>
            <Heading fontSize="2xl">Live Tracking</Heading>
            <Clock />
          </HStack>
          <IconButton onClick={close} icon={<CloseIcon />} size="sm" colorScheme="red" />
        </HStack>
        <Grid
          gridTemplateColumns={{
            base: '1fr',
            md: '1fr',
            lg: mac ? 'repeat(3, 1fr)' : 'repeat(4, 1fr)',
          }}
          gap="15px">
          <GridItem boxSizing="border-box">
            <Heading fontSize="xl" mb="20px">
              Dyeing
            </Heading>
            <VStack>
              {inDyeingBatches?.length > 0 ? (
                inDyeingBatches.map((item) => <Card batch={item} key={item.lotNumber} />)
              ) : (
                <Flex w="300px" h="300px" justify="center" align="center" direction="column">
                  <InfoIcon w={10} h={10} />
                  <Text mt="10px" fontSize="lg" fontWeight="bold">
                    No batch
                  </Text>
                </Flex>
              )}
            </VStack>
          </GridItem>
          <GridItem borderLeft="1px solid rgba(0,0,0,0.2)" px="15px" boxSizing="border-box">
            <Heading fontSize="xl" mb="20px">
              Finishing
            </Heading>
            <VStack>
              {inFinishingBatches?.length > 0 ? (
                inFinishingBatches.map((item) => <Card batch={item} key={item.lotNumber} />)
              ) : (
                <Flex w="300px" h="300px" justify="center" align="center" direction="column">
                  <InfoIcon w={10} h={10} />
                  <Text mt="10px" fontSize="lg" fontWeight="bold">
                    No batch
                  </Text>
                </Flex>
              )}
            </VStack>
          </GridItem>
          <GridItem
            borderLeft="1px solid rgba(0,0,0,0.2)"
            px="15px"
            boxSizing="border-box"
            borderRight={mac ? 'none' : '1px solid rgba(0,0,0,0.2)'}>
            <Heading fontSize="xl" mb="20px">
              QC
            </Heading>
            <VStack>
              {inQCBatches?.length > 0 ? (
                inQCBatches.map((item) => <Card batch={item} key={item.lotNumber} />)
              ) : (
                <Flex w="300px" h="300px" justify="center" align="center" direction="column">
                  <InfoIcon w={10} h={10} />
                  <Text mt="10px" fontSize="lg" fontWeight="bold">
                    No batch
                  </Text>
                </Flex>
              )}
            </VStack>
          </GridItem>
          <GridItem boxSizing="border-box">
            <Heading fontSize="xl" mb="20px">
              Halted
            </Heading>
            {mac ? (
              <Grid
                gridTemplateColumns={{
                  base: '1fr',
                  md: '1fr',
                  xl: '1fr',
                }}>
                {haltedBatches?.length > 0 ? (
                  haltedBatches?.map((item) => <Card batch={item} key={item.lotNumber} />)
                ) : (
                  <Flex w="300px" h="300px" justify="center" align="center" direction="column">
                    <InfoIcon w={10} h={10} />
                    <Text mt="10px" fontSize="lg" fontWeight="bold">
                      No batch
                    </Text>
                  </Flex>
                )}
              </Grid>
            ) : (
              <VStack>
                {haltedBatches?.length > 0 ? (
                  haltedBatches?.map((item) => <Card batch={item} key={item.lotNumber} />)
                ) : (
                  <Flex w="300px" h="300px" justify="center" align="center" direction="column">
                    <InfoIcon w={10} h={10} />
                    <Text mt="10px" fontSize="lg" fontWeight="bold">
                      No batch
                    </Text>
                  </Flex>
                )}
              </VStack>
            )}
          </GridItem>
        </Grid>
      </DrawerContent>
    </Drawer>
  );
};

export default FullScreenBatchCard;
