/* eslint-disable no-unused-vars */
import {
  Box,
  HStack,
  IconButton,
  Input,
  Spinner,
  Text,
  useToast,
  Tooltip,
  Image,
  VStack,
  Divider,
  Grid,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Heading,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import toastFormat from '../../constatnts/toastFormat';
import ReactToPrint from 'react-to-print';
import { RiPrinterLine } from 'react-icons/ri';
import LOGO from '../../assets/images/logo.svg';
import { getProductionReport } from '../../api/batch';
import ExcelExport from 'export-xlsx';
import { DownloadIcon } from '@chakra-ui/icons';

const ProductionReport = () => {
  const { startDate, endDate } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const printRef = useRef();
  const [reportData, setReportData] = useState(null);
  const history = useHistory();

  const handleStartDateCHange = (e) => {
    const date = new Date(e.target.value);
    const end = new Date(endDate);
    if (date > end) {
      toast({
        ...toastFormat,
        status: 'warning',
        title: 'Start date cannot be greater than end date',
      });
    } else {
      history.push(`/report/production/${date.toISOString()}/${endDate}`);
      window.location.reload(false);
    }
  };

  const handleEndDateChange = (e) => {
    const date = new Date(e.target.value);
    const start = new Date(startDate);
    if (date < start) {
      toast({
        ...toastFormat,
        status: 'warning',
        title: 'End date cannot be less than start date',
      });
    } else {
      history.push(`/report/production/${startDate}/${date.toISOString()}`);
      window.location.reload(false);
    }
  };

  const fetchData = async () => {
    const data = await getProductionReport({ startDate, endDate });
    setReportData(data);
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      fetchData();
      setIsLoading(false);
    })();
  }, [startDate, endDate]);

  const generateXL = () => {
    const excelExport = new ExcelExport();

    const SHEET_CONFIG = {
      fileName: `Production report - ${dayjs(startDate).format('DD-MMM-YYYY')} - ${dayjs(
        endDate,
      ).format('DD-MMM-YYYY')}`,
      workSheets: [
        {
          sheetName: 'Production Report',
          startingRowNumber: 1,
          tableSettings: {
            data: {
              importable: true,
              headerDefinition: [
                {
                  name: 'Machine',
                  key: 'machine',
                },
                {
                  name: 'Lot Number',
                  key: 'lotNumber',
                },
                {
                  name: 'FC Number',
                  key: 'fcNumber',
                },
                {
                  name: 'Party Name',
                  key: 'partyName',
                },
                {
                  name: 'Color',
                  key: 'color',
                },
                {
                  name: 'Fabric Type',
                  key: 'fabricType',
                },
                {
                  name: 'Fabric Weight',
                  key: 'qtyToday',
                },
                {
                  name: 'Loading Time',
                  key: 'actualLoadingTime',
                },
                {
                  name: 'Unloading Time',
                  key: 'unloadingTime',
                },
                {
                  name: 'Open / Tube',
                  key: 'finishingType',
                },
                {
                  name: 'Process Type',
                  key: 'processType',
                },
                {
                  name: 'Status',
                  key: 'status',
                },
                {
                  name: 'Standard Time',
                  key: 'stdTime',
                },
                {
                  name: 'Total Time Taken',
                  key: 'totalTime',
                },
                {
                  name: 'Extra Time',
                  key: 'extraTime',
                },
              ],
            },
          },
        },
      ],
    };

    let data = [];

    reportData.fabrics.map((item) => {
      item.fabrics.map((fabric) => {
        data.push({
          machine: item.machine,
          lotNumber: fabric.lotNumber,
          fcNumber: fabric.fcNumber,
          partyName: fabric.partyName,
          color: fabric.color,
          fabricType: fabric.fabricType,
          qtyToday: fabric.qtyToday,
          actualLoadingTime: fabric.actualLoadingTime,
          unloadingTime: fabric.unloadingTime,
          finishingType: fabric.finishingType,
          processType: fabric.processType,
          status: fabric.status,
          stdTime: fabric.stdTime,
          totalTime: fabric.totalTime,
          extraTime: fabric.extraTime,
        });
      });
    });

    excelExport.downloadExcel(SHEET_CONFIG, [{ data: data }]);
  };
  return (
    <>
      {isLoading ? (
        <Box w="100%" h="100vh">
          <Spinner />
        </Box>
      ) : (
        <Box boxSizing="border-box" p="15px">
          <HStack
            bgColor="gray.200"
            rounded="md"
            p="15px"
            boxSizing="border-box"
            justify="space-between">
            <HStack>
              <Text>Start Date</Text>
              <Input
                type="date"
                w="200px"
                value={dayjs(new Date(startDate)).format('YYYY-MM-DD')}
                bgColor="white"
                onChange={(e) => handleStartDateCHange(e)}
              />
              <Text>End Date</Text>
              <Input
                type="date"
                w="200px"
                value={dayjs(new Date(endDate)).format('YYYY-MM-DD')}
                bgColor="white"
                onChange={(e) => handleEndDateChange(e)}
              />
            </HStack>
            <HStack>
              <Tooltip label="Print">
                <Box>
                  <ReactToPrint
                    trigger={() => <IconButton icon={<RiPrinterLine />} colorScheme="facebook" />}
                    content={() => printRef.current}
                  />
                </Box>
              </Tooltip>
              <Tooltip label="Download XL">
                <IconButton icon={<DownloadIcon />} colorScheme="facebook" onClick={generateXL} />
              </Tooltip>
            </HStack>
          </HStack>
          <Box my="30px" />
          <Box ref={printRef}>
            <HStack w="full" my="10px" justify="space-between">
              <Image src={LOGO} h="50px" />
              <VStack align="start">
                <Text fontSize="14px" fontWeight="bold">
                  {dayjs(startDate).format('DD, MMM, YYYY')} -{' '}
                  {dayjs(endDate).format('DD, MMM, YYYY')}
                </Text>
                <Text fontSize="18px" fontWeight="bold">
                  Production Report
                </Text>
              </VStack>
            </HStack>
            <Divider />
            {reportData ? (
              <>
                <Grid my="15px" align="start" gridTemplateColumns="repeat(5, 1fr)" gap="15px">
                  <Text fontSize="sm" fontWeight="bold" color="gray.700">
                    Production Target:{' '}
                    <Text fontWeight="extrabold" fontSize="lg">
                      {reportData.productionTarget} KG
                    </Text>
                  </Text>
                  <Text fontSize="sm" fontWeight="bold" color="gray.800">
                    Processed:{' '}
                    <Text fontWeight="extrabold" fontSize="lg">
                      {reportData.processed} KG
                    </Text>
                  </Text>
                  <Text fontSize="sm" fontWeight="bold" color="gray.800">
                    Production Rate:{' '}
                    <Text fontWeight="extrabold" fontSize="lg">
                      {reportData.productionRate} KG
                    </Text>
                  </Text>
                  <Text fontSize="sm" fontWeight="bold" color="gray.800">
                    Dyeing Rate:{' '}
                    <Text fontWeight="extrabold" fontSize="lg">
                      {reportData.dyeingRate} KG
                    </Text>
                  </Text>
                  <Text fontSize="sm" fontWeight="bold" color="gray.800">
                    Finishing Rate:{' '}
                    <Text fontWeight="extrabold" fontSize="lg">
                      {reportData.finishingRate} KG
                    </Text>
                  </Text>
                  <Text fontSize="sm" fontWeight="bold" color="gray.800">
                    Batches Queued:{' '}
                    <Text fontWeight="extrabold" fontSize="lg">
                      {reportData.queuedBatches}
                    </Text>
                  </Text>
                  <Text fontSize="sm" fontWeight="bold" color="gray.800">
                    Batches Running:{' '}
                    <Text fontWeight="extrabold" fontSize="lg">
                      {reportData.runningBatches}
                    </Text>
                  </Text>
                  <Text fontSize="sm" fontWeight="bold" color="gray.800">
                    Overall Delay:{' '}
                    <Text fontWeight="extrabold" fontSize="lg">
                      {reportData.orverallDelay}
                    </Text>
                  </Text>
                </Grid>
                <Divider />
                {reportData?.fabrics?.length > 0 ? (
                  <Table size="sm" variant="fill">
                    <Thead>
                      <Tr>
                        <Th>Machine</Th>
                        <Th textAlign="center">Details</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {reportData?.fabrics?.map((item, index) => (
                        <>
                          <Tr borderBottom="1px solid rgba(0,0,0,0.4)">
                            <Td
                              bg="gray.100"
                              align="center"
                              justifyContent="center"
                              fontWeight="bold">
                              {item.machine}
                            </Td>
                            <Table siz="sm" variant="fill">
                              <Thead>
                                <Tr>
                                  <Th
                                    fontSize="xs"
                                    w="100px"
                                    fontWeight="extrabold"
                                    opacity={index > 0 ? 0 : 1}>
                                    Lot
                                  </Th>
                                  <Th
                                    fontSize="xs"
                                    w="100px"
                                    fontWeight="extrabold"
                                    opacity={index > 0 ? 0 : 1}>
                                    Fc
                                  </Th>
                                  <Th
                                    fontSize="xs"
                                    w="100px"
                                    fontWeight="extrabold"
                                    opacity={index > 0 ? 0 : 1}>
                                    Party
                                  </Th>
                                  <Th
                                    fontSize="xs"
                                    w="150px"
                                    fontWeight="extrabold"
                                    opacity={index > 0 ? 0 : 1}>
                                    Color
                                  </Th>
                                  <Th
                                    fontSize="xs"
                                    w="100px"
                                    fontWeight="extrabold"
                                    opacity={index > 0 ? 0 : 1}>
                                    Fabric
                                  </Th>
                                  <Th
                                    fontSize="xs"
                                    w="100px"
                                    fontWeight="extrabold"
                                    opacity={index > 0 ? 0 : 1}>
                                    Weight
                                  </Th>
                                  <Th
                                    fontSize="xs"
                                    w="100px"
                                    fontWeight="extrabold"
                                    opacity={index > 0 ? 0 : 1}>
                                    Loading
                                  </Th>
                                  <Th
                                    fontSize="xs"
                                    w="100px"
                                    fontWeight="extrabold"
                                    opacity={index > 0 ? 0 : 1}>
                                    Unloading
                                  </Th>
                                  <Th
                                    fontSize="xs"
                                    w="100px"
                                    fontWeight="extrabold"
                                    opacity={index > 0 ? 0 : 1}>
                                    O/T
                                  </Th>
                                  <Th
                                    fontSize="xs"
                                    w="100px"
                                    fontWeight="extrabold"
                                    opacity={index > 0 ? 0 : 1}>
                                    Process
                                  </Th>
                                  <Th
                                    fontSize="xs"
                                    w="100px"
                                    fontWeight="extrabold"
                                    opacity={index > 0 ? 0 : 1}>
                                    Status
                                  </Th>
                                  <Th
                                    fontSize="xs"
                                    w="100px"
                                    fontWeight="extrabold"
                                    opacity={index > 0 ? 0 : 1}>
                                    STD.
                                  </Th>
                                  <Th
                                    fontSize="xs"
                                    w="100px"
                                    fontWeight="extrabold"
                                    opacity={index > 0 ? 0 : 1}>
                                    Total
                                  </Th>
                                  <Th
                                    fontSize="xs"
                                    w="100px"
                                    fontWeight="extrabold"
                                    opacity={index > 0 ? 0 : 1}>
                                    Extra
                                  </Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {item?.fabrics?.map((fabric) => (
                                  <Tr key={fabric.lotNumber}>
                                    <Td
                                      fontSize="12px"
                                      w="100px"
                                      border="1px solid rgba(0,0,0,0.4)">
                                      {fabric.lotNumber}
                                    </Td>
                                    <Td
                                      fontSize="12px"
                                      w="100px"
                                      border="1px solid rgba(0,0,0,0.4)">
                                      {fabric.fcNumber}
                                    </Td>
                                    <Td
                                      fontSize="12px"
                                      w="100px"
                                      border="1px solid rgba(0,0,0,0.4)">
                                      {fabric.partyName}
                                    </Td>
                                    <Td
                                      fontSize="12px"
                                      w="150px"
                                      border="1px solid rgba(0,0,0,0.4)">
                                      {fabric.color} - ({fabric.shade} %)
                                    </Td>
                                    <Td
                                      fontSize="12px"
                                      w="100px"
                                      border="1px solid rgba(0,0,0,0.4)">
                                      {fabric.fabricType}
                                    </Td>
                                    <Td
                                      fontSize="12px"
                                      w="100px"
                                      border="1px solid rgba(0,0,0,0.4)">
                                      {fabric.qtyToday} Kg
                                    </Td>
                                    <Td
                                      fontSize="12px"
                                      w="100px"
                                      border="1px solid rgba(0,0,0,0.4)">
                                      {fabric.actualLoadingTime}
                                    </Td>
                                    <Td
                                      fontSize="12px"
                                      w="100px"
                                      border="1px solid rgba(0,0,0,0.4)">
                                      {fabric.unloadingTime}
                                    </Td>
                                    <Td
                                      fontSize="12px"
                                      w="100px"
                                      border="1px solid rgba(0,0,0,0.4)">
                                      {fabric.finishingType}
                                    </Td>
                                    <Td
                                      fontSize="12px"
                                      w="100px"
                                      border="1px solid rgba(0,0,0,0.4)">
                                      {fabric.processType}
                                    </Td>
                                    <Td
                                      fontSize="12px"
                                      w="100px"
                                      border="1px solid rgba(0,0,0,0.4)">
                                      {fabric.status}
                                    </Td>
                                    <Td
                                      fontSize="12px"
                                      w="100px"
                                      border="1px solid rgba(0,0,0,0.4)">
                                      {fabric.stdTime}
                                    </Td>
                                    <Td
                                      fontSize="12px"
                                      w="100px"
                                      border="1px solid rgba(0,0,0,0.4)">
                                      {fabric.totalTime}
                                    </Td>
                                    <Td
                                      fontSize="12px"
                                      w="100px"
                                      border="1px solid rgba(0,0,0,0.4)">
                                      {fabric.extraTime}
                                    </Td>
                                  </Tr>
                                ))}
                              </Tbody>
                            </Table>
                          </Tr>
                          <div className="pagebreak"></div>
                        </>
                      ))}
                    </Tbody>
                  </Table>
                ) : (
                  <Box>
                    <Heading my="15px" textAlign="center">
                      No production data for {dayjs(startDate).format('DD, MMM, YYYY')} -{' '}
                      {dayjs(endDate).format('DD, MMM, YYYY')}
                    </Heading>
                  </Box>
                )}
                <div className="page-break"></div>
              </>
            ) : (
              <Box>
                <Spinner />
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default ProductionReport;
