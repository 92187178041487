export const mapStatusToColor = (status) => {
  const colors = {
    'IN USE': 'orange',
    IDLE: 'green',
    MAINTANENCE: 'red',
    'OUT OF ORDER': 'red',
    'IN WASH': 'blue',
    SUPER_ADMIN: 'purple',
    ADMIN: 'red',
    AD: 'orange',
    SI: 'orange',
    DPO: 'orange',
    FS: 'orange',
    QCS: 'orange',
    BM: 'orange',
    MANAGER: 'red',
    ACTIVE: 'green',
    INACTIVE: 'yellow',
    BANNED: 'red',
    BULK: 'green',
    SAMPLE: 'teal',
    'RE PROCESS': 'orange',

    'IS PAUSED': 'orange',
    'ON HOLD': 'red',
    'IN QUEUE': 'yellow',
    STARTED: 'green',
    'READY FOR DELIVERY': 'green',
    DRAFT: 'yellow',
    'WAITING FOR APPROVAL': 'orange',
    DYING: 'purple',
    FINISHING: 'yellow',
    QC: 'blue',
    DONE: 'green',
    DUE: 'red',
    RUNNING: 'yellow',
    HIGH: 'red',
    MEDIUM: 'yellow',
    LOW: 'green',
    DELIVERED: 'green',
    ASSIGNED: 'orange',
  };

  if (typeof status === 'number') {
    if (status >= 70 && status <= 100) {
      return 'green';
    } else if (status >= 30 && status < 70) {
      return 'yellow';
    } else if (status < 30) {
      return 'red';
    }
  }
  return colors[status] ? colors[status] : 'gray';
};
