export const processTypes = {
  FINISHING: [
    {
      name: 'Stenter: 1 Roll Check',
      formFields: [
        {
          type: 'YES/NO',
          label: 'Test passed?',
        },
        {
          type: 'INPUT',
          label: 'Machine speed',
        },
        {
          type: 'INPUT',
          label: 'Machine Temp',
        },
        {
          type: 'INPUT',
          label: 'Machine Over Feed',
        },
        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },

    {
      name: 'Stenter: Full Batch Run',
      formFields: [
        {
          type: 'YES/NO',
          label: 'Test passed?',
        },
        {
          type: 'INPUT',
          label: 'Machine speed',
        },
        {
          type: 'INPUT',
          label: 'Machine Temp',
        },
        {
          type: 'INPUT',
          label: 'Machine Over Feed',
        },
        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },

    {
      name: 'Sliting',
      formFields: [
        {
          type: 'YES/NO',
          label: 'Sliting complete?',
        },
        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },
    {
      name: 'Squeezer',
      formFields: [
        {
          type: 'YES/NO',
          label: 'Test passed?',
        },
        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },
    {
      name: 'Hydro',
      formFields: [
        {
          type: 'YES/NO',
          label: 'Test passed?',
        },
        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },
    {
      name: 'Dryer',
      formFields: [
        {
          type: 'YES/NO',
          label: 'Test passed?',
        },
        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },
    {
      name: 'Tube Compacting',
      formFields: [
        {
          type: 'YES/NO',
          label: 'Test passed?',
        },
        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },
    // COMPACTING START
    {
      name: 'Open Compacting',
      formFields: [
        {
          type: 'YES/NO',
          label: 'Compacting started?',
        },
        {
          type: 'IMAGE INPUT',
          label: 'Image of monitor parameter set',
        },

        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },

    // COMPACTING COMPLETE
    {
      name: 'Compacting complete',
      formFields: [
        {
          type: 'YES/NO',
          label: 'Compacting done',
        },
        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },
  ],
  DYING: [
    // SCOURING RUN
    {
      name: 'Scouring Run',
      formFields: [
        {
          type: 'YES/NO',
          label: 'Scouring run completed successfully?',
        },
        {
          type: 'IMAGE INPUT',
          label: 'Take Image',
        },
        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },
    // SHADE CHECKING
    {
      name: 'Shade checking',
      formFields: [
        {
          type: 'YES/NO',
          label: 'Fabric shade okay?',
        },
        {
          type: 'IMAGE INPUT',
          label: 'Take Image',
        },
        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },
    {
      name: 'Absorbency Check',
      formFields: [
        {
          type: 'YES/NO',
          label: 'Fabric shade okay?',
        },
        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },
    // PH CHECK
    {
      name: 'PH Check',
      formFields: [
        {
          type: 'YES/NO',
          label: 'Correct PH level?',
        },
        {
          type: 'NUMBER INPUT',
          label: 'PH Level',
        },
        {
          type: 'IMAGE INPUT',
          label: 'Take Image',
        },
        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },

    // ENZYME
    {
      name: 'Enzyme',
      formFields: [
        {
          type: 'YES/NO',
          label: 'All enzyme added?',
        },
        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },
    {
      name: 'Leveling',
      formFields: [
        {
          type: 'YES/NO',
          label: 'Leveling done?',
        },
        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },

    // COLOR
    {
      name: 'Color',
      formFields: [
        {
          type: 'YES/NO',
          label: 'Dyes added?',
        },
        {
          type: 'NUMBER INPUT',
          label: 'Weight of dyes',
        },
        {
          type: 'IMAGE INPUT',
          label: 'Take Image',
        },
        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },

    // SALT DOSSING
    {
      name: 'Salt Dossing',
      formFields: [
        {
          type: 'YES/NO',
          label: 'Salt added?',
        },
        {
          type: 'IMAGE INPUT',
          label: 'Take Image of salt bag',
        },
        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },

    // SODA DOSSING 10%
    {
      name: 'Soda dossing 10%',
      formFields: [
        {
          type: 'YES/NO',
          label: '10% soda added?',
        },
        {
          type: 'NUMBER INPUT',
          label: 'Weight of soda',
        },
        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },

    // SODA DOSSING 20%
    {
      name: 'Soda dossing 20%',
      formFields: [
        {
          type: 'YES/NO',
          label: '20% soda added?',
        },
        {
          type: 'NUMBER INPUT',
          label: 'Weight of soda',
        },
        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },

    //SODA DOSSING 70%
    {
      name: 'Soda dossing 70%',
      formFields: [
        {
          type: 'YES/NO',
          label: '70% soda added?',
        },
        {
          type: 'NUMBER INPUT',
          label: 'Weight of soda',
        },
        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },

    // COLOR STEAM 60
    {
      name: 'Color Steam 60',
      formFields: [
        {
          type: 'YES/NO',
          label: 'Color steam 60 okay?',
        },
        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },
    // COLOR STEAM 80
    {
      name: 'Color Steam 80',
      formFields: [
        {
          type: 'YES/NO',
          label: 'Color steam 80 okay?',
        },
        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },
    // B/D
    {
      name: 'B/D',
      formFields: [
        {
          type: 'IMAGE INPUT',
          label: 'Take Fabric Image',
        },
        {
          type: 'YES/NO',
          label: 'B/D Okay?',
        },
        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },

    // B/D HOT
    {
      name: 'B/D Hot',
      formFields: [
        {
          type: 'YES/NO',
          label: 'B/D Hot Okay?',
        },
        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },

    // ACID
    {
      name: 'Acid',
      formFields: [
        {
          type: 'YES/NO',
          label: 'ACID test okay?',
        },
        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },

    // SOFTNER
    {
      name: 'Softner',
      formFields: [
        {
          type: 'YES/NO',
          label: 'Softner test passed',
        },
        {
          type: 'IMAGE INPUT',
          label: 'Take picture of fabric shade',
        },
        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },
    {
      name: 'Loading Hot',
      formFields: [
        {
          type: 'YES/NO',
          label: 'Is step comeplete?',
        },
        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },

    {
      name: 'Sequestering Hot',
      formFields: [
        {
          type: 'YES/NO',
          label: 'Is step comeplete?',
        },
        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },
    {
      name: 'Fixing',
      formFields: [
        {
          type: 'YES/NO',
          label: 'Is step comeplete?',
        },
        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },
    {
      name: 'Normal Hot',
      formFields: [
        {
          type: 'YES/NO',
          label: 'Is step comeplete?',
        },
        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },
    {
      name: 'Enzyme Hot',
      formFields: [
        {
          type: 'YES/NO',
          label: 'Is step comeplete?',
        },
        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },
    {
      name: 'PC Acid',
      formFields: [
        {
          type: 'YES/NO',
          label: 'Is step comeplete?',
        },
        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },
    {
      name: 'Polyester Color',
      formFields: [
        {
          type: 'YES/NO',
          label: 'Is step comeplete?',
        },
        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },
    {
      name: 'Polyester Levelling',
      formFields: [
        {
          type: 'YES/NO',
          label: 'Is step comeplete?',
        },
        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },
    {
      name: 'Reduction',
      formFields: [
        {
          type: 'YES/NO',
          label: 'Is step comeplete?',
        },
        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },
    {
      name: 'Migration',
      formFields: [
        {
          type: 'YES/NO',
          label: 'Is step comeplete?',
        },
        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },
    // Fabric Unloading
    {
      name: 'Fabric Unloading',
      formFields: [
        {
          type: 'YES/NO',
          label: 'Fabric unloaded?',
        },
        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },
  ],
  QC: [
    {
      name: 'One roll check',
      formFields: [
        {
          type: 'YES/NO',
          label: 'Test passed?',
        },
        {
          type: 'IMAGE INPUT',
          label: 'Fabric image',
        },
        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },

    // FULL BATCH CHECK
    {
      name: 'Full batch check',
      formFields: [
        {
          type: 'YES/NO',
          label: 'Test passed?',
        },
        {
          type: 'IMAGE INPUT',
          label: 'Fabric image',
        },
        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },

    // QC PASS
    {
      name: 'QC Check',
      formFields: [
        {
          type: 'YES/NO',
          label: 'Test passed?',
        },
        {
          type: 'IMAGE INPUT',
          label: 'Fabric image',
        },
        {
          type: 'INPUT',
          label: 'Shade',
        },
        {
          type: 'INPUT',
          label: 'Shrinkage %',
        },
        {
          type: 'INPUT',
          label: 'DIA',
        },
        {
          type: 'INPUT',
          label: 'GSM',
        },
        {
          type: 'TEXTAREA',
          label: 'Additional Remarks',
        },
      ],
    },
  ],
};
