import { Button, HStack } from '@chakra-ui/react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { REPORT_GENERATION } from '../../constatnts/routerUrls';

const ProductionPoChartFooter = () => {
  const history = useHistory();
  return (
    <HStack w="100%" justify="flex-end" mt="15px">
      <Button
        size="sm"
        colorScheme="facebook"
        onClick={() => history.push(REPORT_GENERATION, { type: 'po-production' })}
      >
        Generate Report
      </Button>
    </HStack>
  );
};

export default ProductionPoChartFooter;
