import { AddIcon, DeleteIcon, WarningTwoIcon } from '@chakra-ui/icons';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  IconButton,
  Input,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import {
  createNewClient,
  createNewFabricType,
  createNewShade,
  createNewShadeGroup,
  createOrder,
  fetchAllClients,
  fetchAllFabricTypes,
  fetchAllShadeGroup,
  fetchAllShades,
} from '../../api/customer';
import AlertBox from '../../components/common/AlertBox';
import { ORDER_INDEX } from '../../constatnts/routerUrls';
import {
  basicOrderInfoValidation,
  newClientAddValidation,
  newFabricTypeAddValidation,
  newShadeAddValidation,
  newShadeGroupAddValidation,
  useYupValidationResolver,
} from '../../validators/form-validators';
import UpdateShadeGroup from '../../components/order/UpdateShadeGroup';
import UpdateShade from '../../components/order/UpdateShade';
import UpdateFabricType from '../../components/order/UpdateFabricType';
import SelectField from '../../components/common/SelectField';
import { dirtyOption } from '../../constatnts/hook-form-options';

const AddNewItem = ({ title, show, close, onSubmit, children }) => {
  return (
    <AlertDialog isOpen={show} onClose={close}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>{children}</AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={close} size="sm">
              Cancel
            </Button>
            <Button colorScheme="facebook" onClick={onSubmit} size="sm" ml={3}>
              Confirm
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

const AddNewOrder = () => {
  const basicInfoResolver = useYupValidationResolver(basicOrderInfoValidation);
  const addNewClientResolver = useYupValidationResolver(newClientAddValidation);
  const addNewShadeGroupResolver = useYupValidationResolver(newShadeGroupAddValidation);
  const addNewShadeResolver = useYupValidationResolver(newShadeAddValidation);
  const addNewFabricTypeResolver = useYupValidationResolver(newFabricTypeAddValidation);

  const {
    register,
    watch,
    formState: { errors },
    control,
    setValue,
    handleSubmit,
    setError,
  } = useForm({
    defaultValues: {
      partyName: '',
      fcNumber: '',
      salesPerson: '',
      fabricInfo: [
        {
          shadeGroup: '',
          color: '',
          shade: '',
          GSM: '',
          fabricType: '',
          DIA: '',
          processDescription: '',
        },
      ],
    },
    reValidateMode: 'onChange',
    mode: 'onChange',
    resolver: basicInfoResolver,
  });

  const {
    register: newClientRegister,
    formState: { errors: newClientErrors },
    handleSubmit: newClientSubmit,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: addNewClientResolver,
  });

  const {
    register: newShadeRegister,
    setValue: setShadeValue,
    formState: { errors: newShadeErrors },
    handleSubmit: newShadeSubmit,
    control: newShadeControl,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: addNewShadeResolver,
  });

  const {
    register: newShadeGroupRegister,
    formState: { errors: newShadeGroupErrors },
    handleSubmit: newShadeGroupSubmit,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: addNewShadeGroupResolver,
  });

  const {
    register: newFabricTypeRegister,
    formState: { errors: newFabricTypeErrors },
    handleSubmit: newFabricTypeSubmit,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: addNewFabricTypeResolver,
  });

  const { fields, remove, append } = useFieldArray({ control, name: 'fabricInfo' });

  const history = useHistory();

  const [showNewClientAlert, setShowNewClientAlert] = useState(false);
  const [showNewShadeAlert, setShowNewShadeAlert] = useState(false);
  const [showNewShadeGroupAlert, setShowNewShadeGroupAlert] = useState(false);
  const [showNewFabricTypeAlert, setShowNewFabricTypeAlert] = useState(false);

  const [allShades, setAllShades] = useState();
  const [allShadeGroups, setAllShadeGroups] = useState();
  const [allClients, setAllClients] = useState();
  const [allFabricTypes, setAllFabricTypes] = useState();
  const [alertSubTitle, setAlertSubTitle] = useState('');
  const [showSubmissionAlert, setShowSubmissionAlert] = useState(false);

  useEffect(() => {
    (async () => {
      const resposne = await fetchAllShades();
      setAllShades(resposne);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const resposne = await fetchAllShadeGroup();
      setAllShadeGroups(resposne);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const resposne = await fetchAllFabricTypes();
      setAllFabricTypes(resposne);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const resposne = await fetchAllClients();

      setAllClients(resposne);
    })();
  }, []);

  useEffect(() => {
    if (errors) {
      setShowSubmissionAlert(false);
    }
  }, [errors]);
  const handleOrderCreation = async (formData) => {
    if (formData.clientId) {
      const response = await createOrder(formData);
      if (response === 200) {
        history.push(ORDER_INDEX);
      } else {
        setError('fcNumber', {
          type: 'required',
          message: 'Possible duplicate FC Number',
        });
      }
    } else {
      setError('partyName', {
        type: 'required',
        message: 'This client is not in our record please add first',
      });
    }
    setShowSubmissionAlert(false);
  };

  const handleNewClientAdd = async (formData) => {
    const response = await createNewClient(formData.clientName);
    if (response) {
      setAllClients([...allClients, response]);
    }
    setShowNewClientAlert(false);
  };

  const handleNewShadeAdd = async (formData) => {
    const response = await createNewShade({
      shade: formData.shade,
      shadeGroupId: formData.shadeGroupId,
    });
    if (response) {
      setAllShades([...allShades, response]);
    }
    setShowNewShadeAlert(false);
  };

  const handleNewShadeGroup = async ({ shadeGroup, startRange, endRange }) => {
    const response = await createNewShadeGroup({
      shadeGroup,
      startRange,
      endRange,
    });
    if (response) {
      setAllShadeGroups([...allShadeGroups, response]);
    }
    setShowNewShadeGroupAlert(false);
  };

  const handleNewFabricType = async (formData) => {
    const response = await createNewFabricType(formData.fabricType);
    if (response) {
      setAllFabricTypes([...allFabricTypes, response]);
    }
    setShowNewFabricTypeAlert(false);
  };

  const handleClientSelection = (data) => {
    const { partyName, clientId } = data;
    setValue('partyName', partyName, dirtyOption);
    setValue('clientId', clientId, dirtyOption);
  };

  const handleShadeGroupSelection = (data) => {
    const { value, index } = data;
    setValue(`fabricInfo.${index}.shadeGroup`, value, dirtyOption);
  };

  const handleShadeSelection = (data) => {
    const { value, index } = data;
    setValue(`fabricInfo.${index}.color`, value, dirtyOption);
  };

  const handleFabricTypeSelection = (data) => {
    const { value, index } = data;
    setValue(`fabricInfo.${index}.fabricType`, value, dirtyOption);
  };

  const getTotalQuantity = () => {
    let total = 0;
    watch().fabricInfo?.map((item) => (total = total + parseFloat(item.quantity)));
    return total;
  };

  const fabricInfo = useWatch({ control, name: 'fabricInfo' });

  const filterdColorList = (index) => {
    const shadeGroup = fabricInfo[index]?.shadeGroup;

    const filterdedShadeGroup = allShadeGroups?.find((item) => item.shadeGroup === shadeGroup);

    return filterdedShadeGroup
      ? allShades?.filter(({ shadeGroupId }) => shadeGroupId === filterdedShadeGroup.id)
      : [];
  };

  const handleDataError = () => {
    setAlertSubTitle('');
    let alertText = '';
    fabricInfo.map((item, index) => {
      const shadeGroup = allShadeGroups?.find((group) => group.shadeGroup === item.shadeGroup);
      if (item.shade < shadeGroup?.startRange || item.shade > shadeGroup?.endRange) {
        alertText += `${index + 1}. ${item.fabricType} has invalid shade percentage </br>`;
      }
    });

    setAlertSubTitle(alertText);
    setShowSubmissionAlert(true);
  };

  const handleShadeGroupSelectionOnShadeCreation = (data) => {
    setShadeValue('shadeGroupId', data.value, {
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const RenderInvalidFlag = ({ index }) => {
    const fabric = watch().fabricInfo[index];

    const shadeGroup = allShadeGroups?.find((item) => item.shadeGroup === fabric.shadeGroup);

    if (fabric.shade < shadeGroup?.startRange || fabric.shade > shadeGroup?.endRange) {
      return (
        <Tooltip
          placement="top"
          label={`Shade range should be between ${shadeGroup?.startRange} to ${shadeGroup?.endRange}`}
        >
          <Tag colorScheme="red">
            <WarningTwoIcon />
          </Tag>
        </Tooltip>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      <AddNewItem
        title="Add New Client"
        show={showNewClientAlert}
        close={() => setShowNewClientAlert(false)}
        onSubmit={newClientSubmit(handleNewClientAdd)}
      >
        <FormControl isRequired={true} isInvalid={newClientErrors?.clientName}>
          <Input placeholder="Please Enter client name" {...newClientRegister('clientName')} />
          <FormErrorMessage>{newClientErrors?.clientName?.message}</FormErrorMessage>
        </FormControl>
      </AddNewItem>

      <AddNewItem
        title="Add new color"
        show={showNewShadeAlert}
        close={() => setShowNewShadeAlert(false)}
        onSubmit={newShadeSubmit(handleNewShadeAdd)}
      >
        <FormControl isRequired={true} isInvalid={newShadeErrors?.shade}>
          <FormLabel fontSize="xs">Color Name</FormLabel>
          <Input placeholder="Please enter shade color" {...newShadeRegister('shade')} />

          <FormErrorMessage>{newShadeErrors?.shade?.message}</FormErrorMessage>
        </FormControl>
        <FormControl mt="15px">
          <FormLabel fontSize="xs">Shade Group</FormLabel>
          <SelectField
            control={newShadeControl}
            name={'shadeGroupId'}
            handleChange={handleShadeGroupSelectionOnShadeCreation}
            options={allShadeGroups?.map((item) => ({ label: item.shadeGroup, value: item.id }))}
          />
        </FormControl>
      </AddNewItem>

      <AddNewItem
        title="Add new Shade Group"
        show={showNewShadeGroupAlert}
        close={() => setShowNewShadeGroupAlert(false)}
        onSubmit={newShadeGroupSubmit(handleNewShadeGroup)}
      >
        <FormControl isRequired={true} isInvalid={newShadeGroupErrors?.shadeGroup}>
          <FormLabel fontSize="sm">Shade Group Name</FormLabel>
          <Input
            placeholder="Please enter shade group name"
            {...newShadeGroupRegister('shadeGroup')}
          />
          <FormErrorMessage>{newShadeGroupErrors?.shadeGroup?.message}</FormErrorMessage>
        </FormControl>
        <HStack mt="15px">
          <FormControl isRequired={true} isInvalid={newShadeGroupErrors?.startRange}>
            <FormLabel fontSize="sm">Start Range (%)</FormLabel>
            <Input
              placeholder="Start Range"
              {...newShadeGroupRegister('startRange')}
              type="number"
            />
            <FormErrorMessage>{newShadeGroupErrors?.startRange?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired={true} isInvalid={newShadeGroupErrors?.endRange}>
            <FormLabel fontSize="sm">End Range (%)</FormLabel>
            <Input placeholder="End Range" {...newShadeGroupRegister('endRange')} type="number" />
            <FormErrorMessage>{newShadeGroupErrors?.endRange?.message}</FormErrorMessage>
          </FormControl>
        </HStack>
      </AddNewItem>

      <AddNewItem
        title="Add new Fabric Type"
        show={showNewFabricTypeAlert}
        close={() => setShowNewFabricTypeAlert(false)}
        onSubmit={newFabricTypeSubmit(handleNewFabricType)}
      >
        <FormControl isRequired={true} isInvalid={newFabricTypeErrors?.fabricType}>
          <Input
            placeholder="Please enter fabric type name"
            {...newFabricTypeRegister('fabricType')}
          />
          <FormErrorMessage>{newFabricTypeErrors?.fabricType?.message}</FormErrorMessage>
        </FormControl>
      </AddNewItem>

      <AlertBox
        title="Are you sure?"
        onSubmit={handleSubmit(handleOrderCreation)}
        show={showSubmissionAlert}
        onClose={() => setShowSubmissionAlert(false)}
        subTitle={alertSubTitle}
        showWarning={true}
        warningText="Warning"
      />

      <Box w="100%" boxSizing="border-box" padding="15px">
        <HStack w="100%" justify="space-between">
          <Heading mb="15px" fontSize="3xl" textTransform="capitalize">
            Add new order
          </Heading>
          <HStack>
            <UpdateShadeGroup shadeGroups={allShadeGroups} updateData={setAllShadeGroups} />
            <UpdateShade updateData={setAllShades} shades={allShades} />
            <UpdateFabricType updateData={setAllFabricTypes} fabricTypes={allFabricTypes} />
          </HStack>
        </HStack>

        <Grid
          gridTemplateColumns={{
            base: '1fr',
            md: '1fr',
            lg: '1fr 1fr',
          }}
          gap="20px"
        >
          <GridItem
            w="100%"
            boxSizing="border-box"
            padding="15px"
            border="1px solid rgba(0,0,0,0.2)"
            rounded="md"
          >
            <Heading fontSize="xl" mb="15px">
              Basic Order Information
            </Heading>
            <VStack w="100%">
              <FormControl isInvalid={errors?.partyName} isRequired={true} w="100%">
                <FormLabel>Client Name</FormLabel>
                <HStack w="100%">
                  <Box w="100%">
                    <SelectField
                      options={allClients?.map((item) => ({
                        label: item.clientName,
                        value: item.id,
                        partyName: item.clientName,
                        clientId: item.id,
                      }))}
                      control={control}
                      name="partyName"
                      handleChange={handleClientSelection}
                    />
                  </Box>
                  <Tooltip lable="Add new client">
                    <IconButton
                      onClick={() => setShowNewClientAlert(true)}
                      icon={<AddIcon />}
                      _hover={{ bgColor: 'white' }}
                    />
                  </Tooltip>
                </HStack>
                <FormErrorMessage>{errors?.partyName?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.fcNumber} isRequired={true}>
                <FormLabel>FC Number</FormLabel>
                <Input placeholder="FC Number" {...register('fcNumber')} maxLength={6} />
                <FormErrorMessage>{errors?.fcNumber?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.salesPerson} isRequired={true}>
                <FormLabel>Sales Person Name</FormLabel>
                <Input placeholder="Sales Person" {...register('salesPerson')} />
                <FormErrorMessage>{errors?.salesPerson?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.deliveryDate} isRequired={true}>
                <FormLabel>Delivery Date</FormLabel>
                <Input placeholder="Delivery Date" type="date" {...register('deliveryDate')} />
                <FormErrorMessage>{errors?.deliveryDate?.message}</FormErrorMessage>
              </FormControl>
            </VStack>
          </GridItem>

          <GridItem
            w="100%"
            boxSizing="border-box"
            padding="15px"
            border="1px solid rgba(0,0,0,0.2)"
            rounded="md"
          >
            <Heading fontSize="xl" mb="15px">
              Client Information
            </Heading>
            <VStack>
              <FormControl isInvalid={errors?.clientRefNo}>
                <FormLabel>Client Reference</FormLabel>
                <Input placeholder="Client Reference" {...register('clientRefNo')} />
                <FormErrorMessage>{errors?.clientRefNo?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors?.clientContactPerson} isRequired={true}>
                <FormLabel>Client Contact Person</FormLabel>
                <Input placeholder="Client Contact Person" {...register('clientContactPerson')} />
                <FormErrorMessage>{errors?.clientContactPerson?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.clientAddress} isRequired={true}>
                <FormLabel>Delivery Address</FormLabel>
                <Input placeholder="Delivery Address" {...register('clientAddress')} />
                <FormErrorMessage>{errors?.clientAddress?.message}</FormErrorMessage>
              </FormControl>
              <FormControl>
                <FormLabel>Remarks</FormLabel>
                <Textarea placeholder="Remarks" {...register('remarks')} />
              </FormControl>
            </VStack>
          </GridItem>

          <GridItem
            colSpan={2}
            w="100%"
            boxSizing="border-box"
            padding="15px"
            border="1px solid rgba(0,0,0,0.2)"
            rounded="md"
          >
            <Heading fontSize="xl" mb="15px">
              Fabric Information
            </Heading>
            <Box w="100%" h="300px" overflow="auto">
              <Table w="100%" size="sm">
                <Thead position="sticky" top="0" zIndex="1000" bgColor="white">
                  <Tr>
                    <Th>Shade Group</Th>
                    <Th>Color</Th>
                    <Th>Shade %</Th>
                    <Th>Fabric Type</Th>
                    <Th>GSM</Th>
                    <Th>DIA</Th>
                    <Th>Process Description</Th>
                    <Th>Quantity (Kg)</Th>
                    <Th>Lab Dip</Th>
                    <Th>#</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {fields.map((field, index) => (
                    <Tr key={JSON.stringify(field)}>
                      <Td>
                        <HStack>
                          <FormControl
                            w="150px"
                            isRequired={true}
                            isInvalid={errors[`fabricInfo[${index}].shadeGroup`]}
                          >
                            <SelectField
                              options={allShadeGroups?.map((item) => ({
                                label: item.shadeGroup,
                                value: item.shadeGroup,
                                index,
                              }))}
                              control={control}
                              name={`fabricInfo.${index}.shadeGroup`}
                              handleChange={handleShadeGroupSelection}
                            />
                            <FormErrorMessage>
                              {errors[`fabricInfo[${index}].shadeGroup`]?.message}
                            </FormErrorMessage>
                          </FormControl>
                          <IconButton
                            size="sm"
                            icon={<AddIcon />}
                            onClick={() => setShowNewShadeGroupAlert(true)}
                          />
                        </HStack>
                      </Td>
                      <Td>
                        <HStack>
                          <FormControl
                            w="150px"
                            isRequired={true}
                            isInvalid={errors[`fabricInfo[${index}].color`]}
                          >
                            <SelectField
                              options={filterdColorList(index)?.map((item) => ({
                                label: item.shade,
                                value: item.shade,
                                index,
                              }))}
                              control={control}
                              name={`fabricInfo.${index}.color`}
                              handleChange={handleShadeSelection}
                            />
                            <FormErrorMessage>
                              {errors[`fabricInfo[${index}].color`]?.message}
                            </FormErrorMessage>
                          </FormControl>
                          <IconButton
                            size="sm"
                            icon={<AddIcon />}
                            onClick={() => setShowNewShadeAlert(true)}
                          />
                        </HStack>
                      </Td>
                      <Td>
                        <FormControl
                          isRequired={true}
                          isInvalid={errors[`fabricInfo[${index}].shade`]}
                        >
                          <HStack>
                            <Input
                              max={99}
                              type="number"
                              size="sm"
                              placeholder="Shade %"
                              {...register(`fabricInfo.${index}.shade`)}
                              w="110px"
                            />
                            <RenderInvalidFlag index={index} />
                          </HStack>
                          <FormErrorMessage>
                            {errors[`fabricInfo[${index}].shade`]?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </Td>
                      <Td>
                        <HStack>
                          <FormControl
                            w="150px"
                            isRequired={true}
                            isInvalid={errors[`fabricInfo[${index}].fabricType`]}
                          >
                            <SelectField
                              options={allFabricTypes?.map((item) => ({
                                label: item.fabricType,
                                value: item.fabricType,
                                index,
                              }))}
                              control={control}
                              name={`fabricInfo.${index}.fabricType`}
                              handleChange={handleFabricTypeSelection}
                            />
                            <FormErrorMessage>
                              {errors[`fabricInfo[${index}].fabricType`]?.message}
                            </FormErrorMessage>
                          </FormControl>
                          <IconButton
                            size="sm"
                            icon={<AddIcon />}
                            onClick={() => setShowNewFabricTypeAlert(true)}
                          />
                        </HStack>
                      </Td>
                      <Td>
                        <FormControl isInvalid={errors[`fabricInfo[${index}].gsm`]}>
                          <Input
                            type="number"
                            size="sm"
                            w="110px"
                            placeholder="GSM"
                            {...register(`fabricInfo.${index}.gsm`)}
                          />
                          <FormErrorMessage>
                            {errors[`fabricInfo[${index}].gsm`]?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </Td>
                      <Td>
                        <FormControl isInvalid={errors[`fabricInfo[${index}].dia`]}>
                          <Input
                            type="number"
                            size="sm"
                            w="110px"
                            placeholder="DIA"
                            {...register(`fabricInfo.${index}.dia`)}
                          />
                          <FormErrorMessage>
                            {errors[`fabricInfo[${index}].dia`]?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </Td>
                      <Td>
                        <FormControl
                          isRequired={true}
                          isInvalid={errors[`fabricInfo[${index}].processDescription`]}
                        >
                          <Textarea
                            placeholder="Process description for this fabric type"
                            cols={15}
                            w="210px"
                            {...register(`fabricInfo.${index}.processDescription`)}
                          />
                          <FormErrorMessage>
                            {errors[`fabricInfo[${index}].processDescription`]?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </Td>
                      <Td>
                        <FormControl
                          isRequired={true}
                          isInvalid={errors[`fabricInfo[${index}].quantity`]}
                        >
                          <Input
                            type="number"
                            size="sm"
                            placeholder="Quantity"
                            w="110px"
                            {...register(`fabricInfo.${index}.quantity`)}
                          />
                          <FormErrorMessage>
                            {errors[`fabricInfo[${index}].quantity`]?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </Td>
                      <Td>
                        <FormControl
                          isRequired={true}
                          isInvalid={errors[`fabricInfo[${index}].labDipRef`]}
                        >
                          <Input
                            type="text"
                            size="sm"
                            w="110px"
                            placeholder="Lab Dip"
                            {...register(`fabricInfo.${index}.labDipRef`)}
                          />
                          <FormErrorMessage>
                            {errors[`fabricInfo[${index}].labDipRef`]?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </Td>
                      <Td>
                        {index === 0 ? (
                          ''
                        ) : (
                          <IconButton
                            size="sm"
                            icon={<DeleteIcon />}
                            onClick={() => remove(index)}
                          />
                        )}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
            <HStack w="100%" justify="space-between" mt="20px">
              <Button
                onClick={() =>
                  append({
                    shadeGroup: '',
                    color: '',
                    shade: '',
                    gsm: '',
                    fabricType: '',
                    dia: '',
                    processDescription: '',
                  })
                }
                size="sm"
                colorScheme="facebook"
                rightIcon={<AddIcon />}
              >
                Add Another
              </Button>
              <HStack spacing={15}>
                <Text fontSize="sm" fontWeight="bold">
                  Total Item: {fields.length}
                </Text>
                <Text fontSize="sm" fontWeight="bold">
                  Total Quantity: {isNaN(getTotalQuantity()) ? 0 : getTotalQuantity()} KG
                </Text>
                <Button size="sm" colorScheme="facebook" onClick={handleDataError}>
                  Submit
                </Button>
              </HStack>
            </HStack>
          </GridItem>
        </Grid>
      </Box>
    </>
  );
};

export default AddNewOrder;
