import axios from './axios';
import { SIGNIN_URL, SIGNIN_OTP_VERIFY_URL } from './urls';

export const signIn = async (username, password) => {
  try {
    const response = await axios.post(SIGNIN_URL, { username, password, isFromApp: false });
    return { status: 200, data: response.data };
  } catch (error) {
    return {
      status: 500,
      error: error,
    };
  }
};

export const verifyOtp = async (userData, otpToken, otpType) => {
  try {
    const response = await axios.post(
      `${SIGNIN_OTP_VERIFY_URL}${userData.username}/${userData.phone}/${userData.sessionId}`,
      { otpToken, otpType },
    );
    return {
      status: 200,
      data: response.data,
    };
  } catch (error) {
    return {
      status: 500,
      error: error,
    };
  }
};

export const logoutUser = async (username) => {
  try {
    const response = await axios.post(`/auth/logout/${username}`);
    return response.status;
  } catch (error) {
    console.error(error);
    return null;
  }
};
