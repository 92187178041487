import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  HStack,
  IconButton,
  Input,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { deleteShade, updateShade } from '../../api/customer';
import toastFormat from '../../constatnts/toastFormat';
import { newShadeAddValidation, useYupValidationResolver } from '../../validators/form-validators';

const Alert = ({ title, show, close, onSubmit, children }) => {
  return (
    <AlertDialog isOpen={show} onClose={close}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>{children}</AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={close} size="sm">
              Cancel
            </Button>
            <Button colorScheme="facebook" onClick={onSubmit} size="sm" ml={3}>
              Confirm
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

const UpdateShade = ({ shades, updateData }) => {
  const [selectedShade, setSelectedShade] = useState();
  const resolver = useYupValidationResolver(newShadeAddValidation);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: isEditPanelOpen,
    onOpen: openEditpanel,
    onClose: closeEditPanel,
  } = useDisclosure();

  const {
    setValue,
    register,
    formState: { isDirty, errors },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    resolver,
  });

  const toast = useToast();

  const handleEdit = async (data) => {
    if (isDirty) {
      const shadeData = await updateShade({
        id: selectedShade?.id,
        shade: data.shade,
      });

      updateData(shadeData);
      closeEditPanel();
    } else {
      toast({ ...toastFormat, title: 'Nothing Chnaged', status: 'warning' });
    }
  };

  useEffect(() => {
    setValue('shade', selectedShade?.shade);
  }, [selectedShade]);

  const handleDelete = async (id) => {
    const shades = await deleteShade(id);
    updateData(shades);
  };
  return (
    <>
      <Alert
        show={isEditPanelOpen}
        close={closeEditPanel}
        onSubmit={handleSubmit(handleEdit)}
        title="Edit Shade"
      >
        <FormControl isRequired={true}>
          <Input
            placeholder="Please enter color"
            {...register('shade')}
            isInvalid={errors?.shade}
          />

          <FormErrorMessage>{errors?.shade?.message}</FormErrorMessage>
        </FormControl>
      </Alert>
      <Button size="sm" colorScheme="facebook" onClick={onOpen}>
        Color
      </Button>
      <Drawer size="xs" isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Color</DrawerHeader>
          <DrawerBody>
            <VStack>
              {shades?.map((item) => (
                <HStack key={item.id} w="100%" justify="space-between">
                  <Text>{item.shade}</Text>
                  <HStack>
                    <IconButton
                      icon={<EditIcon />}
                      size="sm"
                      onClick={() => {
                        setSelectedShade(item);
                        openEditpanel();
                      }}
                    />
                    <IconButton
                      icon={<DeleteIcon />}
                      size="sm"
                      onClick={async () => await handleDelete(item.id)}
                    />
                  </HStack>
                </HStack>
              ))}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default UpdateShade;
