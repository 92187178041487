import { Button } from '@chakra-ui/button';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/form-control';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Input } from '@chakra-ui/input';
import { Divider, Grid, Text } from '@chakra-ui/layout';
import { Box, Flex } from '@chakra-ui/layout';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu';
import { useToast } from '@chakra-ui/toast';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { fetchBatchByCustomerId } from '../../api/batch';
import { fetchInQueueFc } from '../../api/fcInfo';
import { Badge, Heading, Tooltip } from '@chakra-ui/react';
import { mapStatusToColor } from '../../utilities/mapStatusToColor';
import combineDateTime from '../../utilities/combineDateTime';
import toastFormat from '../../constatnts/toastFormat';
import timeFormat from '../../utilities/timeFormat';
import SelectField from '../common/SelectField';

const BatchDetailForm = ({
  next,
  errors,
  register,
  watchAllField,
  setValue,
  handleSubmit,
  control,
  setError,
}) => {
  const [customerInfos, setCustomerInfos] = useState({
    selected: null,
    customers: [],
  });

  const toast = useToast();

  useEffect(() => {
    (async () => {
      try {
        const response = await fetchInQueueFc();
        if (response.data) {
          setCustomerInfos({ ...customerInfos, customers: response.data });
        }
      } catch (error) {
        toast({
          title: 'failed to fetch order info',
          status: 'error',
          ...toastFormat,
        });
      }
    })();
  }, []);

  const [otherBatches, setOtherBatches] = useState([]);
  const [batchLotNumbers, setBatchLotNumbers] = useState([]);

  useEffect(() => {
    (async () => {
      if (otherBatches) {
        setBatchLotNumbers(otherBatches.map((item) => item.lotNumber));
      }
    })();
  }, [watchAllField.reRunBatchRef]);

  useEffect(() => {
    (async () => {
      if (customerInfos.selected) {
        const batches = await fetchBatchByCustomerId(customerInfos.selected.id);

        if (batches) {
          setOtherBatches(batches);
        }
      }
    })();
  }, [customerInfos.selected]);
  const submit = () => {
    const otherLotNumbers = otherBatches?.map((item) => item.lotNumber.split('-')[1]);

    if (otherLotNumbers.includes(watchAllField.lotNumber)) {
      setError('lotNumber', { type: 'required', messgae: 'Lot Number already in use' });
    } else {
      next(1);
    }
  };

  const customerSelectValueFinder = (options, field) => {
    const customer = options.find((item) => item?.customer?.fcNumber === field?.value?.fcNumber);
    return customer;
  };

  const handleCustomerSelection = (option) => {
    setValue('customer', option.customer, { shouldDirty: true });
    setCustomerInfos({ ...customerInfos, selected: option.customer });
  };

  const handleBatchTypeSelection = (option) => {
    setValue('batchType', option.value);
  };

  return (
    <Grid w="calc(100% - 80px)" gridTemplateColumns="repeat(2, 1fr);">
      <Box w="550px" my={5}>
        <FormLabel>Order Number</FormLabel>
        <Flex>
          <FormControl w="full">
            <SelectField
              control={control}
              options={customerInfos?.customers?.map((customer) => ({
                label: `${customer.fcNumber} - ${customer.partyName}`,
                value: customer.fcNumber,
                customer,
              }))}
              name="customer"
              selectFieldValueFinder={customerSelectValueFinder}
              handleChange={handleCustomerSelection}
            />
          </FormControl>
        </Flex>
        {watchAllField.customer !== null && (
          <>
            <Grid w="100%" gridTemplateColumns="repeat(5, 1fr)" mt="30px">
              <Box>
                <Text fontSize="14px" fontWeight="bold" textColor="gray.600">
                  FC NUMBER
                </Text>
                <Text fontSize="16px" fontWeight="light" textColor="gray.700" mt="9px">
                  {watchAllField.customer.fcNumber}
                </Text>
              </Box>
              <Box>
                <Text fontSize="14px" fontWeight="bold" textColor="gray.600">
                  PARTY
                </Text>
                <Tooltip label={watchAllField.customer.partyName}>
                  <Text fontSize="14px" fontWeight="light" textColor="gray.700" mt="9px">
                    {watchAllField.customer.partyName.substring(0, 12)}...
                  </Text>
                </Tooltip>
              </Box>
              <Box>
                <Text fontSize="14px" fontWeight="bold" textColor="gray.600">
                  Client Ref
                </Text>
                <Text fontSize="16px" fontWeight="light" textColor="gray.700" mt="9px">
                  {watchAllField.customer.clientRefNo}
                </Text>
              </Box>
              <Box>
                <Text fontSize="14px" fontWeight="bold" textColor="gray.600">
                  LAB DIP
                </Text>
                <Text fontSize="16px" fontWeight="light" textColor="gray.700" mt="9px">
                  {watchAllField.customer.labDipRef}
                </Text>
              </Box>
              <Box>
                <Text fontSize="14px" fontWeight="bold" textColor="gray.600">
                  Delivery date
                </Text>
                <Text fontSize="16px" fontWeight="light" textColor="gray.700" mt="9px">
                  {timeFormat(watchAllField.customer.deliveryDate)}
                </Text>
              </Box>
            </Grid>
            <Divider my="20px" />
            <Flex mb="10px">
              <FormControl isInvalid={errors?.batchType} isRequired={true}>
                <FormLabel>Batch Type</FormLabel>
                <SelectField
                  control={control}
                  options={[
                    { label: 'New Shade', value: 'New Shade' },
                    { label: 'Batch To Batch', value: 'Batch To Batch' },
                  ]}
                  name="batchType"
                  handleChange={handleBatchTypeSelection}
                />
                <FormErrorMessage>{errors?.batchType?.message}</FormErrorMessage>
              </FormControl>
            </Flex>
            <Flex>
              <FormControl isRequired={true} isInvalid={!!errors?.lotNumber}>
                <FormLabel>Lot Number</FormLabel>
                <Input {...register('lotNumber')} required type="text" maxLength={6} />
                <FormErrorMessage>{errors?.lotNumber?.message}</FormErrorMessage>
              </FormControl>
              <FormControl ml="15px" isRequired={true} isInvalid={!!errors?.processType}>
                <FormLabel>Process Type</FormLabel>
                <Menu>
                  <MenuButton w="100%">
                    <Flex
                      bgColor="blackAlpha.50"
                      rounded="md"
                      padding="8px 15px"
                      w="100%"
                      justify="space-between"
                      align="center">
                      <Text>
                        {watchAllField.processType.length > 0
                          ? watchAllField.processType
                          : 'Process Type'}
                      </Text>
                      <ChevronDownIcon />
                    </Flex>
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      onClick={() => setValue('processType', 'BULK', { shouldDirty: true })}>
                      BULK
                    </MenuItem>
                    <MenuItem
                      onClick={() => setValue('processType', 'SAMPLE', { shouldDirty: true })}>
                      SAMPLE
                    </MenuItem>
                    <MenuItem
                      onClick={() => setValue('processType', 'RE PROCESS', { shouldDirty: true })}>
                      RE PROCESS
                    </MenuItem>
                  </MenuList>
                </Menu>
                <FormErrorMessage>{errors?.processType?.message}</FormErrorMessage>
              </FormControl>
            </Flex>
            <Flex mt="15px" mb="20px">
              <FormControl isInvalid={!!errors?.finishingType}>
                <FormLabel>Finishing Type</FormLabel>
                <Menu>
                  <MenuButton w="100%">
                    <Flex
                      bgColor="blackAlpha.50"
                      rounded="md"
                      padding="8px 15px"
                      w="100%"
                      justify="space-between"
                      align="center">
                      <Text>
                        {watchAllField.finishingType.length > 0
                          ? watchAllField.finishingType
                          : 'Finishing Type'}
                      </Text>
                      <ChevronDownIcon />
                    </Flex>
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      onClick={() => setValue('finishingType', 'TUBE', { shouldDirty: true })}>
                      TUBE
                    </MenuItem>
                    <MenuItem
                      onClick={() => setValue('finishingType', 'OPEN', { shouldDirty: true })}>
                      OPEN
                    </MenuItem>
                  </MenuList>
                </Menu>
                <FormErrorMessage>{errors?.finishingType?.message}</FormErrorMessage>
              </FormControl>
              <FormControl ml="15px" isRequired={true} isInvalid={!!errors?.processType}>
                <FormLabel>Priority</FormLabel>
                <Menu>
                  <MenuButton w="100%">
                    <Flex
                      bgColor="blackAlpha.50"
                      rounded="md"
                      padding="8px 15px"
                      w="100%"
                      justify="space-between"
                      align="center">
                      <Text>
                        {watchAllField.priority.length > 0 ? watchAllField.priority : 'Priority'}
                      </Text>
                      <ChevronDownIcon />
                    </Flex>
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={() => setValue('priority', 'HIGH', { shouldDirty: true })}>
                      HIGH
                    </MenuItem>
                    <MenuItem onClick={() => setValue('priority', 'MEDIUM', { shouldDirty: true })}>
                      MEDIUM
                    </MenuItem>
                    <MenuItem onClick={() => setValue('priority', 'LOW', { shouldDirty: true })}>
                      LOW
                    </MenuItem>
                  </MenuList>
                </Menu>
                <FormErrorMessage>{errors?.processType?.message}</FormErrorMessage>
              </FormControl>
            </Flex>
            {watchAllField.processType === 'RE PROCESS' && (
              <Flex mt="15px" mb="20px">
                <FormControl isRequired={true} isInvalid={!!errors?.reRunBatchRef}>
                  <FormLabel>Batch Reference - lot number</FormLabel>
                  <Input
                    {...register('reRunBatchRef')}
                    list="lotnumbers"
                    placeholder="Lot number"
                    disabled={otherBatches?.length <= 0}
                  />
                  <datalist id="lotnumbers">
                    {batchLotNumbers.map((lotNumber) => (
                      <option value={lotNumber} key={lotNumber} />
                    ))}
                  </datalist>
                  <FormErrorMessage>{errors?.reRunBatchRef?.message}</FormErrorMessage>
                </FormControl>
              </Flex>
            )}
            <Flex>
              <FormControl isRequired={true} isInvalid={!!errors?.loadDate}>
                <FormLabel>Loading Date</FormLabel>
                <Input
                  {...register('loadDate')}
                  type="date"
                  required
                  value={dayjs(watchAllField?.loadDate).format('YYYY-MM-DD')}
                />
                <FormErrorMessage>{!!errors?.loadDate?.message}</FormErrorMessage>
              </FormControl>
              <FormControl ml="15px" isRequired={true} isInvalid={!!errors?.loadTime}>
                <FormLabel>Loading Time</FormLabel>
                <Input {...register('loadTime')} type="time" required />
                <FormErrorMessage>{!!errors?.loadTime?.message}</FormErrorMessage>
              </FormControl>
            </Flex>
            <Flex mt="15px">
              <FormControl isRequired={true} isInvalid={!!errors?.deliveryDate}>
                <FormLabel>Delivery Date</FormLabel>
                <Input
                  {...register('deliveryDate')}
                  type="date"
                  required
                  value={dayjs(watchAllField?.deliveryDate).format('YYYY-MM-DD')}
                />
                <FormErrorMessage>{!!errors?.deliveryDate?.message}</FormErrorMessage>
              </FormControl>
            </Flex>
            <Flex align="flex-end" justify="flex-end">
              <Button mt="15px" onClick={handleSubmit(submit)} colorScheme="facebook" size="sm">
                Next
              </Button>
            </Flex>
          </>
        )}
      </Box>
      {otherBatches.length > 0 && (
        <Box mt="15px">
          <Heading fontSize="2xl" mb="25px">
            Batches for FC Number {customerInfos.selected.fcNumber}
          </Heading>
          <Grid gridTemplateColumns="repeat(2, 1fr)" gap="10px" w="550px">
            {otherBatches.map((batch) => (
              <Box
                w="100%"
                key={batch.lotNumber}
                border="1px solid rgba(0,0,0,0.2)"
                borderRadius={10}
                boxSizing="border-box"
                padding={15}>
                <Badge colorScheme="gray">{batch.lotNumber}</Badge>
                <Badge colorScheme={mapStatusToColor(batch.status)} ml="5px">
                  {batch.status}
                </Badge>
                <Badge colorScheme={mapStatusToColor(batch.priority)} ml="5px">
                  {batch.priority}
                </Badge>
                <Text fontSize={14} color="gray.700" fontWeight="bold" mt={3}>
                  Process Type: {batch.processType}
                </Text>
                <Text fontSize={14} color="gray.700" fontWeight="bold" mt={3}>
                  Total Fabric Weight: {batch.totalFabricWeight} KG
                </Text>

                <Text fontSize={14} color="gray.700" fontWeight="bold" mt={3}>
                  Machine: {batch.machineInfo.name}
                </Text>
                <Text mt={2} fontSize={12}>
                  Loading Time {combineDateTime(batch.loadDate, batch.loadTime)}
                </Text>
              </Box>
            ))}
          </Grid>
        </Box>
      )}
    </Grid>
  );
};

export default BatchDetailForm;
