export default [
  {
    label: 'Start Time',
    value: 'startTime',
  },
  {
    label: 'Latest Dyeing Task Time',
    value: 'dyeingTime',
  },
  {
    label: 'Latest Finishing Task Time',
    value: 'finishingTime',
  },
  {
    label: 'Machine Name',
    value: 'machineName',
  },
];
