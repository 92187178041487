export const inputTypes = [
  {
    inputType: 'YES/NO',
    inputKeyName: 'yesNoInput',
  },
  {
    inputType: 'IMAGE INPUT',
    inputKeyName: 'image',
  },
  {
    inputType: 'TEXTAREA',
    inputKeyName: 'textAreaInput',
  },
  {
    inputType: 'NUMBER INPUT',
    inputKeyName: 'numberInput',
  },
  {
    inputType: 'INPUT',
    inputKeyName: 'textInput',
  },
];
