import axios from './axios';

export const getAllUser = async (query) => {
  try {
    if (!query?.isPrint) {
      const response = await axios.get('/user', { params: query ? query : undefined });

      return {
        users: response.data.users,
        totalEntries: response.data.totalEntries,
      };
    } else {
      console.log('log');
      const response = await axios.get('/user', { params: query ? query : undefined });

      return response.data.users;
    }
  } catch (error) {
    return error;
  }
};

export const createNewUser = async (data) => {
  try {
    const response = await axios.post('/user', {
      username: data.username,
      password: data.password,
      email: data.email,
      roles: data.roles,
      phone: data.phone,
      name: data.name,
    });
    const { created, sessionId } = response.data;
    return { created, sessionId, status: 200 };
  } catch (error) {
    return error;
  }
};

export const verifyOtp = async (adminUsername, newUserUsername, otp, phone, sessionId) => {
  try {
    const response = await axios.post(
      `user/verify-user-creation/${adminUsername}/${phone}/${sessionId}`,
      { otpToken: otp, otpType: 'create-user-verify', username: newUserUsername },
    );
    if (response.data.status === 200) {
      const users = await axios.get('/user');
      return {
        users: users.data.users,
        entries: users.data.totalEntries,
      };
    }
  } catch (error) {
    return error;
  }
};

export const markuserAsBanned = async (uid) => {
  try {
    const resposne = await axios.delete(`/user/${uid}`);
    return resposne.data.status;
  } catch (error) {
    return null;
  }
};

export const updateUser = async ({ uid, data, username, sessionId, phone, otpToken }) => {
  try {
    const response = await axios.patch(`/user/update/${uid}/${username}/${phone}/${sessionId}`, {
      ...data,
      otpType: 'update-user-verify',
      otpToken,
    });

    return response.data.status;
  } catch (error) {
    return null;
  }
};

export const fetchAllUserForTemplate = async () => {
  try {
    const { data } = await axios.get('/user/template');
    return { data };
  } catch (error) {
    return error;
  }
};

export const getOtpForUserUpdate = async () => {
  try {
    const { data } = await axios.post('/user/get-otp/');
    return data.privateKey;
  } catch (error) {
    return null;
  }
};
