/* eslint-disable no-unused-vars */
import React from 'react';
import { Box, Flex, Grid, Heading, Link, Text, Badge, GridItem } from '@chakra-ui/layout';
import {
  Tag,
  Image,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  HStack,
  VStack,
  Tooltip,
  IconButton,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  useToast,
  Textarea,
} from '@chakra-ui/react';
import { mapStatusToColor } from '../../utilities/mapStatusToColor';
import DONEIMG from '../../assets/images/task-done.svg';
import DUEIMG from '../../assets/images/task-due.svg';
import TASKRUNIMG from '../../assets/images/task-running.svg';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from '@chakra-ui/accordion';
import { Button } from '@chakra-ui/button';
import { BATCH_SET_RECIPE } from '../../constatnts/routerUrls';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';
import { useDisclosure } from '@chakra-ui/hooks';
import { Modal, ModalContent, ModalOverlay, ModalCloseButton, ModalBody } from '@chakra-ui/modal';
import { RiBattery2ChargeFill, RiFocusFill } from 'react-icons/ri';
import ReAssignUserDrawer from './ReAssignUserDrawer';
import { DownloadIcon, EditIcon, SettingsIcon } from '@chakra-ui/icons';
import NoRecipeImage from '../../assets/images/no-chem.png';
import RecipeSheet from './recipe/RecipeSheet';
import { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import msToTime from '../../utilities/msToTime';
import { useState } from 'react';
import { useEffect } from 'react';
import { getAllUser } from '../../api/user';
import { addTaskRemark, skipTask } from '../../api/batch';
import toastFormat from '../../constatnts/toastFormat';

const RenderIcon = ({ status }) => {
  switch (status) {
    case 'DUE':
      return <Image src={DUEIMG} w="30px" />;
    case 'DONE':
      return <Image src={DONEIMG} w="30px" />;
    default:
      return <Image src={TASKRUNIMG} w="30px" />;
  }
};

const getCompltedTaskCount = (tasks) => {
  let completed = 0;
  tasks.forEach((task) => {
    if (task.status === 'DONE') {
      completed += 1;
    }
  });

  return completed;
};

const RenderFormInputs = ({ field, index }) => {
  switch (field.type) {
    case 'IMAGE INPUT':
      return (
        <Box position="relative" my="5px">
          <Text fontWeight="bold" fontSize="sm" textColor="gray.700">
            {index + 1}. Reference Image
          </Text>
          <Image src={field[field.inputKeyName]} rounded="md" h="250px" />
          <Link
            _hover={{ textDecoration: 'none', bgColor: 'blue.600' }}
            textAlign="center"
            padding="5px 10px"
            rounded="md"
            textColor="white"
            fontWeight="bold"
            href={field[field.inputKeyName]}
            bgColor="blue.500"
            position="absolute"
            top="30px"
            left="10px">
            <DownloadIcon />
          </Link>
        </Box>
      );

    case 'YES/NO':
      return (
        <Box my="5px">
          <Text fontWeight="bold" fontSize="sm" textColor="gray.700">
            {index + 1}. {field.label}
          </Text>
          <Badge fontWeight="light" textColor="gray.600" fontSize="xs" mt="3px">
            {field[field.inputKeyName] ? 'YES' : 'NO'}
          </Badge>
        </Box>
      );
    default:
      return (
        <Box>
          <Text fontWeight="bold" fontSize="sm" textColor="gray.700">
            {index + 1}. {field.label}
          </Text>
          <Badge fontWeight="light" textColor="gray.600" fontSize="xs" mt="3px">
            {field[field.inputKeyName]}
          </Badge>
        </Box>
      );
  }
};

const TimeLineItem = ({ task, arrLength, index, batch, tasks, refresh, allTasks }) => {
  const [operator, setOperator] = useState();
  const [supervisor, setSupervisor] = useState();
  const [po, setPo] = useState();

  const [whySkipped, setWhySkipped] = useState();
  const [showTaskSkipModal, setShowTaskSkipModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [taskRemark, setTaskRemark] = useState(task?.taskRemark);

  const toast = useToast();

  const formatStandardTime = (time) => {
    if (time) {
      if (time < 1) {
        return Math.ceil(time * 60) + ' minutes';
      } else {
        const minutes = time - Math.floor(time);
        if (minutes > 0) {
          return (time - minutes).toString() + ' hour ' + Math.ceil(minutes * 60) + ' minutes ';
        } else {
          return time.toString() + ' hour';
        }
      }
    } else {
      return 'Not added';
    }
  };

  const formatEstDuration = () => {
    const endTime = new Date(task.startTime);

    const hour = task.standardTimeHour ? task.standardTimeHour : 0;
    const min = task.standardTimeMinute ? task.standardTimeMinute : 0;

    endTime.setHours(endTime.getHours() + hour);
    endTime.setMinutes(endTime.getMinutes() + min);

    return dayjs(endTime).format('DD/MMM | hh:mm A');
  };

  const formatCompleteTime = () => dayjs(new Date(task.completeTime)).format('DD/MMM - hh:mm A');

  const formatStartTime = () => dayjs(new Date(task.startTime)).format('DD/MMM - hh:mm A');

  const hasDelayed = () => {
    const completeTime = new Date(task.completeTime);

    const shouldHaveCompleted = new Date(task.startTime);

    const hour = task.standardTimeHour ? task.standardTimeHour : 0;
    const min = task.standardTimeMinute ? task.standardTimeMinute : 0;

    shouldHaveCompleted.setHours(shouldHaveCompleted.getHours() + hour);
    shouldHaveCompleted.setMinutes(shouldHaveCompleted.getMinutes() + min);

    if (shouldHaveCompleted < completeTime) {
      return {
        isDelayed: true,
        duration: completeTime - shouldHaveCompleted,
      };
    } else {
      return {
        isDelayed: false,
        duration: shouldHaveCompleted - completeTime,
      };
    }
  };

  const standardTime = task.standardTimeHour
    ? task.standardTimeHour
    : 0 + task.standardTimeMinute
    ? task.standardTimeMinute / 60
    : 0;

  const taskIsDelayed = () => {
    const currentTime = new Date();

    const shouldComplete = new Date(task.startTime);
    const hour = task.standardTimeHour ? task.standardTimeHour : 0;
    const min = task.standardTimeMinute ? task.standardTimeMinute : 0;

    shouldComplete.setHours(shouldComplete.getHours() + hour);
    shouldComplete.setMinutes(shouldComplete.getMinutes() + min);

    if (shouldComplete < currentTime) {
      return true;
    } else {
      return false;
    }
  };

  const { isOpen, onToggle } = useDisclosure();

  useEffect(() => {
    (async () => {
      if (task.status === 'DONE') {
        if (task.operator) {
          const userData = await getAllUser({ searchKey: task.operator });
          setOperator(userData.users[0]);
        }

        if (task.supervisorUsername) {
          const supervisorData = await getAllUser({
            searchKey: task.supervisorUsername,
          });
          setSupervisor(supervisorData.users[0]);
        }

        if (task.po) {
          const po = await getAllUser({
            searchKey: task.po,
          });
          setPo(po.users[0]);
        }
      }
    })();
  }, [task]);

  const calcDuration = () => {
    const startTime = new Date(task.startTime);
    const completeTime = new Date(task.completeTime);

    return msToTime(completeTime - startTime);
  };

  const handleTaskSkip = async (skippingTask) => {
    const indexOfSkippingTask = tasks.indexOf(skippingTask);
    let updateNextTaskRefOn = null;
    let nextTaskRef = null;

    let previousTaskFinderStartIndex = indexOfSkippingTask - 1;
    let nextTaskFinderStartIndex = indexOfSkippingTask + 1;

    if (whySkipped?.length > 0) {
      while (previousTaskFinderStartIndex >= 0) {
        const previousTask = tasks[previousTaskFinderStartIndex];
        if (
          (previousTask.status === 'DUE' || previousTask.status === 'RUNNING') &&
          !previousTask.isSkipped
        ) {
          updateNextTaskRefOn = previousTask;
          break;
        }
        previousTaskFinderStartIndex -= 1;
      }

      while (nextTaskFinderStartIndex <= tasks.length) {
        const nextTask = tasks[nextTaskFinderStartIndex];

        if (nextTask) {
          if (!nextTask.isSkipped) {
            nextTaskRef = nextTask;
            break;
          }
        } else {
          const currentNextTask = tasks.find((_, index) => index === indexOfSkippingTask);
          nextTaskRef = allTasks.find((item) => item.uid === currentNextTask?.nextTask.taskUid);
          break;
        }

        nextTaskFinderStartIndex += 1;
      }

      const updatedTask = {
        ...updateNextTaskRefOn,
        nextTask: {
          taskUid: nextTaskRef.uid,
          phase: nextTaskRef.taskType,
        },
      };
      const response = await skipTask({
        batchId: batch.id,
        updatedTask,
        skippedTaskUid: skippingTask.uid,
        whySkipped,
      });

      if (response === 200) {
        toast({ title: `${skippingTask.taskName} skipped`, status: 'success', ...toastFormat });
        await refresh();
        setShowTaskSkipModal(false);
        setWhySkipped('');
      } else {
        toast({
          title: `Failed to skip ${skippingTask.taskName}`,
          status: 'error',
          ...toastFormat,
        });
        setShowTaskSkipModal(false);
        setWhySkipped('');
      }
    } else {
      toast({
        title: 'Please provide a reason to skip',
        status: 'error',
        ...toastFormat,
      });
    }
  };

  const TaskSkipButton = () => {
    if (
      task.status === 'DUE' &&
      task.taskType === 'DYING' &&
      task.taskName !== 'Fabric Unloading' &&
      task.taskName !== 'Fabric Loading' &&
      task.taskName !== 'Heat Set' &&
      !task.isSkipped
    ) {
      return (
        <Button
          size="xs"
          rounded="sm"
          colorScheme="red"
          mt="5px"
          w="100px"
          onClick={() => setShowTaskSkipModal(true)}>
          Skip
        </Button>
      );
    } else {
      return null;
    }
  };

  const handleModalClose = async () => {
    if (task.taskRemark !== taskRemark) {
      await addTaskRemark({
        lotNumber: batch.lotNumber,
        taskUid: task.uid,
        taskType: task.taskType,
        taskRemark,
      });
    }
    onToggle();
  };

  const calcStandardDuration = () => {
    const hour = task.standardTimeHour ? task.standardTimeHour : 0;
    const minute = task.standardTimeMinute ? task.standardTimeMinute : 0;

    const time = hour + minute / 60;

    return formatStandardTime(time);
  };

  const isTooEarly = () => {
    const start = new Date(task.startTime);
    const complete = new Date(task.completeTime);
    const duration = complete - start;

    const hour = task.standardTimeHour ? task.standardTimeHour : 0;
    const minute = task.standardTimeMinute ? task.standardTimeMinute / 60 : 0;

    const stdTime = (hour + minute) * 3600000;

    if (duration > stdTime * 0.2) {
      return false;
    } else {
      return true;
    }
  };

  switch (task.status) {
    case 'DONE':
      return (
        <>
          <Modal
            isOpen={isOpen}
            onClose={handleModalClose}
            key={task.uid + task.taskName}
            size="xl">
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton />

              <Box w="100%" boxSizing="border-box" p="15px">
                <Heading fontSize="xl" mb="15px">
                  {task.taskName}{' '}
                  <Tag colorScheme={mapStatusToColor(task.status)}>{task.status}</Tag>
                  {isTooEarly() && <Tag colorScheme="orange">Too Early</Tag>}
                </Heading>
                <Grid gridTemplateColumns="repeat(2, 1fr)">
                  <Box mb="5px">
                    <Text fontSize="sm" fontWeight="bold">
                      Task Details
                    </Text>
                    <Text fontSize="sm">Date: {dayjs(task.startTime).format('DD, MMM')}</Text>
                    <Text fontSize="sm">Started Time: {formatStartTime()}</Text>
                    <Text fontSize="sm">Completed Time: {formatCompleteTime()}</Text>
                    <Text fontSize="sm">Standard Time: {calcStandardDuration()}</Text>
                    <Text fontSize="sm">Duration: {calcDuration()}</Text>

                    {hasDelayed().isDelayed && (
                      <Text textColor="red.600" fontSize="sm" fontWeight="medium" my="5px">
                        Delayed for {msToTime(hasDelayed().duration)}
                      </Text>
                    )}
                    {task.hasDelayed && <Text fontSize="sm">( {task.reasonForDelay})</Text>}
                  </Box>

                  {po && (
                    <Box mb="5px">
                      <Text fontSize="sm" fontWeight="bold">
                        PO
                      </Text>
                      <Text fontSize="sm">Name: {po.name}</Text>
                      <Text fontSize="sm">Username: {po.username}</Text>
                      <Text fontSize="sm">Phone: {po.phone}</Text>
                    </Box>
                  )}
                  {operator && (
                    <Box mb="5px">
                      <Text fontSize="sm" fontWeight="bold">
                        Operator
                      </Text>
                      <Text fontSize="sm">Name: {operator?.name}</Text>
                      <Text fontSize="sm">Username: {operator?.username}</Text>
                      <Text fontSize="sm">Phone: {operator?.phone}</Text>
                    </Box>
                  )}
                  {supervisor && (
                    <Box mb="5px">
                      <Text fontSize="sm" fontWeight="bold">
                        Supervisor
                      </Text>
                      <Text fontSize="sm">Name: {supervisor?.name}</Text>
                      <Text fontSize="sm">Username: {supervisor?.username}</Text>
                      <Text fontSize="sm">Phone: {supervisor?.phone}</Text>
                    </Box>
                  )}
                  {task.isRecipeRequired && (
                    <Box mb="5px">
                      <Text fontSize="sm" fontWeight="bold">
                        Chemicals Used
                      </Text>
                      {task?.recipePrepared?.image && (
                        <Image src={task?.recipePrepared?.image} h="250px" />
                      )}

                      <Text fontSize="sm">
                        Prepared At:{' '}
                        {dayjs(task.recipePrepared?.recipePreparedAt).format('DD/MMM - hh:mm A')}
                      </Text>
                      {task.recipes?.map((chemical, index) => (
                        <Text key={JSON.stringify(chemical)} fontSize="sm">
                          {index + 1}. {chemical.chemical}{' '}
                          <span style={{ fontWeight: 'bold' }}>
                            {chemical.quantity} x {chemical.total}{' '}
                            {chemical.unit === 'gl' ? 'G/L' : '%'}
                          </span>
                        </Text>
                      ))}
                    </Box>
                  )}
                </Grid>

                <Text fontSize="sm" fontWeight="bold">
                  Task Information
                </Text>
                {task.formFields.map((field, index) => (
                  <RenderFormInputs key={JSON.stringify(field)} field={field} index={index} />
                ))}
                <FormControl>
                  <FormLabel>Task Remark</FormLabel>
                  <Textarea
                    value={taskRemark}
                    onChange={(e) => setTaskRemark(e.target.value)}
                    placeholder="Do you want to add additional information?"
                  />
                  {task.taskRemark && (
                    <FormLabel color="red.400" fontSize="14px" fontWeight="bold" mt="5px">
                      Remark form {task.remarkSetBy}
                    </FormLabel>
                  )}
                </FormControl>
              </Box>
            </ModalContent>
          </Modal>
          <Accordion w="100%" allowToggle key={task.uid}>
            <AccordionItem w="100%" border="none" isFocusable={false}>
              <AccordionButton
                w="100%"
                padding="10px 0"
                _hover={{ backgroundColor: '#fff' }}
                _focus={{ outline: 'none', border: 'none' }}>
                <Flex w="100%" alignItems="flex-start" minH="78px" justifyContent="start" mb="10px">
                  <Flex position="relative" direction="column" h="100%">
                    <RenderIcon status={task.status} />
                    <Box
                      position="absolute"
                      left="13px"
                      top="40px"
                      w="3px"
                      bg="gray.600"
                      h={index === arrLength - 1 ? '100%' : '40px'}></Box>
                  </Flex>
                  <Flex
                    direction="column"
                    align="flex-start"
                    justifyContent="flex-start"
                    h="100%"
                    ml="15px"
                    w="100%">
                    <Flex w="100%" justifyContent="space-between">
                      <Text fontSize="lg" fontWeight="bold" mb="5px">
                        {task.taskName}{' '}
                        <Tag colorScheme={mapStatusToColor(task.status)} w="min-content" ml="5px">
                          {task.status}
                        </Tag>
                        {isTooEarly() && (
                          <Tag ml="5px" colorScheme="orange">
                            Too Early
                          </Tag>
                        )}
                        {task.isSkipped && (
                          <Tooltip label={task.whySkipped}>
                            <Tag colorScheme="gray" w="min-content" ml="5px">
                              SKIPPED
                            </Tag>
                          </Tooltip>
                        )}
                      </Text>
                      <AccordionIcon />
                    </Flex>
                    <Text textColor="gray.600" fontSize="sm" fontWeight="light">
                      Started At {formatStartTime()}
                    </Text>
                    <Text textColor="gray.600" fontSize="sm" fontWeight="light">
                      Completed At {formatCompleteTime()}
                    </Text>
                    <Text textColor="gray.600" fontSize="sm" fontWeight="light">
                      Duration {calcDuration()}
                    </Text>

                    {hasDelayed().isDelayed && (
                      <Text textColor="red.600" fontSize="sm" fontWeight="medium" my="5px">
                        Delayed for {msToTime(hasDelayed().duration)}
                      </Text>
                    )}
                  </Flex>
                </Flex>
              </AccordionButton>
              <AccordionPanel w="100%" mb="10px" mt="-25px" ml="26px">
                <Grid w="100%" gridTemplateColumns="repeat(2, 1fr)" gap="15px 20px">
                  {task?.hasDelayed && (
                    <GridItem>
                      <VStack align="flex-start">
                        <Badge colorScheme="red" w="max-content" h="max-content">
                          Delayed
                        </Badge>
                        <Text color="gray.500" fontSize="sm">
                          {task?.reasonForDelay}
                        </Text>
                      </VStack>
                    </GridItem>
                  )}
                  <GridItem>
                    <Button size="sm" colorScheme="facebook" onClick={onToggle}>
                      View Details
                    </Button>
                  </GridItem>
                </Grid>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </>
      );
    case 'RUNNING':
      return (
        <Flex alignItems="flex-start" minH="78px" justifyContent="start" mb="10px" key={task.uid}>
          <Flex position="relative" direction="column" h="100%">
            <RenderIcon status={task.status} />
            <Box
              position="absolute"
              left="13px"
              top="40px"
              w="3px"
              bg="gray.600"
              h={index === arrLength - 1 ? '100%' : '40px'}></Box>
          </Flex>
          <Flex direction="column" justifyContent="flex-start" h="100%" ml="15px">
            <Text fontSize="lg" fontWeight="bold" mb="5px">
              {task.taskName}
              <Tag colorScheme={mapStatusToColor(task.status)} w="min-content" ml="5px">
                {task.status}
              </Tag>
            </Text>
            <Text textColor="gray.600" fontSize="sm" fontWeight="light">
              Est Completion {formatEstDuration()}{' '}
              {taskIsDelayed() && <Badge colorScheme="red">Delayed</Badge>}
            </Text>
          </Flex>
        </Flex>
      );
    default:
      return (
        <>
          <Modal isOpen={showTaskSkipModal} onClose={() => setShowTaskSkipModal(false)}>
            <ModalOverlay />
            <ModalContent>
              <ModalBody>
                <ModalCloseButton />
                <VStack align="start">
                  <Text fontSize="lg" fontWeight="medium">
                    Are you sure you want to skip {task.taskName}?
                  </Text>
                  <FormControl isInvalid={whySkipped?.length <= 0}>
                    <FormLabel>Why do you want to skip?</FormLabel>
                    <Input value={whySkipped} onChange={(e) => setWhySkipped(e.target.value)} />
                    <FormErrorMessage>Please provide a reason for skipping</FormErrorMessage>
                  </FormControl>
                  <Button
                    isLoading={isLoading}
                    onClick={async () => {
                      setIsLoading(true);

                      await handleTaskSkip(task);

                      setIsLoading(false);
                    }}>
                    Skip
                  </Button>
                </VStack>
              </ModalBody>
            </ModalContent>
          </Modal>
          <Flex alignItems="flex-start" minH="78px" justifyContent="start" mb="10px" key={task.uid}>
            <Flex position="relative" direction="column" h="100%">
              <RenderIcon status={task.status} />
              <Box
                position="absolute"
                left="13px"
                top="40px"
                w="3px"
                bg="gray.600"
                h={index === arrLength - 1 ? '100%' : '40px'}></Box>
            </Flex>
            <Flex direction="column" justifyContent="flex-start" h="100%" ml="15px">
              <Text fontSize="lg" fontWeight="bold" mb="5px">
                {task.taskName}{' '}
                <Tag colorScheme={mapStatusToColor(task.status)} w="min-content" ml="5px">
                  {task.status}
                </Tag>
                {task.isHalted && (
                  <Tooltip label={task.reasonForHalt}>
                    <Tag colorScheme="red" w="min-content" ml="5px">
                      HALTED
                    </Tag>
                  </Tooltip>
                )}
                {task.isSkipped && (
                  <Tooltip label={task.whySkipped}>
                    <Tag colorScheme="orange" w="min-content" ml="5px">
                      SKIPPED
                    </Tag>
                  </Tooltip>
                )}
              </Text>
              <Text textColor="gray.600" fontSize="sm" fontWeight="light">
                Standard Time {formatStandardTime(standardTime)}
              </Text>
              <TaskSkipButton task={task} />
            </Flex>
          </Flex>
        </>
      );
  }
};

const TaskTimeLine = ({ batchProgress, batch, refresh }) => {
  const history = useHistory();
  const {
    isOpen: isUserReassignDrawerOpen,
    onClose: closeUserReAssignDrawer,
    onOpen: openUserReAssignDrawer,
  } = useDisclosure();

  const recipeRef = useRef();

  const hasAlRequiredRecipes = () => {
    let hasRecipes = false;

    batchProgress.tasks.DYING.map((task) => {
      if (task.isRecipeRequired && task.recipes) {
        hasRecipes = true;
      }
    });

    batchProgress.tasks.QC.map((task) => {
      if (task.isRecipeRequired && task.recipes) {
        hasRecipes = true;
      }
    });

    batchProgress.tasks.FINISHING.map((task) => {
      if (task.isRecipeRequired && task.recipes) {
        hasRecipes = true;
      }
    });
    return hasRecipes;
  };

  const getRecipeList = () => {
    let recipeList = [];

    batchProgress.tasks.DYING.map((task) => {
      if (task.isRecipeRequired && task.recipes) {
        recipeList.push(task);
      }
    });

    return recipeList;
  };

  const getBatteryIcon = (percentage) => {
    if (percentage >= 70 && percentage <= 100) {
      return <RiBattery2ChargeFill color="green" size={20} />;
    } else if (percentage >= 30 && percentage < 70) {
      return <RiBattery2ChargeFill color="yellow" size={20} />;
    } else if (percentage < 30) {
      return <RiBattery2ChargeFill color="red" size={20} />;
    }
  };

  const getCurrentSupperVisor = () => {
    if (batchProgress.batchPhase === 'DYING') {
      return batch.dyeingOperatorInfo;
    } else if (batchProgress.batchPhase === 'FINISHING') {
      return batch.finishingOperatorInfo;
    } else if (batchProgress.batchPhase === 'QC') {
      return batch.qcOperatorInfo;
    }
  };

  return (
    <>
      <ReAssignUserDrawer
        refresh={refresh}
        isDrawerOpen={isUserReassignDrawerOpen}
        closeDrawer={closeUserReAssignDrawer}
        dyeingOperator={batch?.dyeingOperatorInfo}
        qcOperator={batch?.qcOperatorInfo}
        finishingOperator={batch?.finishingOperatorInfo}
        batchId={batch?.id}
      />
      <Box w="100%">
        <Grid
          gridTemplateColumns={{
            base: '1fr',
            md: '1fr',
            lg: '1fr 1fr',
            xl: '1fr 1fr 1fr',
          }}
          gap="14px"
          w="99%"
          mx="auto"
          padding="15px 0">
          <GridItem
            border="1px solid rgba(0,0,0,0.2)"
            boxSizing="border-box"
            padding="15px 10px 0 20px"
            rounded="lg">
            <Box
              position="relative"
              w="100%"
              height="450px"
              overflowY="scroll"
              css={{
                '&::-webkit-scrollbar': {
                  width: '4px',
                },
                '&::-webkit-scrollbar-track': {
                  width: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#f2f2f2',
                  borderRadius: '24px',
                },
              }}>
              <Flex
                justify="space-between"
                position="sticky"
                top="0"
                padding="5px 0"
                bgColor="#fff"
                zIndex="40"
                mb="20px">
                <Heading fontSize="2xl">Process Timeline</Heading>
              </Flex>
              {/* DYING */}
              {batchProgress.tasks.DYING?.length > 0 && (
                <>
                  <Flex align="center" justify="space-between" w="92%">
                    <Heading fontSize="xl">Dyeing</Heading>
                    <Tag size="lg">
                      {getCompltedTaskCount(batchProgress.tasks['DYING'])}/
                      {batchProgress.tasks['DYING'].length}
                    </Tag>
                  </Flex>
                  <Flex
                    mt="30px"
                    direction="column"
                    align="flex-start"
                    justify="flex-start"
                    w="92%">
                    {batchProgress.tasks['DYING'].map((task, index) => (
                      <TimeLineItem
                        refresh={refresh}
                        batch={batch}
                        tasks={batchProgress.tasks.DYING}
                        allTasks={[
                          ...batchProgress.tasks.DYING,
                          ...batchProgress.tasks.FINISHING,
                          ...batchProgress.tasks.QC,
                        ]}
                        key={task.taskName}
                        task={task}
                        index={index}
                        arrLength={batchProgress.tasks['DYING'].length}
                      />
                    ))}
                  </Flex>
                </>
              )}

              {/* FINISHING */}
              {batchProgress.tasks.FINISHING?.length > 0 && (
                <>
                  <Flex mt="15px" align="center" justify="space-between" w="92%">
                    <Heading fontSize="xl">Finishing</Heading>
                    <Tag size="lg">
                      {getCompltedTaskCount(batchProgress.tasks['FINISHING'])}/
                      {batchProgress.tasks['FINISHING'].length}
                    </Tag>
                  </Flex>
                  <Flex
                    mt="30px"
                    direction="column"
                    align="flex-start"
                    justify="flex-start"
                    w="92%">
                    {batchProgress.tasks['FINISHING'].map((task, index) => (
                      <TimeLineItem
                        batch={batch}
                        key={task.taskName}
                        task={task}
                        index={index}
                        arrLength={batchProgress.tasks['FINISHING'].length}
                      />
                    ))}
                  </Flex>
                </>
              )}
              {/* QC */}
              {batchProgress.tasks.QC?.length > 0 && (
                <>
                  <Flex mt="15px" align="center" justify="space-between" w="92%">
                    <Heading fontSize="xl">QC</Heading>
                    <Tag size="lg">
                      {getCompltedTaskCount(batchProgress.tasks['QC'])}/
                      {batchProgress.tasks['QC'].length}
                    </Tag>
                  </Flex>
                  <Flex
                    mb="25px"
                    mt="30px"
                    direction="column"
                    align="flex-start"
                    justify="flex-start"
                    w="92%">
                    {batchProgress.tasks['QC'].map((task, index) => (
                      <TimeLineItem
                        batch={batch}
                        key={task.taskName}
                        task={task}
                        index={index}
                        arrLength={batchProgress.tasks['QC'].length}
                      />
                    ))}
                  </Flex>
                </>
              )}
            </Box>
          </GridItem>
          <GridItem
            w="100%"
            h="480px"
            padding="15px"
            boxSizing="border-box"
            border="1px solid rgba(0,0,0,0.2)"
            rounded="lg">
            {!hasAlRequiredRecipes() ? (
              <Flex direction="column" align="center" justify="center" w="100%" h="100%">
                <Image src={NoRecipeImage} w="150px" />
                <Button
                  mt="25px"
                  colorScheme="red"
                  size="sm"
                  onClick={() => history.push(BATCH_SET_RECIPE + `/${batch.lotNumber}`)}>
                  SET RECIPE
                </Button>
              </Flex>
            ) : (
              <VStack spacing={5}>
                <HStack w="100%" justify="space-between">
                  <Heading fontSize="2xl">Recipe</Heading>
                  <HStack>
                    <Tooltip label="Edit recipe">
                      <IconButton
                        icon={<EditIcon />}
                        colorScheme="facebook"
                        size="sm"
                        onClick={() => history.push(BATCH_SET_RECIPE + `/${batch.lotNumber}`)}
                      />
                    </Tooltip>
                    <Tooltip label="Download recipe sheet">
                      <Box>
                        <ReactToPrint
                          trigger={() => (
                            <IconButton
                              icon={<DownloadIcon size={24} />}
                              size="sm"
                              colorScheme="facebook"
                            />
                          )}
                          content={() => recipeRef.current}
                        />
                      </Box>
                    </Tooltip>
                  </HStack>
                </HStack>
                <Box
                  w="100%"
                  h="400px"
                  overflow="scroll"
                  css={{
                    '&::-webkit-scrollbar': {
                      width: '4px',
                    },
                    '&::-webkit-scrollbar-track': {
                      width: '6px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: '#f2f2f2',
                      borderRadius: '24px',
                    },
                  }}>
                  <Table size="sm">
                    <Thead>
                      <Tr>
                        <Th>Chemical</Th>
                        <Th>Quantity</Th>
                        <Th>Total</Th>
                        <Th>Unit</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {getRecipeList()?.map((item) => (
                        <>
                          <Tr key={JSON.stringify(item)}>
                            <Td colSpan={4} bgColor="gray.100" textAlign="left" fontWeight="bold">
                              {item.taskName}
                            </Td>
                          </Tr>
                          {item?.recipes?.map((recipe) => (
                            <Tr key={JSON.stringify(recipe)}>
                              <Td>
                                {recipe?.chemical} {recipe?.dye}
                              </Td>
                              <Td>{recipe.quantity}</Td>
                              <Td>{recipe.total}</Td>
                              <Td>{recipe.unit === 'gl' ? 'G/L' : '%'}</Td>
                            </Tr>
                          ))}
                        </>
                      ))}
                    </Tbody>
                  </Table>
                </Box>
              </VStack>
            )}
          </GridItem>
          <GridItem height="480px">
            {batch && (
              <Box
                boxSizing="border-box"
                padding="20px 10px"
                borderRadius={10}
                width="100%"
                h="100%"
                border="1px solid rgba(0,0,0,0.2)">
                <VStack spacing="15px" align="flex-start" mb="25px">
                  <HStack justify="space-between" w="100%">
                    <Heading fontSize="24px" color="gray.700" ml="15px">
                      Current Supervisor
                    </Heading>
                    <Tooltip placement="top" label="Assign new operator">
                      <IconButton
                        icon={<SettingsIcon size={20} />}
                        size="sm"
                        colorScheme="facebook"
                        onClick={openUserReAssignDrawer}
                      />
                    </Tooltip>
                  </HStack>
                  <HStack mt="15px" align="flex-start" spacing="30px">
                    <HStack>
                      <Text ml="15px" fontSize="16px" fontWeight="bold" color="gray.600">
                        {getCurrentSupperVisor().name}
                      </Text>
                      <RiFocusFill
                        color={getCurrentSupperVisor().isActive ? 'green' : 'red'}
                        size={10}
                      />
                    </HStack>
                    <Text>{getCurrentSupperVisor().phone}</Text>
                    <HStack align="center">
                      <Text fontSize="12px" fontWeight="bold" color="gray.600">
                        {getCurrentSupperVisor().batteryPercent} %
                      </Text>
                      {getBatteryIcon(getCurrentSupperVisor().batteryPercent)}
                    </HStack>
                  </HStack>
                </VStack>
                {
                  <Table size="sm">
                    <Thead>
                      <Tr>
                        <Th>Name</Th>
                        <Th>Role</Th>
                        <Th>Battery</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {batch.creatorInfo && (
                        <Tr>
                          <Td>
                            <HStack>
                              <Text fontSize="14px" fontWeight="bold" color="gray.700">
                                {batch.creatorInfo.name}
                              </Text>
                              <RiFocusFill
                                color={batch.creatorInfo.isActive ? 'green' : 'red'}
                                size={10}
                              />
                            </HStack>
                          </Td>
                          <Td>Creator</Td>
                          <Td>
                            <HStack spacing="0px" align="flex-end" justify="flex-end">
                              <Text fontSize="11px" fontWeight="bold" color="gray.600">
                                {batch.creatorInfo.batteryPercent} %
                              </Text>
                              {getBatteryIcon(batch.creatorInfo.batteryPercent)}
                            </HStack>
                          </Td>
                        </Tr>
                      )}
                      {batch.approverInfo && (
                        <Tr>
                          <Td>
                            <HStack>
                              <Text fontSize="14px" fontWeight="bold" color="gray.700">
                                {batch.approverInfo.name}
                              </Text>
                              <RiFocusFill
                                color={batch.approverInfo.isActive ? 'green' : 'red'}
                                size={10}
                              />
                            </HStack>
                          </Td>
                          <Td>Approver</Td>
                          <Td>
                            <HStack spacing="0px" align="flex-end" justify="flex-end">
                              <Text fontSize="11px" fontWeight="bold" color="gray.600">
                                {batch.approverInfo.batteryPercent} %
                              </Text>
                              {getBatteryIcon(batch.approverInfo.batteryPercent)}
                            </HStack>
                          </Td>
                        </Tr>
                      )}
                      {batch.dyeingOperatorInfo && (
                        <Tr>
                          <Td>
                            <HStack>
                              <Text fontSize="14px" fontWeight="bold" color="gray.700">
                                {batch.dyeingOperatorInfo.name}
                              </Text>
                              <RiFocusFill
                                color={batch.dyeingOperatorInfo.isActive ? 'green' : 'red'}
                                size={10}
                              />
                            </HStack>
                          </Td>
                          <Td>Dyeing Operator</Td>
                          <Td>
                            <HStack spacing="0px" align="flex-end" justify="flex-end">
                              <Text fontSize="11px" fontWeight="bold" color="gray.600">
                                {batch.dyeingOperatorInfo.batteryPercent} %
                              </Text>
                              {getBatteryIcon(batch.dyeingOperatorInfo.batteryPercent)}
                            </HStack>
                          </Td>
                        </Tr>
                      )}
                      {batch.finishingOperatorInfo && (
                        <Tr>
                          <Td>
                            <HStack>
                              <Text fontSize="14px" fontWeight="bold" color="gray.700">
                                {batch.finishingOperatorInfo.name}
                              </Text>
                              <RiFocusFill
                                color={batch.finishingOperatorInfo.isActive ? 'green' : 'red'}
                                size={10}
                              />
                            </HStack>
                          </Td>
                          <Td>Finishing Operator</Td>
                          <Td>
                            <HStack spacing="0px" align="flex-end" justify="flex-end">
                              <Text fontSize="11px" fontWeight="bold" color="gray.600">
                                {batch.finishingOperatorInfo.batteryPercent} %
                              </Text>
                              {getBatteryIcon(batch.finishingOperatorInfo.batteryPercent)}
                            </HStack>
                          </Td>
                        </Tr>
                      )}
                      {batch.qcOperatorInfo && (
                        <Tr>
                          <Td>
                            <HStack>
                              <Text fontSize="14px" fontWeight="bold" color="gray.700">
                                {batch.qcOperatorInfo.name}
                              </Text>
                              <RiFocusFill
                                color={batch.qcOperatorInfo.isActive ? 'green' : 'red'}
                                size={10}
                              />
                            </HStack>
                          </Td>
                          <Td>QC</Td>
                          <Td>
                            <HStack spacing="0px" align="flex-end" justify="flex-end">
                              <Text fontSize="11px" fontWeight="bold" color="gray.600">
                                {batch.qcOperatorInfo.batteryPercent} %
                              </Text>
                              {getBatteryIcon(batch.qcOperatorInfo.batteryPercent)}
                            </HStack>
                          </Td>
                        </Tr>
                      )}
                    </Tbody>
                  </Table>
                }
              </Box>
            )}
          </GridItem>
        </Grid>
      </Box>
      {batch && hasAlRequiredRecipes() && (
        <Box display="none">
          <RecipeSheet batch={batch} ref={recipeRef} />
        </Box>
      )}
    </>
  );
};

export default TaskTimeLine;
