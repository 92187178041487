import axios from './axios';

export const fetchFabricByIds = async (ids) => {
  try {
    const response = await axios.post('/fabric/used', { ids });

    return response.data.fabrics;
  } catch (error) {
    console.error(error);
    return null;
  }
};
