import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  HStack,
  IconButton,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { AddIcon, EditIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';
import { NEW_ORDER, ORDER_DETAILS, UPDATE_ORDER } from '../../constatnts/routerUrls';
import { useEffect } from 'react';
import { getAllFcWithBatchInfo } from '../../api/fcInfo';
import CustomTable2 from '../../components/common/CustomTable2';
import { ORDER_LIST_REFRESH_TIMEOUT } from '../../constatnts/time';
import BulkUploadModal from '../../components/order/BulkUploadModal';
import { useDispatch, useSelector } from 'react-redux';

const UpdateOrderButton = ({ data }) => {
  const history = useHistory();

  return (
    <IconButton
      icon={<EditIcon />}
      size="sm"
      colorScheme="facebook"
      onClick={() => history.push(`${UPDATE_ORDER}/${data.fcNumber}`, { fcNumber: data.fcNumber })}
    />
  );
};
const OrderList = () => {
  const history = useHistory();

  // const [customers, setCutomers] = useState([]);
  const customers = useSelector((state) => state.order);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [showBulkUploadModal, setShowBulkUploadModal] = useState(false);
  const [stats, setStats] = useState([]);

  const dispatch = useDispatch();

  const getCompltedBatchCount = (batches) => {
    let count = 0;

    batches.map((batch) => (batch.isComplete ? (count += 1) : ''));

    return count;
  };

  const formatData = (data) => {
    let formatedData = [];

    data.map((item) =>
      formatedData.push({
        ...item,
        fdShade: Math.floor(item.shade * 100).toString() + ' %',
        fdBatchCount: item.batchInfo?.length <= 0 ? 'No batches assigned' : item.batchInfo?.length,
        fdCompletedBatch: isNaN(getCompltedBatchCount(item.batchInfo) / item.batchInfo?.length)
          ? 0
          : getCompltedBatchCount(item.batchInfo) / item.batchInfo?.length,
      }),
    );
    return formatedData;
  };

  const fetchData = async () => {
    setIsLoading(true);
    const response = await getAllFcWithBatchInfo();
    if (response?.data) {
      dispatch({ type: 'SET_ALL_ORDERS', payload: formatData(response.data) });
      setTotal(response.total);
      setStats(response.stats);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();

    const interval = setInterval(async () => {
      await fetchData();
    }, ORDER_LIST_REFRESH_TIMEOUT);

    return () => clearInterval(interval);
  }, []);

  const columns = [
    {
      header: 'fc Number',
      key: 'fcNumber',
      type: 'TAG',
    },
    {
      header: 'party name',
      key: 'partyName',
      type: 'TEXT',
    },
    {
      header: 'status',
      key: 'orderStatus',
      type: 'BADGE',
    },
    {
      header: 'Batches',
      key: 'fdBatchCount',
      type: 'TEXT',
    },
    {
      header: 'Progress',
      key: 'fdCompletedBatch',
      type: 'PROGRESS',
    },
    {
      header: 'sales person',
      key: 'salesPerson',
      type: 'TEXT',
    },
    {
      header: 'Delivery',
      key: 'deliveryDate',
      type: 'DATE',
    },
    {
      header: 'Order Date',
      key: 'createdAt',
      type: 'DATE',
    },
  ];

  const filters = [
    {
      name: 'Search by FC number or Party Name',
      type: 'INPUT',
      queryParamKey: 'searchKey',
    },
    {
      name: 'Order Status',
      type: 'SELECT',
      queryParamKey: 'orderStatus',
      options: [
        {
          value: 'IN QUEUE',
          label: 'In Queue',
        },
        {
          value: 'READY FOR DELIVERY',
          label: 'Ready For Delivery',
        },
        {
          value: 'DELIVERED',
          label: 'Delivered',
        },
      ],
    },
  ];

  const handleQuery = async (data) => {
    setIsLoading(true);
    const response = await getAllFcWithBatchInfo(data);
    if (response?.data) {
      dispatch({ type: 'SET_ALL_ORDERS', payload: formatData(response.data) });
      setTotal(response.total);
    }
    setIsLoading(false);
  };

  return (
    <>
      <BulkUploadModal show={showBulkUploadModal} onClose={() => setShowBulkUploadModal(false)} />
      <Box w="100%" boxSizing="border-box" p="15px">
        <HStack spacing={5} justify="space-between" w="100%">
          <Heading>Orders</Heading>
          <HStack>
            <Tooltip label="Add New Order">
              <Button
                size="sm"
                rightIcon={<AddIcon size={24} />}
                colorScheme="facebook"
                onClick={() => history.push(NEW_ORDER)}>
                Add Order
              </Button>
            </Tooltip>
            <Tooltip label="Create order from csv">
              <Button
                size="sm"
                rightIcon={<AddIcon size={24} />}
                colorScheme="facebook"
                onClick={() => setShowBulkUploadModal(true)}>
                Upload CSV
              </Button>
            </Tooltip>
          </HStack>
        </HStack>
        <Grid gridTemplateColumns="repeat(4, 1fr)" gap="5px" w="500px" my="15px">
          {stats?.map((item) => (
            <GridItem
              key={item.title}
              w="100%"
              padding="10px"
              rounded="lg"
              bgColor="white"
              border="1px solid rgba(0,0,0,0.2)">
              <Text fontSize="xs" fontWeight="bold" color="gray.600">
                {item.title}
              </Text>
              <Text fontSize="2xl" fontWeight="bold" color={`${item.color}`}>
                {item.total}
              </Text>
            </GridItem>
          ))}
        </Grid>
        <Box my="20px">
          <CustomTable2
            loading={isLoading}
            entries={total}
            data={customers}
            columns={columns}
            filters={filters}
            detailPath={ORDER_DETAILS}
            detailPathParam="fcNumber"
            queryCallback={handleQuery}
            refreshCallback={fetchData}
            Actions={[UpdateOrderButton]}
            exportEnable={true}
            exportTitle="orders"
            exportCols={[
              'fcNumber',
              'partyName',
              'orderStatus',
              'deliveryDate',
              'createdAt',
              'clientRefNo',
              'remarks',
              'clientContactPerson',
              'clientAddress',
            ]}
            exportDataFetch={getAllFcWithBatchInfo}
          />
        </Box>
      </Box>
    </>
  );
};

export default OrderList;
