import axios from './axios';

export const getAllMachine = async () => {
  try {
    const response = await axios.get('/machine');
    return {
      status: 200,
      ...response.data,
    };
  } catch (error) {
    return {
      error: error,
    };
  }
};

export const queryMachine = async (query) => {
  try {
    const response = await axios.get('/machine', { params: query });
    return {
      status: 200,
      ...response.data,
    };
  } catch (error) {
    return {
      error: error,
    };
  }
};

export const addMachine = async (data) => {
  try {
    const response = await axios.post('/machine', { ...data });

    return {
      status: 200,
      data: response.data.machine,
    };
  } catch (error) {
    return {
      error: error,
    };
  }
};

export const updateMachineInfor = async (data) => {
  try {
    const response = await axios.patch('/machine', { ...data });

    return {
      status: 200,
      data: response.data.machine,
    };
  } catch (error) {
    return {
      error: error,
    };
  }
};

export const markMachineOOO = async (name) => {
  try {
    const response = await axios.delete(`machine/${name}`);
    return {
      status: response.status,
    };
  } catch (error) {
    return {
      status: 500,
      error: error,
    };
  }
};

export const getMachineLogs = async (name) => {
  try {
    const response = await axios.get(`machine/logs/${name}`);
    return {
      status: response.status,
      ...response.data,
    };
  } catch (error) {
    return {
      status: 500,
      error: error,
    };
  }
};

export const queryMachineLogs = async (name, query) => {
  try {
    const response = await axios.get(`machine/logs/${name}`, { params: query });
    return {
      status: response.status,
      ...response.data,
    };
  } catch (error) {
    return {
      status: 500,
      error: error,
    };
  }
};

export const updateMaintanenceLog = async (logId, data) => {
  try {
    const response = await axios.patch(`/machine/logs/${logId}`, { ...data });

    return {
      status: response.data.status,
    };
  } catch (error) {
    return {
      status: 500,
      error: error,
    };
  }
};

export const updateMachineQrId = async (machineId, qrId) => {
  try {
    await axios.patch('/machine/qr', { machineId, qrId });
  } catch (error) {
    console.error(error);
    return null;
  }
};
