import { DeleteIcon } from '@chakra-ui/icons';
import { IconButton, Tooltip, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { markuserAsBanned } from '../../api/user';
import toastFormat from '../../constatnts/toastFormat';
import { MARK_USER_AS_BANNED } from '../../store/actions/action-types';
import AlertBox from '../common/AlertBox';

export const DeleteUserInfo = ({ data }) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);

  const handleDelete = async () => {
    setLoading(true);

    const response = await markuserAsBanned(data.uid);
    if (response === 200) {
      dispatch({
        type: MARK_USER_AS_BANNED,
        payload: data.uid,
      });
      toast({
        title: 'User banned',
        status: 'warning',
        ...toastFormat,
      });
      setShowAlert(false);
    } else {
      toast({
        title: 'Error updating user status',
        status: 'error',
        ...toastFormat,
      });
    }
    setLoading(false);
  };

  return (
    <>
      {data.status !== 'BANNED' && (
        <>
          <AlertBox
            title="Mark user as banned"
            subTitle="Are you sure you want to go ahead"
            onClose={() => setShowAlert(false)}
            onSubmit={handleDelete}
            show={showAlert}
          />
          <Tooltip label="Mark user as banned">
            <IconButton
              colorScheme="yellow"
              ml="5px"
              size="sm"
              isLoading={loading}
              onClick={() => setShowAlert(true)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
    </>
  );
};
