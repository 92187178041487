import { Button, IconButton } from '@chakra-ui/button';
import { Checkbox } from '@chakra-ui/checkbox';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { AddIcon, ChevronDownIcon, DeleteIcon } from '@chakra-ui/icons';
import { Input } from '@chakra-ui/input';
import { Box, Flex, Heading, Text } from '@chakra-ui/layout';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu';
import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/table';
import { Tag } from '@chakra-ui/tag';
import { useToast } from '@chakra-ui/toast';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Select as ChakraSelect } from '@chakra-ui/react';

import {
  addRecipe,
  addRecipeTemplate,
  fetchAllChemicals,
  fetchRecipeTemplates,
} from '../../../api/batch';
import { BATCHES } from '../../../constatnts/routerUrls';
import toastFormat from '../../../constatnts/toastFormat';
import Select from 'react-select';

const RecipeStepperForm = ({
  updateStep,
  currentStep,
  totalStep,
  steps,
  liqureRatio,
  updateBatch,
  batch,
}) => {
  const currentTask = steps[currentStep];
  const history = useHistory();

  const [recepies, setRecipes] = useState(
    currentTask && currentTask.recipes ? currentTask.recipes : [],
  );
  const [shouldSaveTemplate, setShouldSaveTemplate] = useState(false);
  const [dyeGroups, setDyeGroups] = useState(null);
  const [dyes, setDyes] = useState(null);
  const [dyeOptions, setDyeOptions] = useState([]);
  const [chemicals, setChemicals] = useState(null);
  const [isLoading, setIsloading] = useState(false);
  const [recipeTemplates, setRecipeTemplates] = useState(null);
  const [selectedTemplateName, setSelectedTemplateName] = useState(null);
  const [templateName, setTemplateName] = useState('');

  const toast = useToast();

  useEffect(() => {
    (async () => {
      try {
        const { chemicals, dyes, dyeGroups } = await fetchAllChemicals();
        setChemicals(chemicals);
        setDyes(dyes);
        setDyeGroups(dyeGroups);
      } catch (error) {
        toast({
          title: 'Error fetching chemical list',
          status: 'error',
          ...toastFormat,
        });
      }
    })();
  }, []);

  const handleTemplateLoad = (template) => {
    setRecipes(template.recipe);
    setSelectedTemplateName(template.name);
  };

  const calculateTotal = (unit, item) => {
    let total = 0;
    if (unit === 'gl') {
      total += item.quantity * liqureRatio * batch.totalFabricWeight;
    } else {
      const changePercent = parseFloat(item.changeBy) * 0.01;
      const totalQty = item.quantity + changePercent;
      total += totalQty * batch.totalFabricWeight * 10;
    }
    return total;
  };

  const updateState = (val) => {
    const modifyTotal = val.map((item) => {
      return {
        ...item,
        total: calculateTotal(item.unit, item),
      };
    });

    updateBatch({
      ...batch,
      tasks: {
        ...batch.tasks,
        [currentTask.taskType]: batch.tasks[currentTask.taskType].map((task) =>
          task.uid === currentTask.uid
            ? {
                ...currentTask,
                recipes: modifyTotal,
              }
            : task,
        ),
      },
    });
  };

  const handleSaveTemplate = async () => {
    try {
      if (recepies.length > 0 && templateName.length > 0) {
        const data = {
          name: templateName,
          recipe: recepies,
          taskName: currentTask.taskName,
        };
        const response = await addRecipeTemplate(data);
        if (response === 200) {
          toast({
            title: 'Template saved',
            status: 'success',
            ...toastFormat,
          });
          return true;
        } else {
          toast({
            title: 'Failed to save template',
            status: 'error',
            ...toastFormat,
          });
          return false;
        }
      } else {
        toast({
          title: 'Please add template name',
          status: 'warning',
          ...toastFormat,
        });
      }
      return false;
    } catch (error) {
      toast({
        title: 'Failed to save template',
        status: 'error',
        ...toastFormat,
      });
      return false;
    }
  };

  const handleNext = async () => {
    const hasRecipes = validateRecipe();

    if (hasRecipes) {
      const step = currentStep + 1 >= totalStep ? totalStep - 1 : currentStep + 1;

      updateState(recepies);

      if (shouldSaveTemplate) {
        setIsloading(true);
        const templateSaved = await handleSaveTemplate();
        setIsloading(false);
        setSelectedTemplateName('');

        if (templateSaved) {
          updateStep(step);
          setShouldSaveTemplate(false);
          if (steps[step].recipes) {
            setRecipes(steps[step].recipes);
          } else {
            setRecipes([]);
          }
        }
      } else {
        updateStep(step);
        setShouldSaveTemplate(false);
        setSelectedTemplateName('');

        if (steps[step].recipes) {
          setRecipes(steps[step].recipes);
        } else {
          setRecipes([]);
        }
      }
    } else {
      toast({
        title: 'Please Add Recipe',
        status: 'warning',
        ...toastFormat,
      });
    }
  };

  const handlePrev = () => {
    const step = currentStep - 1 > 0 ? currentStep - 1 : 0;

    updateState(recepies);

    updateStep(step);
    if (steps[step].recipes) {
      setRecipes(steps[step].recipes);
    } else {
      setRecipes([]);
    }
    setSelectedTemplateName('');
  };

  const submitData = async () => {
    try {
      if (validateRecipe()) {
        const modifiedRecipes = recepies.map((item) => {
          return {
            ...item,
            total: calculateTotal(item.unit, item),
          };
        });

        const modifiedTaskWithRecipe = {
          ...currentTask,
          recipes: modifiedRecipes,
        };
        const modifiedTasks = {
          ...batch.tasks,
          [currentTask.taskType]: batch.tasks[currentTask.taskType].map((task) =>
            task.uid === currentTask.uid ? { ...modifiedTaskWithRecipe } : task,
          ),
        };

        if (shouldSaveTemplate) {
          setIsloading(true);
          const templateSaved = await handleSaveTemplate();
          const response = await addRecipe({
            tasks: modifiedTasks,
            id: batch.id,
            batchId: batch.batchId,
            totalFabricWeight: batch.totalFabricWeight,
          });
          if (templateSaved) {
            if (response.status === 200) {
              toast({
                title: 'Recipe added',
                status: 'success',
                ...toastFormat,
              });
              history.push(BATCHES + `/${batch.lotNumber}`);
            } else {
              toast({
                title: 'Error adding recipes',
                status: 'error',
                ...toastFormat,
              });
            }
          }
          setIsloading(false);
        } else {
          setIsloading(true);
          const response = await addRecipe({
            tasks: modifiedTasks,
            id: batch.id,
            batchId: batch.batchId,
            totalFabricWeight: batch.totalFabricWeight,
          });
          if (response.status === 200) {
            toast({
              title: 'Recipe added',
              status: 'success',
              ...toastFormat,
            });
            history.push(BATCHES + `/${batch.lotNumber}`);
          } else {
            toast({
              title: 'Error adding recipes',
              status: 'error',
              ...toastFormat,
            });
          }
          setIsloading(false);
        }
      } else {
        toast({
          title: 'Please add recipe',
          status: 'warning',
          ...toastFormat,
        });
      }
    } catch (error) {
      toast({
        title: 'Error saving',
        status: 'error',
        ...toastFormat,
      });
    }
  };

  const validateRecipe = () => {
    const taskName = currentTask.taskName;
    let isValid = true;
    if (taskName === 'Color') {
      recepies.map((item) => {
        if (item.dyeGroup?.length <= 0) {
          isValid = false;
        }
        if (item.dye?.length <= 0) {
          isValid = false;
        }
        if (item.unit !== 'percent') {
          isValid = false;
        }
        if (item.quantity <= 0) {
          isValid = false;
        }
      });
    } else {
      recepies.map((item) => {
        if (item.chemical?.length <= 0) {
          isValid = false;
        }
        if (item.quantity <= 0) {
          isValid = false;
        }
      });
    }
    return isValid;
  };

  const validateChemicalData = (index) => {
    let dataValid = {
      hasChemicalName: false,
      hasQuantity: false,
      hasUnit: false,
    };

    const data = recepies[index];

    if (data?.chemical?.length <= 0) {
      dataValid = { ...dataValid, hasChemicalName: true };
    }
    if (parseFloat(data?.quantity) <= 0) {
      dataValid = { ...dataValid, hasQuantity: true };
    }
    if (data?.unit !== 'gl') {
      dataValid = { ...dataValid, hasUnit: true };
    }

    return dataValid;
  };

  const validateColorData = (index) => {
    let dataValid = {
      hasDyeGroup: false,
      hasDye: false,
      hasQuantity: false,
      hasUnit: false,
    };

    const data = recepies[index];

    if (data?.dyeGroup?.length <= 0) {
      dataValid = { ...dataValid, hasDyeGroup: true };
    }
    if (data?.dye?.length <= 0) {
      dataValid = { ...dataValid, hasDye: true };
    }
    if (parseFloat(data?.quantity) <= 0) {
      dataValid = { ...dataValid, hasQuantity: true };
    }
    if (data?.unit !== 'percent') {
      dataValid = { ...dataValid, hasUnit: true };
    }

    return dataValid;
  };

  useEffect(() => {
    (async () => {
      const response = await fetchRecipeTemplates();
      const filteredTemplates = response.recipes.filter(
        (item) => item.taskName === currentTask.taskName,
      );
      if (response.status === 200) {
        setRecipeTemplates(filteredTemplates);
      } else {
        toast({
          title: 'Failed to load recipe templates',
          status: 'error',
          ...toastFormat,
        });
      }
    })();
  }, [currentTask]);

  const handleDyeGroupSelect = (data, index) => {
    const updatedRecipe = recepies.map((item, recipeIndex) =>
      recipeIndex === index ? { ...item, dyeGroup: data.value } : item,
    );

    setRecipes(updatedRecipe);

    const filteredDyeGroup = dyeGroups?.find((dyeGroup) => dyeGroup.name === data.value);

    const filteredDyes = dyes.filter((dye) => dye.dyeGroupId === filteredDyeGroup.id);
    setDyeOptions(filteredDyes);
  };

  const handleChemicalSelect = (data, index) => {
    const updatedRecipe = recepies.map((item, recipeIndex) =>
      recipeIndex === index ? { ...item, chemical: data.value } : item,
    );
    setRecipes(updatedRecipe);
  };

  const handleDyeSelect = (data, index) => {
    const updatedRecipe = recepies.map((item, recipeIndex) =>
      recipeIndex === index ? { ...item, dye: data.value } : item,
    );
    setRecipes(updatedRecipe);
  };

  const SelectField = ({ options, onChange, index, field, name }) => {
    return (
      <Select
        options={options}
        onChange={(opt) => onChange(opt, index)}
        value={options?.find((item) => item.value === field[name])}
      />
    );
  };
  return steps.length > 0 ? (
    <Box my="20px" w="100%">
      <Flex w="100%" justify="space-between">
        {recepies.length > 0 ? (
          <Text textColor="green.400" fontSize="12px">
            Recipe Added
          </Text>
        ) : (
          <Text textColor="red.400" fontSize="12px">
            Please add recipe
          </Text>
        )}
        <Tag>
          {currentStep + 1}/{totalStep}
        </Tag>
      </Flex>

      <Flex w="100%" my="15px" border="1px solid rgba(0,0,0,0.2)" rounded="lg">
        <Flex w="200px" direction="column" borderRight="1px solid rgba(0,0,0,0.2)">
          {steps &&
            steps.map((step, index) => (
              <Box
                key={step.uid}
                padding="25px 15px"
                bgColor={index === currentStep ? 'gray.50' : 'none'}
                borderLeft={index === currentStep ? '6px solid #2B6CB0' : 'none'}
              >
                <Text fontSize="md" fontWeight="bold">
                  {step.taskName}
                </Text>
              </Box>
            ))}
        </Flex>
        <Flex w="calc(100% - 200px)" direction="column">
          <Flex w="100%">
            <Table>
              <Thead>
                <Tr>
                  {currentTask.taskName === 'Color' ? (
                    <>
                      <Th>Dye Group</Th>
                      <Th>Dye</Th>
                    </>
                  ) : (
                    <Th>Chemical</Th>
                  )}
                  <Th>QTY</Th>
                  <Th>UNIT</Th>
                  <Th>Total</Th>
                  <Th>Addition</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {recepies.map((field, index) => (
                  <Tr key={field.id}>
                    {currentTask.taskName === 'Color' ? (
                      <>
                        <Td>
                          <FormControl isInvalid={validateColorData(index).hasDyeGroup}>
                            <SelectField
                              options={dyeGroups?.map((item) => ({
                                value: item.name,
                                label: item.name,
                              }))}
                              onChange={handleDyeGroupSelect}
                              name="dyeGroup"
                              field={field}
                              index={index}
                            />
                          </FormControl>
                        </Td>
                        <Td>
                          <FormControl isInvalid={validateColorData(index).hasDye}>
                            <SelectField
                              options={dyeOptions.map((item) => ({
                                label: item.name,
                                value: item.name,
                              }))}
                              onChange={handleDyeSelect}
                              name="dye"
                              field={field}
                              index={index}
                            />
                          </FormControl>
                        </Td>
                      </>
                    ) : (
                      <Td>
                        <FormControl isInvalid={validateChemicalData(index).hasChemicalName}>
                          <SelectField
                            options={chemicals?.map((item) => ({
                              value: item.name,
                              label: item.name,
                            }))}
                            onChange={handleChemicalSelect}
                            name="chemical"
                            field={field}
                            index={index}
                          />
                        </FormControl>
                      </Td>
                    )}
                    <Td w="200px">
                      <FormControl
                        isInvalid={
                          currentTask.taskName !== 'Color'
                            ? validateChemicalData(index).hasQuantity
                            : validateColorData(index).hasQuantity
                        }
                      >
                        <Input
                          size="sm"
                          placeholder="Quantity"
                          min={1}
                          type="number"
                          value={recepies[index].quantity}
                          onChange={(e) => {
                            const updatedRecipe = recepies.map((item, recipeIndex) =>
                              recipeIndex === index
                                ? { ...item, quantity: parseFloat(e.target.value) }
                                : item,
                            );
                            setRecipes(updatedRecipe);
                          }}
                        />
                      </FormControl>
                      {field.unit === 'percent' && (
                        <>
                          <FormControl>
                            <FormLabel fontSize="12px" mt="5px">
                              Change By
                            </FormLabel>
                            <Input
                              size="sm"
                              placeholder="Change By (%)"
                              min={1}
                              type="number"
                              value={recepies[index].changeBy}
                              onChange={(e) => {
                                const updatedRecipe = recepies.map((item, recipeIndex) =>
                                  recipeIndex === index
                                    ? { ...item, changeBy: parseFloat(e.target.value) }
                                    : item,
                                );
                                setRecipes(updatedRecipe);
                              }}
                            />
                          </FormControl>
                          <FormControl isDisabled={true}>
                            <FormLabel fontSize="12px" mt="5px">
                              Total Change
                            </FormLabel>
                            <Input
                              size="sm"
                              placeholder="Total Change"
                              min={1}
                              type="number"
                              value={recepies[index].quantity + recepies[index].changeBy * 0.01}
                              onChange={(e) => {
                                const updatedRecipe = recepies.map((item, recipeIndex) =>
                                  recipeIndex === index
                                    ? { ...item, totalQuantity: parseFloat(e.target.value) }
                                    : item,
                                );
                                setRecipes(updatedRecipe);
                              }}
                            />
                          </FormControl>
                        </>
                      )}
                    </Td>

                    <Td>
                      <ChakraSelect
                        size="sm"
                        defaultValue={currentTask.taskName === 'Color' ? 'percent' : 'gl'}
                        onChange={(e) => {
                          const updatedRecipe = recepies.map((item, recipeIndex) =>
                            recipeIndex === index ? { ...item, unit: e.target.value } : item,
                          );
                          setRecipes(updatedRecipe);
                        }}
                      >
                        <option value="gl">g/l</option>
                        <option value="percent">%</option>
                      </ChakraSelect>
                    </Td>
                    <Td>
                      <FormControl isDisabled={true}>
                        <Input value={calculateTotal(field.unit, field)} />
                      </FormControl>
                    </Td>
                    <Td>
                      <FormControl>
                        <Input
                          placeholder={field.addition}
                          onChange={(e) => {
                            const updatedRecipe = recepies.map((item, recipeIndex) =>
                              recipeIndex === index
                                ? { ...item, addition: parseFloat(e.target.value) }
                                : item,
                            );
                            setRecipes(updatedRecipe);
                          }}
                        />
                      </FormControl>
                    </Td>
                    <Td>
                      {index !== 0 ? (
                        <IconButton
                          size="sm"
                          onClick={() => {
                            const newRecipes = recepies.filter(
                              (val, recipeIndex) => index !== recipeIndex,
                            );
                            setRecipes(newRecipes);
                          }}
                          colorScheme="red"
                          icon={<DeleteIcon />}
                        />
                      ) : (
                        <Box padding="8px" bgColor="white"></Box>
                      )}
                    </Td>
                  </Tr>
                ))}
                <Tr>
                  <Td>
                    <Button
                      leftIcon={<AddIcon />}
                      colorScheme="blue"
                      size="sm"
                      onClick={() => {
                        if (currentTask.taskName === 'Color') {
                          setRecipes([
                            ...recepies,
                            {
                              dyeGroup: '',
                              dye: '',
                              quantity: '0',
                              changeBy: '0',
                              totalQuantity: '0',
                              total: 0,
                              unit: 'percent',
                              addition: 0,
                            },
                          ]);
                        } else {
                          setRecipes([
                            ...recepies,
                            {
                              chemical: '',
                              quantity: '0',
                              total: '0',
                              unit: 'gl',
                              addition: 0,
                            },
                          ]);
                        }
                      }}
                    >
                      Add
                    </Button>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Flex>
          <Flex w="100%" justify="space-between">
            <Flex ml="25px" mt="15px">
              <Menu>
                <MenuButton as={Button} size="sm" rightIcon={<ChevronDownIcon />}>
                  {selectedTemplateName ? selectedTemplateName : 'Load From Template'}
                </MenuButton>
                <MenuList>
                  {recipeTemplates && recipeTemplates.length > 0 ? (
                    recipeTemplates.map((template) => (
                      <MenuItem key={template.uid} onClick={() => handleTemplateLoad(template)}>
                        {template.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>No Template found</MenuItem>
                  )}
                </MenuList>
              </Menu>
              <Button
                colorScheme="blue"
                size="sm"
                ml="15px"
                onClick={() => {
                  setRecipes([]);
                  setSelectedTemplateName('');
                }}
              >
                RESET
              </Button>
            </Flex>
            <Flex mt="15px" align="center"></Flex>
            <Flex padding="15px">
              <Checkbox
                isChecked={shouldSaveTemplate}
                onChange={(e) => setShouldSaveTemplate(e.target.checked)}
              >
                Save for later
              </Checkbox>
              {shouldSaveTemplate && (
                <FormControl w="200px" ml="15px">
                  <Input
                    placeholder="name"
                    size="sm"
                    onChange={(e) => setTemplateName(e.target.value)}
                    value={templateName}
                  />
                </FormControl>
              )}
              {currentStep > 0 && (
                <Button colorScheme="blue" ml="15px" size="sm" onClick={handlePrev}>
                  Previous
                </Button>
              )}
              {currentStep < totalStep - 1 && (
                <Button
                  size="sm"
                  colorScheme="green"
                  ml="15px"
                  onClick={handleNext}
                  isLoading={isLoading}
                  disabled={!validateRecipe()}
                >
                  Next
                </Button>
              )}
              {currentStep === totalStep - 1 && (
                <Button
                  size="sm"
                  ml="15px"
                  colorScheme="twitter"
                  isLoading={isLoading}
                  onClick={submitData}
                  disabled={!validateRecipe()}
                >
                  Done
                </Button>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  ) : (
    <Heading fontSize="32px" fontWeight="medium" textColor="gray.400" my="30px">
      No required recipe found
    </Heading>
  );
};

export default RecipeStepperForm;
