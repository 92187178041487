import axios from './axios';

export const currentDayBatches = async () => {
  try {
    const response = await axios.get('/stats/batches');
    return response.data.stats;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const basicStat = async () => {
  try {
    const response = await axios.get('/stats');
    return response.data.stats;
  } catch (error) {
    console.error(error);
    return null;
  }
};
