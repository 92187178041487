/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Box, Flex, Heading } from '@chakra-ui/layout';
import CustomTable2 from '../../components/common/CustomTable2';
import { toast, useToast } from '@chakra-ui/toast';
import { Button, IconButton } from '@chakra-ui/button';
import { useHistory } from 'react-router';
import { FC_INFO } from '../../constatnts/routerUrls';
import { getAllFc, updateFc } from '../../api/fcInfo';
import { useForm } from 'react-hook-form';
import { fcUpdateValidator, useYupValidationResolver } from '../../validators/form-validators';
import AlertBox from '../../components/common/AlertBox';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import { Textarea } from '@chakra-ui/textarea';
import { useDisclosure } from '@chakra-ui/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { GET_ALL_FC, UPDATE_FC_INFO } from '../../store/actions/action-types';
import toastFormat from '../../constatnts/toastFormat';

const UpdateInfo = ({ data }) => {
  const [showAlert, setShowAlert] = useState(false);
  const { onOpen, isOpen, onClose } = useDisclosure();
  const resolver = useYupValidationResolver(fcUpdateValidator);
  const toast = useToast();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      partyName: data.partyName,
      color: data.color,
      shade: data.shade,
      labDipRef: data.labDipRef,
      clientRefNo: data.clientRefNo,
      remarks: data.remarks,
    },
    resolver,
  });

  const submit = async (formData) => {
    try {
      if (isDirty) {
        const { status } = await updateFc({ fcNumber: data.fcNumber, ...formData });
        if (status === 200) {
          onClose();
          setShowAlert(false);
          dispatch({
            type: UPDATE_FC_INFO,
            payload: {
              fcNumber: data.fcNumber,
              data: formData,
            },
          });
          toast({
            title: 'Data updated',
            status: 'success',
            ...toastFormat,
          });
        }
      } else {
        setShowAlert(false);
        toast({
          title: 'Nothing changed',
          status: 'warning',
          ...toastFormat,
        });
      }
    } catch (error) {
      toast({
        title: 'Failed to update',
        status: 'error',
        ...toastFormat,
      });
    }
  };
  return (
    <>
      <AlertBox
        title="Update order information"
        subTitle="Are you sure you want to update"
        show={showAlert}
        onClose={() => setShowAlert(false)}
        onSubmit={handleSubmit(submit)}
      />
      <IconButton icon={<EditIcon />} colorScheme="gray" onClick={onOpen} size="sm" />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <Flex direction="column" padding="15px" boxSizing="border-box">
            <FormControl isInvalid={!!errors.partyName} isRequired={true}>
              <FormLabel mt="15px">Party Name</FormLabel>
              <Input {...register('partyName')} placeholder="Party name" required />
              <FormErrorMessage>{errors?.partyName?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.color} isRequired={true}>
              <FormLabel mt="15px">Color</FormLabel>
              <Input {...register('color')} placeholder="Color" required />
              <FormErrorMessage>{errors?.color?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.shade} isRequired={true}>
              <FormLabel mt="15px">Shade</FormLabel>
              <Input
                {...register('shade')}
                placeholder="Shade ie - 0.2 or 0.5 or 1"
                type="number"
                required
                max={1}
              />
              <FormErrorMessage>{errors?.shade?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.labDipRef} isRequired={true}>
              <FormLabel mt="15px">Lab dip reference</FormLabel>
              <Input {...register('labDipRef')} placeholder="Lab dip reference" required />
              <FormErrorMessage>{errors?.labDipRef?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.clientRefNo} isRequired={true}>
              <FormLabel mt="15px">Client reference number</FormLabel>
              <Input {...register('clientRefNo')} placeholder="Client reference number" required />
              <FormErrorMessage>{errors?.clientRefNo?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.remarks} isRequired={true}>
              <FormLabel mt="15px">Remarks</FormLabel>
              <Textarea {...register('remarks')} placeholder="Additional Remarks" required />
              <FormErrorMessage>{errors?.remarks?.message}</FormErrorMessage>
            </FormControl>
            <Button
              w="100%"
              type="submit"
              colorScheme="telegram"
              mt="15px"
              onClick={() => setShowAlert(true)}
            >
              Update Order
            </Button>
          </Flex>
        </ModalContent>
      </Modal>
    </>
  );
};

const FCListView = () => {
  const [contentLoading, setContentLoading] = useState(false);
  const [entries, setEntries] = useState(false);
  const toast = useToast();
  const history = useHistory();
  const dispatch = useDispatch();
  const fcs = useSelector((state) => state.fc);

  const cleanData = (data) => {
    let cleanedData = [];

    data.map((item) => cleanedData.push({ ...item, cdShade: <p>{item.shade * 100} %</p> }));

    return cleanedData;
  };
  useEffect(() => {
    (async () => {
      try {
        setContentLoading(true);
        const { status, data } = await getAllFc();
        if (status === 200 && data) {
          dispatch({
            type: GET_ALL_FC,
            payload: cleanData(data.customers),
          });
          setEntries(data.totalEntries);
          setContentLoading(false);
        } else {
          setContentLoading(false);
          toast({
            title: 'Failed to get data',
            status: 'error',
            ...toastFormat,
          });
        }
      } catch (error) {
        setContentLoading(false);
        toast({
          title: 'Failed to get data',
          status: 'error',
          ...toastFormat,
        });
      }
    })();
  }, []);

  const columns = [
    {
      header: 'fc number',
      key: 'fcNumber',
      type: 'BADGE',
    },
    {
      header: 'party name',
      key: 'partyName',
      type: 'TEXT',
    },
    {
      header: 'color',
      key: 'color',
      type: 'TAG',
    },
    {
      header: 'shade',
      key: 'cdShade',
      type: 'TEXT',
    },
    {
      header: 'Lab ref',
      key: 'labDipRef',
    },
    {
      header: 'client ref',
      key: 'clientRefNo',
    },
    {
      header: 'remarks',
      key: 'remarks',
      type: 'TOOLTIP',
    },
  ];
  const filters = [
    {
      name: 'Search',
      type: 'INPUT',
      queryParamKey: 'searchKey',
    },
  ];

  const handleQuery = async (searchData) => {
    try {
      setContentLoading(true);
      const { status, data } = await getAllFc(searchData);
      if (status === 200 && data) {
        setEntries(data.totalEntries);
        setContentLoading(false);
        dispatch({
          type: GET_ALL_FC,
          payload: cleanData(data.customers),
        });
      } else {
        setContentLoading(false);
        toast({
          title: 'Failed to get data',
          status: 'error',
          ...toastFormat,
        });
      }
    } catch (error) {
      setContentLoading(false);
      toast({
        title: 'Failed to get data',
        status: 'error',
        ...toastFormat,
      });
    }
  };
  const handleRefresh = async () => {
    try {
      setContentLoading(true);
      const { status, data } = await getAllFc();
      if (status === 200 && data) {
        dispatch({
          type: GET_ALL_FC,
          payload: cleanData(data.customers),
        });
        setEntries(data.totalEntries);
        setContentLoading(false);
      } else {
        setContentLoading(false);
        toast({
          title: 'Failed to get data',
          status: 'error',
          ...toastFormat,
        });
      }
    } catch (error) {
      setContentLoading(false);
      toast({
        title: 'Failed to get data',
        status: 'error',
        ...toastFormat,
      });
    }
  };

  return (
    <Box>
      <Flex my="30px" ml="30px" w="98%" align="center" justify="space-between">
        <Heading>Orders</Heading>
        <Button colorScheme="telegram" onClick={() => history.push(FC_INFO)} mr="30px">
          ADD ORDER
        </Button>
      </Flex>
      <Box ml="30px">
        <CustomTable2
          columns={columns}
          data={fcs}
          entries={entries}
          queryCallback={handleQuery}
          filters={filters}
          refreshCallback={handleRefresh}
          Actions={[UpdateInfo]}
          loading={contentLoading}
        />
      </Box>
    </Box>
  );
};

export default FCListView;
