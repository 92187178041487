import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  useToast,
  Heading,
  Flex,
  Text,
  Grid,
  Button,
  GridItem,
  HStack,
  IconButton,
  Progress,
  VStack,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Badge,
  useDisclosure,
  Center,
  Spinner,
  ModalBody,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Textarea,
} from '@chakra-ui/react';
import { approveBatch, changeBatchStatus, deleteBatch, getBatchByLotNumber } from '../../api/batch';
import { Tooltip } from '@chakra-ui/tooltip';
import {
  ChatIcon,
  ChevronDownIcon,
  DeleteIcon,
  DownloadIcon,
  SettingsIcon,
} from '@chakra-ui/icons';
import TaskTimeLine from '../../components/batch/TaskTimeLine';
import { useHistory } from 'react-router';
import { BATCHES, BATCH_TEMPLATE_UPDATE } from '../../constatnts/routerUrls';
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/menu';
import QRCode from 'react-qr-code';
import { RiBookletLine, RiCheckDoubleLine, RiPrinterCloudLine, RiQrCodeLine } from 'react-icons/ri';
import ReactToPrint from 'react-to-print';
import { useRef } from 'react';
import AlertBox from '../../components/common/AlertBox';
import BatchCard from '../../components/batch/BatchCard';
import dayjs from 'dayjs';
import BatchLogsDrawer from '../../components/batch/BatchLogs';
import MachineEditDrawer from '../../components/batch/MachineEditDrawer';
import combineDateTime from '../../utilities/combineDateTime';
import msToTime from '../../utilities/msToTime';
import toastFormat from '../../constatnts/toastFormat';
import { useSelector } from 'react-redux';
import BatchRemarksDrawer from '../../components/batch/BatchRemarksDrawer';
import { BatchDetailReport } from '../../components/batch/BatchDetailReport';
import ModalView from '../../components/common/ModalView';
import { useForm } from 'react-hook-form';
import {
  batchRejectionFormSchema,
  useYupValidationResolver,
} from '../../validators/form-validators';
const BatchQrpage = React.forwardRef((props, ref) => {
  const { batch } = props;

  return (
    <Flex
      w="100%"
      ref={ref}
      direction="column"
      align="start"
      justify="start"
      boxSizing="border-box"
      p="25px">
      <Heading mb="30px" fontSize="4xl">
        #LOT {batch.lotNumber}
      </Heading>
      <QRCode value={batch.id.toString()} size={130} />
    </Flex>
  );
});

BatchQrpage.displayName = 'Batch QR Code';

const BatchDetails = () => {
  const { lotNumber } = useParams();
  const [batch, setBatch] = useState(null);
  const toast = useToast();
  const history = useHistory();

  const batchReportRef = useRef();
  const batchScanRef = useRef();
  const batchCardRef = useRef();

  const [showStatusChangeAlert, setshowStatusChangeAlert] = useState(false);
  const [showBatchApproveAlert, setShowBatchApproveAlert] = useState(false);
  const [showBatchRemarkDrawer, setShowBatchRemarkDrawer] = useState(false);
  const [showBatchDeleteModal, setShowBatchDeleteModal] = useState(false);
  const [showBatchRejectionModal, setShowBatchRejectionModal] = useState(false);

  const [batchStatus, setBatchStatus] = useState(batch && batch.status);
  const [isLoading, setIsLoading] = useState(false);

  const {
    isOpen: isLogDrawerOpen,
    onClose: closeLogDrawer,
    onOpen: openLogDrawer,
  } = useDisclosure();

  const {
    isOpen: isMachineDrawerOpen,
    onClose: closeMachineDrawer,
    onOpen: openMachineDrawer,
  } = useDisclosure();

  const updateBatchStatus = async () => {
    if (batchStatus === batch.status) {
      toast({
        title: `Batch already in ${batchStatus}`,
        status: 'warning',
        ...toastFormat,
      });
      await fetchData();
    } else {
      const response = await changeBatchStatus(batchStatus, null, batch.lotNumber);
      if (response) {
        toast({
          title: `Batch status changed to ${batchStatus}`,
          status: 'success',
          ...toastFormat,
        });
        setBatch({ ...batch, status: batchStatus });
        await fetchData();
      } else {
        toast({
          title: `Failed update status to ${batchStatus}`,
          status: 'warning',
          ...toastFormat,
        });
      }
    }
  };

  const handleBatchDelete = async () => {
    const status = await deleteBatch(batch?.lotNumber);
    if (status === 200) {
      toast({
        ...toastFormat,
        status: 'success',
        title: 'Batch Deleted',
      });

      history.push(BATCHES);
    } else {
      toast({ ...toastFormat, status: 'error', title: 'Failed to delete batch' });
    }

    setShowBatchDeleteModal(false);
  };

  const handleBatchStatusSubmit = async () => {
    await updateBatchStatus();
    setshowStatusChangeAlert(false);
  };

  const calculateRemainingTime = (tasks) => {
    const modifiedTasks = [...tasks.DYING, ...tasks.FINISHING, ...tasks.QC];

    let totalTime = 0;

    modifiedTasks.map((task) => {
      if (task.status !== 'DONE') {
        const minute = task.standardTimeMinute ? task.standardTimeMinute / 60 : 0;
        const hour = task.standardTimeHour ? task.standardTimeHour : 0;

        totalTime = totalTime + hour + minute;
      }
    });

    const time = formatTime(totalTime);
    return time;
  };

  const fetchData = async () => {
    try {
      const data = await getBatchByLotNumber(lotNumber);
      if (data.status === 200) {
        setBatch(data.batch);
      }
    } catch (error) {
      toast({
        title: 'Failed to get batch info ',
        status: 'error',
        ...toastFormat,
      });
    }
  };

  useEffect(() => {
    (async () => {
      {
        setIsLoading(true);
        await fetchData();
        setIsLoading(false);
      }
    })();

    let interval = setInterval(async () => {
      setIsLoading(true);
      await fetchData();
      setIsLoading(false);
    }, 300000);
    return () => clearInterval(interval);
  }, []);

  const user = useSelector((state) => state.auth.user);

  const handleApproveBatch = async () => {
    try {
      const { status } = await approveBatch(lotNumber);
      if (status === 200) {
        toast({
          title: `Batch #${lotNumber} approved`,
          status: 'success',
          ...toastFormat,
        });
        history.push(BATCHES);
      }
    } catch (error) {
      toast({
        title: `Failed to approve - Batch #${lotNumber}`,
        status: 'error',
        ...toastFormat,
      });
    }
  };

  const handleBatchApproveSubmit = async () => {
    await handleApproveBatch();
    setShowBatchApproveAlert(false);
  };

  const getStatusColor = (batchStatus) => {
    switch (batchStatus) {
      case 'ON HOLD':
        return 'red';

      case 'IS PAUSED':
        return 'yellow';
      case 'REJECTED':
        return 'red';
      default:
        return 'green';
    }
  };

  const modifyTasks = () => [
    ...batch.batchProgressInfo.tasks.DYING,
    ...batch.batchProgressInfo.tasks.FINISHING,
    ...batch.batchProgressInfo.tasks.QC,
  ];

  const calculateStandardTime = (hour, min) => {
    const tempHour = hour ? hour : 0;
    const tempMin = min ? min / 60 : 0;

    let time = tempHour + tempMin;
    return time;
  };

  const hasDelayed = () => {
    const tasks = modifyTasks();

    let totalDelay = tasks.reduce((prValue, task) => {
      if (task.status === 'DONE') {
        const complete = new Date(task.completeTime);
        const start = new Date(task.startTime);

        const hour = task.standardTimeHour ? task.standardTimeHour : 0;
        const minutes = task.standardTimeMinute ? task.standardTimeMinute / 60 : 0;
        const stdMs = (hour + minutes) * 3600000;

        const timeTook = complete - start;

        const delay = timeTook - stdMs;

        return prValue + delay;
      } else {
        return prValue;
      }
    }, 0);

    const batchLoadingTime = new Date(
      batch.loadDate.substring(0, 4),
      batch.loadDate.substring(5, 7) - 1,
      batch.loadDate.substring(8, 10),
      batch.loadTime.substring(0, 2),
      batch.loadTime.substring(3, 5),
    );
    const actualLoadingTime = tasks[0].startTime;

    if (actualLoadingTime > batchLoadingTime) {
      totalDelay -= parseInt(actualLoadingTime - batchLoadingTime);
    }
    if (totalDelay > 0) {
      return {
        isDelayed: true,
        duration: totalDelay,
      };
    } else {
      return {
        isDelayed: false,
        duration: totalDelay,
      };
    }
  };

  const hasComptedEarly = () => {
    const tasks = modifyTasks();
    let totalDelay = 0;

    tasks.map((task) => {
      if (task.status === 'DONE') {
        const completeTime = new Date(task.completeTime);

        const shouldHaveCompleted = new Date(task.startTime);

        const hour = task.standardTimeHour ? task.standardTimeHour : 0;
        const minutes = task.standardTimeMinute ? task.standardTimeMinute : 0;

        shouldHaveCompleted.setHours(shouldHaveCompleted.getHours() + hour);
        shouldHaveCompleted.setMinutes(shouldHaveCompleted.getMinutes() + minutes);

        if (completeTime < shouldHaveCompleted) {
          totalDelay = totalDelay + parseInt(shouldHaveCompleted - completeTime);
        }
      }
    });
    const batchLoadingTime = new Date(
      batch.loadDate.substring(0, 4),
      batch.loadDate.substring(5, 7) - 1,
      batch.loadDate.substring(8, 10),
      batch.loadTime.substring(0, 2),
      batch.loadTime.substring(3, 5),
    );
    const actualLoadingTime = tasks[0].startTime;

    if (actualLoadingTime < batchLoadingTime) {
      totalDelay -= parseInt(batchLoadingTime - actualLoadingTime);
    }

    if (totalDelay > 0) {
      return {
        isDelayed: true,
        duration: totalDelay,
      };
    } else {
      return {
        isDelayed: false,
        duration: totalDelay,
      };
    }
  };

  const formatTime = (totalTime) => {
    if (totalTime < 1) {
      return Math.ceil(totalTime * 60) + ' mins';
    } else {
      const minutes = totalTime - Math.floor(totalTime);
      if (minutes > 0) {
        return (totalTime - minutes).toString() + ' hour ' + Math.ceil(minutes * 60) + ' mins ';
      } else {
        return totalTime.toString() + ' hour';
      }
    }
  };

  const getActualLoadingTime = (tasks) => {
    const firstTask = tasks.DYING[0];
    if (firstTask.status === 'RUNNING' || firstTask.status === 'DONE') {
      const startTime = firstTask.startTime;
      return dayjs(startTime).format('DD/MMM/YYYY - hh:mm A ');
    } else {
      return 'Batch not started yet';
    }
  };

  const calculateTotalStandardTime = () => {
    const tasks = modifyTasks();
    let totalTime = 0;

    tasks.map((task) => {
      totalTime = totalTime + calculateStandardTime(task.standardTimeHour, task.standardTimeMinute);
    });

    const time = formatTime(totalTime);
    return time;
  };

  const calculateEstDuration = () => {
    let totalTime = 0;

    const tasks = modifyTasks();
    const { duration: delay } = hasDelayed();
    const { duration: early } = hasComptedEarly();

    tasks.map((task) => {
      totalTime = totalTime + calculateStandardTime(task.standardTimeHour, task.standardTimeMinute);
    });

    totalTime += delay / (1000 * 60 * 60);
    totalTime -= early / (1000 * 60 * 60);

    const time = formatTime(totalTime);
    return time;
  };

  const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date);
  };

  const calculateEstimatedFinishTime = () => {
    let totalTime = 0;
    const tasks = modifyTasks();
    const { duration: delay } = hasDelayed();
    const { duration: eraly } = hasComptedEarly();

    tasks.map((task) => {
      if (task.status != 'DONE') {
        totalTime =
          totalTime + calculateStandardTime(task.standardTimeHour, task.standardTimeMinute);
      }
    });

    totalTime += delay / (1000 * 60 * 60);
    totalTime -= eraly / (1000 * 60 * 60);

    const hour = Math.floor(totalTime);
    const min = totalTime - Math.floor(totalTime);

    const date = new Date();

    date.setHours(date.getHours() + hour);
    date.setMinutes(date.getMinutes() + min * 60);

    if (isValidDate(date)) {
      return dayjs(date).format('DD/MMM/YYYY - hh:mm A');
    } else {
      return 'Not started';
    }
  };

  const getCurrentTask = () => {
    const tasks = batch.batchProgressInfo.tasks;
    const batchPhase = batch.batchProgressInfo.batchPhase;
    const currentTaskUid = batch.batchProgressInfo.currentTaskIndex;
    return tasks[batchPhase].find((task) => task.uid === currentTaskUid);
  };

  const getStdTime = (phase) => {
    const tasks = batch.batchProgressInfo.tasks[phase];

    const totalStdTime = tasks.reduce((prValue, task) => {
      const hour = task.standardTimeHour ? task.standardTimeHour : 0;
      const minute = task.standardTimeMinute ? task.standardTimeMinute : 0;

      return prValue + hour + minute / 60;
    }, 0);

    return formatTime(totalStdTime);
  };

  const getActualTime = (phase) => {
    const tasks = batch.batchProgressInfo.tasks[phase];

    const allDoneTask = tasks.filter((item) => item.status === 'DONE');

    if (tasks.length !== allDoneTask.length) {
      return '-';
    } else {
      const actualTime = allDoneTask.reduce((prValue, task) => {
        const startTime = new Date(task.startTime);
        const endTime = new Date(task.completeTime);

        const timeInMs = endTime - startTime;

        const timeInHour = timeInMs / (60 * 60 * 1000);

        return prValue + timeInHour;
      }, 0);

      return formatTime(actualTime);
    }
  };

  const getAdditionalTime = (phase) => {
    const tasks = batch.batchProgressInfo.tasks[phase];

    const allDoneTask = tasks.filter((item) => item.status === 'DONE');

    if (tasks?.length !== allDoneTask?.length) {
      return '-';
    } else {
      const time = allDoneTask.reduce((prValue, task) => {
        const taskStartTime = new Date(task.startTime);
        const taskCompleteTime = new Date(task.completeTime);

        const timeTaken = (taskCompleteTime - taskStartTime) / (60 * 60 * 1000);

        const hour = task.standardTimeHour ? task.standardTimeHour : 0;
        const minute = task.standardTimeMinute ? task.standardTimeMinute : 0;

        const stdTime = hour + minute / 60;

        return prValue + timeTaken - stdTime;
      }, 0);
      return formatTime(time);
    }
  };

  const resolver = useYupValidationResolver(batchRejectionFormSchema);
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm({ resolver });

  const BatchRejectionForm = () => {
    const handleRejection = async (data) => {
      if (batchStatus === batch.status) {
        toast({
          title: `Batch already in ${batchStatus}`,
          status: 'warning',
          ...toastFormat,
        });
        await fetchData();
      } else {
        const response = await changeBatchStatus(
          batchStatus,
          data.batchRejectionRemark,
          batch.lotNumber,
        );
        if (response === 200) {
          toast({
            title: `Batch status changed to ${batchStatus}`,
            status: 'success',
            ...toastFormat,
          });
          setBatch({ ...batch, status: batchStatus });
          await fetchData();
        } else {
          toast({
            title: `Failed update status to ${batchStatus}`,
            status: 'warning',
            ...toastFormat,
          });
        }
      }
      setShowBatchRejectionModal(false);
    };
    return (
      <ModalBody>
        <FormControl isRequired={true} isInvalid={errors?.batchRejectionRemark}>
          <FormLabel>Reason for rejection</FormLabel>
          <Textarea {...register('batchRejectionRemark')} />
          <FormErrorMessage>{errors?.batchRejectionRemark?.message}</FormErrorMessage>
        </FormControl>
        <HStack w="100%" justify="end" mt="15px">
          <Button colorScheme="gray" onClick={() => setShowBatchRejectionModal(false)}>
            Cancel
          </Button>
          <Button colorScheme="facebook" onClick={handleSubmit(handleRejection)}>
            Reject
          </Button>
        </HStack>
      </ModalBody>
    );
  };

  const disableUpdateButton = () => {
    if (batch.isApproved) {
      const { status, batchProgressInfo } = batch;
      const disbaleStatus = [
        'REJECTED',
        'WAITING FOR DELIVERY',
        'READY FOR DELIVERY',
        'DELIVERED',
        'STARTED',
      ];
      const tasks = [
        ...batchProgressInfo.tasks.DYING,
        ...batchProgressInfo.tasks.FINISHING,
        ...batchProgressInfo.tasks.QC,
      ];
      const firstTask = tasks[0];

      if (disbaleStatus.includes(status) || firstTask.status === 'DONE') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const disableBatchInQueueStatusOption = (status) => {
    const disbaleStatus = [
      'REJECTED',
      'WAITING FOR DELIVERY',
      'READY FOR DELIVERY',
      'DELIVERED',
      'STARTED',
      'WAITING FOR APPROVAL',
    ];

    if (disbaleStatus.includes(batch.status) || batch.status === status) {
      return true;
    } else {
      return false;
    }
  };

  const disableBatchStatusOption = (status) => {
    const disbaleStatus = ['REJECTED'];

    if (disbaleStatus.includes(batch.status) || batch.status === status) {
      return true;
    } else {
      return false;
    }
  };
  if (isLoading) {
    return (
      <Center w="100%" h="100vh">
        <Spinner size="lg" />
      </Center>
    );
  } else {
    return (
      <>
        {/* Batch status changed to rejected */}
        <ModalView
          isOpen={showBatchRejectionModal}
          onClose={() => setShowBatchRejectionModal(false)}
          modalTitle="Reject batch"
          Form={BatchRejectionForm}
        />
        {/* Batch Status change alert */}
        <AlertBox
          title="Change batch status"
          subTitle="Are you sure you want to change batch status"
          onClose={() => {
            setshowStatusChangeAlert(false);
            setBatchStatus(batch.status);
          }}
          onSubmit={handleBatchStatusSubmit}
          show={showStatusChangeAlert}
        />
        {/* Batch delete alert */}
        <AlertBox
          title="Delete this batch?"
          subTitle="Are you sure you want to delete this batch"
          onClose={() => {
            setShowBatchDeleteModal(false);
          }}
          onSubmit={handleBatchDelete}
          show={showBatchDeleteModal}
        />
        {/* Batch approve flow alert */}
        <AlertBox
          title="Approve Batch"
          subTitle="Are you sure you want to approve batch"
          show={showBatchApproveAlert}
          onClose={() => setShowBatchApproveAlert(false)}
          onSubmit={handleBatchApproveSubmit}
        />
        <BatchLogsDrawer
          logs={batch?.batchLogs}
          users={[
            batch?.approverInfo,
            batch?.creatorInfo,
            batch?.dyeingOperatorInfo,
            batch?.finishingOperatorInfo,
            batch?.qcOperatorInfo,
          ]}
          isOpen={isLogDrawerOpen}
          onClose={closeLogDrawer}
        />

        <MachineEditDrawer
          machine={batch?.machineInfo}
          isDrawerOpen={isMachineDrawerOpen}
          closeDrawer={closeMachineDrawer}
          fabricWeight={batch?.totalFabricWeight}
          batchId={batch?.id}
          refresh={fetchData}
        />

        <BatchRemarksDrawer
          show={showBatchRemarkDrawer}
          onClose={() => setShowBatchRemarkDrawer(false)}
          refresh={fetchData}
          batch={batch}
        />

        {batch?.status === 'ON HOLD' && (
          <Box w="100%" padding="15px" bgColor="red.500" boxSizing="border-box">
            <Text textAlign="center" color="white" fontWeight="bold">
              {getCurrentTask().reasonForHalt} Please resolve as soon as possible.
            </Text>
          </Box>
        )}
        {!batch?.isApproved && (
          <Box w="100%" padding="5px" bgColor="yellow.500" boxSizing="border-box">
            <Text textAlign="center" color="white" fontWeight="bold">
              This batch requires approval.
            </Text>
          </Box>
        )}

        {batch?.status === 'IS PAUSED' && (
          <Box w="100%" padding="5px" bgColor="yellow.500" boxSizing="border-box">
            <Text textAlign="center" color="white" fontWeight="bold">
              This batch is currently paused
            </Text>
          </Box>
        )}
        {batch?.status === 'REJECTED' && (
          <Box w="100%" padding="5px" bgColor="red.500" boxSizing="border-box">
            <Text textAlign="center" color="white" fontWeight="bold">
              This batch has been rejected. -&gt; {batch?.batchRejectionRemark}
            </Text>
          </Box>
        )}
        {batch && (
          <Box
            bgColor="#F7FAFC"
            w="100%"
            __css={{
              zoom: '90%',
            }}>
            <Grid
              gridTemplateColumns={{
                base: '1fr',
                md: '1fr 1fr',
                lg: 'repeat(3, 1fr)',
              }}
              gap="14px"
              w="99%"
              mx="auto"
              padding="15px 0">
              <GridItem
                colSpan={1}
                boxSizing="border-box"
                padding="20px 25px"
                bgColor="white"
                borderRadius={10}
                border="1px solid rgba(0,0,0,0.2)">
                <HStack justify="space-between">
                  <HStack align="flex-end" spacing="20px">
                    <Heading fontSize="24px" color="#272739">
                      Batch #{batch.lotNumber.split('-')[1]}
                    </Heading>
                    <Text fontSize="14px" fontWeight="600" color="#718096">
                      {batch.progress} % Complete
                    </Text>
                  </HStack>
                  <HStack>
                    <Tooltip label="Print Batch Report" placement="top">
                      <Box>
                        <ReactToPrint
                          trigger={() => (
                            <IconButton
                              onClick={() => setShowBatchRemarkDrawer(true)}
                              icon={<RiPrinterCloudLine />}
                              colorScheme="facebook"
                              size="sm"
                            />
                          )}
                          content={() => batchReportRef.current}
                        />
                      </Box>
                    </Tooltip>
                    <Tooltip label="Add batch remarks" placement="top">
                      <IconButton
                        onClick={() => setShowBatchRemarkDrawer(true)}
                        icon={<ChatIcon />}
                        colorScheme="facebook"
                        size="sm"
                      />
                    </Tooltip>
                    <Tooltip placement="top" label="Generate QR Code">
                      <Box>
                        <ReactToPrint
                          trigger={() => (
                            <IconButton
                              icon={<RiQrCodeLine size={18} />}
                              size="sm"
                              colorScheme="facebook"
                            />
                          )}
                          content={() => batchScanRef.current}
                        />
                      </Box>
                    </Tooltip>

                    {batch.isApproved && (
                      <Tooltip placement="top" label="Print Batch card">
                        <Box>
                          <ReactToPrint
                            trigger={() => (
                              <IconButton
                                icon={<DownloadIcon />}
                                size="sm"
                                colorScheme="facebook"
                              />
                            )}
                            content={() => batchCardRef.current}
                          />
                        </Box>
                      </Tooltip>
                    )}
                    {!batch.isApproved && (
                      <Tooltip placement="top" label="Approve Batch">
                        <IconButton
                          icon={<RiCheckDoubleLine size={18} />}
                          size="sm"
                          disabled={
                            user.roles.includes('ADMIN') || user.roles.includes('MANAGER')
                              ? false
                              : true
                          }
                          colorScheme="green"
                          onClick={() => setShowBatchApproveAlert(true)}
                        />
                      </Tooltip>
                    )}
                    {!batch.isApproved && (
                      <Tooltip placement="top" label="Delete Batch">
                        <IconButton
                          icon={<DeleteIcon size={18} />}
                          size="sm"
                          disabled={
                            user.roles.includes('ADMIN') || user.roles.includes('MANAGER')
                              ? false
                              : true
                          }
                          colorScheme="red"
                          onClick={() => setShowBatchDeleteModal(true)}
                        />
                      </Tooltip>
                    )}
                  </HStack>
                </HStack>
                <Progress value={batch.progress} size="sm" rounded="md" mt="15px" />
                <Text mt="10px" fontSize="sm" color="gray.500">
                  Scheduled Loading Time: {combineDateTime(batch.loadDate, batch.loadTime)}
                </Text>
                <Text mt="10px" fontSize="sm" color="gray.500">
                  Actual Loading Time: {getActualLoadingTime(batch.batchProgressInfo.tasks)}
                </Text>
                <Flex mt="10px" justify="space-between">
                  <VStack align="flex-start">
                    <Text color="gray.600" fontSize="14px" fontWeight="bold">
                      FC
                    </Text>
                    <Text color="gray.700" fontSize="16px" fontWeight="bold">
                      {batch.customerInfo.fcNumber}
                    </Text>
                  </VStack>
                  <VStack align="flex-start">
                    <Text color="gray.600" fontSize="14px" fontWeight="bold">
                      Process Type
                    </Text>
                    <Text
                      color="gray.700"
                      fontSize="16px"
                      fontWeight="bold"
                      textTransform="capitalize">
                      {batch.processType}
                    </Text>
                  </VStack>
                  <VStack align="flex-start">
                    <Text color="gray.600" fontSize="14px" fontWeight="bold">
                      Phase
                    </Text>
                    <Text
                      color="gray.700"
                      fontSize="16px"
                      fontWeight="bold"
                      textTransform="capitalize">
                      {batch.batchProgressInfo.batchPhase === 'DYING'
                        ? 'DYEING'
                        : batch.batchProgressInfo.batchPhase}
                    </Text>
                  </VStack>

                  <VStack align="flex-start">
                    <Text color="gray.600" fontSize="14px" fontWeight="bold">
                      Current Task
                    </Text>
                    <Text color="gray.700" fontSize="16px" fontWeight="bold">
                      {batch.batchProgressInfo.currentTaskName}
                    </Text>
                  </VStack>
                </Flex>

                <HStack mt="30px">
                  <Button
                    colorScheme="facebook"
                    w="100%"
                    rounded="md"
                    size="sm"
                    isDisabled={disableUpdateButton()}
                    onClick={() => {
                      history.push(BATCH_TEMPLATE_UPDATE + `/${lotNumber}`);
                    }}>
                    Update Batch
                  </Button>
                  {batch.isApproved && (
                    <Menu>
                      <Tooltip label={batch.status ? batch.status : 'UPDATE BATCH STATUS'}>
                        <MenuButton
                          w="100%"
                          colorScheme={getStatusColor(batch.status)}
                          rounded="md"
                          as={Button}
                          size="sm"
                          disabled={batch?.status === 'REJECTED'}
                          rightIcon={<ChevronDownIcon />}>
                          {batch.status ? batch.status : 'UPDATE BATCH STATUS'}
                        </MenuButton>
                      </Tooltip>
                      <MenuList zIndex={1000}>
                        <MenuItem
                          isDisabled={disableBatchStatusOption('ON HOLD')}
                          onClick={async () => {
                            setBatchStatus('ON HOLD');
                            setshowStatusChangeAlert(true);
                          }}>
                          On Hold
                        </MenuItem>
                        <MenuItem
                          isDisabled={disableBatchStatusOption('IS PAUSED')}
                          onClick={async () => {
                            setBatchStatus('IS PAUSED');
                            setshowStatusChangeAlert(true);
                          }}>
                          Paused
                        </MenuItem>
                        <MenuItem
                          onClick={async () => {
                            setBatchStatus('IN QUEUE');
                            setshowStatusChangeAlert(true);
                          }}
                          isDisabled={disableBatchInQueueStatusOption('IN QUEUE')}>
                          In Queue
                        </MenuItem>
                        <MenuItem
                          isDisabled={disableBatchStatusOption('REJECTED')}
                          onClick={async () => {
                            setBatchStatus('REJECTED');
                            setShowBatchRejectionModal(true);
                          }}>
                          Rejected
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  )}
                </HStack>
              </GridItem>
              <GridItem
                colSpan={1}
                boxSizing="border-box"
                padding="20px 25px"
                bgColor="white"
                borderRadius={10}
                border="1px solid rgba(0,0,0,0.2)">
                <Flex w="100%" justify="space-between">
                  <Heading fontSize="24px" color="#272739">
                    Batch Information
                  </Heading>
                  <HStack>
                    <Tooltip label="Open logs" placement="top">
                      <IconButton
                        onClick={openLogDrawer}
                        icon={<RiBookletLine size={18} />}
                        size="sm"
                        ml="25px"
                        colorScheme="facebook"
                      />
                    </Tooltip>
                    <Tooltip label="Edit machine infomation" placement="top">
                      <IconButton
                        onClick={openMachineDrawer}
                        icon={<SettingsIcon />}
                        size="sm"
                        ml="15px"
                        colorScheme="facebook"
                      />
                    </Tooltip>
                  </HStack>
                </Flex>
                <Flex
                  mt="20px"
                  direction={{
                    base: 'column',
                    md: 'column',
                    lg: 'row',
                  }}
                  align="flex-start"
                  justify="space-between"
                  w="100%">
                  <Grid
                    gridTemplateColumns={{
                      base: '1fr 1fr 1fr',
                      md: '1fr 1fr 1fr',
                      lg: 'repeat(4, 1fr)',
                    }}
                    w="full">
                    <GridItem>
                      <VStack align="flex-start">
                        <Text color="gray.600" fontSize="14px" fontWeight="">
                          Machine
                        </Text>
                        <Text color="gray.700" fontSize="16px" fontWeight="bold">
                          {batch.machineInfo.name}
                        </Text>
                      </VStack>
                    </GridItem>
                    <GridItem>
                      <VStack align="flex-start">
                        <Text color="gray.600" fontSize="14px" fontWeight="">
                          Finish Type
                        </Text>
                        <Text color="gray.700" fontSize="16px" fontWeight="bold">
                          {batch.finishingType}
                        </Text>
                      </VStack>
                    </GridItem>
                    <GridItem>
                      <VStack align="flex-start">
                        <Text color="gray.600" fontSize="14px" fontWeight="">
                          Weight Loaded
                        </Text>
                        <Text color="gray.700" fontSize="16px" fontWeight="bold">
                          {batch.totalFabricWeight} KG
                        </Text>
                      </VStack>
                    </GridItem>
                    <GridItem>
                      <VStack align="flex-start">
                        <Text color="gray.600" fontSize="14px" fontWeight="">
                          Machine Usage
                        </Text>
                        <Text color="gray.700" fontSize="16px" fontWeight="bold">
                          {Math.floor(
                            (batch.totalFabricWeight / batch.machineInfo.maxCapacity) * 100,
                          )}{' '}
                          %
                        </Text>
                      </VStack>
                    </GridItem>

                    <GridItem mt="10px">
                      <VStack align="flex-start">
                        <Text color="gray.600" fontSize="14px" fontWeight="">
                          Machine Status
                        </Text>
                        <Text color="gray.700" fontSize="14px" fontWeight="bold">
                          {batch.machineInfo.status}
                        </Text>
                      </VStack>
                    </GridItem>

                    <Tooltip label={batch.fabricInfo[0]?.color} placement="top">
                      <GridItem mt="10px">
                        <VStack align="flex-start">
                          <Text color="gray.600" fontSize="14px" fontWeight="">
                            Color
                          </Text>
                          <Text color="gray.700" fontSize="14px" fontWeight="bold">
                            {batch.fabricInfo[0]?.color.substring(0, 10)}...
                          </Text>
                        </VStack>
                      </GridItem>
                    </Tooltip>
                    <GridItem mt="10px">
                      <VStack align="flex-start">
                        <Text color="gray.600" fontSize="14px" fontWeight="">
                          Party
                        </Text>
                        <Tooltip label={batch.customerInfo.partyName} placement="top">
                          <Text color="gray.700" fontSize="14px" fontWeight="bold">
                            {batch.customerInfo.partyName.substring(0, 12)}...
                          </Text>
                        </Tooltip>
                      </VStack>
                    </GridItem>
                    <GridItem mt="10px">
                      <VStack align="flex-start">
                        <Text color="gray.600" fontSize="14px" fontWeight="">
                          Batch Type
                        </Text>
                        <Text color="gray.700" fontSize="14px" fontWeight="bold">
                          {batch.batchType}
                        </Text>
                      </VStack>
                    </GridItem>
                  </Grid>
                </Flex>
                <HStack spacing="10px" mt="15px">
                  <VStack
                    h={{ 1440: '85px', lg: '65px' }}
                    align="center"
                    border="1px solid rgba(0,0,0,0.2)"
                    padding="10px"
                    rounded="md">
                    <Badge>Std. Runtime</Badge>
                    <Text fontSize="sm" color="gray.700" fontWeight="bold">
                      {calculateTotalStandardTime()}
                    </Text>
                  </VStack>
                  <VStack
                    bgColor="red.100"
                    h={{ 1440: '85px', lg: '65px' }}
                    align="center"
                    border="1px solid rgba(0,0,0,0.2)"
                    padding="10px"
                    rounded="md">
                    <Badge colorScheme="red">Delay</Badge>
                    <Text fontSize="sm" color="gray.700" fontWeight="bold">
                      {hasDelayed().duration > 0 ? msToTime(hasDelayed().duration) : 0 + 'secs'}
                    </Text>
                  </VStack>
                  <VStack
                    h={{ 1440: '85px', lg: '65px' }}
                    align="center"
                    border="1px solid rgba(0,0,0,0.2)"
                    padding="10px"
                    rounded="md">
                    <Badge>Est. Runtime</Badge>
                    <Text fontSize="sm" color="gray.700" fontWeight="bold">
                      {calculateEstDuration()}
                    </Text>
                  </VStack>
                  <VStack
                    h={{ 1440: '85px', lg: '65px' }}
                    align="center"
                    border="1px solid rgba(0,0,0,0.2)"
                    padding="10px"
                    rounded="md">
                    <Badge>Time Left</Badge>
                    <Text fontSize="sm" color="gray.700" fontWeight="bold">
                      {calculateRemainingTime(batch.batchProgressInfo.tasks) === '0 minutes'
                        ? 'Batch Complete'
                        : calculateRemainingTime(batch.batchProgressInfo.tasks)}
                    </Text>
                  </VStack>
                </HStack>
              </GridItem>
              <GridItem
                colSpan={1}
                boxSizing="border-box"
                padding="20px 25px"
                bgColor="white"
                borderRadius={10}
                border="1px solid rgba(0,0,0,0.2)">
                <Heading fontSize="24px" color="#272739">
                  Batch Stats
                </Heading>
                <Table size="sm" my="15px" variant="fill">
                  <Thead>
                    <Tr>
                      <Th>Phase</Th>
                      <Th>Standard Time</Th>
                      <Th>Actual Time</Th>
                      <Th>Additional Time</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>Dyeing</Td>
                      <Td>{getStdTime('DYING')}</Td>
                      <Td>{getActualTime('DYING')}</Td>
                      <Td>{getAdditionalTime('DYING')}</Td>
                    </Tr>
                    <Tr>
                      <Td>Finishing</Td>
                      <Td>{getStdTime('FINISHING')}</Td>
                      <Td>{getActualTime('FINISHING')}</Td>
                      <Td>{getAdditionalTime('FINISHING')}</Td>
                    </Tr>
                    <Tr>
                      <Td>QC</Td>
                      <Td>{getStdTime('QC')}</Td>
                      <Td>{getActualTime('QC')}</Td>
                      <Td>{getAdditionalTime('QC')}</Td>
                    </Tr>
                  </Tbody>
                </Table>
                <VStack
                  h="65px"
                  align="center"
                  border="1px solid rgba(0,0,0,0.2)"
                  padding="10px"
                  rounded="md">
                  <Badge>Finish Time</Badge>
                  <Text fontSize="xs" color="gray.700">
                    {calculateEstimatedFinishTime()}
                  </Text>
                </VStack>
              </GridItem>
              <GridItem
                colSpan={3}
                boxSizing="border-box"
                padding="20px 25px"
                bgColor="white"
                borderRadius={10}
                border="1px solid rgba(0,0,0,0.2)">
                <Heading fontSize="24px" color="#272739">
                  Fabric Information
                </Heading>
                <Box
                  w="full"
                  h="auto"
                  maxH="200px"
                  overflow="auto"
                  css={{
                    '&::-webkit-scrollbar': {
                      width: '4px',
                    },
                    '&::-webkit-scrollbar-track': {
                      width: '6px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: '#f2f2f2',
                      borderRadius: '24px',
                    },
                  }}>
                  <Table size="sm" variant="striped" mt="15px">
                    <Thead>
                      <Tr>
                        <Th>#</Th>
                        <Th>Fabric Type</Th>
                        <Th>Shade Group</Th>
                        <Th>Shade (%)</Th>
                        <Th>Color</Th>
                        <Th>DIA</Th>
                        <Th>GSM</Th>
                        <Th>Roll</Th>
                        <Th>Used (kg)</Th>
                        <Th>Lab dip</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {batch.fabricInfo.map((fabric, index) => (
                        <Tr key={JSON.stringify(fabric)}>
                          <Td>{index + 1}</Td>
                          <Td>{fabric.fabricType}</Td>
                          <Td>{fabric.shadeGroup}</Td>
                          <Td>{fabric.shade}</Td>
                          <Td>{fabric.color}</Td>
                          <Td>{fabric.dia}</Td>
                          <Td>{fabric.gsm}</Td>
                          <Td>{fabric.roll}</Td>
                          <Td>
                            {fabric.usedQty} / ({fabric.quantity})
                          </Td>
                          <Td>{fabric.labDipRef}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Box>
              </GridItem>
            </Grid>
            <Box display="none">
              <BatchQrpage batch={batch} ref={batchScanRef} />
              <BatchCard batch={batch} ref={batchCardRef} />
              <BatchDetailReport ref={batchReportRef} batch={batch} />
            </Box>
          </Box>
        )}

        {batch && batch.batchProgressInfo.tasks && (
          <>
            <TaskTimeLine
              batchProgress={batch.batchProgressInfo}
              batch={batch}
              refresh={fetchData}
            />
          </>
        )}
      </>
    );
  }
};

export default BatchDetails;
