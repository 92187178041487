import { Button, HStack, Stat, StatArrow, StatHelpText, StatNumber, Text } from '@chakra-ui/react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { REPORT_GENERATION } from '../../constatnts/routerUrls';

const DeliveredVsReceivedFooter = ({ data }) => {
  const history = useHistory();
  return (
    <HStack w="100%" justify="space-between" mt="15px">
      <Stat>
        <StatNumber fontSize="lg">
          Delivered: <br />
          {Math.abs(data?.delivery?.dif)} Kg{' '}
          <Text fontSize="sm" fontWeight="bold" color="gray.600">
            (This {data?.range})
          </Text>
        </StatNumber>
        <StatHelpText>
          <StatArrow type={data?.delivery?.status} />
          {data?.delivery?.percent} %
        </StatHelpText>
      </Stat>
      <Stat>
        <StatNumber fontSize="lg">
          Processing: <br />
          {Math.abs(data?.processing?.dif)} Kg{' '}
          <Text fontSize="sm" fontWeight="bold" color="gray.600">
            (This {data?.range})
          </Text>
        </StatNumber>
        <StatHelpText>
          <StatArrow type={data?.processing?.status} />
          {data?.processing?.percent} %
        </StatHelpText>
      </Stat>
      <Button
        size="sm"
        colorScheme="facebook"
        onClick={() => history.push(REPORT_GENERATION, { type: 'client-production' })}>
        Generate Report
      </Button>
    </HStack>
  );
};

export default DeliveredVsReceivedFooter;
