import ReportGeneration from '../components/reports/ReportGeneration';
import OrderDetail from '../pages/account-delivery/OrderDetail';
import Batch from '../pages/bathces/Batch';
import BatchCreation from '../pages/bathces/BatchCreation';
import BatchDetails from '../pages/bathces/BatchDetails';
import BatchTemplatingFlow from '../pages/bathces/BatchTemplatingFlow';
import FcCreationForm from '../pages/bathces/FcCreationForm';
import FCListView from '../pages/bathces/FcListView';
import RecipeCreationForm from '../pages/bathces/RecipeCreationForm';
import ChemicalsDyes from '../pages/chemical-dye/ChemicalsDyes';
import ClientDetails from '../pages/clients/ClientDetails';
import ClientList from '../pages/clients/ClientList';
import Dashboard from '../pages/Dashboard';
import MachineDetails from '../pages/machines/MachineDetails';
import Machines from '../pages/machines/Machines';
import AddNewOrder from '../pages/orders/AddNewOrder';
import OrderList from '../pages/orders/OrderList';
import UpdateOrder from '../pages/orders/UpdateOrder';
import BatchOptimizationReport from '../pages/reports/BatchOptimizationReport';
import DailyPlanningReport from '../pages/reports/DailyPlanningReport';
import DeliveryReport from '../pages/reports/DeliveryReport';
import ProductionReport from '../pages/reports/ProductionReport';
import Reports from '../pages/reports/Reports';
import SignIn from '../pages/SignIn';
import Users from '../pages/users/Users';

export const ROOT_URL = '/';
export const LOGIN_ROUTE = '/sign-in';
export const DASHBOARD_ROUTE = '/dashboard';

export const MACHINES = '/machine';
export const MACHINE_DETAILS = '/machine/';

export const DYES = '/dye';
export const CHEMICALS_DYES = '/chemicals dyes';

export const USERS = '/users';

export const BATCHES = '/batch';
export const FC_INFO = '/batch/order/new';
export const FC_LIST = '/batch/order';
export const NEW_BATCH = '/batch/new';
export const BATCH_TEMPLATE_UPDATE = '/batch/update';
export const BATCH_SET_RECIPE = '/batch/recipe';

export const ACCOUNT_AND_DELIVERY = '/accounts';
export const ORDER_DETAILS = '/accounts/details';

export const ORDER_INDEX = '/orders';
export const NEW_ORDER = '/orders/new';
export const UPDATE_ORDER = '/orders/update';

export const CLIENTS = '/clients';

export const REPORTS = '/reports';
export const REPORT_GENERATION = '/reports/generation';
export const DAILY_PLANNING_REPORT = '/reports/daily-planning';
export const PRODUCTION_REPORT = '/reports/production/:startDate/:endDate';
export const BATCH_REPORT = '/reports/batch';
export const DELIVERY_REPORT = '/reports/delivery';

export const routes = [
  {
    path: LOGIN_ROUTE,
    comp: SignIn,
    label: 'SIGN IN',
    protected: false,
  },
  {
    path: DASHBOARD_ROUTE,
    comp: Dashboard,
    label: 'Dashboard',
    protected: true,
  },
  {
    path: MACHINES,
    comp: Machines,
    protected: true,
    label: 'Machine',
  },
  {
    path: MACHINE_DETAILS + ':name',
    comp: MachineDetails,
    protected: true,
    label: 'Machine Details',
  },
  {
    path: CHEMICALS_DYES,
    comp: ChemicalsDyes,
    protected: true,
    label: 'Chemicals',
  },
  {
    path: USERS,
    comp: Users,
    protected: true,
    label: 'Users',
  },
  {
    path: BATCHES,
    comp: Batch,
    protected: true,
    label: 'Batches',
  },
  {
    path: FC_LIST,
    comp: FCListView,
    protected: true,
    label: 'Fc',
  },
  {
    path: FC_INFO,
    comp: FcCreationForm,
    protected: true,
    label: 'Add New Fc',
  },
  {
    path: NEW_BATCH,
    comp: BatchCreation,
    protected: true,
    label: 'ADD NEW BATCH',
  },
  {
    path: BATCH_TEMPLATE_UPDATE + '/:lotNumber',
    comp: BatchTemplatingFlow,
    protected: true,
    label: 'UPDATE BATCH TEMPLATE',
  },
  {
    path: BATCH_SET_RECIPE + '/:lotNumber',
    comp: RecipeCreationForm,
    protected: true,
    label: 'SET RECIPE',
  },
  {
    path: BATCHES + '/:lotNumber',
    comp: BatchDetails,
    protected: true,
    label: 'Batch Details',
  },

  {
    path: ORDER_DETAILS + '/:fcNumber',
    comp: OrderDetail,
    protected: true,
    label: 'Order Detail',
  },

  {
    path: ORDER_INDEX,
    comp: OrderList,
    protected: true,
    label: 'Orders',
  },

  {
    path: NEW_ORDER,
    comp: AddNewOrder,
    protected: true,
    label: 'New Orders',
  },
  {
    path: CLIENTS,
    comp: ClientList,
    protected: true,
    label: 'Clients',
  },

  {
    path: CLIENTS + '/:clientUid',
    comp: ClientDetails,
    protected: true,
    label: 'Client Details',
  },
  {
    path: REPORTS,
    comp: Reports,
    protected: true,
    label: 'Reports',
  },
  {
    path: REPORT_GENERATION,
    comp: ReportGeneration,
    protected: true,
    label: 'Report Generation',
  },
  {
    path: UPDATE_ORDER + '/:fcNumber',
    comp: UpdateOrder,
    protected: true,
    label: 'Update order',
  },

  {
    path: DAILY_PLANNING_REPORT + '/:date',
    comp: DailyPlanningReport,
    protected: true,
    label: 'Daily Planning Report',
  },
  {
    path: PRODUCTION_REPORT,
    comp: ProductionReport,
    protected: true,
    label: 'Production Report',
  },
  {
    path: BATCH_REPORT + '/:date',
    comp: BatchOptimizationReport,
    protected: true,
    label: 'Batch Report',
  },
  {
    path: DELIVERY_REPORT,
    comp: DeliveryReport,
    protected: true,
    label: 'Delivery Report',
  },
];
