import { ExternalLinkIcon, InfoIcon } from '@chakra-ui/icons';
import { RiListUnordered } from 'react-icons/ri';
import {
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useDisclosure,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { currentDayBatches } from '../../api/stats';
import { DASHBOARD_REFRESH_TIME } from '../../constatnts/time';
import Loader from '../common/Loader';
import Card from './Card';
import FullScreenBatchCard from './FullScreenBatchCard';
import FullScreenListView from './FullScreenListView';
import sortTypes from '../../constatnts/sortTypes';

const BatchCards = () => {
  const [inDyeingBatches, setInDyeingBatches] = useState([]);
  const [inFinishingBatches, setInFinishingBatches] = useState([]);
  const [inQCBatches, setInQCBatches] = useState([]);
  const [haltedBatches, sethaldedBatches] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    const data = await currentDayBatches();
    setInDyeingBatches(data?.inDyeingBatches);
    sortData(data?.inDyeingBatches, sortTypes[0].value, setInDyeingBatches);
    setInFinishingBatches(data?.inFinishingBatches);
    sortData(data?.inFinishingBatches, sortTypes[0].value, setInFinishingBatches);
    setInQCBatches(data?.inQCBatches);
    sortData(data?.inQCBatches, sortTypes[0].value, setInQCBatches);
    sethaldedBatches(data?.haltedBatches);
    sortData(data?.haltedBatches, sortTypes[0].value, sethaldedBatches);

    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();

    const interval = setInterval(async () => await fetchData(), DASHBOARD_REFRESH_TIME);

    return () => clearInterval(interval);
  }, []);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isListViewOpen,
    onOpen: onListViewOpen,
    onClose: onListViewClose,
  } = useDisclosure();

  const [mac] = useMediaQuery('(max-width: 1440px)');

  const [selectedDyeingSortType, setSelectedDyeingSortType] = useState(sortTypes[0]);
  const [selectedFinishingSortType, setSelectedFinishingSortType] = useState(sortTypes[0]);
  const [selectedQCSortType, setSelectedQCSortType] = useState(sortTypes[0]);
  const [selectedHaltedSortType, setSelectedHaltedSortType] = useState(sortTypes[0]);

  const sortByStartTime = (data, updateCallback) => {
    const sortedData = data?.sort((prev, next) => {
      const prevDate = new Date(prev.startTime);
      const nextDate = new Date(next.startTime);

      return nextDate - prevDate;
    });
    updateCallback(sortedData);
  };
  const sortByDyeingTime = (data, updateCallback) => {
    const sortedData = data?.sort((prev, next) => {
      const prevDate = new Date(prev.currentTask.startTime);
      const nextDate = new Date(next.currentTask.startTime);

      return nextDate - prevDate;
    });
    updateCallback(sortedData);
  };
  const sortByFinishingTime = (data, updateCallback) => {
    const sortedData = data?.sort((prev, next) => {
      const prevDate = new Date(prev.currentTask.startTime);
      const nextDate = new Date(next.currentTask.startTime);

      return nextDate - prevDate;
    });
    updateCallback(sortedData);
  };
  const sortByMachineName = (data, updateCallback) => {
    const sortedData = data?.sort((prev, next) => {
      const prevDate = prev.machineName;
      const nextDate = next.machineName;

      return prevDate.localeCompare(nextDate);
    });
    updateCallback(sortedData);
  };

  const sortData = (data, sortType, updateCallback) => {
    switch (sortType) {
      case 'startTime':
        sortByStartTime(data, updateCallback);
        break;
      case 'dyeingTime':
        sortByDyeingTime(data, updateCallback);
        break;
      case 'finishingTime':
        sortByFinishingTime(data, updateCallback);
        break;
      case 'machineName':
        sortByMachineName(data, updateCallback);
        break;
    }
  };

  return (
    <>
      {inDyeingBatches && inFinishingBatches && inQCBatches && haltedBatches && (
        <FullScreenListView
          show={isListViewOpen}
          close={onListViewClose}
          batches={[...inDyeingBatches, ...inFinishingBatches, ...inQCBatches, ...haltedBatches]}
        />
      )}
      <FullScreenBatchCard
        show={isOpen}
        close={onClose}
        batches={{
          inDyeingBatches,
          inFinishingBatches,
          inQCBatches,
          haltedBatches,
        }}
      />
      <GridItem colSpan={7}>
        <HStack spacing={3}>
          <Heading fontSize="2xl">Live Tracking</Heading>
          <Tooltip label="full screen">
            <IconButton
              icon={<ExternalLinkIcon />}
              onClick={onOpen}
              size="sm"
              colorScheme="facebook"
            />
          </Tooltip>
          <Tooltip label="full screen list view">
            <IconButton
              icon={<RiListUnordered />}
              onClick={onListViewOpen}
              size="sm"
              colorScheme="facebook"
            />
          </Tooltip>
        </HStack>
        <Grid
          gridTemplateColumns={{
            base: '1fr',
            md: '1fr',
            xl: mac ? 'repeat(3, 1fr)' : 'repeat(4, 1fr)',
          }}
          mt="15px"
          gap="20px">
          <GridItem boxSizing="border-box">
            <HStack align="center" justify="space-between" mb={2}>
              <Heading fontSize="xl" mb="20px">
                Dyeing
              </Heading>
              <Menu>
                <MenuButton as={Button}>Sort By - {selectedDyeingSortType.label}</MenuButton>
                <MenuList>
                  {sortTypes.map((type) => {
                    if (type.value !== 'finishingTime') {
                      return (
                        <MenuItem
                          key={type.value}
                          onClick={() => {
                            sortData(inDyeingBatches, type.value, setInDyeingBatches);
                            setSelectedDyeingSortType(type);
                          }}>
                          {type.label}
                        </MenuItem>
                      );
                    } else {
                      return null;
                    }
                  })}
                </MenuList>
              </Menu>
            </HStack>
            {isLoading ? (
              <Loader />
            ) : (
              <VStack>
                {inDyeingBatches?.length > 0 ? (
                  inDyeingBatches.map((item) => <Card batch={item} key={item.lotNumber} />)
                ) : (
                  <Flex w="300px" h="300px" justify="center" align="center" direction="column">
                    <InfoIcon w={10} h={10} />
                    <Text mt="10px" fontSize="lg" fontWeight="bold">
                      No batch
                    </Text>
                  </Flex>
                )}
              </VStack>
            )}
          </GridItem>
          <GridItem borderLeft="1px solid rgba(0,0,0,0.2)" pl="15px" boxSizing="border-box">
            <HStack align="center" justify="space-between" mb={2}>
              <Heading fontSize="xl" mb="20px">
                Finishing
              </Heading>
              <Menu>
                <MenuButton as={Button}>Sort By - {selectedFinishingSortType.label}</MenuButton>
                <MenuList>
                  {sortTypes.map((type) => {
                    if (type.value !== 'dyeingTime') {
                      return (
                        <MenuItem
                          key={type.value}
                          onClick={() => {
                            sortData(inFinishingBatches, type.value, setInFinishingBatches);
                            setSelectedFinishingSortType(type);
                          }}>
                          {type.label}
                        </MenuItem>
                      );
                    } else {
                      return null;
                    }
                  })}
                </MenuList>
              </Menu>
            </HStack>
            {isLoading ? (
              <Loader />
            ) : (
              <VStack>
                {inFinishingBatches?.length > 0 ? (
                  inFinishingBatches.map((item) => <Card batch={item} key={item.lotNumber} />)
                ) : (
                  <Flex w="300px" h="300px" justify="center" align="center" direction="column">
                    <InfoIcon w={10} h={10} />
                    <Text mt="10px" fontSize="lg" fontWeight="bold">
                      No batch
                    </Text>
                  </Flex>
                )}
              </VStack>
            )}
          </GridItem>
          <GridItem
            borderLeft="1px solid rgba(0,0,0,0.2)"
            px="15px"
            boxSizing="border-box"
            borderRight={mac ? 'none' : '1px solid rgba(0,0,0,0.2)'}>
            <HStack align="center" justify="space-between" mb={2}>
              <Heading fontSize="xl" mb="20px">
                QC
              </Heading>
              <Menu>
                <MenuButton as={Button}>Sort By - {selectedQCSortType.label}</MenuButton>
                <MenuList>
                  {sortTypes.map((type) => (
                    <MenuItem
                      key={type.value}
                      onClick={() => {
                        sortData(inQCBatches, type.value, setInQCBatches);
                        setSelectedQCSortType(type);
                      }}>
                      {type.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </HStack>
            {isLoading ? (
              <Loader />
            ) : (
              <VStack>
                {inQCBatches?.length > 0 ? (
                  inQCBatches.map((item) => <Card batch={item} key={item.lotNumber} />)
                ) : (
                  <Flex w="300px" h="300px" justify="center" align="center" direction="column">
                    <InfoIcon w={10} h={10} />
                    <Text mt="10px" fontSize="lg" fontWeight="bold">
                      No batch
                    </Text>
                  </Flex>
                )}
              </VStack>
            )}
          </GridItem>
          <GridItem boxSizing="border-box">
            <HStack align="center" justify="space-between" mb={2}>
              <Heading fontSize="xl" mb="20px">
                Halted
              </Heading>
              <Menu>
                <MenuButton as={Button}>Sort By - {selectedHaltedSortType.label}</MenuButton>
                <MenuList>
                  {sortTypes.map((type) => (
                    <MenuItem
                      key={type.value}
                      onClick={() => {
                        sortData(haltedBatches, type.value, haltedBatches);
                        setSelectedHaltedSortType(type);
                      }}>
                      {type.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </HStack>
            {isLoading ? (
              <Loader />
            ) : mac ? (
              <Grid
                gridTemplateColumns={{
                  base: '1fr',
                  md: '1fr',
                  xl: '1fr',
                }}>
                {haltedBatches?.length > 0 ? (
                  haltedBatches?.map((item) => <Card batch={item} key={item.lotNumber} />)
                ) : (
                  <Flex w="300px" h="300px" justify="center" align="center" direction="column">
                    <InfoIcon w={10} h={10} />
                    <Text mt="10px" fontSize="lg" fontWeight="bold">
                      No batch
                    </Text>
                  </Flex>
                )}
              </Grid>
            ) : (
              <VStack>
                {haltedBatches?.length > 0 ? (
                  haltedBatches?.map((item) => <Card batch={item} key={item.lotNumber} />)
                ) : (
                  <Flex w="300px" h="300px" justify="center" align="center" direction="column">
                    <InfoIcon w={10} h={10} />
                    <Text mt="10px" fontSize="lg" fontWeight="bold">
                      No batch
                    </Text>
                  </Flex>
                )}
              </VStack>
            )}
          </GridItem>
        </Grid>
      </GridItem>
    </>
  );
};

export default BatchCards;
