/* eslint-disable react/no-children-prop */
import { Badge, Box, Flex, Heading, Text, HStack } from '@chakra-ui/layout';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUser } from '../../api/user';
import { GET_ALL_USER } from '../../store/actions/action-types';
import { useToast } from '@chakra-ui/toast';
import { mapStatusToColor } from '../../utilities/mapStatusToColor';
import CustomTable2 from '../../components/common/CustomTable2';
import { Tooltip } from '@chakra-ui/tooltip';
import { Button, Image } from '@chakra-ui/react';
import Tick from '../../assets/images/tick.svg';
import Cross from '../../assets/images/cross.svg';
import ONLINE from '../../assets/images/online.svg';
import OFFLINE from '../../assets/images/offline.svg';
import { UserCreationFrom } from '../../components/user/UserCreationFrom';
import { DeleteUserInfo } from '../../components/user/DeleteUserInfo';
import { UpdateUserInfo } from '../../components/user/UpdateUserInfo';
import toastFormat from '../../constatnts/toastFormat';
import { RiQrCodeFill } from 'react-icons/ri';
import UserQrCodes from '../../components/user/UserQrCodes';
import { useReactToPrint } from 'react-to-print';

const Users = () => {
  const dispatch = useDispatch();
  const [entries, setEntries] = useState(0);
  const users = useSelector((state) => state.user);
  const toast = useToast();
  const [contentLoading, setContentLoading] = useState(false);
  const scanRef = useRef();

  const refineData = (data) => {
    return data.map((user) => {
      return {
        ...user,
        rdUsername: (
          <Flex>
            <Text>{user.username}</Text>
            {user.isVerified ? (
              <Image src={Tick} width={5} ml={2} />
            ) : (
              <Image src={Cross} width={5} ml={2} />
            )}
          </Flex>
        ),

        rdContact: (
          <Tooltip label={user.email}>
            <Text>{user.phone}</Text>
          </Tooltip>
        ),

        rdOnline: (
          <Flex>
            {user.online ? (
              <Image src={ONLINE} width={3} ml={2} />
            ) : (
              <Image src={OFFLINE} width={3} ml={2} />
            )}
            <Badge ml={3} colorScheme={mapStatusToColor(user.battery)}>
              {user.battery}
            </Badge>
          </Flex>
        ),
      };
    });
  };

  useEffect(() => {
    (async () => {
      setContentLoading(true);
      const { users, totalEntries } = await getAllUser();

      if (users) {
        dispatch({
          type: GET_ALL_USER,
          payload: refineData(users),
        });

        setEntries(totalEntries);
        setContentLoading(false);
      } else {
        toast({
          title: 'Failed to get users',
          status: 'error',
          ...toastFormat,
        });
        setContentLoading(false);
      }
    })();
  }, []);

  const filters = [
    {
      name: 'Search',
      type: 'INPUT',
      queryParamKey: 'searchKey',
    },
    {
      name: 'Roles',
      type: 'SELECT',
      queryParamKey: 'roles',
      options: [
        { value: 'ADMIN', label: 'Admin' },
        { value: 'SI', label: 'Store Incharge' },
        { value: 'AD', label: 'Account & Delivery' },
        { value: 'DPO', label: 'Dyeing Operator' },
        { value: 'FS', label: 'Finishing Operator' },
        { value: 'QCS', label: 'Quality Supervisor' },
        { value: 'BM', label: 'Batch Manager' },
        { value: 'MANAGER', label: 'Manager' },
        { value: 'SUPERVISOR', label: 'Supervisor' },
        { value: 'DI', label: 'Delivery Incharge' },
      ],
    },
    {
      name: 'User Verified',
      type: 'SELECT',
      queryParamKey: 'isVerified',
      options: [
        { value: true, label: 'YES' },
        { value: false, label: 'NO' },
      ],
    },

    {
      name: 'User Status',
      type: 'SELECT',
      queryParamKey: 'status',
      options: [
        { value: 'ACTIVE', label: 'Active' },
        { value: 'BANNED', label: 'Banned' },
        { value: 'INACTIVE', label: 'In Active' },
      ],
    },
  ];

  const handleQuery = async (data) => {
    try {
      setContentLoading(true);
      const { users, totalEntries } = await getAllUser(data);
      if (users) {
        dispatch({
          type: GET_ALL_USER,
          payload: refineData(users),
        });

        setEntries(totalEntries);
        setContentLoading(false);
      }
    } catch (error) {
      setContentLoading(false);
      toast({
        title: 'Failed to query user',
        status: 'error',
        ...toastFormat,
      });
    }
  };

  const handleRefresh = async () => {
    setContentLoading(true);
    const { users, totalEntries } = await getAllUser();
    if (users) {
      dispatch({
        type: GET_ALL_USER,
        payload: refineData(users),
      });

      setEntries(totalEntries);
      setContentLoading(false);
    } else {
      setContentLoading(false);
      toast({
        title: 'Failed to get users',
        status: 'error',
        ...toastFormat,
      });
    }
  };

  const columns = [
    {
      header: 'uid',
      key: 'uid',
      type: 'BADGE',
      isTooltip: true,
      shouldShorten: true,
    },
    {
      header: 'username',
      key: 'rdUsername',
      type: 'TEXT',
      isTooltip: false,
      shouldShorten: false,
    },
    {
      header: 'name',
      key: 'name',
      type: 'TEXT',
      isTooltip: false,
      shouldShorten: false,
    },
    {
      header: 'Contact',
      key: 'rdContact',
      type: 'TEXT',
      isTooltip: false,
      shouldShorten: false,
    },
    {
      header: 'roles',
      key: 'roles',
      type: 'ARRAY TAG',
      isTooltip: false,
      shouldShorten: false,
    },
    {
      header: 'status',
      key: 'status',
      type: 'BADGE',
      isTooltip: false,
      shouldShorten: false,
    },
    {
      header: 'online',
      key: 'rdOnline',
      type: 'TEXT',
      isTooltip: false,
      shouldShorten: false,
    },
  ];

  const printQrCode = useReactToPrint({
    documentTitle: 'User QR codes',
    content: () => scanRef.current,
  });

  const generateQrCode = () => {
    setTimeout(() => {
      printQrCode();
    }, 500);
  };

  const user = useSelector((state) => state.auth.user);

  const canUserFeature = () => {
    if (user.roles.includes('ADMIN') || user.roles.includes('MANAGER')) {
      return true;
    } else {
      return false;
    }
  };

  const showCreateButton = canUserFeature();
  return (
    <Box w="100%" boxSizing="border-box" p="15px">
      <HStack>
        <Heading>Users</Heading>
        {canUserFeature() && (
          <Button
            leftIcon={<RiQrCodeFill />}
            size="sm"
            colorScheme="facebook"
            onClick={generateQrCode}>
            Download QR Codes
          </Button>
        )}
      </HStack>
      <Box mt="30px">
        <CustomTable2
          columns={columns}
          data={users}
          entries={entries}
          queryCallback={handleQuery}
          filters={filters}
          refreshCallback={handleRefresh}
          showCreateButton={showCreateButton}
          CreateNewComponent={UserCreationFrom}
          Actions={showCreateButton ? [UpdateUserInfo, DeleteUserInfo] : null}
          createButtonText="Add"
          loading={contentLoading}
          exportEnable={true}
          exportTitle="users"
          exportCols={[
            'uid',
            'username',
            'name',
            'phone',
            'email',
            'roles',
            'status',
            'online',
            'isVerified',
            'battery',
          ]}
          exportDataFetch={getAllUser}
        />
      </Box>
      <Box display="none">
        <UserQrCodes users={users} ref={scanRef} />
      </Box>
    </Box>
  );
};

export default Users;
