import { Box, Flex, Heading, Divider } from '@chakra-ui/layout';
import { Tag } from '@chakra-ui/tag';
import React from 'react';

const StepperForm = ({ steps, currentStep, isFull }) => {
  const { label, Form } = steps[currentStep];
  const getWidth = () => {
    if (currentStep === 2) {
      return '975px';
    }
    if (isFull) {
      return 'calc(100% - 80px)';
    } else {
      return '700px';
    }
  };
  return (
    <Box mx={30} mt={30} w={getWidth()} position="relative">
      <Flex w={getWidth()} justify="space-between" align="flex-start">
        <Heading fontSize="2xl">{label}</Heading>
        <Tag id="tag">
          {currentStep + 1} of {steps.length}
        </Tag>
      </Flex>
      <Divider w={getWidth()} mt="15px" />
      {Form}
    </Box>
  );
};

export default StepperForm;
