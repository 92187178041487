import { Button, IconButton } from '@chakra-ui/button';
import { ChevronDownIcon, DeleteIcon } from '@chakra-ui/icons';
import { Badge, Box, Flex, Heading, Text, Grid } from '@chakra-ui/layout';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu';
import {
  Table,
  Tbody,
  Th,
  Thead,
  HStack,
  Tr,
  Td,
  FormControl,
  Input,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/toast';
import React, { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import {
  createBatch,
  getFilteredMachineList,
  searchBatchByMachineId,
  updateBatchBasicInfo,
} from '../../api/batch';
import { fetchFabricByIds } from '../../api/fabric';
import { getAllFcWithBatchInfo } from '../../api/fcInfo';
import { dirtyOption } from '../../constatnts/hook-form-options';
import toastFormat from '../../constatnts/toastFormat';
import combineDateTime from '../../utilities/combineDateTime';

const FabricAndMachineForm = ({
  handleSubmit,
  update,
  fields,
  errors,
  register,
  remove,
  append,
  control,
  watchAllField,
  setValue,
  isUpdate,
  isDirty,
}) => {
  const toast = useToast();
  const fabricInfo = useWatch({ control, name: 'fabricInfo' });

  const location = useLocation();

  const getTotalWeight = () => {
    let totalWeight = 0;

    fabricInfo?.map((item) => {
      totalWeight += parseFloat(item.usedQty ? item.usedQty : 0);
    });

    return totalWeight;
  };

  const submit = async (data) => {
    try {
      if (isUpdate) {
        if (isDirty) {
          const { status } = await updateBatchBasicInfo({
            ...data,
            oldLotNumber: location.pathname.split('/')[3],
            totalFabricWeight: getTotalWeight(fabricInfo),
          });
          if (status === 200) {
            update(2);
          } else {
            update(2);
            toast({
              title: 'Failed to update info',
              status: 'warning',
              ...toastFormat,
            });
          }
        } else {
          update(2);
        }
      } else {
        const batch = await createBatch({
          ...data,
          totalFabricWeight,
        });

        if (batch.status === 200) {
          toast({
            title: 'Batch saved as draft',
            status: 'success',
            ...toastFormat,
          });

          update(2);
        } else {
          toast({
            title: 'Failed to create batch',
            status: 'warning',
            ...toastFormat,
          });
        }
      }
    } catch (error) {
      toast({
        title: 'Faild to save batch',
        status: 'error',
        ...toastFormat,
      });
    }
  };

  const [machines, setMachines] = useState([]);
  const [selectedMachine, setSelectedMachine] = useState();
  const [otherBatches, setOtherBatches] = useState();
  const [colors, setColors] = useState([]);
  const [selectedColor, setSelectedColor] = useState();
  const [otherOrders, setOtherOrders] = useState([]);
  const [totalFabricWeight, setTotalFabricWeight] = useState(0);
  const [fabricsInUse, setFabricsInUse] = useState([]);

  const customer = useWatch({ control, name: 'customer' });

  // * Fetch all batch assigned to the machine
  useEffect(() => {
    (async () => {
      const data = await searchBatchByMachineId(selectedMachine?.id);
      if (data?.status === 200) {
        setOtherBatches(data?.batches);
      }
    })();
  }, [selectedMachine]);

  // * Set total fabric weight
  useEffect(() => {
    setTotalFabricWeight(getTotalWeight());
  }, [fabricInfo]);

  // * get filtered machine list
  useEffect(() => {
    (async () => {
      const response = await getFilteredMachineList(getTotalWeight(watchAllField.fabricInfo));
      setMachines(response.machines ? response.machines : []);
    })();
  }, [fabricInfo]);

  // * remove machine if fabirc weight is higher
  useEffect(() => {
    if (getTotalWeight(fabricInfo) > watchAllField.machine?.maxCapacity) {
      setValue('machine', null, { shouldDirty: true });
      setOtherBatches([]);
    }
  }, [fabricInfo, totalFabricWeight]);

  // * set fabric info to batch info
  useEffect(() => {
    let fabrics;
    let extraFabricInfo;

    if (!isUpdate) {
      fabrics = customer?.fabricInfo?.filter((fabric) => {
        if (fabric.color === selectedColor) {
          return {
            ...fabric,
          };
        }
      });
      extraFabricInfo = fabrics.map((fabric) => {
        return {
          ...fabric,
          fabricId: fabric.id,
          fcNumber: customer.fcNumber,
        };
      });
    } else {
      fabrics = fabricInfo;
      extraFabricInfo = fabrics.map((fabric) => {
        return {
          ...fabric,
          fabricId: fabric.id ? fabric.id : fabric.fabricId,
        };
      });
    }

    setValue('fabricInfo', extraFabricInfo);
  }, [selectedColor]);

  // * get Color data
  useEffect(() => {
    let colors;
    if (!isUpdate) {
      colors = watchAllField?.customer?.fabricInfo?.map((fabric) => fabric.color);
    } else {
      colors = fabricInfo.map((fabric) => fabric.color);
    }
    const uniqueColors = [...new Set(colors)];

    setColors(uniqueColors);
  }, []);

  // * Get other orders
  useEffect(() => {
    (async () => {
      const orders = await getAllFcWithBatchInfo({ searchKey: 'IN QUEUE' });
      if (orders.data) {
        const filteredOrder = orders.data.filter(
          (order) => order.fcNumber !== watchAllField.customer.fcNumber,
        );

        const orderWithMatchingColor = filteredOrder.filter((item) => {
          item.fabricInfo.map((fabric) => {
            if (fabric.color === selectedColor) {
              return true;
            }
          });
        });

        setOtherOrders(orderWithMatchingColor);
      }
    })();
  }, []);

  // * update default color if in updateMode
  useEffect(() => {
    if (isUpdate) {
      setSelectedColor(fabricInfo[0]?.color);
    }
  }, []);

  // * fetch other batches
  useEffect(() => {
    (async () => {
      const ids = fabricInfo.map((item) => item.fabricId);
      const fabrics = await fetchFabricByIds(ids);
      setFabricsInUse(fabrics);
    })();
  }, [customer, fabricInfo]);

  // * Handle new fabric addition
  const handleFabricAppend = (order) => {
    const filteredFabricInfo = order.fabricInfo?.filter((item) => item.color === selectedColor);

    const extendInfo = filteredFabricInfo.map((item) => {
      return {
        ...item,
        fabricId: item.id,
        fcNumber: order.fcNumber,
      };
    });
    const alReadyAdded = fabricInfo.find((fabric) => fabric.fcNumber === order.fcNumber);

    if (!alReadyAdded) {
      append([...extendInfo]);
    }
  };

  const handleFabricQuantityError = (event, fabric, index) => {
    const qty = parseFloat(event.target.value);
    const filteredFabric = fabricsInUse?.filter((item) => item.fabricId === fabric.fabricId);

    let totalUsedQty = 0;

    filteredFabric?.map((item) => (totalUsedQty += item.quantity));

    const leftQty = fabric.quantity - totalUsedQty;

    if (qty > leftQty) {
      setValue(`fabricInfo.[${index}].usedQty`, leftQty, dirtyOption);
    }
    if (qty > fabric.quantity) {
      setValue(`fabricInfo.[${index}].usedQty`, fabric.quantity - totalUsedQty, dirtyOption);
    } else {
      setValue(`fabricInfo.[${index}].usedQty`, event.target.value, dirtyOption);
    }
  };

  const findFbricUsedQty = (fabric) => {
    let totalQty = 0;

    fabricsInUse?.map((item) =>
      item.fabricId === fabric.fabricId ? (totalQty += item.quantity) : null,
    );

    return totalQty;
  };

  return (
    <>
      <Box mt="25px">
        <HStack w="100%" justify="space-between">
          <Heading fontSize="2xl" mt="25px" mb="15px">
            Fabric Information
          </Heading>
          <Menu>
            <MenuButton as={Button} size="sm" rightIcon={<ChevronDownIcon />} disabled={isUpdate}>
              {selectedColor ? selectedColor : 'Select a color'}
            </MenuButton>
            <MenuList>
              {colors.map((color) => (
                <MenuItem key={color} onClick={() => setSelectedColor(color)}>
                  {color}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </HStack>
        <Table my="15px">
          <Thead>
            <Th>FC Number</Th>
            <Th>Fabric Type</Th>
            <Th>Quantity (KG)</Th>
            <Th>Rolls</Th>
            <Th>Use (KG)</Th>
            <Th textAlign="right">#</Th>
          </Thead>
          <Tbody>
            {fields?.map((item, index) => (
              <Tr key={index}>
                <Td>{item.fcNumber}</Td>
                <Td>{item.fabricType}</Td>
                <Td>
                  {item.quantity} ({findFbricUsedQty(item)})
                </Td>
                <Td>
                  <FormControl isInvalid={errors[`fabricInfo[${index}].rolls`]}>
                    <Input size="sm" type="number" {...register(`fabricInfo.[${index}].rolls`)} />
                    <FormErrorMessage>
                      {errors[`fabricInfo[${index}].rolls`]?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Td>
                <Td>
                  <FormControl isInvalid={errors[`fabricInfo[${index}].usedQty`]}>
                    <Input
                      size="sm"
                      type="number"
                      {...register(`fabricInfo.[${index}].usedQty`)}
                      onChange={(e) => handleFabricQuantityError(e, item, index)}
                    />
                    <FormErrorMessage>
                      {errors[`fabricInfo[${index}].usedQty`]?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Td>
                <Td>
                  {
                    <IconButton
                      size="sm"
                      icon={<DeleteIcon />}
                      onClick={() => {
                        if (fabricInfo.length > 1) {
                          remove(index);
                        }
                      }}
                    />
                  }
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <HStack w="100%" justify="space-between">
          <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size="sm">
              Add Fabric from other order
            </MenuButton>
            <MenuList>
              {otherOrders?.length > 0 ? (
                otherOrders?.map((order) => (
                  <MenuItem key={order.fcNumber} onClick={() => handleFabricAppend(order)}>
                    {order.fcNumber}
                  </MenuItem>
                ))
              ) : (
                <MenuItem>No order with matching color</MenuItem>
              )}
            </MenuList>
          </Menu>
          <Text fontWeight="bold">
            Total Weight: {isNaN(totalFabricWeight) ? 0 : totalFabricWeight} KG
          </Text>
        </HStack>
        {/* Machine Section */}
        <Heading fontSize="2xl" mt="25px" mb="15px">
          Select Machine
        </Heading>
        <Flex my="15px">
          <Menu>
            <MenuButton
              w="100%"
              as={Button}
              rightIcon={<ChevronDownIcon />}
              disabled={getTotalWeight(watchAllField.fabricInfo) > 0 ? false : true}>
              <Text>{watchAllField.machine ? watchAllField.machine.name : 'Select Machine'}</Text>
            </MenuButton>
            <MenuList zIndex="1000">
              {machines.length > 0 ? (
                machines
                  .sort((mca, mcb) => mcb.utilization - mca.utilization)
                  .map((mc) => (
                    <MenuItem
                      key={mc.name}
                      onClick={() => {
                        setValue('machine', mc, { shouldDirty: true });
                        setSelectedMachine(mc);
                      }}>
                      {mc.name} - {Math.floor(mc.utilization)} % Utilized
                    </MenuItem>
                  ))
              ) : (
                <MenuItem>
                  No machines meet the capacity <br /> requirements or no idle machine found
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        </Flex>
        {watchAllField.machine && (
          <Flex my="15px" justify="space-between">
            <Box>
              <Badge colorScheme="purple">Name</Badge>
              <Text mt="10px" textColor="gray.600">
                {watchAllField.machine.name}
              </Text>
            </Box>
            <Box>
              <Badge colorScheme="purple">Max Capacity</Badge>
              <Text mt="10px" textColor="gray.600">
                {watchAllField.machine.maxCapacity} KG
              </Text>
            </Box>
            <Box>
              <Badge colorScheme="purple">Min Capacity</Badge>
              <Text mt="10px" textColor="gray.600">
                {watchAllField.machine.minCapacity} KG
              </Text>
            </Box>

            <Box>
              <Badge colorScheme="purple">Utilization</Badge>
              <Text mt="10px" textColor="gray.600">
                {Math.floor(
                  (getTotalWeight(watchAllField.fabricInfo) / watchAllField.machine.maxCapacity) *
                    100,
                )}
                %
              </Text>
            </Box>
          </Flex>
        )}
        <Flex mt="15px" justify="flex-end">
          <Button onClick={() => update(0)} colorScheme="facebook" size="sm" mr="5px">
            Previous
          </Button>
          <Button type="submit" colorScheme="facebook" size="sm" onClick={handleSubmit(submit)}>
            Next
          </Button>
        </Flex>
      </Box>
      {selectedMachine && otherBatches?.length > 0 && (
        <Box mt="25px">
          <Heading fontSize="xl">
            Other batches assign <br /> to {selectedMachine?.name}
          </Heading>
          <Grid gridTemplateColumns="repeat(2, 1fr)" mt="25px">
            {otherBatches?.map((batch) => (
              <Box
                border="1px solid rgba(0,0,0,0.2)"
                boxSizing="border-box"
                padding="15px"
                key={batch?.lotNumber}>
                <Text fontSize="sm" fontWeight="bold" color="gray.600">
                  # Batch - {batch?.lotNumber?.split('-')[1]}
                </Text>
                <Text fontSize="sm" fontWeight="bold" color="gray.600">
                  Loading Time - {combineDateTime(batch?.loadDate, batch?.loadTime)}
                </Text>
              </Box>
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default FabricAndMachineForm;
