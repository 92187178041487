import React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Box,
  Tag,
} from '@chakra-ui/react';

const AlertBox = ({ show, title, subTitle, onClose, onSubmit, showWarning, warningText }) => {
  return (
    <AlertDialog isOpen={show} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>
            {showWarning && subTitle.length > 0 && (
              <Tag mb="15px" colorScheme="orange">
                {warningText}
              </Tag>
            )}
            <Box dangerouslySetInnerHTML={{ __html: subTitle }}></Box>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={onClose} size="sm">
              Cancel
            </Button>
            <Button colorScheme="facebook" onClick={onSubmit} size="sm" ml={3}>
              Confirm
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default AlertBox;
