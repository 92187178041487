export const productionHeaders = [
  { label: 'Fabric Type', value: 'fabricType' },
  { label: 'Shade', value: 'shade' },
  { label: 'Color', value: 'color' },
  { label: 'GSM', value: 'gsm' },
  { label: 'DIA', value: 'dia' },
  { label: 'Quantity', value: 'quantity' },
  { label: 'Status', value: 'status' },
  { label: 'Processed', value: 'processed' },
  { label: 'Party Name', value: 'customerInfo.partyName' },
  { label: 'Date', value: 'fabrics.createdAt' },
  { label: 'Shade Group', value: 'shadeGroup' },
];

export const clientProductionHeaders = [
  {
    label: 'Party Name',
    value: 'partyName',
  },
  {
    label: 'Order Status',
    value: 'orderStatus',
  },
  {
    label: 'Remarks',
    value: 'remarks',
  },
  {
    label: 'Client Ref',
    value: 'clientRefNo',
  },
  {
    label: 'FC Number',
    value: 'fcNumber',
  },
  {
    label: 'Date',
    value: 'customers.createdAt',
  },
  {
    label: 'Sales Person',
    value: 'salesPerson',
  },

  {
    label: 'Delivery Date',
    value: 'deliveryDate',
  },
  {
    label: 'Client Contact Person',
    value: 'clientContactPerson',
  },
  {
    label: 'Delivery Address',
    value: 'clientAddress',
  },
];

export const machineProductionHeaders = [
  { label: 'Machine Name', value: 'name' },
  { label: 'capacity', value: 'capacity' },
  { label: 'Max Capacity', value: 'maxCapacity' },
  { label: 'Min Capacity', value: 'minCapacity' },
  { label: 'Status', value: 'status' },
];
