import axios from './axios';

export const addTaskRemark = async (formData) => {
  try {
    const response = await axios.patch('/batch/task/remark', { ...formData });
    return response.status;
  } catch (error) {
    console.error(error);
    return null;
  }
};
export const addBatchRemark = async (formData) => {
  try {
    const response = await axios.patch('/batch/remark', { ...formData });
    return response.status;
  } catch (error) {
    console.error(error);
    return null;
  }
};
export const getAllBatch = async (query) => {
  try {
    if (!query?.isPrint) {
      const response = await axios.get('/batch', { params: query });
      return {
        status: response.data.status,
        batches: response.data.batches,
        totalEntries: response.data.totalEntries,
        stats: response.data.stats,
      };
    } else {
      const response = await axios.get('/batch', { params: query });
      return response.data.batches;
    }
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getBatchByLotNumber = async (lotNumber) => {
  try {
    const response = await axios.get(`/batch/by/${lotNumber}`);
    return {
      status: 200,
      batch: response.data.batch,
    };
  } catch (error) {
    console.log(error);

    return error;
  }
};

export const skipTask = async ({ batchId, updatedTask, skippedTaskUid, whySkipped }) => {
  try {
    const response = await axios.patch('batch/task/skip', {
      batchId,
      updatedTask,
      skippedTaskUid,
      whySkipped,
    });

    return response.status;
  } catch (error) {
    console.log(error);
  }
};

export const getFilteredMachineList = async (weight) => {
  try {
    const response = await axios.get(`/machine/by/${weight}`);
    return {
      machines: response.data.machines,
    };
  } catch (error) {
    console.log(error);

    return error;
  }
};

export const createBatch = async (formData) => {
  try {
    const modifiedFormData = {
      ...formData,
      lotNumber: `${formData.customer.fcNumber}-${formData.lotNumber}`,
    };

    const response = await axios.post('/batch', { ...modifiedFormData });
    return {
      status: response.status,
    };
  } catch (error) {
    console.log(error);

    return error;
  }
};

export const updateBatchBasicInfo = async (formData) => {
  try {
    const response = await axios.patch('/batch/basic-info', { ...formData });
    return {
      status: response.status,
    };
  } catch (error) {
    console.log(error);

    return error;
  }
};

export const updateBatchWithTaskInfo = async (formData) => {
  try {
    const modifiedFormData = {
      ...formData,
      lotNumber: `${formData.customer.fcNumber}-${formData.lotNumber}`,
    };
    const response = await axios.patch('/batch/task-info', { ...modifiedFormData });
    return {
      status: response.status,
    };
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const fetchTemplates = async () => {
  try {
    const response = await axios.get('/batch/templates');

    return {
      status: response.status,
      templates: response.data.templates,
    };
  } catch (error) {
    console.log(error);

    return error;
  }
};

export const publishBatch = async (lotNumber, formData) => {
  try {
    const modifiedFormData = {
      ...formData,
      lotNumber: `${formData.customer.fcNumber}-${formData.lotNumber}`,
    };
    const response = await axios.patch(`/batch/publish/${lotNumber}`, { ...modifiedFormData });

    return {
      status: response.status,
    };
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const approveBatch = async (lotNumber) => {
  try {
    const response = await axios.patch(`/batch/approve/${lotNumber}`);
    return {
      status: response.status,
    };
  } catch (error) {
    console.log(error);

    return error;
  }
};

export const fetchBatchProgressDeatilForRecipe = async (lotNumber) => {
  try {
    const response = await axios.get(`/batch/progress/${lotNumber}`);
    return {
      status: response.status,
      batch: response.data.batch,
    };
  } catch (error) {
    console.log(error);

    return error;
  }
};

export const addRecipe = async (formData) => {
  try {
    const { tasks, id, batchId, totalFabricWeight } = formData;
    const patchData = {
      tasks,
      id,
      totalFabricWeight,
      batchId,
    };
    const response = await axios.patch('/batch/add-recipe', { ...patchData });
    return {
      status: response.status,
    };
  } catch (error) {
    console.log(error);

    return error;
  }
};

export const fetchAllChemicals = async () => {
  try {
    const responseFromChemicals = await axios.get('/chemicals/chemical');
    const responseFromDyeGroups = await axios.get('/chemicals/dye-group');
    const responseFromDyes = await axios.get('/chemicals/dye');

    return {
      status: 200,
      chemicals: responseFromChemicals.data.chemicals,
      dyes: responseFromDyes.data.dyes,
      dyeGroups: responseFromDyeGroups.data.dyeGroups,
    };
  } catch (error) {
    console.log(error);

    return error;
  }
};

export const fetchRecipeTemplates = async () => {
  try {
    const response = await axios.get('/batch/recipe-template');

    return {
      status: response.status,
      recipes: response.data.recipes,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const addRecipeTemplate = async (data) => {
  try {
    const postData = {
      name: data.name,
      recipe: data.recipe,
      taskName: data.taskName,
    };

    const response = await axios.post('/batch/add-recipe-template', { ...postData });
    return response.status;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const fetchBatchByCustomerId = async (customerId) => {
  try {
    const data = await axios.get(`/batch/fc/${customerId}`);

    return data.data.batches;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const changeBatchStatus = async (status, batchRejectionRemark, lotNumber) => {
  try {
    const response = await axios.patch(`/batch/update/status/${lotNumber}`, {
      status,
      batchRejectionRemark,
    });

    return response.status;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const reAssignMachine = async (machineId, batchId) => {
  try {
    const response = await axios.patch('/batch/re-assign/machine', { batchId, machineId });

    return response.status;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const reAssignUser = async ({ dyerId, finisherId, qcId, batchId }) => {
  try {
    const response = await axios.patch('/batch/re-assign/user', {
      batchId,
      dyerId,
      finisherId,
      qcId,
    });

    return response.status;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const searchBatchByMachineId = async (machineId) => {
  try {
    const response = await axios.get('/batch/search/batch/machine', {
      params: {
        machineId,
      },
    });

    return {
      status: response.status,
      batches: response.data.batches,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const increaseBatchPriority = async (priority, lotNumber) => {
  try {
    const response = await axios.patch('/batch/update/priority', { priority, lotNumber });
    return response.status;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateBatchAsDelivered = async (lotNumber) => {
  try {
    const response = await axios.patch('/batch/deliver', { lotNumber });

    return response.status;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateOrderAsDelivered = async (lots, fcNumber) => {
  try {
    const response = await axios.patch('/batch/order/deliver', { lots, fcNumber });

    return response.status;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const deleteBatch = async (lotNumber) => {
  try {
    const response = await axios.delete(`/batch/${lotNumber}`);
    return response.status;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getDailyPlanningReport = async (date) => {
  try {
    const response = await axios.get(`/batch/planning/report/${date}`);
    return response.data.report;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getProductionReport = async ({ startDate, endDate }) => {
  try {
    const response = await axios.get(`/batch/production/report/date-range/${startDate}/${endDate}`);
    return response.data.report;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const batchDetailReport = async (date) => {
  try {
    const response = await axios.get(`/batch/report/${date}`);

    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const deliveryDelayReport = async (duration) => {
  try {
    const response = await axios.get('/batch/report/delivery/', { params: { duration } });

    return response.data.report;
  } catch (error) {
    console.error(error);
    return null;
  }
};
