import {
  Box,
  Button,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Heading,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { ChevronDownIcon, CloseIcon } from '@chakra-ui/icons';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import { RiFullscreenLine } from 'react-icons/ri';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';

ChartJS.register(...registerables);

const Charts = ({ chartHeader, chartConfig, fetchConfig, footer }) => {
  const [selectedFilter, setSelectedFilter] = useState('week');
  const { isOpen, onClose, onOpen } = useDisclosure();

  const getDate = () => {
    if (selectedFilter === 'week') {
      dayjs.extend(weekday);

      const firstDay = dayjs(new Date()).weekday(0).format('DD, MMM');
      const lastDay = dayjs(new Date()).weekday(7).format('DD, MMM');
      return `${firstDay} - ${lastDay}`;
    } else {
      return dayjs(new Date()).format('MMMM, YYYY');
    }
  };
  useEffect(() => {
    (async () => {
      await fetchConfig(selectedFilter);
    })();
  }, [selectedFilter]);

  return (
    <>
      <Drawer size="full" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <HStack
            w="95%"
            mx="auto"
            justify="space-between"
            position="sticky"
            top="0"
            bgColor="#fff"
            py="15px"
            zIndex="1555"
          >
            <Heading fontSize="2xl">{chartHeader}</Heading>
            <IconButton onClick={onClose} icon={<CloseIcon />} size="sm" colorScheme="red" />
          </HStack>
          <HStack w="58%" mx="auto" justify="space-between" my="5px">
            <Text>{getDate()}</Text>
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size="sm" rounded="md">
                {selectedFilter}
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => setSelectedFilter('week')}>This Week</MenuItem>
                <MenuItem onClick={() => setSelectedFilter('month')}>This Month</MenuItem>
              </MenuList>
            </Menu>
          </HStack>
          <Box w="60%" h="60%" boxSizing="border-box" p="15px" mx="auto">
            {chartConfig && <Bar options={chartConfig.options} data={chartConfig.data} />}
          </Box>
          <Box w="58%" boxSizing="border-box" p="15px" mx="auto">
            {footer}
          </Box>
        </DrawerContent>
      </Drawer>
      <Box w="100%">
        <HStack w="100%" justify="space-between">
          <Text fontSize="sm" fontWeight="bold" color="gray.700">
            {chartHeader}{' '}
            <Tooltip label="Fullscreen">
              <IconButton
                icon={<RiFullscreenLine />}
                size="sm"
                colorScheme="gray"
                onClick={onOpen}
              />
            </Tooltip>
          </Text>
          <HStack>
            <Text>{getDate()}</Text>=
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size="sm" rounded="md">
                {selectedFilter}
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => setSelectedFilter('week')}>This Week</MenuItem>
                <MenuItem onClick={() => setSelectedFilter('month')}>This Month</MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </HStack>
        {chartConfig && <Bar options={chartConfig.options} data={chartConfig.data} />}
        {footer}
      </Box>
    </>
  );
};

export default Charts;
