import { LOGIN, LOGOUT, OTP_VERIFY, REFRESH_TOKEN, SAVE_SESSION_ID } from '../actions/action-types';

const initialState = {
  isLoggedIn: false,
  user: null,
  refreshToken: null,
  accessToken: null,
  tempUserData: null,
  sessionId: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        tempUserData: action.payload.tempUserData,
      };

    case OTP_VERIFY:
      return {
        ...state,
        isLoggedIn: true,
        accessToken: action.payload.accessToken,
        user: action.payload.user,
        refreshToken: action.payload.refreshToken,
        tempUserData: null,
        sessionId: action.payload.sessionId,
      };

    case SAVE_SESSION_ID:
      return {
        ...state,
        sessionId: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        accessToken: null,
      };
    case REFRESH_TOKEN: {
      return {
        ...state,
        accessToken: action.payload,
      };
    }
    default:
      return state;
  }
}
