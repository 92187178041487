import React from 'react';
import ReactDOM from 'react-dom';
import { store } from './store';
import './index.css';

import App from './App';
import { ColorModeScript } from '@chakra-ui/color-mode';

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  window.store = store;
}

ReactDOM.render(
  <>
    <ColorModeScript initialColorMode="light" />
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </>,
  document.getElementById('root'),
);
