import { extendTheme } from '@chakra-ui/react';
const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};
const theme = extendTheme({
  config,
  // fonts: {
  //   heading: 'Ubuntu',
  //   body: 'Ubuntu',
  // },
});

export default theme;
