import { ADD_NEW_FC, GET_ALL_FC, UPDATE_FC_INFO } from '../actions/action-types';

const initState = [];

const fcReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_ALL_FC:
      return action.payload;

    case UPDATE_FC_INFO:
      return state.map((fc) =>
        fc.fcNumber === action.payload.fcNumber ? { ...fc, ...action.payload.data } : fc,
      );
    case ADD_NEW_FC:
      return [...state, action.payload];
    default:
      return state;
  }
};

export default fcReducer;
