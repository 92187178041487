import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import React from 'react';
import Logo from '../../../assets/images/logo.svg';

const RenderRecipe = ({ recipe }) => {
  const formatTotalAmount = (amount) => {
    const kg = Math.floor(amount * 0.001);
    const gm = kg > 0 ? Math.floor((amount * 0.001 - Math.floor(amount * 0.001)) * 1000) : amount;
    const mg = gm > 0 ? 0 : Math.floor(amount * 0.001);
    return `${kg} Kg ${gm} Gm ${mg} Mg`;
  };
  if (recipe.unit === 'gl') {
    return (
      <Tr>
        <Td>{recipe.chemical}</Td>
        <Td>{recipe.quantity}</Td>
        <Td></Td>
        <Td>{formatTotalAmount(parseFloat(recipe.total))}</Td>
        <Td>{recipe.addition}</Td>
      </Tr>
    );
  } else {
    return (
      <Tr>
        <Td>{recipe.dye}</Td>
        <Td></Td>
        <Td>
          {(recipe.quantity + recipe.quantity * recipe.changeBy * 0.01).toString().substring(0, 4)}
        </Td>
        <Td>{formatTotalAmount(parseFloat(recipe.total))}</Td>
        <Td>{recipe.addition}</Td>
      </Tr>
    );
  }
};

const RecipeSheet = React.forwardRef((props, ref) => {
  const { batch } = props;
  const tasks = batch.batchProgressInfo.tasks.DYING;
  const taskWithRecipes = tasks.filter((task) => task.isRecipeRequired);

  return (
    <Box width="1080px" ref={ref} boxSizing="border-box" padding="15px 30px">
      <Flex my="25px" justifyContent="space-between">
        <Box>
          <Image src={Logo} w="150px" />
          <Text fontSize="sm" mt="15px">
            2/1 Gadda, Savar-1340, Dhaka
          </Text>
        </Box>
        <Heading fontSize="3xl">Dyeing Report</Heading>
      </Flex>

      <Table width="100%" border="1px solid rgba(0,0,0,0.2)" size="sm">
        <Thead>
          <Tr>
            <Th>Client Name</Th>
            <Th>Machine Name</Th>
            <Th>Lot Number</Th>
            <Th>Loading Time</Th>
            <Th>Total Fabric Weight</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>{batch.customerInfo.partyName}</Td>
            <Td>{batch.machineInfo.name}</Td>
            <Td>{batch.lotNumber}</Td>
            <Td>
              {dayjs(new Date(batch.loadDate)).format('DD, MMM')}, {batch.loadTime}
            </Td>
            <Td>{batch.totalFabricWeight} KG</Td>
          </Tr>
        </Tbody>
      </Table>
      <Table width="100%" border="1px solid rgba(0,0,0,0.2)" size="sm">
        <Thead>
          <Tr>
            <Th>Color</Th>
            <Th>Lab Dip Ref</Th>
            <Th>Client Ref</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>{batch.fabricInfo[0].color}</Td>
            <Td>{batch.customerInfo.labDipRef}</Td>
            <Td>{batch.customerInfo.clientRefNo}</Td>
          </Tr>
        </Tbody>
      </Table>

      <Heading my="15px" fontSize="lg">
        Fabric Types
      </Heading>
      <Grid templateColumns="repeat(4, 1fr)">
        {batch.fabricInfo?.map((fabric, index) => (
          <GridItem key={Date.now() + index}>
            <Text>
              {fabric.fabricType} - {fabric.quantity} KG
            </Text>
          </GridItem>
        ))}
      </Grid>
      <Heading my="15px" fontSize="lg">
        Chemicals
      </Heading>
      <Table mt="25px" width="100%" border="1px solid rgba(0,0,0,0.2)" size="sm">
        <Thead>
          <Tr>
            <Th>Chemical</Th>
            <Th>Quantity</Th>
            <Th>Quantity (%)</Th>
            <Th>Total Amount</Th>
            <Th>Addition</Th>
          </Tr>
        </Thead>
        {taskWithRecipes &&
          taskWithRecipes.length > 0 &&
          taskWithRecipes?.map((item) => (
            <Tbody key={item.uid}>
              <Tr>
                <Td colSpan={5} bgColor="gray.200">
                  <Heading fontSize="lg">{item.taskName}</Heading>
                </Td>
              </Tr>
              {item.recipes?.map((recipe, index) => (
                <RenderRecipe key={Date.now() + index} recipe={recipe} />
              ))}
            </Tbody>
          ))}
      </Table>
    </Box>
  );
});

RecipeSheet.displayName = 'Recipe Sheet';

export default RecipeSheet;
