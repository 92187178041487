import dayjs from 'dayjs';

export default function (date, time) {
  const loadingDateTime = new Date(
    date.substring(0, 4),
    date.substring(5, 7) - 1,
    date.substring(8, 10),
    time.substring(0, 2),
    time.substring(3, 5),
  );

  return dayjs(loadingDateTime).format('DD/MMM/YYYY hh:mm A');
}
