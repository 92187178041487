import React from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';

const SelectField = ({
  options,
  handleChange,
  control,
  name,
  isDisabled,
  selectFieldValueFinder,
  index,
}) => {
  const getValue = (field) => {
    if (selectFieldValueFinder) {
      return selectFieldValueFinder(options, field, index);
    } else {
      return options?.find((item) => item.value === field.value);
    }
  };
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Select
          isDisabled={isDisabled}
          options={options}
          onChange={(opt) => handleChange(opt, index)}
          value={getValue(field)}
        />
      )}
    />
  );
};

export default SelectField;
