import axios from './axios';

export const fetchAllClients = async () => {
  try {
    const response = await axios.get('/clients');
    return response.data.clients;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const clientStats = async () => {
  try {
    const response = await axios.get('/clients/stats');
    return response.data.stats;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const fetchClientExtendedData = async (query) => {
  try {
    const response = await axios.get('/clients/extended', {
      params: query,
    });
    return {
      client: response.data.client,
      orders: response.data.orders,
      stats: response.data.stats,
      total: response.data.total,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};
