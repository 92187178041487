import { ADD_RECIPE, CLEAR_RECIPE } from '../actions/action-types';

const initState = [];

const recipeReducer = (state = initState, action) => {
  switch (action.type) {
    case ADD_RECIPE:
      return [...state, ...action.payload];
    case CLEAR_RECIPE:
      return [];
    default:
      return state;
  }
};

export default recipeReducer;
