/* eslint-disable react/no-children-prop */
import React, { useEffect, useState } from 'react';
import { Flex, Heading, Box, Link, Text } from '@chakra-ui/layout';
import { useForm } from 'react-hook-form';
import { fcCreationValidator, useYupValidationResolver } from '../../validators/form-validators';
import { Input } from '@chakra-ui/input';
import { Button } from '@chakra-ui/button';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react';
import AlertBox from '../../components/common/AlertBox';
import { useToast } from '@chakra-ui/toast';
import { addBulkFc, addSingleFc, searchOrderByColor, searchOrderByName } from '../../api/fcInfo';
import { useDispatch } from 'react-redux';
import { ADD_NEW_FC } from '../../store/actions/action-types';
import { CSVReader } from 'react-papaparse';
import { Modal, ModalOverlay, ModalContent, ModalCloseButton } from '@chakra-ui/modal';
import SampleFile from '../../assets/files/order-info.csv';
import { Table, Tbody, Thead, Th, Td, Tr } from '@chakra-ui/table';
import { useHistory } from 'react-router';
import { FC_LIST } from '../../constatnts/routerUrls';
import toastFormat from '../../constatnts/toastFormat';

const convertToCamelCase = (word) => {
  let convertedText = '';
  word.split('-').map((item, index) => {
    if (index !== 0) {
      convertedText = convertedText + item[0].toUpperCase() + item.toLowerCase().slice(1, 100);
    } else {
      convertedText = convertedText + item.toLowerCase();
    }
  });
  return convertedText;
};

const FcBulkUpload = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [orderInfos, setOrderInfos] = useState([]);
  const [duplicateFc, setDuplicateFc] = useState([]);
  const [promtForreview, setPromptForReview] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [parseSuccess, setParseSuccess] = useState('');

  const toast = useToast();
  const history = useHistory();

  const handleFileUpload = (data) => {
    try {
      setParseSuccess('');
      if (
        convertToCamelCase(data[0].data[0]) === 'fcNumber' &&
        convertToCamelCase(data[0].data[1]) === 'partyName' &&
        convertToCamelCase(data[0].data[2]) === 'color' &&
        convertToCamelCase(data[0].data[3]) === 'shade' &&
        convertToCamelCase(data[0].data[4]) === 'labDipRef' &&
        convertToCamelCase(data[0].data[5]) === 'clientRefNo' &&
        convertToCamelCase(data[0].data[6]) === 'remarks'
      ) {
        for (let i = 1; i <= data.length - 1; i++) {
          if (
            data[i].data[0].length > 3 &&
            data[i].data[1].length > 0 &&
            data[i].data[2].length > 0 &&
            (parseFloat(data[i].data[3]) >= 0 || parseFloat(data[i].data[3]) <= 1)
          ) {
            orderInfos.push({
              [convertToCamelCase(data[0].data[0])]: data[i].data[0],
              [convertToCamelCase(data[0].data[1])]: data[i].data[1],
              [convertToCamelCase(data[0].data[2])]: data[i].data[2],
              [convertToCamelCase(data[0].data[3])]: data[i].data[3],
              [convertToCamelCase(data[0].data[4])]: data[i].data[4],
              [convertToCamelCase(data[0].data[5])]: data[i].data[5],
              [convertToCamelCase(data[0].data[6])]: data[i].data[6],
            });
          }
        }
        if (orderInfos.length > 0) {
          setPromptForReview(true);
          toast({
            title: 'Order info extracted',
            status: 'success',
            ...toastFormat,
          });
        } else {
          setParseSuccess('No data added');
          toast({
            title: 'No information addeed',
            status: 'warning',
            ...toastFormat,
          });
        }
      } else {
        setParseSuccess('Failed to parse data');
        toast({
          title: 'Failed to parse file',
          status: 'error',
          ...toastFormat,
        });
      }
    } catch (error) {
      toast({
        title: 'Failed to parse file',
        status: 'error',
        ...toastFormat,
      });
    }
  };

  const handleFileRemove = () => {
    setOrderInfos([]);
    setPromptForReview(false);
    setParseSuccess('');
  };

  const handleSubmit = async () => {
    try {
      if (orderInfos.length > 0) {
        const response = await addBulkFc(orderInfos);
        if (response.status === 200 && !response.data) {
          toast({
            title: 'Data uploaded',
            status: 'success',
            ...toastFormat,
          });
          setShowAlert(false);
          onClose();
          history.push(FC_LIST);
        } else {
          setDuplicateFc(response.data);
          setShowAlert(false);
          toast({
            title: 'Possible duplicate fc number',
            status: 'warning',
            ...toastFormat,
          });
        }
      } else {
        toast({
          title: 'No information addeed',
          status: 'warning',
          ...toastFormat,
        });
      }
    } catch (error) {
      toast({
        title: 'Internal Server error',
        status: 'error',
        ...toastFormat,
      });
    }
  };

  return (
    <>
      <Box w="400px">
        <Flex justifyContent="space-between" mb="15px">
          <Heading fontSize="2xl" mb="15px">
            Bulk upload orders
          </Heading>
          <Link
            h="max-content"
            href={SampleFile}
            target="_blank"
            fontSize="sm"
            padding="8px 15px"
            rounded="md"
            _hover={{ textDecoration: 'none', bgColor: 'facebook.600', textColor: 'white' }}
            bgColor="facebook.500"
            fontWeight="semibold"
            textColor="white"
          >
            Sample CSV
          </Link>
        </Flex>
        <box mt="15px">
          <CSVReader onDrop={handleFileUpload} addRemoveButton onRemoveFile={handleFileRemove}>
            <FormLabel>Upload or drag and drop csv file</FormLabel>
          </CSVReader>
        </box>
        <Flex w="100%" justify="space-between" align="center" mt="15px">
          <Text textColor="red.300">{parseSuccess}</Text>
          {promtForreview && (
            <Button colorScheme="facebook" mt="15px" onClick={onOpen}>
              Review Data
            </Button>
          )}
        </Flex>
      </Box>
      <AlertBox
        show={showAlert}
        title="Upload order info"
        subTitle="Are you sure you want to procced"
        onSubmit={handleSubmit}
        onClose={() => setShowAlert(false)}
      />
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setDuplicateFc([]);
          onClose();
        }}
        size="full"
      >
        <ModalOverlay>
          <ModalContent>
            <ModalCloseButton />
            <Box
              w="85%"
              mx="auto"
              my="30px"
              maxHeight="400px"
              overflowY="scroll"
              css={{
                '&::-webkit-scrollbar': {
                  width: '4px',
                },
                '&::-webkit-scrollbar-track': {
                  width: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#f2f2f2',
                  borderRadius: '24px',
                },
              }}
            >
              <Table>
                <Thead position="sticky" top="0" bgColor="gray.50">
                  <Tr>
                    <Th>Fc Number</Th>
                    <Th>Party name</Th>
                    <Th>Color</Th>
                    <Th>Shade</Th>
                    <Th>Lab Dip Ref</Th>
                    <Th>Client Ref No</Th>
                    <Th>Remarks</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {promtForreview &&
                    orderInfos.map((order) => (
                      <Tr key={order.fcNumber}>
                        <Td>{order.fcNumber}</Td>
                        <Td>{order.partyName}</Td>
                        <Td>{order.color}</Td>
                        <Td>{order.shade}</Td>
                        <Td>{order.labDipRef}</Td>
                        <Td>{order.clientRefNo}</Td>
                        <Td>{order.remarks}</Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </Box>

            <Flex w="85%" mx="auto" mt="15px" justify="flex-end">
              <Button
                colorScheme="facebook"
                ml="7.5%"
                w="max-content"
                onClick={() => setShowAlert(true)}
              >
                Submit
              </Button>
            </Flex>
            {duplicateFc.length > 0 && (
              <Box
                w="85%"
                mx="auto"
                my="30px"
                maxHeight="400px"
                overflowY="scroll"
                css={{
                  '&::-webkit-scrollbar': {
                    width: '4px',
                  },
                  '&::-webkit-scrollbar-track': {
                    width: '6px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: '#f2f2f2',
                    borderRadius: '24px',
                  },
                }}
              >
                <Heading mb="15px" fontSize="2xl">
                  Possible duplicate fc number or in valid data
                </Heading>
                <Table>
                  <Thead position="sticky" top="0" bgColor="red.200">
                    <Tr>
                      <Th>Fc Number</Th>
                      <Th>Party name</Th>
                      <Th>Color</Th>
                      <Th>Shade</Th>
                      <Th>Lab Dip Ref</Th>
                      <Th>Client Ref No</Th>
                      <Th>Remarks</Th>
                    </Tr>
                  </Thead>
                  <Tbody bgColor="red.100">
                    {duplicateFc.map((order) => (
                      <Tr key={order.fcNumber}>
                        <Td>{order.fcNumber}</Td>
                        <Td>{order.partyName}</Td>
                        <Td>{order.color}</Td>
                        <Td>{order.shade}</Td>
                        <Td>{order.labDipRef}</Td>
                        <Td>{order.clientRefNo}</Td>
                        <Td>{order.remarks}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            )}
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  );
};

const FcCreationForm = () => {
  const resolver = useYupValidationResolver(fcCreationValidator);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const toast = useToast();
  const dispatch = useDispatch();
  const history = useHistory();
  const [customers, setCustomers] = useState([]);
  const [colors, setColors] = useState([]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fcNumber: '',
      partyName: '',
      color: '',
      shade: '',
      labDipRef: '',
      clientRefNo: '',
      remarks: '',
    },
    mode: 'onChange',
    resolver,
  });

  useEffect(() => {
    (async () => {
      if (watch().partyName?.length > 2) {
        const { data } = await searchOrderByName(watch().partyName);
        const uniquePartyName = [...new Set(data?.map((item) => item.partyName))];
        setCustomers([...uniquePartyName]);
      }
    })();
  }, [watch().partyName]);

  useEffect(() => {
    (async () => {
      if (watch().color?.length >= 1) {
        const { data } = await searchOrderByColor(watch().color);
        const uniqueColor = [...new Set(data?.map((item) => item.color))];
        setColors([...uniqueColor]);
      }
    })();
  }, [watch().color]);

  const submit = async (data) => {
    try {
      setLoading(true);
      const { status } = await addSingleFc(data);
      if (status === 200) {
        dispatch({
          type: ADD_NEW_FC,
          payload: data,
        });
        toast({
          title: `Fc info added for - ${data.partyName}`,
          status: 'success',
          ...toastFormat,
        });
        history.push(FC_LIST);
      } else {
        toast({
          title: 'FC number already in use',
          status: 'error',
          ...toastFormat,
        });
      }
      setLoading(false);
      setShowAlert(false);
    } catch (error) {
      toast({
        title: 'Failed to add FC Info',
        status: 'error',
        ...toastFormat,
      });
      setLoading(false);
      setShowAlert(false);
    }
  };

  return (
    <Flex
      mt="30px"
      mx="auto"
      w="max-content"
      gridTemplateColumns={{
        md: 'repeat(1, auto)',
        lg: 'repeat(2, auto)',
      }}
    >
      <AlertBox
        title="Add new fc info."
        subTitle="Are you sure you want to add"
        onSubmit={handleSubmit(submit)}
        onClose={() => setShowAlert(false)}
        show={showAlert}
      />
      <Box w="400px" pr="30px" borderRight="1px solid rgba(0,0,0,0.1)" pb="30px">
        <Flex direction="column" align="flex-start" justify="flex-start">
          <Heading fontSize="2xl">Add singe order</Heading>
          <FormControl isInvalid={!!errors?.fcNumber} isRequired={true}>
            <FormLabel mb="10px" mt="15px">
              FC NUMBER
            </FormLabel>
            <Input
              required
              {...register('fcNumber')}
              placeholder="Fc Number ie - 0150"
              minLength={4}
              maxLength={6}
              type="number"
            />
            <FormErrorMessage>{errors?.fcNumber?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.partyName} isRequired={true}>
            <FormLabel mt="15px">Party Name</FormLabel>
            <Input {...register('partyName')} placeholder="Party name" required list="customers" />
            <datalist id="customers">
              {customers.map((customer, index) => (
                <option value={customer} key={customer + index.toString()} />
              ))}
            </datalist>
            <FormErrorMessage>{errors?.partyName?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.color} isRequired={true}>
            <FormLabel mt="15px">Color</FormLabel>
            <Input {...register('color')} placeholder="Color" required list="colors" />
            <datalist id="colors">
              {colors.map((color, index) => (
                <option value={color} key={color + index.toString()} />
              ))}
            </datalist>
            <FormErrorMessage>{errors?.color?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.shade} isRequired={true}>
            <FormLabel mt="15px">Shade</FormLabel>
            <Input
              {...register('shade')}
              placeholder="Shade ie - 0.2 or 0.5 or 1"
              type="number"
              required
              max={1}
            />
            <FormErrorMessage>{errors?.shade?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.labDipRef} isRequired={true}>
            <FormLabel mt="15px">Lab dip reference</FormLabel>
            <Input {...register('labDipRef')} placeholder="Lab dip reference" required />
            <FormErrorMessage>{errors?.labDipRef?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.clientRefNo} isRequired={true}>
            <FormLabel mt="15px">Client reference number</FormLabel>
            <Input {...register('clientRefNo')} placeholder="Client reference number" required />
            <FormErrorMessage>{errors?.clientRefNo?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.remarks} isRequired={true}>
            <FormLabel mt="15px">Remarks</FormLabel>
            <Textarea {...register('remarks')} placeholder="Additional Remarks" required />
            <FormErrorMessage>{errors?.remarks?.message}</FormErrorMessage>
          </FormControl>
          <Button
            isLoading={loading}
            w="100%"
            type="submit"
            colorScheme="facebook"
            mt="15px"
            onClick={() => setShowAlert(true)}
          >
            ADD NEW ORDER
          </Button>
        </Flex>
      </Box>
      <Box ml="30px">
        <FcBulkUpload />
      </Box>
    </Flex>
  );
};

export default FcCreationForm;
