import {
  ADD_NEW_MACHINE,
  GET_ALL_MACHINE,
  GET_MACHINE_LOGS,
  MARK_MACHINE_AS_OOO,
  UPDATE_MACHINE_INFO,
  UPDATE_MACHINE_LOG,
} from '../actions/action-types';

const initState = {
  machines: [],
  logs: [],
};

const machineReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_ALL_MACHINE:
      return {
        ...state,
        machines: action.payload,
      };
    case ADD_NEW_MACHINE:
      return {
        ...state,
        machines: [...state.machines, action.payload],
      };
    case UPDATE_MACHINE_INFO:
      return {
        ...state,
        machines: state.machines.map((machine) =>
          machine.name === action.payload.name ? action.payload.machine : machine,
        ),
      };
    case MARK_MACHINE_AS_OOO:
      return {
        ...state,
        machines: state.machines.map((machine) =>
          machine.name === action.payload ? { ...machine, status: 'OUT OF ORDER' } : machine,
        ),
      };
    case GET_MACHINE_LOGS:
      return {
        ...state,
        logs: action.payload,
      };
    case UPDATE_MACHINE_LOG:
      return {
        ...state,
        logs: state.logs.map((log) =>
          log.logId === action.payload.logId ? { ...log, ...action.payload.data } : log,
        ),
      };

    case 'UPDATE_QR_ID':
      return {
        ...state,
        machines: state.machines.map((machine) =>
          machine.name === action.payload.name
            ? { ...machine, qrId: action.payload.qrId }
            : machine,
        ),
      };
    default:
      return state;
  }
};

export default machineReducer;
