/* eslint-disable react/no-children-prop */
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  PinInput,
  PinInputField,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import Countdown from 'react-countdown';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { createNewUser, verifyOtp } from '../../api/user';
import toastFormat from '../../constatnts/toastFormat';
import { GET_ALL_USER } from '../../store/actions/action-types';
import { mapStatusToColor } from '../../utilities/mapStatusToColor';
import {
  userCreationFormValidator,
  useYupValidationResolver,
} from '../../validators/form-validators';
import AlertBox from '../common/AlertBox';
import Tick from '../../assets/images/tick.svg';
import Cross from '../../assets/images/cross.svg';
import ONLINE from '../../assets/images/online.svg';
import OFFLINE from '../../assets/images/offline.svg';

export const UserCreationFrom = ({ onClose }) => {
  const resolver = useYupValidationResolver(userCreationFormValidator);
  const [showAlert, setShowAlert] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isValid },
  } = useForm({
    resolver,
    mode: 'onChange',
  });

  const [roles, setRoles] = useState([]);
  const toast = useToast();
  const [shouldGetPinInput, setShouldGetPinInput] = useState(false);
  const [tempInfo, setTempInfo] = useState();
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const addRole = (role) => {
    if (roles.includes(role)) {
      toast({
        title: `Role "${role}" already added`,
        status: 'warning',
        ...toastFormat,
      });
    } else {
      setRoles([...roles, role]);
    }
  };
  const refineData = (data) => {
    return data.map((user) => {
      return {
        ...user,
        rdUsername: (
          <Flex>
            <Text>{user.username}</Text>
            {user.isVerified ? (
              <Image src={Tick} width={5} ml={2} />
            ) : (
              <Image src={Cross} width={5} ml={2} />
            )}
          </Flex>
        ),

        rdContact: (
          <Tooltip label={user.email}>
            <Text>{user.phone}</Text>
          </Tooltip>
        ),

        rdOnline: (
          <Flex>
            {user.online ? (
              <Image src={ONLINE} width={3} ml={2} />
            ) : (
              <Image src={OFFLINE} width={3} ml={2} />
            )}
            <Badge ml={3} colorScheme={mapStatusToColor(user.battery)}>
              {user.battery}
            </Badge>
          </Flex>
        ),
      };
    });
  };

  const submit = async (data) => {
    try {
      if (isValid && roles.length > 0) {
        if (
          (roles.includes('ADMIN') && !data.email) ||
          (roles.includes('BM') && !data.email) ||
          (roles.includes('MANAGER') && !data.email) ||
          (roles.includes('SUPERVISOR') && !data.email)
        ) {
          setError(
            'email',
            { type: 'required', message: 'Admin user must have email' },
            { shouldFocus: true },
          );
          setShowAlert(false);
        } else {
          let rls = '';
          roles.forEach((rl) => {
            rls = rls + rl + ',';
          });
          const insertData = { ...data, roles: rls };
          const { created, sessionId, status } = await createNewUser(insertData);
          if (status === 200) {
            setTempInfo({ created, sessionId });
            setShowAlert(false);
            setShouldGetPinInput(true);
            toast({
              title: 'User created, please verify with OTP',
              status: 'info',
              ...toastFormat,
            });
          } else {
            setShowAlert(false);
            setError(
              'phone',
              { type: 'required', message: 'Possible duplicate' },
              { shouldFocus: true },
            );
            setError(
              'username',
              { type: 'required', message: 'Possible duplicate' },
              { shouldFocus: true },
            );
            toast({
              title: 'Failed to create user',
              status: 'error',
              ...toastFormat,
            });
          }
        }
      } else {
        toast({
          title: 'Invalid user data',
          status: 'warning',
          ...toastFormat,
        });
        setShowAlert(false);
      }
    } catch (error) {
      setError('phone', { type: 'required', message: 'Possible duplicate' }, { shouldFocus: true });
      setError(
        'username',
        { type: 'required', message: 'Possible duplicate' },
        { shouldFocus: true },
      );
      toast({
        title: 'Failed to create user',
        status: 'error',
        ...toastFormat,
      });
    }
  };
  const handleOtp = async (otp) => {
    try {
      const { users } = await verifyOtp(
        user.username,
        tempInfo.created,
        otp,
        user.phone,
        tempInfo.sessionId,
      );

      dispatch({
        type: GET_ALL_USER,
        payload: refineData(users),
      });
      toast({
        title: 'User creation verified',
        status: 'success',
        ...toastFormat,
      });
      onClose();
    } catch (error) {
      toast({
        title: 'Failed to verify otp',
        status: 'error',
        ...toastFormat,
      });
    }
  };

  const [otpValid, setOtpValid] = useState(true);
  return (
    <Box>
      <AlertBox
        onSubmit={() => handleSubmit(submit)()}
        onClose={() => setShowAlert(false)}
        show={showAlert}
        title="Add new user"
        subTitle="Are you sure? You want to add new user"
      />
      <Flex width="100%" direction="column" align="center" justify="center">
        {shouldGetPinInput ? (
          <>
            <Text textAlign="center">
              Please enter OTP sent to your verified <br /> Email to complete user creation
            </Text>
            <HStack margin="30px 0">
              <PinInput otp size="lg" mask onComplete={handleOtp} isDisabled={!otpValid}>
                <PinInputField size="lg" />
                <PinInputField size="lg" />
                <PinInputField size="lg" />
                <PinInputField size="lg" />
                <PinInputField size="lg" />
                <PinInputField size="lg" />
              </PinInput>
            </HStack>
            {otpValid && (
              <Countdown
                zeroPadTime={2}
                date={Date.now() + 240000}
                onComplete={() => {
                  setOtpValid(false);
                  toast({
                    title: 'OTP is expired',
                    status: 'warning',
                    ...toastFormat,
                  });
                }}
                renderer={({ minutes, seconds }) => (
                  <Flex>
                    <Text mb={15} fontSize={16} color="gray.600" fontWeight="bold">
                      Valid till{' '}
                    </Text>
                    <Text fontWeight="bold" ml={15} color="red.400">
                      {minutes}:{seconds}
                    </Text>
                  </Flex>
                )}
              />
            )}
            {!otpValid && (
              <Flex width="100%">
                <Button colorScheme="red" onClick={onClose} width="100%">
                  Close
                </Button>
              </Flex>
            )}
          </>
        ) : (
          <form>
            <FormControl
              my="10px"
              width="100%"
              isRequired={true}
              isInvalid={!!errors?.name}
              isTruncated={true}
            >
              <FormLabel>Name</FormLabel>
              <Input
                {...register('name')}
                maxLength={30}
                required
                placeholder="Name of the user"
                width="300px"
              />
              <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
            </FormControl>
            <FormControl my="10px" width="100%" isRequired={true} isInvalid={!!errors?.username}>
              <FormLabel>Username</FormLabel>
              <Input
                maxLength={10}
                {...register('username')}
                required
                placeholder="Username - ie - name"
                width="300px"
              />
              <FormErrorMessage>{errors?.username?.message}</FormErrorMessage>
            </FormControl>
            <FormControl my="10px" width="100%" isRequired={true} isInvalid={!!errors?.password}>
              <FormLabel>Password</FormLabel>
              <Input
                {...register('password')}
                required
                placeholder="Password - 6 to 10 character"
                width="300px"
                type="password"
                maxLength={10}
                minLength={6}
              />
              <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
            </FormControl>
            <FormControl my="10px" width="100%" isInvalid={!!errors?.email}>
              <FormLabel>Email</FormLabel>
              <Input
                {...register('email')}
                placeholder="User email (optional)"
                width="300px"
                required={roles.includes('ADMIN')}
              />
              <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
            </FormControl>
            <FormControl my="10px" width="100%" isRequired={true} isInvalid={!!errors?.phone}>
              <FormLabel>Phone</FormLabel>
              <InputGroup width="300px">
                <InputLeftAddon children="+88" />
                <Input
                  {...register('phone')}
                  required
                  placeholder="Phone number  - ie - 017***"
                  width="300px"
                  type="tel"
                  minLength={11}
                  maxLength={11}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.phone?.message}</FormErrorMessage>
            </FormControl>
            <FormControl my="10px" width="100%">
              <FormLabel mb="15px">
                Roles{' '}
                <Grid
                  gridTemplateColumns="repeat(3, minmax(80px, max-content))"
                  w="300px"
                  borderRadius="8px"
                  border="1px solid rgba(0,0,0,0.1)"
                  margin="10px 0"
                  boxSizing="border-box"
                  padding="5px"
                >
                  {roles.length > 0 ? (
                    roles.map((role) => (
                      <Tag
                        key={role}
                        mr="5px"
                        my="5px"
                        colorScheme={mapStatusToColor(role)}
                        w="max-content"
                      >
                        <TagLabel>{role}</TagLabel>
                        <TagCloseButton
                          onClick={() => {
                            if (roles.length > 1) {
                              const filteredSelectedRoles = roles.filter((rl) => rl !== role);
                              setRoles(filteredSelectedRoles);
                            } else {
                              toast({
                                title: 'Must add atleast one role',
                                status: 'info',
                                ...toastFormat,
                              });
                            }
                          }}
                        />
                      </Tag>
                    ))
                  ) : (
                    <Text textColor="red" fontSize="xs" fontWeight="light">
                      Must add atleast one role
                    </Text>
                  )}
                </Grid>
              </FormLabel>
              <Menu>
                <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                  Add Role{' '}
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={() => addRole('ADMIN')}>Admin</MenuItem>
                  <MenuItem onClick={() => addRole('AD')}>Accounts & Delivery</MenuItem>
                  <MenuItem onClick={() => addRole('SI')}>Store Incharge</MenuItem>
                  <MenuItem onClick={() => addRole('DPO')}>Dyeing Operator</MenuItem>
                  <MenuItem onClick={() => addRole('FS')}>Finishing Operator</MenuItem>
                  <MenuItem onClick={() => addRole('QCS')}>Quality Supervisor</MenuItem>
                  <MenuItem onClick={() => addRole('BM')}>Batch Manager</MenuItem>
                  <MenuItem onClick={() => addRole('MANAGER')}>Manager</MenuItem>
                  <MenuItem onClick={() => addRole('SUPERVISOR')}>Supervisor</MenuItem>
                  <MenuItem onClick={() => addRole('DI')}>Delivery Inchange</MenuItem>
                </MenuList>
              </Menu>
            </FormControl>
            <Button
              type="submit"
              mt="15px"
              colorScheme="facebook"
              size="lg"
              width="300px"
              onClick={(e) => {
                e.preventDefault();
                setShowAlert(true);
              }}
            >
              ADD NEW
            </Button>
          </form>
        )}
      </Flex>
    </Box>
  );
};
