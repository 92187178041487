import React, { useEffect } from 'react';
import { Box, Grid } from '@chakra-ui/react';
import BatchCards from '../components/dashboard/BatchCards';
import StatCard from '../components/dashboard/StatCard';
import { useState } from 'react';
import { basicStat } from '../api/stats';
import { DASHBOARD_REFRESH_TIME } from '../constatnts/time';
import Loader from '../components/common/Loader';

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [stats, setStats] = useState([]);

  const fetchStats = async () => {
    setIsLoading(true);
    const stats = await basicStat();
    setStats(stats);
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      await fetchStats();
    })();

    const interval = setInterval(async () => await fetchStats(), DASHBOARD_REFRESH_TIME);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box w="100%" boxSizing="border-box" padding="15px">
      <Grid
        gridTemplateColumns={{
          base: '1fr',
          md: '1fr',
          lg: 'repeat(7, 1fr)',
        }}
        gap="20px"
      >
        {isLoading ? (
          <Loader />
        ) : (
          stats?.map((item) => (
            <StatCard
              title={item.title}
              stat={item.count}
              bgColor={item.bgColor}
              iconName={item.iconName}
              key={item.title}
            />
          ))
        )}
        <BatchCards />
      </Grid>
    </Box>
  );
};

export default Dashboard;
