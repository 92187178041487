import { Button, IconButton } from '@chakra-ui/button';
import { AddIcon, EditIcon, ViewIcon } from '@chakra-ui/icons';
import { Box, Flex, Heading, Text, Badge, Grid, GridItem } from '@chakra-ui/layout';
import { useToast } from '@chakra-ui/toast';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getAllBatch } from '../../api/batch';
import CustomTable2 from '../../components/common/CustomTable2';
import { BATCHES, BATCH_TEMPLATE_UPDATE, NEW_BATCH } from '../../constatnts/routerUrls';
import { GET_ALL_BATCHES } from '../../store/actions/action-types';
import dayjs from 'dayjs';
import toastFormat from '../../constatnts/toastFormat';

const UpdateAction = ({ data }) => {
  const history = useHistory();
  if (data.batchStatus === 'DRAFT') {
    return (
      <IconButton
        size="sm"
        colorScheme="green"
        icon={<EditIcon />}
        onClick={() => {
          history.push(BATCH_TEMPLATE_UPDATE + `/${data.lotNumber}`);
        }}
      />
    );
  } else {
    return (
      <IconButton
        icon={<ViewIcon />}
        size="sm"
        colorScheme="green"
        onClick={() => history.push(BATCHES + `/${data.lotNumber}`)}
      />
    );
  }
};
const Batch = () => {
  const toast = useToast();
  const [entries, setEntries] = useState();
  const [stats, setStats] = useState([]);
  const batches = useSelector((state) => state.batch);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const calculateEstDuration = (tasks) => {
    const modifiedTasks = [...tasks.DYING, ...tasks.FINISHING, ...tasks.QC];

    let totalTime = 0;

    modifiedTasks.map((task) => {
      if (task.status !== 'DONE') {
        const minute = task.standardTimeMinute ? task.standardTimeMinute / 60 : 0;
        const hour = task.standardTimeHour ? task.standardTimeHour : 0;

        totalTime = totalTime + hour + minute;
      }
    });

    if (totalTime < 1) {
      return Math.ceil(totalTime * 60) + ' minutes';
    } else {
      const minutes = totalTime - Math.floor(totalTime);
      if (minutes > 0) {
        return (totalTime - minutes).toString() + ' hour ' + Math.ceil(minutes * 60) + ' minutes ';
      } else {
        return totalTime.toString() + ' hour';
      }
    }
  };

  const formatData = (data) => {
    let formattedData = [];
    data.map((item) => {
      const date = new Date(
        item.loadDate.substring(0, 4),
        item.loadDate.substring(5, 7) - 1,
        item.loadDate.substring(8, 10),
        item.loadTime.substring(0, 2),
        item.loadTime.substring(3, 5),
      );

      formattedData.push({
        ...item,

        estDuration: item.tasks ? calculateEstDuration(item.tasks) : 0,

        fdColor: item.fabricInfo[0]?.color,
        fdShade: item.fabricInfo[0]?.shade,

        fdLotNumber: item.lotNumber.split('-')[1],
        fdLoadTime: dayjs(date).format('DD/MMM/YYYY hh:mm A'),
        fdProcessType: <Badge>{item.batchProcessType}</Badge>,
      });
    });

    return formattedData;
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const { status, batches, totalEntries, stats } = await getAllBatch();
      if (status === 200) {
        dispatch({
          type: GET_ALL_BATCHES,
          payload: formatData(batches),
        });
        setEntries(totalEntries);
        setStats(stats);
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      toast({
        title: 'Failed to get batches',
        status: 'error',
        ...toastFormat,
      });
      setIsLoading(false);
    }
  };

  const handleRefresh = async () => {
    try {
      setIsLoading(true);
      const { status, batches, totalEntries } = await getAllBatch();
      if (status === 200) {
        dispatch({
          type: GET_ALL_BATCHES,
          payload: formatData(batches),
        });
        setEntries(totalEntries);
      }
      setIsLoading(false);
    } catch (error) {
      toast({
        title: 'Failed to get batches',
        duration: 5000,
        ...toastFormat,
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();

    let interval = setInterval(async () => await fetchData(), 600000);
    return () => clearInterval(interval);
  }, []);

  const handleQuery = async (data) => {
    try {
      setIsLoading(true);
      const { status, batches, totalEntries } = await getAllBatch(data);
      if (status === 200) {
        dispatch({
          type: GET_ALL_BATCHES,
          payload: formatData(batches),
        });
        setEntries(totalEntries);
      }
      setIsLoading(false);
    } catch (error) {
      toast({
        title: 'Failed to get batches',
        duration: 5000,
        ...toastFormat,
      });
      setIsLoading(false);
    }
  };

  const columns = [
    {
      header: 'lot',
      key: 'fdLotNumber',
      type: 'TAG',
    },
    {
      header: 'FC',
      key: 'fcNumber',
      type: 'TEXT',
    },
    {
      header: 'Party',
      key: 'partyName',
      type: 'TOOLTIP',
    },
    {
      header: 'Machine',
      key: 'machineName',
    },
    {
      header: 'weight (kg)',
      key: 'totalFabricWeight',
    },
    {
      header: 'Color',
      key: 'fdColor',
      type: 'TOOLTIP',
    },
    {
      header: 'Shade (%)',
      key: 'fdShade',
    },
    {
      header: 'Status',
      key: 'batchStatus',
      type: 'TAG',
    },
    {
      header: 'process type',
      key: 'fdProcessType',
      type: 'TEXT',
    },
    {
      header: 'Loading Time',
      key: 'fdLoadTime',
      type: 'TEXT',
    },
    {
      header: 'Progress',
      key: 'progress',
      type: 'PROGRESS',
    },
  ];

  const filters = [
    {
      name: 'Search',
      type: 'INPUT',
      queryParamKey: 'searchKey',
    },

    {
      name: 'Batch Status',
      type: 'SELECT',
      queryParamKey: 'batchStatus',
      options: [
        { value: 'IS PAUSED', label: 'Paused' },
        { value: 'REJECTED', label: 'Rejected' },
        { value: 'DELIVERED', label: 'Delivered' },
        { value: 'ON HOLD', label: 'On Hold' },
        { value: 'IN QUEUE', label: 'In Queue' },
        { value: 'STARTED', label: 'Started' },
        { value: 'READY FOR DELIVERY', label: 'Ready for delivery' },
        { value: 'WAITING FOR DELIVERY', label: 'Waiting for delivery' },
        { value: 'DRAFT', label: 'In Draft' },
        { value: 'WAITING FOR APPROVAL', label: 'Waiting For Approval' },
      ],
    },
    {
      name: 'Start Date',
      queryParamKey: 'startDate',
      type: 'DATE',
    },
    {
      name: 'End Date',
      queryParamKey: 'endDate',
      type: 'DATE',
    },
  ];

  const history = useHistory();

  return (
    <Box w="100%" boxSizing="border-box" p="15px">
      <Flex align="center" justify="space-between">
        <Heading>Batches</Heading>
        <Box>
          <Button
            rightIcon={<AddIcon />}
            colorScheme="green"
            ml="10px"
            onClick={() => history.push(NEW_BATCH)}
            size="sm">
            CREATE BATCH
          </Button>
        </Box>
      </Flex>
      <Grid gridTemplateColumns="repeat(4, 1fr)" gap="5px" w="500px" my="15px">
        {stats?.map((item) => (
          <GridItem
            key={item.title}
            w="100%"
            padding="10px"
            rounded="lg"
            bgColor="white"
            border="1px solid rgba(0,0,0,0.2)">
            <Text fontSize="xs" fontWeight="bold" color="gray.600">
              {item.title}
            </Text>
            <Text fontSize="2xl" fontWeight="bold" color={`${item.color}`}>
              {item.total}
            </Text>
          </GridItem>
        ))}
      </Grid>

      <Box mt="15px">
        <CustomTable2
          data={batches}
          entries={entries}
          columns={columns}
          filters={filters}
          Actions={[UpdateAction]}
          queryCallback={handleQuery}
          refreshCallback={handleRefresh}
          exportEnable={true}
          loading={isLoading}
          exportCols={[
            'lotNumber',
            'fcNumber',
            'partyName',
            'machineName',
            'totalFabricWeight',
            'batchProcessType',
            'batchType',
            'progress',
            'batchStatus',
            'batchPhase',
            'currentTaskName',
            'color',
            'shade',
            'loadingTime',
          ]}
          exportTitle="batches"
          exportDataFetch={getAllBatch}
        />
      </Box>
    </Box>
  );
};

export default Batch;
