import { Box, Grid, GridItem, Text } from '@chakra-ui/react';
import React from 'react';
import QRCode from 'react-qr-code';

const UserQrCodes = React.forwardRef((props, ref) => {
  const { users } = props;

  return (
    <Grid
      gridTemplateColumns="repeat(4, 150px)"
      gap="25px"
      ref={ref}
      w="100%"
      boxSizing="border-box"
      p="15px">
      {users?.map((item) => (
        <>
          <GridItem key={item.username} my="15px">
            <Box>
              <QRCode value={`${item.username}-${item.roles}`} size={150} />
              <Text textAlign="center" mt="10px">
                {item.username}
              </Text>
              <Text textAlign="center" mt="10px">
                {item.name}
              </Text>
            </Box>
          </GridItem>
        </>
      ))}
    </Grid>
  );
});

UserQrCodes.displayName = 'User QR codes';

export default UserQrCodes;
