import { GET_ALL_USER, MARK_USER_AS_BANNED, UPDATE_USER_DATA } from '../actions/action-types';

const initState = [];

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_ALL_USER:
      return action.payload;
    case MARK_USER_AS_BANNED:
      return state.map((user) =>
        user.uid === action.payload ? { ...user, status: 'BANNED' } : user,
      );
    case UPDATE_USER_DATA:
      return state.map((user) =>
        user.uid === action.payload.uid ? { ...user, ...action.payload.data } : user,
      );
    default:
      return state;
  }
};

export default userReducer;
