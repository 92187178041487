import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Input,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import { ArrowRightIcon, CloseIcon, DownloadIcon } from '@chakra-ui/icons';
import {
  clientProductionHeaders,
  machineProductionHeaders,
  productionHeaders,
} from '../../constatnts/report-headers';
import { useForm } from 'react-hook-form';
import {
  reportGenerationValidators,
  useYupValidationResolver,
} from '../../validators/form-validators';
import { fetchReportData } from '../../api/reports';
import ExcelExport from 'export-xlsx';
import dayjs from 'dayjs';
import SelectField from '../common/SelectField';
import { fetchAllClients } from '../../api/customer';
import { dirtyOption } from '../../constatnts/hook-form-options';
import { useWord } from '../../utilities/hooks/useWord';
import { queryMachine } from '../../api/machine';

const ReportGeneration = () => {
  const resolver = useYupValidationResolver(reportGenerationValidators);

  const {
    formState: { errors },
    setError,
    handleSubmit,
    register,
    control,
    setValue,
    watch,
  } = useForm({ resolver, reValidateMode: 'onChange', mode: 'onChange' });

  const location = useLocation();
  const {
    state: { type },
  } = location;

  const [headerOptions, setHeaderOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [allClients, setAllClients] = useState([]);
  const [allMachines, setAllMachines] = useState([]);

  const [reportData, setReportData] = useState();

  const setListElement = () => {
    if (type === 'production') {
      setHeaderOptions(productionHeaders);
      setSelectedOptions([]);
    }
    if (type === 'client-production') {
      setHeaderOptions(clientProductionHeaders);
      setSelectedOptions([]);
    }
    if (type === 'machine-production') {
      setHeaderOptions(machineProductionHeaders);
      setSelectedOptions([]);
    }
  };

  useEffect(() => {
    setListElement();
  }, [type]);

  useEffect(() => {
    (async () => {
      const clients = await fetchAllClients();
      setAllClients(clients);
    })();
  }, [type]);

  const onSelectAll = () => {
    if (type === 'production') {
      setSelectedOptions(productionHeaders);
      setHeaderOptions([]);
    }
    if (type === 'client-production') {
      setSelectedOptions(clientProductionHeaders);
      setHeaderOptions([]);
    }
    if (type === 'machine-production') {
      setSelectedOptions(machineProductionHeaders);
      setHeaderOptions([]);
    }
  };

  const onClearAll = () => {
    if (type === 'production') {
      setSelectedOptions([]);
      setHeaderOptions(productionHeaders);
    }
    if (type === 'client-production') {
      setSelectedOptions([]);
      setHeaderOptions(clientProductionHeaders);
    }
    if (type === 'machine-production') {
      setSelectedOptions([]);
      setHeaderOptions(machineProductionHeaders);
    }
  };

  const onSelect = (value) => {
    const selectedItem = headerOptions.find((item) => item.value === value);
    setSelectedOptions([...selectedOptions, selectedItem]);
    const restHeaderOptions = headerOptions.filter((item) => item.value !== value);
    setHeaderOptions(restHeaderOptions);
  };

  const onDeselect = (value) => {
    const selectedItem = selectedOptions.find((item) => item.value === value);
    setHeaderOptions([...headerOptions, selectedItem]);
    const restSelectedOption = selectedOptions.filter((item) => item.value !== value);
    setSelectedOptions(restSelectedOption);
  };

  const handleReportGeneration = async (data) => {
    if (data.startDate > data.endDate) {
      setError('endDate', { type: 'pattern', message: 'End date cannot be before start date' });
    }

    const fields =
      selectedOptions.length > 0
        ? selectedOptions.map((item) => item.value)
        : headerOptions.map((item) => item.value);

    const reportOptions = {
      type,
      fields,
      startDate: data.startDate,
      endDate: data.endDate,
    };

    let fetchedData;

    if (type === 'production') {
      fetchedData = await fetchReportData(reportOptions);
    }
    if (type === 'client-production') {
      fetchedData = await fetchReportData({ ...reportOptions, partyName: data.partyName });
    }
    if (type === 'machine-production') {
      fetchedData = await fetchReportData({ ...reportOptions, name: data.name });
    }
    if (type === 'po-production') {
      fetchedData = await fetchReportData(reportOptions);
    }

    setReportData(fetchedData?.reportData);
  };

  const RenderHeader = () => {
    return (
      <Thead>
        {reportData &&
          Object.entries(reportData[0]).map((val) => {
            if (val[0] === 'quantity') {
              return <Th key={JSON.stringify(val)}>{val[0]} (Kg)</Th>;
            }
            if (val[0] === 'shade') {
              return <Th key={JSON.stringify(val)}>Shade (%)</Th>;
            }
            if (val[0] === 'processed') {
              return <Th key={JSON.stringify(val)}>Processed (Kg)</Th>;
            }
            if (val[0] === 'createdAt') {
              return <Th key={JSON.stringify(val)}>Date</Th>;
            }
            if (val[0] === 'utilization') {
              return <Th key={JSON.stringify(val)}>Utilization (%)</Th>;
            }
            if (val[0] === 'maxCapacity') {
              return <Th key={JSON.stringify(val)}>Max Capacity (Kg)</Th>;
            }
            if (val[0] === 'minCapacity') {
              return <Th key={JSON.stringify(val)}>Min Capacity (Kg)</Th>;
            }
            if (val[0] === 'capacity') {
              return <Th key={JSON.stringify(val)}>Capacity (Kg)</Th>;
            }
            if (val[0] === 'totalTimeRun') {
              return <Th key={JSON.stringify(val)}>Total Run Time (h)</Th>;
            }
            if (val[0] === 'idleTime') {
              return <Th key={JSON.stringify(val)}>Idle Time (h)</Th>;
            }
            if (val[0] === 'totalFabricProcessed') {
              return <Th key={JSON.stringify(val)}>Fabric Processed (Kg)</Th>;
            }
            if (val[0] === 'totalHourWorked') {
              return <Th key={JSON.stringify(val)}>Hours Worked (h)</Th>;
            } else {
              return <Th key={JSON.stringify(val)}>{useWord(val[0])}</Th>;
            }
          })}
      </Thead>
    );
  };

  const RenderBody = () => {
    return (
      <Tbody>
        {reportData?.map((item) => (
          <Tr key={JSON.stringify(item)}>
            {Object.entries(item).map((val) => (
              <Th key={JSON.stringify(val)}>{val[1]}</Th>
            ))}
          </Tr>
        ))}
      </Tbody>
    );
  };

  const generateXlHeaders = () => {
    const defs = [];

    Object.entries(reportData[0]).map((val) =>
      defs.push({
        name: useWord(val[0]),
        key: val[0],
      }),
    );
    return defs;
  };

  const parseAndDownloadXl = () => {
    if (reportData) {
      const xlExport = new ExcelExport();

      const SHEET_CONFIG = {
        fileName: type + '-report-' + dayjs(new Date()).format('DD-MMM-YYYY'),
        workSheets: [
          {
            sheetName: type + ' report',
            startingRowNumber: 1,
            tableSettings: {
              data: {
                importable: true,
                headerDefinition: generateXlHeaders(),
              },
            },
          },
        ],
      };

      xlExport.downloadExcel(SHEET_CONFIG, [{ data: reportData }]);
    }
  };

  const handlePartyNameSelection = (data) => {
    const { partyName } = data;
    setValue('partyName', partyName, { ...dirtyOption });
  };

  const handleMachineNameSelection = (data) => {
    const { name } = data;
    setValue('name', name, { ...dirtyOption });
  };

  useEffect(() => {
    (async () => {
      const machineData = await queryMachine({ name: watch().name });
      setAllMachines(machineData.machines);
    })();
  }, [type]);

  const RenderGenerateReportForm = () => {
    switch (type) {
      case 'production':
        return (
          <VStack align="end" w="100%" spacing={5}>
            <HStack mt="15px" w="100%">
              <FormControl isInvalid={errors?.startDate}>
                <FormLabel>Start Date</FormLabel>
                <Input {...register('startDate')} type="date" />
                <FormErrorMessage>{errors?.startDate?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.endDate}>
                <FormLabel>End Date</FormLabel>
                <Input type="date" {...register('endDate')} />
                <FormErrorMessage>{errors?.endDate?.message}</FormErrorMessage>
              </FormControl>
            </HStack>
            <Button size="sm" colorScheme="facebook" onClick={handleSubmit(handleReportGeneration)}>
              GENERATE
            </Button>
          </VStack>
        );

      case 'client-production':
        return (
          <VStack align="end" w="100%" spacing={5}>
            <FormControl mt="15px">
              <FormLabel>Party Name</FormLabel>
              <SelectField
                control={control}
                name="partyName"
                options={allClients?.map((item) => ({
                  label: item.clientName,
                  value: item.clientName,
                  partyName: item.clientName,
                }))}
                handleChange={handlePartyNameSelection}
              />
            </FormControl>
            <HStack mt="5px" w="100%">
              <FormControl isInvalid={errors?.startDate}>
                <FormLabel>Start Date</FormLabel>
                <Input {...register('startDate')} type="date" />
                <FormErrorMessage>{errors?.startDate?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.endDate}>
                <FormLabel>End Date</FormLabel>
                <Input type="date" {...register('endDate')} />
                <FormErrorMessage>{errors?.endDate?.message}</FormErrorMessage>
              </FormControl>
            </HStack>
            <Button size="sm" colorScheme="facebook" onClick={handleSubmit(handleReportGeneration)}>
              GENERATE
            </Button>
          </VStack>
        );

      case 'machine-production':
        return (
          <VStack align="end" w="100%" spacing={5}>
            <FormControl mt="15px">
              <FormLabel>Machine</FormLabel>
              <SelectField
                control={control}
                name="name"
                options={allMachines.map((item) => ({
                  label: item.name,
                  value: item.name,
                  name: item.name,
                }))}
                handleChange={handleMachineNameSelection}
              />
            </FormControl>
            <HStack mt="5px" w="100%">
              <FormControl isInvalid={errors?.startDate}>
                <FormLabel>Start Date</FormLabel>
                <Input {...register('startDate')} type="date" />
                <FormErrorMessage>{errors?.startDate?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.endDate}>
                <FormLabel>End Date</FormLabel>
                <Input type="date" {...register('endDate')} />
                <FormErrorMessage>{errors?.endDate?.message}</FormErrorMessage>
              </FormControl>
            </HStack>
            <Button size="sm" colorScheme="facebook" onClick={handleSubmit(handleReportGeneration)}>
              GENERATE
            </Button>
          </VStack>
        );
      default:
        return (
          <VStack align="end" w="100%" spacing={5}>
            <HStack mt="15px" w="100%">
              <FormControl isInvalid={errors?.startDate}>
                <FormLabel>Start Date</FormLabel>
                <Input {...register('startDate')} type="date" />
                <FormErrorMessage>{errors?.startDate?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.endDate}>
                <FormLabel>End Date</FormLabel>
                <Input type="date" {...register('endDate')} />
                <FormErrorMessage>{errors?.endDate?.message}</FormErrorMessage>
              </FormControl>
            </HStack>
            <Button size="sm" colorScheme="facebook" onClick={handleSubmit(handleReportGeneration)}>
              GENERATE
            </Button>
          </VStack>
        );
    }
  };

  return (
    <Box w="100%" boxSizing="border-box" p="15px">
      <Heading mb="15px" textTransform="capitalize">
        Generate {type} report
      </Heading>

      <HStack gap="20px" align="flex-start">
        {type !== 'po-production' && (
          <>
            <Box
              h="300px"
              overflowY="auto"
              w="200px"
              boxSizing="border-box"
              p="15px"
              border="1px solid rgba(0,0,0,0.2)"
              rounded="md"
              css={{
                '&::-webkit-scrollbar': {
                  width: '4px',
                },
                '&::-webkit-scrollbar-track': {
                  width: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#f2f2f2',
                  borderRadius: '24px',
                },
              }}>
              <HStack mb="15px" w="full" justify="space-between">
                <Text>Headers</Text>
                <Tooltip label="Clear All">
                  <IconButton
                    size="sm"
                    colorScheme="facebook"
                    icon={<ArrowRightIcon />}
                    onClick={onSelectAll}
                  />
                </Tooltip>
              </HStack>
              {headerOptions.map((item) => (
                <Box
                  w="100%"
                  p="10px"
                  boxSizing="border-box"
                  rounded="md"
                  bgColor="gray.100"
                  mb="5px"
                  key={item.value}
                  onClick={() => onSelect(item.value)}>
                  {item.label}
                </Box>
              ))}
            </Box>
            <Box
              h="300px"
              overflowY="auto"
              w="200px"
              boxSizing="border-box"
              p="15px"
              border="1px solid rgba(0,0,0,0.2)"
              rounded="md"
              css={{
                '&::-webkit-scrollbar': {
                  width: '4px',
                },
                '&::-webkit-scrollbar-track': {
                  width: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#f2f2f2',
                  borderRadius: '24px',
                },
              }}>
              <HStack mb="15px" w="full" justify="space-between">
                <Text>Selected Headers</Text>
                <Tooltip label="Clear All">
                  <IconButton
                    size="sm"
                    colorScheme="facebook"
                    icon={<CloseIcon />}
                    onClick={onClearAll}
                  />
                </Tooltip>
              </HStack>
              {selectedOptions.map((item) => (
                <Box
                  w="100%"
                  p="10px"
                  boxSizing="border-box"
                  rounded="md"
                  bgColor="gray.100"
                  mb="5px"
                  key={item.value}
                  onClick={() => onDeselect(item.value)}>
                  {item.label}
                </Box>
              ))}
            </Box>
          </>
        )}
        <Box
          w="500px"
          h="300px"
          boxSizing="border-box"
          p="15px"
          border="1px solid rgba(0,0,0,0.2)"
          rounded="md">
          <Heading fontSize="xl" textTransform="capitalize">
            Generate {type} Report
          </Heading>
          <RenderGenerateReportForm />
        </Box>
      </HStack>
      <Box my="15px">
        <HStack mb="15px" spacing={15}>
          <Heading fontSize="xl">Report Data</Heading>
          <IconButton
            icon={<DownloadIcon />}
            size="sm"
            colorScheme="facebook"
            onClick={parseAndDownloadXl}
          />
        </HStack>
        <Table size="sm">
          {reportData && reportData.length > 0 && (
            <>
              <RenderHeader />
              <RenderBody />
            </>
          )}
        </Table>
      </Box>
    </Box>
  );
};

export default ReportGeneration;
