import { Box, Center } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/spinner';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { getBatchByLotNumber } from '../../api/batch';
import BatchDetailForm from '../../components/batch/BatchDetailForm';
import BatchTemplateForm from '../../components/batch/BatchTemplateForm';
import FabricAndMachineForm from '../../components/batch/FabricAndMachineForm';
import StepperForm from '../../components/common/StepperForm';
import {
  batchDetailFromValidator,
  useYupValidationResolver,
} from '../../validators/form-validators';

const BatchTemplatingFlow = () => {
  const params = useParams();
  const [step, setStep] = useState(2);
  const [loading, setLoading] = useState(false);
  const resolver = useYupValidationResolver(batchDetailFromValidator[step]);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    setError,
    formState: { isValid, errors, isDirty },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      customer: null,
      processType: '',
      lotNumber: params.lotNumber,
      finishingType: '',
      priority: '',
      loadTime: null,
      loadDate: null,
      fabricInfo: [],
      machine: null,
      deliveryDate: null,
      creator: null,
      dyeingOperator: null,
      finishingOperator: null,
      qcOperator: null,
      isWashRequired: false,
      batchType: '',
      tasks: [
        {
          taskType: '',
          formFields: [],
          taskName: '',
          standardTime: null,
          isRecipeRequired: false,
        },
      ],
    },
    resolver,
  });

  const modifyTasks = (tasks) => {
    const allTasks = [...tasks.DYING, ...tasks.FINISHING, ...tasks.QC];
    const modifidTasks = [];

    modifidTasks.push(allTasks[0]);

    let nextTaskUid = allTasks[0].nextTask ? allTasks[0].nextTask.taskUid : null;

    while (nextTaskUid) {
      const task = allTasks.find((item, index) => item.uid === nextTaskUid && index !== 0);
      modifidTasks.push(task);
      nextTaskUid = task.nextTask ? task.nextTask.taskUid : null;
    }

    return modifidTasks;
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const data = await getBatchByLotNumber(params.lotNumber);
        const {
          customerInfo,
          machineInfo,
          fabricInfo,
          processType,
          finishingType,
          loadDate,
          loadTime,
          deliveryDate,
          priority,
          creatorInfo,
          batchProgressInfo,
          finishingOperatorInfo,
          dyeingOperatorInfo,
          qcOperatorInfo,
        } = data.batch;
        setValue('isWashRequired', batchProgressInfo.isWashRequired);
        setValue('isHeatSetRequired', batchProgressInfo.isHeatSetRequired);
        setValue('lotNumber', params.lotNumber.split('-')[1]);
        setValue('customer', customerInfo);
        setValue('machine', machineInfo);
        setValue('fabricInfo', fabricInfo);
        setValue('processType', processType);
        setValue('finishingType', finishingType);
        setValue('priority', priority);
        setValue('loadDate', loadDate);
        setValue('deliveryDate', deliveryDate);
        setValue('loadTime', loadTime);
        setValue('creator', creatorInfo);
        setValue('dyeingOperator', dyeingOperatorInfo);
        setValue('finishingOperator', finishingOperatorInfo);
        setValue('qcOperator', qcOperatorInfo);
        setValue('batchType', data.batch.batchType);
        const modifiedTasks = modifyTasks(batchProgressInfo.tasks);
        setValue('tasks', modifiedTasks);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, []);
  const { fields, remove, append } = useFieldArray({ control, name: 'fabricInfo' });

  const {
    fields: processFields,
    remove: processRemove,
    append: processAppend,
  } = useFieldArray({ control, name: 'tasks' });

  const watchAllField = watch();
  const steps = [
    {
      label: 'Batch Details',
      Form: (
        <BatchDetailForm
          control={control}
          next={setStep}
          errors={errors}
          register={register}
          watchAllField={watchAllField}
          setValue={setValue}
          isValid={isValid}
          isUpdate={true}
          handleSubmit={handleSubmit}
          setError={setError}
        />
      ),
    },
    {
      label: 'Fabric & Machine Info',
      Form: (
        <FabricAndMachineForm
          errors={errors}
          register={register}
          watchAllField={watchAllField}
          setValue={setValue}
          isValid={isValid}
          fields={fields}
          control={control}
          remove={remove}
          append={append}
          update={setStep}
          handleSubmit={handleSubmit}
          isUpdate={true}
          isDirty={isDirty}
        />
      ),
    },
    {
      label: 'Batch Template',
      Form: (
        <BatchTemplateForm
          watchAllField={watchAllField}
          fields={processFields}
          control={control}
          remove={processRemove}
          append={processAppend}
          errors={errors}
          register={register}
          setValue={setValue}
          isValid={isValid}
          handleSubmit={handleSubmit}
          isUpdate={true}
          isDirty={isDirty}
          update={setStep}
        />
      ),
    },
  ];
  return (
    <Box m="30px">
      {loading ? (
        <Center>
          <Spinner color="blue.500" size="xl" />
        </Center>
      ) : (
        <StepperForm steps={steps} currentStep={step} isFull={step === 2 ? true : false} />
      )}
    </Box>
  );
};

export default BatchTemplatingFlow;
