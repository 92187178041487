import { Button, HStack, Stat, StatArrow, StatHelpText, StatNumber, Text } from '@chakra-ui/react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { REPORT_GENERATION } from '../../constatnts/routerUrls';

const ProductionChartFooter = ({ data }) => {
  const history = useHistory();

  return (
    <HStack w="100%" justify="space-between" mt="15px">
      <Stat mb="-15px">
        <StatNumber fontSize="lg">
          Total: {Math.abs(data?.dif)} Kg{' '}
          <Text fontSize="sm" fontWeight="bold" color="gray.600">
            (This {data?.range})
          </Text>
        </StatNumber>
        <StatHelpText>
          <StatArrow type={data?.status} />
          {data?.percent} %
        </StatHelpText>
      </Stat>
      <Button
        size="sm"
        colorScheme="facebook"
        onClick={() => history.push(REPORT_GENERATION, { type: 'production' })}>
        Generate Report
      </Button>
    </HStack>
  );
};

export default ProductionChartFooter;
