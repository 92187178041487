/* eslint-disable no-unused-vars */
import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import React from 'react';
import { Badge, Box, Text } from '@chakra-ui/layout';

const TaskDetailModal = ({ task, isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        {task && (
          <Box padding="15px 20px">
            <Text fontSize="lg" fontWeight="bold">
              {task.taskName} -{' '}
              {task.standardTimeHour && <Badge>{task.standardTimeHour} Hours</Badge>}
              {task.standardTimeMinute && <Badge ml="3px">{task.standardTimeMinute} Minutes</Badge>}
            </Text>
            <Text my="10px">Will take inputs for</Text>
            {task.formFields.map((field, index) => (
              <Text
                mb="10px"
                key={field.label}
                fontSize="sm"
                fontWeight="light"
                textColor="gray.500"
              >
                <Badge mr="10px">{index + 1}.</Badge>
                {field.label}
              </Text>
            ))}
          </Box>
        )}
      </ModalContent>
    </Modal>
  );
};

export default TaskDetailModal;
