import { Button, IconButton } from '@chakra-ui/button';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/form-control';
import { useDisclosure } from '@chakra-ui/hooks';
import { ChevronDownIcon, DeleteIcon, ViewIcon } from '@chakra-ui/icons';
import { Input } from '@chakra-ui/input';
import { Badge, Box, Divider, Flex, Heading, Text, Grid, HStack, VStack } from '@chakra-ui/layout';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Switch,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/toast';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { fetchTemplates, publishBatch, updateBatchWithTaskInfo } from '../../api/batch';
import { fetchAllUserForTemplate } from '../../api/user';
import { dirtyOption } from '../../constatnts/hook-form-options';
import { inputTypes } from '../../constatnts/inputTypes';
import { processTypes } from '../../constatnts/processTypes';
import { BATCHES } from '../../constatnts/routerUrls';
import combineDateTime from '../../utilities/combineDateTime';
import timeFormat from '../../utilities/timeFormat';
import SelectField from '../common/SelectField';
import TaskDetailModal from './TaskDetailModal';

const BatchTemplateForm = ({
  watchAllField,
  fields,
  remove,
  append,
  update,
  setValue,
  handleSubmit,
  errors,
  control,
}) => {
  const [users, setUsers] = useState(null);
  const [shouldSave, setShouldSave] = useState(false);
  const [templateInfo, setTemplateInfo] = useState({
    name: '',
    description: '',
  });

  const [templates, setTemplates] = useState({ all: null, selected: null });
  const [taskViewIndex, setTaskViewIndex] = useState();
  const toast = useToast();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const { data } = await fetchAllUserForTemplate();
      const templateData = await fetchTemplates();
      setTemplates({ ...templates, all: templateData.templates });
      setUsers({ dyo: data.dyer, fio: data.finisher, qco: data.qc });
    })();
  }, []);

  const handleTaskAppend = (data, index) => {
    const { task } = data;
    setValue(`tasks.${index}.formFields`, task.formFields);
    setValue(`tasks.${index}.taskName`, task.name);
  };

  const handleTaskTypeAppend = (index, type) => {
    setValue(`tasks.${index}.taskType`, type, { shouldDirty: true });
  };

  const handleSaveAsDraft = async (data) => {
    try {
      if (data.qcOperator && data.finishingOperator && data.dyeingOperator && data.tasks) {
        const qcId = data.qcOperator.id;
        const fiId = data.finishingOperator.id;
        const dyId = data.dyeingOperator.id;

        data.tasks.map((task) => {
          task.formFields.map((field) => {
            const input = inputTypes.filter((inp) => inp.inputType === field.type)[0];

            field.inputKeyName = input.inputKeyName;
          });
        });
        const tasks = data.tasks;
        const formData = {
          customer: data?.customer,
          dyingOperatorId: dyId,
          finishingOperatorId: fiId,
          qcOperatorId: qcId,
          tasks,
          shouldSave,
          lotNumber: watchAllField.lotNumber,
          templateName: templateInfo.name,
          templateDescription: templateInfo.description,
          isWashRequired: watchAllField.isWashRequired,
          isHeatSetRequired: watchAllField.isHeatSetRequired,
        };

        const response = await updateBatchWithTaskInfo(formData);
        if (response.status === 200) {
          toast({
            title: 'Task added to batch',
            status: 'success',
            duration: 5000,
            position: 'bottom',
            isClosable: true,
          });
          history.push(BATCHES);
        }
      } else {
        // TODO: Handle error feedback
        toast({
          title: 'Invalid Data',
          status: 'error',
          duration: 5000,
          position: 'bottom',
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: 'Failed to add tasks to batch',
        status: 'error',
        duration: 5000,
        position: 'bottom',
        isClosable: true,
      });
    }
  };

  const handlePublish = async (data) => {
    try {
      if (data.qcOperator && data.finishingOperator && data.dyeingOperator && data.tasks) {
        const qcId = data.qcOperator.id;
        const fiId = data.finishingOperator.id;
        const dyId = data.dyeingOperator.id;

        data.tasks.map((task) => {
          task.formFields.map((field) => {
            const input = inputTypes.filter((inp) => inp.inputType === field.type)[0];

            field.inputKeyName = input.inputKeyName;
          });
        });
        const tasks = data.tasks;

        const formData = {
          dyingOperatorId: dyId,
          finishingOperatorId: fiId,
          qcOperatorId: qcId,
          tasks,
          shouldSave,
          customer: data.customer,
          lotNumber: watchAllField.lotNumber,
          templateName: templateInfo.name,
          templateDescription: templateInfo.description,
          isWashRequired: watchAllField.isWashRequired,
          isHeatSetRequired: watchAllField.isHeatSetRequired,
        };
        const { status } = await publishBatch(watchAllField.lotNumber, formData);
        if (status === 200) {
          toast({
            title: 'Batch Published',
            status: 'success',
            position: 'bottom',
            duration: 5000,
            isClosable: true,
          });
          history.push(BATCHES);
        }
      } else {
        toast({
          title: 'Please fill all input',
          status: 'info',
          position: 'bottom',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Failed to publish Batch',
        status: 'error',
        isClosable: true,
        position: 'bottom',
        duration: 5000,
      });
    }
  };

  const handleTemplateLoading = () => {
    const tasks = templates.selected.tasks;
    const modifiedTasks = [...tasks['DYING'], ...tasks['FINISHING'], ...tasks['QC']];
    setValue('tasks', modifiedTasks, { shouldDirty: true, shouldTouch: true });
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const calculateTotalTime = () => {
    let totalTime = 0;

    watchAllField.tasks.map((task) => {
      let hour = task.standardTimeHour && !isNaN(task.standardTimeHour) ? task.standardTimeHour : 0;

      let minutes =
        task.standardTimeMinute && !isNaN(task.standardTimeMinute)
          ? task.standardTimeMinute / 60
          : 0;

      totalTime = totalTime + hour + minutes;
    });

    if (totalTime < 1) {
      return Math.ceil(totalTime * 60) + ' minutes';
    } else {
      const minutes = totalTime - Math.floor(totalTime);
      if (minutes > 0) {
        return (totalTime - minutes).toString() + ' hour ' + Math.ceil(minutes * 60) + ' minutes ';
      } else {
        return totalTime.toString() + ' hour';
      }
    }
  };

  // * Batch Detail drawer controller

  const {
    isOpen: isBatchInfoDrawerOpen,
    onClose: closeBatchInfoDrawer,
    onOpen: openBatchInfoDrawer,
  } = useDisclosure();

  const handleDyingPOSelection = (data) => {
    const { po } = data;
    setValue('dyeingOperator', po, { ...dirtyOption });
  };
  const handleFinishingPOSelection = (data) => {
    const { po } = data;
    setValue('finishingOperator', po, { ...dirtyOption });
  };
  const handleQCPOSelection = (data) => {
    const { po } = data;
    setValue('qcOperator', po, { ...dirtyOption });
  };

  const selectFieldValueFinder = (options, field) => {
    return options?.find((item) => {
      return item?.value?.uid === field?.value?.uid;
    });
  };

  const taskSelectFieldValueFinder = (options, field, index) => {
    if (field.value === 'Fabric Loading') {
      return {
        label: 'Fabric Loading',
        value: 'Fabric Loading',
        task: 'Fabric Loading',
      };
    }
    if (field.value === 'Heat Set') {
      return {
        label: 'Heat Set',
        value: 'Heat Set',
        task: 'Heat Set',
      };
    } else {
      return options?.find((item) => item.value === watchAllField?.tasks[index].taskName);
    }
  };

  return (
    <Flex justify="space-between" mr="10px" direction="column" w="1080px">
      <Drawer isOpen={isBatchInfoDrawerOpen} onClose={closeBatchInfoDrawer}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>Batch Information</DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody>
            <VStack align="flex-start">
              <Heading size="lg"># {watchAllField?.lotNumber}</Heading>
              <Text>
                Party Name <br />
                {watchAllField?.customer?.partyName}
              </Text>
              <Text>
                Machine <br />
                {watchAllField?.machine?.name}
              </Text>
              <Text>
                Priority <br />
                {watchAllField?.priority}
              </Text>
              <Text>
                Finishing Type <br />
                {watchAllField?.finishingType}
              </Text>
              <Text>
                Loading Date <br />
                {watchAllField.loadDate &&
                  watchAllField.loadTime &&
                  combineDateTime(watchAllField?.loadDate, watchAllField?.loadTime)}
              </Text>
              <Text>
                Delivery Date <br />
                {timeFormat(watchAllField?.deliveryDate)}
              </Text>
            </VStack>
            <Heading fontSize="md" my="25px">
              Fabric Information{' '}
            </Heading>
            <VStack>
              {watchAllField?.fabricInfo?.map((item, index) => (
                <Box
                  key={item.id}
                  w="100%"
                  p="5px"
                  bgColor="gray.50"
                  rounded="sm"
                  boxSizing="border-box"
                  mb="10px"
                >
                  <Text fontWeight="bold">
                    {index + 1}. {item.fabricType}
                  </Text>
                  <Text>Color: {item.color}</Text>
                  <Text>
                    Shade: {item.shadeGroup} - {item.shade} %
                  </Text>
                  <Text>DIA: {item.dia}</Text>
                  <Text>GSM: {item.gsm}</Text>
                  <Text>Lab Dip Ref: {item.labDipRef}</Text>
                  <Text fontWeight="bold">
                    Quantity: {item.quantity} ({item.usedQty}) (Kg)
                  </Text>
                </Box>
              ))}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <TaskDetailModal
        task={watchAllField.tasks[taskViewIndex]}
        isOpen={isOpen}
        onClose={onClose}
      />
      <Box w="90%">
        <HStack w="100%" justify="space-between">
          <Heading fontSize="2xl" my="15px">
            Assign PO
          </Heading>
          <Button size="sm" colorScheme="facebook" onClick={openBatchInfoDrawer}>
            View Details
          </Button>
        </HStack>
        <Grid gridTemplateColumns="repeat(3, 1fr)" gap="25px">
          <FormControl isInvalid={errors.dyeingOperator ? true : false}>
            <FormLabel w="100%">Dyeing PO</FormLabel>
            <SelectField
              control={control}
              name="dyeingOperator"
              options={users?.dyo?.map((item) => ({
                label: item.name,
                value: item,
                po: item,
              }))}
              selectFieldValueFinder={selectFieldValueFinder}
              handleChange={handleDyingPOSelection}
            />
            <FormErrorMessage>
              {errors.dyeingOperator ? errors.dyeingOperator.message : ''}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.finishingOperator ? true : false}>
            <FormLabel w="100%">Finishing PO</FormLabel>
            <SelectField
              control={control}
              name="finishingOperator"
              options={users?.fio?.map((item) => ({
                label: item.name,
                value: item,
                po: item,
              }))}
              selectFieldValueFinder={selectFieldValueFinder}
              handleChange={handleFinishingPOSelection}
            />

            <FormErrorMessage ml="15px">
              {errors.finishingOperator ? errors.finishingOperator.message : ''}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.qcOperator}>
            <FormLabel>QC PO</FormLabel>
            <SelectField
              control={control}
              name="qcOperator"
              options={users?.qco?.map((item) => ({
                label: item.name,
                value: item,
                po: item,
              }))}
              selectFieldValueFinder={selectFieldValueFinder}
              handleChange={handleQCPOSelection}
            />
            <FormErrorMessage>{errors?.qcOperator?.message}</FormErrorMessage>
          </FormControl>
        </Grid>
        <Divider my="15px" />
        <Heading fontSize="2xl" mb="15px">
          Load from template
        </Heading>
        {templates.all ? (
          <Flex>
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                {templates.selected ? templates.selected.name : 'Select Template'}
              </MenuButton>
              <MenuList>
                {templates.all.length > 0 ? (
                  templates.all.map((template) => (
                    <MenuItem
                      key={template.uid}
                      onClick={() => setTemplates({ ...templates, selected: template })}
                    >
                      {template.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>No Saved Templates found</MenuItem>
                )}
              </MenuList>
            </Menu>
            {templates.selected && (
              <Button size="sm" ml="15px" colorScheme="facebook" onClick={handleTemplateLoading}>
                Load Template
              </Button>
            )}
          </Flex>
        ) : (
          <Text>No saved templates found</Text>
        )}
      </Box>
      <Box w="85%" mt="20px">
        <Flex justify="space-between" align="center">
          <Heading fontSize="2xl" my="15px">
            Processes
          </Heading>
        </Flex>
        <Table>
          <Thead>
            <Tr>
              <Th>Step #</Th>
              <Th>Task Type</Th>
              <Th>Task</Th>
              <Th>Standard Time</Th>
              <Th>Recipe Required</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {fields.map((_, index) => (
              <Tr key={index}>
                <Td>
                  <Badge colorScheme="orange">Step {index + 1}</Badge>
                </Td>
                <Td>
                  <FormControl
                    w="max-content"
                    isInvalid={errors[`tasks[${index}].taskType`] ? true : false}
                  >
                    <Menu>
                      <MenuButton>
                        <Flex
                          bgColor="blackAlpha.50"
                          rounded="md"
                          padding="7px 12px"
                          justify="space-between"
                          align="center"
                        >
                          <Text fontSize="sm">
                            {watchAllField.tasks[index].taskType.length > 0
                              ? watchAllField.tasks[index].taskType
                              : 'Step Type'}
                          </Text>
                          <ChevronDownIcon ml="5px" />
                        </Flex>
                      </MenuButton>
                      <MenuList>
                        <MenuItem onClick={() => handleTaskTypeAppend(index, 'DYING')}>
                          DYEING
                        </MenuItem>
                        <MenuItem onClick={() => handleTaskTypeAppend(index, 'FINISHING')}>
                          FINISHING
                        </MenuItem>
                        <MenuItem onClick={() => handleTaskTypeAppend(index, 'QC')}>QC</MenuItem>
                      </MenuList>
                    </Menu>
                    <FormErrorMessage>
                      {errors[`tasks[${index}].taskType`]
                        ? errors[`tasks[${index}].taskType`].message
                        : ''}
                    </FormErrorMessage>
                  </FormControl>
                </Td>
                <Td>
                  <FormControl isInvalid={errors[`tasks[${index}].taskName`] ? true : false}>
                    <SelectField
                      control={control}
                      options={processTypes[
                        watchAllField.tasks[index].taskType.length > 0
                          ? watchAllField.tasks[index].taskType
                          : 'DYING'
                      ].map((item) => ({ label: item.name, value: item.name, task: item }))}
                      index={index}
                      name={`tasks[${index}].taskName`}
                      selectFieldValueFinder={taskSelectFieldValueFinder}
                      handleChange={handleTaskAppend}
                      isDisabled={
                        watchAllField.tasks[index].taskName === 'Fabric Loading' ||
                        watchAllField.tasks[index].taskName === 'Heat Set'
                      }
                    />

                    <FormErrorMessage>
                      {errors[`tasks[${index}].taskName`]
                        ? errors[`tasks[${index}].taskName`].message
                        : ''}
                    </FormErrorMessage>
                  </FormControl>
                </Td>
                <Td>
                  <FormControl isInvalid={errors[`tasks[${index}].standardTimeHour`]}>
                    <Input
                      type="number"
                      min={0}
                      max={12}
                      size="sm"
                      value={watchAllField.tasks[index].standardTimeHour}
                      placeholder="Hours"
                      onChange={(e) => {
                        if (e.target.value > 12) {
                          setValue(`tasks.${index}.standardTimeHour`, 12, {
                            shouldDirty: true,
                          });
                        } else {
                          setValue(`tasks.${index}.standardTimeHour`, parseInt(e.target.value), {
                            shouldDirty: true,
                          });
                        }
                      }}
                    />
                    <FormErrorMessage>
                      {errors[`tasks[${index}].standardTimeHour`]?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors[`tasks[${index}].standardTimeMinute`]}>
                    <Input
                      type="number"
                      min={0}
                      max={12}
                      size="sm"
                      value={watchAllField.tasks[index].standardTimeMinute}
                      placeholder="Minutes"
                      onChange={(e) => {
                        if (e.target.value > 59) {
                          setValue(`tasks.${index}.standardTimeMinute`, 59, {
                            shouldDirty: true,
                          });
                        } else {
                          setValue(`tasks.${index}.standardTimeMinute`, parseInt(e.target.value), {
                            shouldDirty: true,
                          });
                        }
                      }}
                    />
                    <FormErrorMessage>
                      {errors[`tasks[${index}].standardTimeMinute`]?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Td>
                <Td>
                  {watchAllField.tasks[index].taskType === 'DYING' && (
                    <FormControl ml="15px">
                      <Switch
                        onChange={(e) =>
                          setValue(`tasks.${index}.isRecipeRequired`, e.target.checked)
                        }
                        defaultValue={
                          watchAllField.tasks[index].isRecipeRequired
                            ? watchAllField.tasks[index].isRecipeRequired
                            : false
                        }
                        isChecked={
                          watchAllField.tasks[index].isRecipeRequired
                            ? watchAllField.tasks[index].isRecipeRequired
                            : false
                        }
                      />
                    </FormControl>
                  )}
                </Td>
                <Td>
                  <Flex>
                    {index !== 0 && watchAllField.tasks[index].taskName !== 'Fabric Loading' && (
                      <IconButton
                        icon={<DeleteIcon />}
                        ml={5}
                        onClick={() => remove(index)}
                        size="sm"
                      />
                    )}
                    {watchAllField.tasks[index].formFields && (
                      <IconButton
                        icon={<ViewIcon />}
                        ml={5}
                        disabled={
                          (watchAllField.tasks[index].standardTimeHour > 0 ||
                            watchAllField.tasks[index].standardTimeMinute > 0) &&
                          watchAllField.tasks[index].taskName.length > 0 &&
                          watchAllField.tasks[index].taskType.length > 0
                            ? false
                            : true
                        }
                        onClick={() => {
                          setTaskViewIndex(index);
                          onOpen();
                        }}
                        size="sm"
                      />
                    )}
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        <Flex align="center" justify="space-between" w="100%" my="20px">
          <Button
            ml="15px"
            colorScheme="gray"
            onClick={() =>
              append({
                taskType: '',
                formFields: [],
                taskName: '',
              })
            }
            size="sm"
            textColor="blue.500"
          >
            Add Another
          </Button>
          <FormControl w="max-content">
            <Flex>
              <FormLabel>Wash Required</FormLabel>
              <Switch
                defaultValue={watchAllField.isWashRequired}
                isChecked={watchAllField.isWashRequired}
                onChange={(e) =>
                  setValue('isWashRequired', e.target.checked, {
                    shouldDirty: true,
                    shouldTouch: true,
                  })
                }
              />
            </Flex>
          </FormControl>
          <FormControl w="max-content">
            <Flex>
              <FormLabel>Heat Set Required</FormLabel>
              <Switch
                defaultValue={watchAllField.isHeatSetRequired}
                isChecked={watchAllField.isHeatSetRequired}
                onChange={(e) =>
                  setValue('isHeatSetRequired', e.target.checked, {
                    shouldDirty: true,
                    shouldTouch: true,
                  })
                }
              />
            </Flex>
          </FormControl>
          <FormControl w="max-content">
            <Flex>
              <FormLabel>Save as template</FormLabel>
              <Switch onChange={(e) => setShouldSave(e.target.checked)} />
            </Flex>
          </FormControl>
        </Flex>
        {shouldSave && (
          <Flex mb="20px">
            <FormControl isInvalid={templateInfo.name.length > 0 ? false : true} isRequired={true}>
              <FormLabel>Template Name</FormLabel>
              <Input
                value={templateInfo.name}
                onChange={(e) => setTemplateInfo({ ...templateInfo, name: e.target.value })}
              />
              <FormErrorMessage>Must enter template name</FormErrorMessage>
            </FormControl>
            <FormControl ml="15px">
              <FormLabel>Template Description</FormLabel>
              <Input
                value={templateInfo.description}
                onChange={(e) => setTemplateInfo({ ...templateInfo, description: e.target.value })}
              />
            </FormControl>
          </Flex>
        )}
        <Flex justify="space-between" mt="25px">
          <Text size="sm">
            Total Duration - {calculateTotalTime()} <br /> (± 30 mins for fabric loading)
          </Text>
          <Flex justify="flex-end">
            <Button mr="15px" size="sm" colorScheme="facebook" onClick={() => update(1)}>
              Previous
            </Button>
            <Button
              mr="15px"
              size="sm"
              colorScheme="facebook"
              onClick={handleSubmit(handleSaveAsDraft)}
            >
              Save as Draft
            </Button>
            <Button size="sm" colorScheme="facebook" onClick={handleSubmit(handlePublish)}>
              Publish
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};

export default BatchTemplateForm;
