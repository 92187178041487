/* eslint-disable no-unused-vars */
import {
  Box,
  Center,
  Grid,
  GridItem,
  Heading,
  HStack,
  Spinner,
  Tag,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchClientExtendedData } from '../../api/clients';
import CustomTable2 from '../../components/common/CustomTable2';
import { ORDER_DETAILS } from '../../constatnts/routerUrls';

const ClientDetails = () => {
  const { clientUid } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [client, setClient] = useState();
  const [orders, setOrders] = useState();
  const [stats, setStats] = useState();
  const [total, setTotal] = useState(0);

  const fetchData = async () => {
    setIsLoading(true);
    const clientData = await fetchClientExtendedData({ uid: clientUid });
    setClient(clientData?.client);
    setOrders(clientData?.orders);
    setStats(clientData?.stats);
    setTotal(clientData?.total);
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => await fetchData())();
  }, []);

  const columns = [
    {
      header: 'fc',
      key: 'fcNumber',
      type: 'TEXT',
    },
    {
      header: 'party',
      key: 'partyName',
    },

    {
      header: 'status',
      key: 'orderStatus',
      type: 'BADGE',
    },
    {
      header: 'delivery date',
      key: 'deliveryDate',
      type: 'DATE',
    },
    {
      header: 'sales person',
      key: 'salesPerson',
    },
    {
      header: 'client contact',
      key: 'clientContactPerson',
    },
  ];

  const filters = [
    {
      name: 'Search',
      type: 'INPUT',
      queryParamKey: 'fcNumber',
    },
  ];

  const handleSearch = async (data) => {
    setIsLoading(true);
    const clientData = await fetchClientExtendedData({ uid: clientUid, ...data });
    setClient(clientData?.client);
    setOrders(clientData?.orders);
    setStats(clientData?.stats);
    setTotal(clientData?.total);
    setIsLoading(false);
  };

  return (
    <Box w="100%" boxSizing="border-box" padding="15px">
      {client && <Heading>{client.clientName}</Heading>}

      <Grid gridTemplateColumns="repeat(4, 1fr)" gap="5px" w="550px" my="15px">
        {stats &&
          stats.map((item) => (
            <GridItem
              key={item.label}
              w="100%"
              padding="10px"
              rounded="lg"
              bgColor="white"
              border="1px solid rgba(0,0,0,0.2)">
              <Text fontSize="xs" fontWeight="bold" color="gray.600">
                {item.label}
              </Text>
              <Text fontSize="2xl" fontWeight="bold" color={item.color}>
                {item.count}
              </Text>
            </GridItem>
          ))}
      </Grid>

      <Heading fontSize="xl" my="15px">
        Orders
      </Heading>
      <Box my="15px">
        {orders && (
          <CustomTable2
            columns={columns}
            data={orders}
            entries={total}
            detailPath={ORDER_DETAILS}
            detailPathParam={'fcNumber'}
            filters={filters}
            queryCallback={handleSearch}
            refreshCallback={fetchData}
          />
        )}
      </Box>
    </Box>
  );
};

export default ClientDetails;
