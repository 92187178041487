import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Menu,
  Avatar,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
  Image,
  Badge,
  Button,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { LOGOUT } from '../store/actions/action-types';
import Notif from '../assets/images/notif.svg';
import NotificationPanel from './NotificationPanel';
import { fetchAllAdminNotification } from '../api/global';
import { logoutUser } from '../api/auth';

const TopNavbar = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const [notifications, setNotifications] = useState([]);

  const {
    onClose: closeNotifPanel,
    onOpen: openNotifPanel,
    isOpen: isNotifPanelOpen,
  } = useDisclosure();

  const makeUrl = (index) => {
    let path = '';
    let pathNames = location.pathname.split('/');

    for (let i = 1; i <= index; i++) {
      path = path + '/' + pathNames[i];
    }
    return path;
  };

  const toast = useToast();
  const fetchNotification = async () => {
    if (isLoggedIn) {
      const notiLen = localStorage.getItem('notificationLen');

      const data = await fetchAllAdminNotification();
      if (data) {
        if (data.length > notiLen) {
          data.map((item) =>
            toast({
              title: `${item.title}`,
              status: 'info',
              position: 'bottom-right',
              isClosable: true,
            }),
          );
        }
        setNotifications(data);
        localStorage.setItem('notificationLen', data.length);
      }
    }
  };

  useEffect(() => {
    (async () => {
      await fetchNotification();
    })();
    const interval = setInterval(async () => {
      await fetchNotification();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const logout = async () => {
    const status = await logoutUser(user.username);

    if (status === 200) {
      dispatch({ type: LOGOUT });
    }
  };

  if (isLoggedIn === false) {
    return <></>;
  } else {
    return (
      <>
        <NotificationPanel
          onClose={closeNotifPanel}
          isOpen={isNotifPanelOpen}
          notifs={notifications}
          dataRefresh={fetchNotification}
        />
        <Box
          width="calc(100% - 70px)"
          height="70px"
          backgroundColor="gray.100"
          zIndex="140"
          position="fixed"
          top="0"
          left="70px"
        >
          <Flex width="100%" height="100%" direction="row" align="center" justify="space-between">
            <Breadcrumb separator=">" ml="30px">
              {location.pathname.split('/').map((link, index) =>
                index === 0 ? (
                  <></>
                ) : index === location.pathname.split('/').length - 1 ? (
                  <BreadcrumbItem key={JSON.stringify({ link, index })}>
                    <BreadcrumbLink>{link.toUpperCase()}</BreadcrumbLink>
                  </BreadcrumbItem>
                ) : (
                  <BreadcrumbItem key={JSON.stringify({ link, index })}>
                    <Link to={{ pathname: makeUrl(index) }}>{link.toUpperCase()}</Link>
                  </BreadcrumbItem>
                ),
              )}
            </Breadcrumb>
            <HStack mr="30px">
              <HStack>
                <Button rightIcon={<Image src={Notif} w="25px" />} onClick={openNotifPanel}>
                  <Badge colorScheme="orange">{notifications.length}</Badge>{' '}
                </Button>
              </HStack>
              <Menu>
                <MenuButton>
                  <Avatar size="md" name={user.name} />
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={logout}>Logout</MenuItem>
                </MenuList>
              </Menu>
            </HStack>
          </Flex>
        </Box>
      </>
    );
  }
};

export default TopNavbar;
