/* eslint-disable no-unused-vars */
import { Badge, Box, Flex, Heading, HStack, Text, Grid, GridItem } from '@chakra-ui/layout';
import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/table';
import React, { useEffect, useRef, useState } from 'react';
import { mapStatusToColor } from '../../utilities/mapStatusToColor';
import Tick from '../../assets/images/tick.svg';
import Cross from '../../assets/images/cross.svg';
import { Tag, TagLabel } from '@chakra-ui/tag';
import { Image } from '@chakra-ui/image';
import { Button, IconButton } from '@chakra-ui/button';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu';
import { ChevronDownIcon, Search2Icon, ViewIcon } from '@chakra-ui/icons';
import { Tooltip } from '@chakra-ui/tooltip';
import { Input } from '@chakra-ui/input';
import RefreshIco from '../RefreshIco';
import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import { useDisclosure } from '@chakra-ui/hooks';
import { useHistory } from 'react-router';
import EMPTYTABLE from '../../assets/images/empty-table.svg';
import { Spinner } from '@chakra-ui/spinner';
import { Progress } from '@chakra-ui/progress';
import CsvExport from '../user/CsvExport';
import timeFormat from '../../utilities/timeFormat';
import { RiQrCodeLine } from 'react-icons/ri';
import QRCode from 'react-qr-code';
import ReactToPrint from 'react-to-print';

const Pagination = ({
  totalEntries,
  queryCallback,
  exportEnable,
  exportTitle,
  exportCols,
  query,
  exportDataFetch,
  qrExportEnable,
  qrParam,
  qrLabel,
}) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [qrDatas, setQrDatas] = useState(null);
  const pageCount = totalEntries / pageSize < 1 ? 1 : totalEntries / pageSize;
  const printRef = useRef();

  const sliceIntoChunks = (arr, chunkSize) => {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  };
  useEffect(() => {
    (async () => {
      if (qrExportEnable) {
        const data = await exportDataFetch({ ...query, isPrint: true });
        const items =
          data && data?.map((item) => ({ [qrParam]: item[qrParam], [qrLabel]: item[qrLabel] }));
        const chunkedQrDatas = sliceIntoChunks(items, 25);

        setQrDatas(chunkedQrDatas);
      }
    })();
  }, [query]);

  return (
    <>
      <Flex direction="row" align="center" justify="flex-end" width="98%" height="70px">
        <Text mr="20px" fontSize="20px" fontWeight="bold">
          Page {page} of {Math.ceil(pageCount)}
        </Text>
        <Button
          isLoading={isLoading}
          colorScheme="facebook"
          mr="15px"
          onClick={async () => {
            setIsLoading(true);
            setPage(page - 1 <= 0 ? page : page - 1);
            await queryCallback({ page: page - 1 <= 0 ? page : page - 1, pageSize, ...query });
            setIsLoading(false);
          }}>
          Previous
        </Button>
        <Button
          isLoading={isLoading}
          colorScheme="teal"
          onClick={async () => {
            setIsLoading(true);
            setPage(page + 1 > Math.ceil(pageCount) ? page : page + 1);
            await queryCallback({
              page: page + 1 > Math.ceil(pageCount) ? page : page + 1,
              pageSize,
              ...query,
            });
            setIsLoading(false);
          }}>
          Next
        </Button>
        <Menu>
          <MenuButton rightIcon={<ChevronDownIcon />} as={Button} ml="15px">
            Show - {pageSize}
          </MenuButton>
          <MenuList>
            <MenuItem
              onClick={async () => {
                setPageSize(10);
                await queryCallback({ page, pageSize: 10 });
              }}>
              10
            </MenuItem>
            <MenuItem
              onClick={async () => {
                setPageSize(15);
                await queryCallback({ page, pageSize: 15 });
              }}>
              15
            </MenuItem>
            <MenuItem
              onClick={async () => {
                setPageSize(20);
                await queryCallback({ page, pageSize: 20 });
              }}>
              20
            </MenuItem>
            <MenuItem
              onClick={async () => {
                setPageSize(25);
                await queryCallback({ page, pageSize: 25 });
              }}>
              25
            </MenuItem>
          </MenuList>
        </Menu>
        {exportEnable && (
          <CsvExport
            title={exportTitle}
            query={exportDataFetch}
            queryParams={query}
            dataCols={exportCols}
          />
        )}
        {qrExportEnable && (
          <Tooltip placement="top" label="Download qr code">
            <Box>
              <ReactToPrint
                trigger={() => <IconButton icon={<RiQrCodeLine size={18} />} ml="5px" />}
                content={() => printRef.current}
              />
            </Box>
          </Tooltip>
        )}
      </Flex>

      <Box display="none">
        <div ref={printRef}>
          {qrDatas &&
            qrDatas?.length > 0 &&
            qrDatas?.map((item, index) => (
              <>
                <Grid w="full" gridTemplateColumns="repeat(5, 1fr)" gap="20px" key={index}>
                  {item?.map((data) => (
                    <GridItem key={data[qrLabel]}>
                      <QRCode value={data[qrParam].toString()} size={130} key={data[qrLabel]} />
                      <Text fontSize="xl" mt="5px">
                        # {data[qrLabel]}
                      </Text>
                    </GridItem>
                  ))}
                </Grid>
                <div className="page-break"></div>
              </>
            ))}
        </div>
      </Box>
    </>
  );
};

const getText = (val) => {
  if (typeof val === 'boolean') {
    return val ? 'Yes' : 'No';
  } else {
    return val;
  }
};

const Inputs = ({ field, setValue, paramVal }) => {
  switch (field.type) {
    case 'SELECT':
      return (
        <Tooltip label={field.name} placement="top">
          <Box mr="10px">
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                bgColor="white"
                border="2px solid #E2E8F0">
                {paramVal && paramVal[field.queryParamKey]?.toString()
                  ? getText(paramVal[field.queryParamKey])
                  : field.name}
              </MenuButton>
              <MenuList zIndex="120">
                {field.options.map((opt) => (
                  <MenuItem
                    key={opt.value}
                    value={opt.value}
                    onClick={() => setValue({ ...paramVal, [field.queryParamKey]: opt.value })}>
                    {opt.label}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </Box>
        </Tooltip>
      );
    case 'INPUT':
      return (
        <Tooltip label={field.name} placement="top">
          <Input
            value={paramVal ? paramVal[field.queryParamKey] : ''}
            mr="10px"
            placeholder={field.name}
            size="md"
            onChange={(e) => setValue({ ...paramVal, [field.queryParamKey]: e.target.value })}
          />
        </Tooltip>
      );
    case 'DATE':
      return (
        <Tooltip label={field.name} placement="top">
          <Input
            mr="15px"
            type="date"
            placeholder={field.name}
            size="md"
            onChange={(e) => setValue({ ...paramVal, [field.queryParamKey]: e.target.value })}
          />
        </Tooltip>
      );
  }
};

const RenderFilter = ({
  showCreateButton,
  createButtonText,
  filters,
  refreshCallback,
  queryCallback,
  CreateNewComponent,
  createModalTitle,
  searchKey,
  setSearchKey,
}) => {
  const [showQueryParam, setShowQueryParam] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Flex mb="15px" align="center" justify="space-between" w="100%">
        <HStack>
          {filters?.map((filter) => (
            <Inputs field={filter} setValue={setSearchKey} key={filter.name} paramVal={searchKey} />
          ))}
          <Tooltip label="Search" placement="top">
            <IconButton
              colorScheme="facebook"
              onClick={async () => {
                await queryCallback(searchKey);
                setShowQueryParam(true);
              }}>
              <Search2Icon />
            </IconButton>
          </Tooltip>
          <Tooltip label="Refresh" placement="top">
            <IconButton
              colorScheme="facebook"
              onClick={async () => {
                await refreshCallback();
                setSearchKey(null);
                setShowQueryParam(false);
              }}>
              <RefreshIco />
            </IconButton>
          </Tooltip>
        </HStack>
        {showCreateButton && (
          <Button colorScheme="facebook" onClick={onOpen}>
            {createButtonText}
          </Button>
        )}
        <Modal isOpen={isOpen} onClose={onClose} size="sm">
          <ModalOverlay />
          <ModalContent>
            <Flex
              width="100%"
              boxSizing="border-box"
              padding="16px"
              direction="column"
              align="center"
              justify="center">
              <Text fontSize="20px" fontWeight="black" color="#272739" textAlign="center" mb="15px">
                {createModalTitle}
              </Text>
              {CreateNewComponent && (
                <CreateNewComponent onClose={onClose} refreshCallback={refreshCallback} />
              )}
            </Flex>
          </ModalContent>
        </Modal>
      </Flex>
      {showQueryParam ? (
        <Text textAlign="left" width="100%" mb="30px" fontWeight="bold">
          Showing search Result for
          {searchKey &&
            Object.entries(searchKey).map((val) => (
              <Badge colorScheme="yellow" fontSize="md" ml="5px" key={val}>
                {getText(val[1])}
              </Badge>
            ))}
        </Text>
      ) : (
        <></>
      )}
    </>
  );
};
const CustomTable2 = ({
  columns,
  data,
  entries,
  queryCallback,
  refreshCallback,
  showCreateButton,
  createButtonText = 'Add new',
  filters,
  CreateNewComponent,
  detailPathParam,
  detailPath,
  Actions,
  loading,
  exportEnable,
  exportTitle,
  exportCols,
  hidePagination,
  exportDataFetch,
  qrExportEnable,
  qrParam,
  qrLabel,
}) => {
  const history = useHistory();
  const [searchKey, setSearchKey] = useState(null);
  const RenderBody = ({ row, index }) => {
    return columns.map((col) => {
      switch (col.type) {
        case 'INCREMENTS':
          return <Td>{index + 1}</Td>;
        case 'BADGE':
          return (
            <Td>
              <Badge colorScheme={mapStatusToColor(row[col.key])}>
                {row[col.key]} {col.suffix}
              </Badge>
            </Td>
          );
        case 'DATE':
          return (
            <Td>
              <Text>{timeFormat(row[col.key])}</Text>
            </Td>
          );
        case 'BOOL':
          return <Td>{row[col.key] ? <Image src={Tick} /> : <Image src={Cross} />}</Td>;
        case 'ARRAY TAG':
          return (
            <Td>
              {row[col.key]
                .split(',')
                .slice(0, row[col.key].split(',').length - 1)
                .map((item) => (
                  <Tag key={item} colorScheme={mapStatusToColor(item)} mr="5px" my="5px">
                    <TagLabel>{item}</TagLabel>
                  </Tag>
                ))}
            </Td>
          );
        case 'TAG':
          return (
            <Td>
              <Tag colorScheme={mapStatusToColor(row[col.key])} mr="5px" my="5px">
                <TagLabel>{row[col.key]}</TagLabel>
              </Tag>
            </Td>
          );
        case 'TOOLTIP':
          return (
            <Td>
              <Tooltip label={row[col.key]}>
                <Text>{row[col.key] && row[col.key].toString().substring(0, 20)}</Text>
              </Tooltip>
            </Td>
          );
        case 'CONDITIONAL BADGE':
          return (
            <Td>
              <Badge colorScheme={mapStatusToColor(row[col.conditionalParam])}>
                {row[col.key]} {col.suffix}
              </Badge>
            </Td>
          );
        case 'PROGRESS':
          return (
            <Td w="200px">
              <Flex align="center">
                <Text mr="15px" fontSize="sm" fontWeight="bold" textColor="gray.800">
                  {row[col.key]} %
                </Text>
                <Progress value={row[col.key]} w="68px" rounded="lg" bgColor="rgba(0,0,0,0.3)" />
              </Flex>
            </Td>
          );
        default:
          return (
            <Td>
              {row[col.key]} {col.suffix}
            </Td>
          );
      }
    });
  };

  return (
    <>
      {filters && (
        <RenderFilter
          showCreateButton={showCreateButton}
          createButtonText={createButtonText}
          filters={filters}
          refreshCallback={refreshCallback}
          queryCallback={queryCallback}
          CreateNewComponent={CreateNewComponent}
          searchKey={searchKey}
          setSearchKey={setSearchKey}
        />
      )}
      <Box
        w="100%"
        shadow="md"
        overflowX="auto"
        maxH="600px"
        css={{
          '&::-webkit-scrollbar': {
            width: '4px',
          },
          '&::-webkit-scrollbar-track': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#f2f2f2',
            borderRadius: '24px',
          },
        }}>
        <Table w="100%" maxH="600px" colorScheme="gray" size="sm" shadow="sm">
          <Thead bgColor="gray.50" shadow="md" position="sticky" zIndex="100" top="0">
            <Tr>
              {columns.map((col, index) => (
                <Th key={index}>{col.header}</Th>
              ))}
              {detailPathParam && <Th>Details</Th>}
              {Actions && <Th>Actions</Th>}
            </Tr>
          </Thead>
          <Tbody>
            {data &&
              data.map((row, rowIndex) => (
                <Tr key={JSON.stringify(row)}>
                  {<RenderBody row={row} key={JSON.stringify(row)} index={rowIndex} />}
                  {detailPathParam && (
                    <Td>
                      <IconButton
                        size="sm"
                        colorScheme="green"
                        onClick={() => history.push(`${detailPath}/${row[detailPathParam]}`)}>
                        <ViewIcon />
                      </IconButton>
                    </Td>
                  )}
                  {Actions && (
                    <Td>
                      <Flex>
                        {Actions.map((Action, index) => (
                          <Action data={row} key={index} refreshCallback={refreshCallback} />
                        ))}
                      </Flex>
                    </Td>
                  )}
                </Tr>
              ))}
          </Tbody>
        </Table>
        {data.length <= 0 ? (
          <Flex
            w="100%"
            h="300px"
            bgColor="gray.200"
            direction="column"
            align="center"
            justify="center">
            {loading ? (
              <Spinner color="blue.500" size="xl" />
            ) : (
              <>
                <Image src={EMPTYTABLE} w="120px" />
                <Heading fontSize="xl" textColor="gray.600">
                  Nothing To Show
                </Heading>
              </>
            )}
          </Flex>
        ) : (
          <></>
        )}
      </Box>
      {hidePagination !== true && (
        <Pagination
          totalEntries={entries}
          queryCallback={queryCallback}
          exportEnable={exportEnable}
          exportTitle={exportTitle}
          data={data}
          exportCols={exportCols}
          query={searchKey}
          exportDataFetch={exportDataFetch}
          qrExportEnable={qrExportEnable}
          qrParam={qrParam}
          qrLabel={qrLabel}
        />
      )}
    </>
  );
};

export default CustomTable2;
