import { GET_ALL_CHEMICALS, GET_ALL_DYE_GROUPS } from '../actions/action-types';

const initState = {
  chemicals: [],
  dyeGroups: [],
};

const chemicalsReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_ALL_CHEMICALS:
      return {
        ...state,
        chemicals: action.payload,
      };
    case GET_ALL_DYE_GROUPS:
      return {
        ...state,
        dyeGroups: action.payload,
      };

    default:
      return state;
  }
};

export default chemicalsReducer;
